import {
    DetailsListLayoutMode,
    DetailsRow,
    DirectionalHint,
    FontIcon,
    IColumn,
    IDetailsColumnProps,
    IDetailsRowProps,
    IconButton,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Spinner,
    Stack,
    Text,
    TooltipHost,
    mergeStyles,
    useTheme,
} from '@fluentui/react';
import { Section, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RouteParams } from 'interfaces/route-params';
import { flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SaveStatusIcon, { SyncStatus } from 'views/component/SavingStatusIcon';
import { ITenantOnboardingProcessHistory, ITenantOnboardingSteps } from 'views/pages/Tenants/state/tenants.model';

import { format, formatDistanceToNow } from 'date-fns';
import OnBoardingStepsList from './Onboarding/OnBoardingStepsList';
import { getTenantConfigCopy, rerunTenantConfigCopy } from './state/tenants.actions';
import { selectTenantConfigCopy, selectTenantConfigCopyLoading, selectTenantCopyConfig } from './state/tenants.selectors';
import { isRerunModalOpen } from './state/tenants.slice';
import RerunModal from './RerunModal';

export default function CopyConfig() {
    const theme = useTheme();
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (tenantId) dispatch(getTenantConfigCopy({ tenantId }));
    }, [tenantId, dispatch]);

    const [indexRow, setIndexRow] = useState<number | undefined>(undefined);
    const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);

    const copyConfigStep = useSelector(selectTenantCopyConfig);

    const loading = useSelector(selectTenantConfigCopyLoading);

    const rerunCopyConfigStep = (item: ITenantOnboardingSteps) => {
        const model = {
            tenantId: tenantId ?? '',
            tenantConfigCopyStepIds: [item.id],
        };
        if (tenantId && item.id) dispatch(rerunTenantConfigCopy({ model }));
    };

    const iconColumnClass = mergeStyles({
        fontSize: 15,
        fontWeight: 'bold !important',
        height: 24,
        width: 24,
        padding: '0 5px',
        cursor: 'pointer',
        color: theme.palette.black,
        userSelect: 'none',
    });

    const iconRowClass = mergeStyles({
        fontSize: 15,
        fontWeight: 'bold !important',
        height: 24,
        width: 24,
        padding: '1px 5px',
        cursor: 'pointer',
        color: theme?.palette.black,
        userSelect: 'none',
    });

    const columns: IColumn[] = [
        {
            key: 'stepOrder',
            name: 'Step',
            fieldName: 'stepOrder',
            minWidth: 50,
            maxWidth: 50,
        },
        {
            key: 'all',
            name: 'All',
            fieldName: '',
            minWidth: 25,
            maxWidth: 25,
            onRenderHeader: (props: IDetailsColumnProps | undefined) => {
                return (
                    <Stack horizontalAlign="center" verticalAlign="center">
                        <TooltipHost content={`${!expandAll ? 'Expand' : 'Collapse'} ${props?.column.name}`} id="toggle">
                            <FontIcon
                                iconName={`${!expandAll ? 'ChevronDown' : 'ChevronUp'}`}
                                className={iconColumnClass}
                                onClick={() => {
                                    setExpandAll(!expandAll);
                                    setIndexRow(undefined);
                                }}
                            />
                        </TooltipHost>
                    </Stack>
                );
            },
            onRender: (item, index) => {
                if (item)
                    return (
                        <Stack horizontalAlign="center" verticalAlign="center">
                            <TooltipHost
                                content={`${indexRow !== index && !expandAll ? 'Expand' : 'Collapse'} Procedures`}
                                id="toggle"
                            >
                                <FontIcon
                                    iconName={`${indexRow !== index && !expandAll ? 'ChevronDown' : 'ChevronUp'}`}
                                    className={iconRowClass}
                                    onClick={() => {
                                        setExpandAll(false);
                                        if (indexRow !== index && !expandAll) {
                                            setIndexRow(index);
                                        } else {
                                            setIndexRow(undefined);
                                        }
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    );
            },
        },
        {
            key: 'displayName',
            name: 'Name',
            fieldName: 'displayName',
            minWidth: 200,
            maxWidth: 650,
            isResizable: true,
            isMultiline: true,
            onRender: (item: ITenantOnboardingSteps) => {
                return (
                    <Stack>
                        <Stack.Item>
                            <Text variant="smallPlus">{item.displayName}</Text>
                        </Stack.Item>
                        <Stack wrap>
                            <Text variant="xSmall">
                                <i>{item.description}</i>
                            </Text>
                        </Stack>
                    </Stack>
                );
            },
        },

        {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 50,
            maxWidth: 300,

            onRender: (item) => {
                return <SaveStatusIcon saveStatus={item.status ?? ''} />;
            },
        },
        {
            key: 'lastRunDurationSeconds',
            name: '',
            fieldName: 'lastRunDurationSeconds',
            minWidth: 200,
            onRender: (item) => {
                if (item.processHistories?.length > 0) {
                    const stepIndex = item.processHistories ? item.processHistories?.length - 1 : 0;
                    const lastRan = (flatten(item?.processHistories[stepIndex].steps) as ITenantOnboardingSteps[]) ?? [];
                    const lastRanDate = lastRan.length ? lastRan[0].createdOn : '';

                    if (item.lastRunDurationSeconds) {
                        return (
                            <TooltipHost
                                directionalHint={DirectionalHint.rightCenter}
                                content={
                                    <Stack>
                                        <Text> Last Ran: {format(new Date(lastRanDate ?? ''), 'Pp')} </Text>
                                    </Stack>
                                }
                            >
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                    <IconButton
                                        iconProps={{ iconName: 'Calendar' }}
                                        styles={{ root: { maxHeight: 5, maxWidth: 5 } }}
                                    />
                                    <Text> {formatDistanceToNow(new Date(lastRanDate ?? ''))} ago </Text>
                                </Stack>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                    <IconButton
                                        iconProps={{ iconName: 'Stopwatch' }}
                                        styles={{ root: { maxHeight: 5, maxWidth: 5 } }}
                                    />
                                    <Text> {` ${item.lastRunDurationSeconds.toFixed(2)}s `} </Text>
                                </Stack>
                            </TooltipHost>
                        );
                    }
                    return null;
                }
                return null;
            },
        },
        {
            key: 'reRun',
            name: 'Run',
            fieldName: 'reRun',
            minWidth: 50,
            onRender: (item: ITenantOnboardingSteps) => {
                if (item.status !== SyncStatus.Completed || item.canBeRerun) {
                    return (
                        <>
                            <IconButton
                                onClick={() => {
                                    dispatch(isRerunModalOpen());
                                }}
                                iconProps={{ iconName: 'Refresh' }}
                            />
                            <RerunModal itemId={item.id} />
                        </>
                    );
                }
                return null;
            },
        },
    ];

    const onRenderRow = (props?: IDetailsRowProps | undefined): JSX.Element | null => {
        if (props) {
            const steps = props.item.processHistories.map((x: ITenantOnboardingProcessHistory) => x.steps ?? []);
            return (
                <Stack>
                    <DetailsRow {...props} />
                    {(props.itemIndex === indexRow && indexRow !== undefined) || expandAll ? (
                        <OnBoardingStepsList items={flatten(steps)} />
                    ) : null}
                </Stack>
            );
        }
        return null;
    };

    // TODO: Fix the section component to prevent table from being cut off

    if (loading !== LoadingStatus.Completed) {
        return <Spinner />;
    }

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Section heading={'Config Copy Steps'}>
                    <Stack>
                        <SortableDetailsList
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            items={copyConfigStep}
                            columns={columns}
                            setKey="column1"
                            onRenderRow={onRenderRow}
                            isHeaderVisible={true}
                            sortOnMount={true}
                        />
                    </Stack>
                </Section>
            </ScrollablePane>
        </Stack>
    );
}
