import { IDropdownOption } from '@fluentui/react';
import IPovertyGuideline, { IFederalPovertyRegions } from 'api/models/povertyGuidline.model';
import { map, orderBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const selectPovertyGuidelinesState = (state: RootState) => state.povertyGuidelines;
export const povertyGuildelinesData = createSelector(selectPovertyGuidelinesState, (state) =>
    state.data ? state.data : {},
);

export const povertyGuidelinesAsList = createSelector(
    povertyGuildelinesData,
    (data) => map(data, (d) => d).filter((d) => !d?.isDeleted) as IPovertyGuideline[],
);

export const povertyGuidelinesByUniqLocations = createSelector(povertyGuidelinesAsList, (items) => {
    return uniqBy(orderBy(items, 'year', 'desc'), 'location');
});

export const poveryGuidelinesBySelectedLocation = createSelector(
    [povertyGuidelinesAsList, selectPovertyGuidelinesState],
    (items, state) => items.filter((item) => item.location === state.selectedLocation),
);

export const latestPovertyGuideline = createSelector(
    poveryGuidelinesBySelectedLocation,
    (items) => orderBy(items, 'year', 'desc')[0],
);

export const federalPovertyRegions = createSelector(selectPovertyGuidelinesState, (state) =>
    state.federalPovertyRegions ? state.federalPovertyRegions : {},
);

export const federalPovertyRegionsAsList = createSelector(
    federalPovertyRegions,
    (regions) => map(regions, (r) => r) as IFederalPovertyRegions[],
);

export const federalPovertyRegionsAsOption = createSelector(
    federalPovertyRegionsAsList,
    (regions) => {
        const option: IDropdownOption[] = [
            { key: '', text: 'Select a Region' },
            ...regions.map((r) => ({ key: r?.code ?? '', text: r?.displayName ?? '' })),
        ];
        return option;
    },
);
