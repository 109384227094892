import { createAsyncThunk } from '@reduxjs/toolkit';
import ILocationOfCare from 'api/models/locationOfCare.model';
import ISubscribeToChangesPayload, { SubscriptionType } from 'api/models/subscribeToChangesPayload.model';
import ErrorTypes from 'state/errorTypes';
import tenantsApi from '../tenants.api';

export const tenantUnsubscribeToChanges = createAsyncThunk<
    void,
    { tenantId: string; subscription: SubscriptionType },
    { rejectValue: string }
>('tenants/tenantUnsubscribeToChanges', async (params, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.unsubscribeToChanges(params.tenantId, params.subscription);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const tenantSubscribeToChanges = createAsyncThunk<
    void,
    { tenantId: string; data: ISubscribeToChangesPayload },
    { rejectValue: string }
>('tenants/tenantSubscribeToChanges', async (params, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.subscribeToChanges(params.tenantId, params.data);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getLocationsOfCareForChangesetEventSubscriptions = createAsyncThunk<
    Record<string, ILocationOfCare>,
    { tenantId: string },
    { rejectValue: string }
>('tenants/getLocationsOfCareForChangesetEventSubscriptions', async (params, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.getTenantLocationsOfCare(params.tenantId);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});
