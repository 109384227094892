import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IconButton,
    IDetailsRowStyles,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Toggle,
} from '@fluentui/react';
import { serviceNameLookup, Services } from 'views/pages/Tenants/state/tenants.model';
import ITenantProduct from 'api/models/tenantProduct.model';
import { Section, SortableDetailsList } from 'components';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { filter, map } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { doesDateRangeOverlap } from 'utils/doesDateRangeOverlap';
import { isDateBetween } from 'utils/isDateBetween';
import AddEditTenantProductModal from './AddEditTenantSubscriptionPanel';
import { getTenantProductsById, editTenantProduct } from 'views/pages/Tenants/state/tenants.actions';
import { CombinedSubscriptionProduct } from 'views/pages/Tenants/state/tenants.selectors';

export default function Subscriptions() {
    const dispatch = useDispatch();
    const {
        productSubscriptions,
        addNewTenantProduct,

        setTenantProductPanelIsOpen,
        loadingTenantProducts,
    } = useTenant();

    const { tenantId } = useParams<{ tenantId: string; path: string }>();
    const { selectedTheme } = useSelector((state) => state.ui);
    const { loading } = useSelector((state) => state.tenants);

    //local state

    const [showDeleted, setShowDeleted] = useState<boolean>(false);

    const openSubscriptionPanel = () => {
        addNewTenantProduct();
        setTenantProductPanelIsOpen(true);
    };

    const onTenantProductClick = (item: ITenantProduct) => {
        if (loadingTenantProducts !== LoadingStatus.Pending) {
            dispatch(getTenantProductsById({ tenantId, productId: item.id }));
            setTenantProductPanelIsOpen(true);
        }
    };

    const validateSubscription = (subscription: ITenantProduct) => {
        let isValid = true;

        //validate that dates don't overlap
        if (productSubscriptions) {
            const subscriptions = productSubscriptions.filter(
                (ts) => !ts.isDeleted && ts.id !== subscription.id && ts.productId === subscription.productId,
            );
            //can only have one indefinite product subscription
            if (!subscription.endDate) {
                const indefiniteSubscriptions = subscriptions.filter((s) => !s.endDate);
                if (indefiniteSubscriptions.length > 0) {
                    isValid = false;
                }
            }

            if (isValid) {
                for (let i = 0; i < subscriptions.length; i++) {
                    const s = subscriptions[i];
                    const hasOverlap = doesDateRangeOverlap(
                        new Date(subscription.startDate),
                        new Date(s.startDate),
                        subscription.endDate ? new Date(subscription.endDate) : undefined,
                        s.endDate ? new Date(s.endDate) : undefined,
                    );
                    if (hasOverlap) {
                        isValid = false;
                        break;
                    }
                }
            }
        }

        if (isValid && subscription.endDate && subscription.startDate >= subscription.endDate) {
            isValid = false;
        }
        return isValid;
    };
    const toggleSubscription = (subscription: ITenantProduct) => {
        if (subscription.isDeleted) {
            //if activating, we have to re-validate to make sure there are no overlaps
            const isValid = validateSubscription(subscription);
            if (!isValid) {
                alert('Unable to activate product subscription.');
                return false;
            }
        }
        const updatedSubscription: ITenantProduct = {
            ...subscription,
            isDeleted: !subscription.isDeleted,
        };
        dispatch(editTenantProduct({ tenantId, product: updatedSubscription }));
    };

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: '',
            isResizable: true,
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ITenantProduct) => (
                <IconButton
                    id={`tenant-editButton-${item.id}`}
                    title="Edit Subscription"
                    iconProps={{ iconName: 'Edit' }}
                    disabled={item.isDeleted}
                    onClick={() => {
                        onTenantProductClick(item);
                    }}
                />
            ),
        },
        {
            key: 'column1',
            name: 'Product Name',
            data: 'string',
            fieldName: 'productName',
            isResizable: true,
            minWidth: 150,
            maxWidth: 200,
            onRender: (item: CombinedSubscriptionProduct) => (
                <div style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}>
                    {item.productId ? item.productName : ''}
                </div>
            ),
        },
        {
            key: 'column2',
            name: 'Services',
            isResizable: true,
            minWidth: 150,
            maxWidth: 400,
            onRender: (item: CombinedSubscriptionProduct) => (
                <span>
                    {filter(
                        map(item.services, (service, key) => ({ ...service, id: key })),
                        (service) => {
                            return isDateBetween({
                                dateToCheck: new Date().toISOString(),
                                start: service?.startDate,
                                end: service?.endDate,
                            });
                        },
                    )
                        .map((service) => service.serviceName ?? serviceNameLookup[service.serviceType ?? ('' as Services)])
                        .join(', ')}
                </span>
            ),
        },
        {
            key: 'column3',
            name: 'Subscription Start Date',
            data: 'string',
            fieldName: 'startDate',
            isResizable: true,
            minWidth: 150,
            maxWidth: 300,
            onRender: (item: CombinedSubscriptionProduct) => (
                <div style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}>
                    {format(parseISO(item.startDate.toString()), 'MM/dd/yyyy')}
                </div>
            ),
        },
        {
            key: 'column4',
            name: 'Subscription End Date',
            data: 'string',
            fieldName: 'endDate',
            isResizable: true,
            minWidth: 150,
            // maxWidth: 150,
            onRender: (item: CombinedSubscriptionProduct) => (
                <div style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}>
                    {item.endDate ? format(parseISO(item.endDate!.toString()), 'MM/dd/yyyy') : ''}
                </div>
            ),
        },
        {
            key: 'column5',
            name: '',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: CombinedSubscriptionProduct) => (
                <IconButton
                    id={`tenant-editButton-${item.id}`}
                    title={`${item.isDeleted ? 'Activate' : 'Deactivate'} Subscription`}
                    iconProps={{ iconName: item.isDeleted ? 'Undo' : 'Delete' }}
                    onClick={() => toggleSubscription(item)}
                />
            ),
        },
    ];

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Section
                    grow
                    heading="Product Subscriptions"
                    headingRightContent={
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <Toggle
                                styles={{ root: { marginBottom: 0 } }}
                                inlineLabel
                                label="Active Only"
                                onClick={() => setShowDeleted(!showDeleted)}
                                id="tenant-profile-showHistory"
                            />
                            <DefaultButton
                                text="Add Subscription"
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => openSubscriptionPanel()}
                            />
                        </Stack>
                    }
                >
                    <SortableDetailsList
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        selectionMode={SelectionMode.none}
                        items={showDeleted ? productSubscriptions : productSubscriptions!.filter((tp) => !tp.isDeleted)}
                        columns={columns}
                        setKey="column1"
                        onRenderRow={(props) => {
                            if (props) {
                                const customStyles: Partial<IDetailsRowStyles> = {
                                    ...props.styles,
                                    root: {
                                        color: selectedTheme === 'light' ? 'black' : props.theme?.palette.neutralLight,
                                        backgroundColor: props.theme?.palette.neutralLight,
                                        cursor: 'pointer',
                                    },
                                };
                                return <DetailsRow {...props} styles={customStyles} />;
                            }
                            return null;
                        }}
                        isHeaderVisible={true}
                        sortOnMount={true}
                        sortColumns={['productName']}
                    />
                </Section>
            </ScrollablePane>
            <AddEditTenantProductModal />
        </Stack>
    );
}
