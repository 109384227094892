import {
    Selection,
    mergeStyles,
    ScrollablePane,
    Stack,
    Text,
    ISelection,
    IObjectWithKey,
    SelectionMode,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import ILocationOfCare from 'api/models/locationOfCare.model';
import { SubscriptionType } from 'api/models/subscribeToChangesPayload.model';
import { SortableDetailsList, TModal } from 'components';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RouteParams } from 'interfaces/route-params';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getLocationsOfCareForChangesetEventSubscriptions,
    tenantSubscribeToChanges,
    tenantUnsubscribeToChanges,
} from './state/EventingChangsetsState/eventingChangesets.action';
import {
    selectEventingChangesetsActionLoading,
    selectEventingChangesetsDentalLocationsOfCare,
    selectEventingChangesetsLocationsOfCareAsList,
    selectEventingChangesetsLocationsOfCareLoading,
    selectEventingChangesetsModalAction,
    selectEventingChangesetsModalOpen,
    selectEventingChangesetsPayload,
} from './state/EventingChangsetsState/eventingChangesets.selector';
import { EventingChangesetAction } from './state/EventingChangsetsState/eventingChangesets.state';
import {
    cleanupSubscribeToChangesModal,
    dismissSubscribeToChangesModal,
    updateSubscribeToChangesAppointmentDepartmentIds,
    updateSubscribeToChangesPAMIDepartmentIds,
} from './state/tenants.slice';

const modalComponentLookup: Record<EventingChangesetAction, Record<SubscriptionType, () => JSX.Element | null>> = {
    [EventingChangesetAction.Unsubscribe]: {
        [SubscriptionType.Changesets]: UnsubscribeComponent,
        [SubscriptionType.Events]: UnsubscribeComponent,
    },
    [EventingChangesetAction.Subscribe]: {
        [SubscriptionType.Changesets]: SubscribeChangesetsComponent,
        [SubscriptionType.Events]: SubscribeEventingComponent,
    },
};

export default function EventingChangesetsModal() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    const payload = useSelector(selectEventingChangesetsPayload);
    const modalAction = useSelector(selectEventingChangesetsModalAction);

    const modalActionLoading = useSelector(selectEventingChangesetsActionLoading);
    const isModalActionLoading = modalActionLoading === LoadingStatus.Pending;

    const isOpen = useSelector(selectEventingChangesetsModalOpen);

    if (!payload || !modalAction) return null;

    function onDismiss() {
        dispatch(dismissSubscribeToChangesModal());
    }

    function onDismissed() {
        dispatch(cleanupSubscribeToChangesModal());
    }

    const actionLookup: Record<EventingChangesetAction, () => void> = {
        [EventingChangesetAction.Subscribe]: subscribe,
        [EventingChangesetAction.Unsubscribe]: unsubscribe,
    };

    function subscribe() {
        if (payload?.subscription && tenantId) dispatch(tenantSubscribeToChanges({ tenantId, data: payload }));
    }

    function unsubscribe() {
        if (payload?.subscription && tenantId)
            dispatch(tenantUnsubscribeToChanges({ tenantId, subscription: payload.subscription }));
    }

    const Component = modalComponentLookup[modalAction][payload.subscription];
    const action = actionLookup[modalAction];

    return (
        <TModal
            isDraggable
            modalProps={{ isOpen, onDismiss, onDismissed }}
            mainButtons={[
                {
                    text: isModalActionLoading ? 'Saving...' : modalAction,
                    primary: true,
                    onClick: action,
                    disabled: isModalActionLoading,
                },
                { text: 'Cancel', onClick: onDismiss, disabled: isModalActionLoading },
            ]}
            title={`${modalAction} ${modalAction === EventingChangesetAction.Unsubscribe ? 'from' : 'to'} ${payload.subscription}`}
        >
            <Stack styles={{ root: { padding: 10 } }} grow>
                <Component />
                {modalActionLoading === LoadingStatus.Failed && (
                    <MessageBar messageBarType={MessageBarType.error}>Something went wrong</MessageBar>
                )}
            </Stack>
        </TModal>
    );
}

function UnsubscribeComponent() {
    const payload = useSelector(selectEventingChangesetsPayload);
    return <Text>Are you sure that you want to unsubscribe from all {payload?.subscription.toLowerCase()}?</Text>;
}

function SubscribeEventingComponent() {
    const payload = useSelector(selectEventingChangesetsPayload);
    return <Text>Are you sure that you want to subscribe to all {payload?.subscription.toLowerCase()}?</Text>;
}

function SubscribeChangesetsComponent() {
    const { tenantId } = useParams<RouteParams>();
    const payload = useSelector(selectEventingChangesetsPayload);
    const dispatch = useDispatch();

    const locs = useSelector(selectEventingChangesetsLocationsOfCareAsList);
    const dentalLocs = useSelector(selectEventingChangesetsDentalLocationsOfCare);

    useEffect(() => {
        if (locs.length) {
            const locIds = locs.map((loc) => loc.id);
            dispatch(updateSubscribeToChangesPAMIDepartmentIds({ pamiDepartmentIds: locIds }));
        }
        if (dentalLocs.length) {
            const locIds = dentalLocs.map((loc) => loc.id);
            dispatch(updateSubscribeToChangesAppointmentDepartmentIds({ appointmentDepartmentIds: locIds }));
        }
    }, [locs, dentalLocs]);

    useEffect(() => {
        if (tenantId) dispatch(getLocationsOfCareForChangesetEventSubscriptions({ tenantId }));
    }, [tenantId]);

    if (!payload) return null;

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <LocationsOfCareList
                headerText="Subscribe for Appointments"
                items={dentalLocs}
                selectedLocationOfCareIds={payload.appointmentDepartmentIds}
                onSelectLocationOfCareIds={(items) => {
                    dispatch(updateSubscribeToChangesAppointmentDepartmentIds({ appointmentDepartmentIds: items }));
                }}
            />
            <LocationsOfCareList
                headerText="Subscribe for PAMI"
                items={locs}
                selectedLocationOfCareIds={payload.pamiDepartmentIds}
                onSelectLocationOfCareIds={(items) => {
                    dispatch(updateSubscribeToChangesPAMIDepartmentIds({ pamiDepartmentIds: items }));
                }}
            />
        </Stack>
    );
}

type LocationsOfCareListProps = {
    headerText: string;
    items: ILocationOfCare[];
    selectedLocationOfCareIds?: string[];
    onSelectLocationOfCareIds?: (locationOfCareIds: string[]) => void;
};

const locationsOfCareListStyles = mergeStyles({
    position: 'relative',
    minHeight: 230,
    minWidth: 500,
});

function LocationsOfCareList({
    headerText,
    selectedLocationOfCareIds,
    onSelectLocationOfCareIds,
    items,
}: LocationsOfCareListProps) {
    const loading = useSelector(selectEventingChangesetsLocationsOfCareLoading);
    const isLoading = loading === LoadingStatus.Pending;

    const locationsOfCareSelection = useMemo(
        () =>
            new Selection<ILocationOfCare>({
                onSelectionChanged: () => {
                    const selectedLocs = _getLocationsOfCareSelected();
                    if (onSelectLocationOfCareIds) onSelectLocationOfCareIds(selectedLocs.map((loc) => loc.id));
                },
                getKey: (item) => item.id,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        selectedLocationOfCareIds?.forEach((locId) => {
            locationsOfCareSelection.setKeySelected(locId, true, false);
        });
    }, [selectedLocationOfCareIds]);

    function _getLocationsOfCareSelected(): ILocationOfCare[] {
        return locationsOfCareSelection.getSelection() as ILocationOfCare[];
    }

    if (loading === LoadingStatus.Failed)
        return <MessageBar messageBarType={MessageBarType.error}>Something went wrong</MessageBar>;

    if (loading === LoadingStatus.Completed && !items.length) return <MessageBar>No Departments/LOCs to show here.</MessageBar>;

    return (
        <Stack grow>
            <Text styles={{ root: { fontWeight: 'bold' } }} variant="mediumPlus">
                {headerText}
            </Text>
            <div className={locationsOfCareListStyles}>
                <ScrollablePane>
                    <SortableDetailsList<ILocationOfCare>
                        items={items}
                        sortOnMount
                        selectionMode={SelectionMode.multiple}
                        selection={locationsOfCareSelection as unknown as ISelection<IObjectWithKey>}
                        enableShimmer={isLoading}
                        shimmerLines={3}
                        compact
                        stickyHeader
                        sortColumns={['id']}
                        columns={[
                            { key: 'id', fieldName: 'id', name: 'ID', minWidth: 40, maxWidth: 40 },
                            { key: 'displayName', fieldName: 'displayName', name: 'Department/LOC', minWidth: 190 },
                            {
                                key: 'departmentType',
                                fieldName: 'departmentType',
                                name: 'Dept. Type',
                                minWidth: 100,
                                onRender: (item) => {
                                    const text = item?.departmentType ? item.departmentType : 'None';
                                    return <Text variant="small">{text}</Text>;
                                },
                            },
                        ]}
                    />
                </ScrollablePane>
            </div>
        </Stack>
    );
}
