export interface IUserTaskItem {
    userId?: string;
    id: string;
    createdOn?: string;
    completedOn?: string;
    dueDate?: string;
    service?: string;
    target?: UserTaskTarget;
    type?: string;
    references: Record<string, unknown>;
    status?: UserTaskStatus;
    severity?: UserTaskSeverity;
    note?: string;
    assignedTo?: string[];
}

export type IUserTaskItemList = IUserTaskItem[];

export enum UserTaskSeverity {
    Normal = 'Normal',
    Medium = 'Medium',
    High = 'High',
}

export enum UserTaskStatus {
    New = 'New',
    Active = 'Active',
    Completed = 'Completed',
    Dismissed = 'Dismissed',
    Snoozed = 'Snoozed',
}

export enum UserTaskTarget {
    TenantSetup = 'platform-beacon-home',
}
