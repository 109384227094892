import { CommandBar, MessageBar, Stack } from '@fluentui/react';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTenantConfigView } from './state/tenants.actions';
import { selectLoadingTenantConfigView } from './state/tenants.selectors';

export default function EventingChangesetCheckStatusButton() {
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const _loading = useSelector(selectLoadingTenantConfigView) === LoadingStatus.Pending;

    function onCheckStatus() {
        if (tenantId) dispatch(getTenantConfigView({ tenantId }));
    }
    return (
        <Stack>
            <MessageBar>
                {
                    'Updates to Eventing/Changesets could take up to 5 minutes to reflect. Use the "Check Eventing/Changeset status" button below to check for changes after updates.'
                }
            </MessageBar>
            <CommandBar
                styles={{ root: { paddingLeft: 0 } }}
                items={[
                    {
                        text: _loading ? 'Loading...' : 'Check Eventing/Changeset Status',
                        iconProps: { iconName: 'Refresh' },
                        key: 'checkStatus',
                        onClick: onCheckStatus,
                        disabled: _loading,
                    },
                ]}
            />
        </Stack>
    );
}
