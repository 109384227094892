import { createSelector, createSlice } from '@reduxjs/toolkit';
import IAccount from 'api/models/account.model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RootState } from 'state/store';
import { getAccount } from './user.actions';
import { GlobalPermissions } from 'hooks/usePermissions';

const initialState: UserState = {
    photo: null,
    profile: null,
    photoLoading: false,
    profileLoading: false,
    photoError: null,
    profileError: null,
    loading: LoadingStatus.Idle,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAccount.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAccount.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.account = action.payload;
            })
            .addCase(getAccount.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

const currentUserState = (state: RootState) => state.user;

export const userAccountLoading = createSelector(currentUserState, (userState) => userState.loading);

export const userAccount = createSelector(currentUserState, (userState) => userState.account);

export const selectIsAccountGlobalAdmin = (state: RootState) =>
    state.user.account ? state.user.account?.identity?.systemRoles?.includes(GlobalPermissions.GlobalAdmin) : false;
export default userSlice.reducer;

type UserState = {
    photo: any;
    profile: any | null;
    photoLoading: boolean;
    profileLoading: boolean;
    photoError: string | null;
    profileError: string | null;
    loading: LoadingStatus;
    account?: IAccount;
    errors?: any;
};
