import { IColumn, IObjectWithKey, ISelection, SearchBox, SelectionMode, Stack } from '@fluentui/react';
import { Section, SortableDetailsList } from 'components';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { IWorkspace, IWorkspaceReport } from './state/reports.model';

export type IWorkspaceReportAndWorkspace = IWorkspaceReport[] & IWorkspace[];
export type IWorkspaceReportOrWorkspace = IWorkspaceReport | IWorkspace;

type ReportDetailListProps<T> = {
    heading: string;
    columns: IColumn[];
    searchKeys: (keyof T)[];
    list: T[];
    sortBy?: string;
    selection?: ISelection<IObjectWithKey>;
};

function ReportDetailList<T>({ columns, heading, list, searchKeys, selection, sortBy }: ReportDetailListProps<T>) {
    const { results, onSearch, search, onSearchChange } = useFuseSearch<T>({
        fuseOptions: { keys: searchKeys as string[], threshold: 0.1 },
        list: list,
    });

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Section
                heading={heading}
                headingRightContent={
                    <SearchBox
                        placeholder={`Search by ${heading}`}
                        value={search}
                        onSearch={onSearch}
                        onChange={onSearchChange}
                    />
                }
            >
                <SortableDetailsList<T>
                    selection={selection as unknown as ISelection<IObjectWithKey>}
                    selectionMode={SelectionMode.multiple}
                    columns={columns}
                    selectionPreservedOnEmptyClick
                    items={results.length ? results : list}
                    sortOnMount
                    sortColumns={[`${sortBy}`]}
                    initialSortDirection={['asc']}
                />
            </Section>
        </Stack>
    );
}

export default ReportDetailList;
