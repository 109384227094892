import { Icon, Label, Link, Stack, Text, useTheme } from '@fluentui/react';
import { forwardRef } from 'react';

type PanelSectionHeaderProps = {
    text: string;
    isPrimary?: boolean;
    subHeader?: boolean;
    rightAction?: {
        onClick: () => void;
        text?: string;
        iconName?: string;
    };
    required?: boolean;
    rightContent?: JSX.Element;
    leftContent?: JSX.Element;
    style?: React.CSSProperties;
    onClick?: React.MouseEventHandler<HTMLElement>;
};

const PanelSectionHeader = forwardRef<HTMLDivElement, PanelSectionHeaderProps>(
    (
        {
            text,
            isPrimary,
            rightAction,
            subHeader,
            rightContent,
            leftContent,
            onClick,
            style,
            required,
        },
        ref,
    ): JSX.Element => {
        const { palette } = useTheme();

        return (
            <div
                onClick={onClick}
                style={{
                    backgroundColor: isPrimary
                        ? palette.themeLighter
                        : palette.neutralQuaternaryAlt,
                    padding: '5px 10px',
                    borderLeftWidth: subHeader ? 0 : '5px',
                    borderLeftStyle: 'solid',
                    borderLeftColor: isPrimary ? palette.themePrimary : palette.neutralPrimaryAlt,
                    display: 'flex',
                    alignItems: 'center',
                    ...style,
                }}
                ref={ref}
            >
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Text variant="smallPlus">
                        <Label styles={{ root: { padding: 0, fontSize: 13 } }} required={required}>
                            <strong>{text}</strong>
                        </Label>
                    </Text>
                    {leftContent}
                </Stack>
                {rightAction && (
                    <Stack grow horizontalAlign="end">
                        <Text variant="smallPlus">
                            <Link onClick={rightAction.onClick}>
                                <Stack
                                    tokens={{ childrenGap: 5 }}
                                    verticalAlign="center"
                                    horizontal
                                >
                                    {rightAction.iconName && (
                                        <Icon iconName={rightAction.iconName} />
                                    )}
                                    <span>{rightAction.text}</span>
                                </Stack>
                            </Link>
                        </Text>
                    </Stack>
                )}
                {rightContent && (
                    <Stack grow horizontalAlign="end">
                        {rightContent}
                    </Stack>
                )}
            </div>
        );
    },
);

PanelSectionHeader.displayName = 'PanelSectionHeader';

export default PanelSectionHeader;
