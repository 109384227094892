import { DefaultButton, IColumn, Link, MessageBar, MessageBarType, ScrollablePane, Spinner, Stack } from '@fluentui/react';
import { ActivityPage } from 'components';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportDetailList from './ReportDetailList';
import { getWorkSpaceReport, getWorkspaces, updatedBatchReports } from './state/reports.actions';
import { IWorkspace, IWorkspaceReport, IWorkspaceReports, IWorkspaces } from './state/reports.model';
import {
    selectWorkspaceLoadingErrors,
    selectedReport,
    selectedWorkspace,
    workSpaceAndReportListLoading,
    workspaceList,
    workspaceReportList,
} from './state/reports.selectors';
import { cleanupReports, setSelectedReports, setSelectedWorkspaces } from './state/reports.slice';
import { Selection } from '@fluentui/utilities';

function Reporting() {
    const reportList = useSelector(workspaceReportList);
    const selectedReports = useSelector(selectedReport);
    const loadingWorkspacesAndReports = useSelector(workSpaceAndReportListLoading);
    const workspaceDetailList = useSelector(workspaceList);
    const selectedWorkspaces = useSelector(selectedWorkspace);
    const errors = useSelector(selectWorkspaceLoadingErrors);
    const dispatch = useDispatch();

    function getReportsAndWorkspaces() {
        dispatch(getWorkspaces());
        dispatch(getWorkSpaceReport());
    }

    const disableButton = !selectedReports.length || !selectedWorkspaces.length;

    useEffect(() => {
        getReportsAndWorkspaces();
        return () => {
            dispatch(cleanupReports());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getSelectedReports(item: IWorkspaceReports) {
        dispatch(setSelectedReports(item));
    }

    function getSelectedWorkspaces(item: IWorkspaces) {
        dispatch(setSelectedWorkspaces(item));
    }

    function batchReports() {
        dispatch(updatedBatchReports());
        workspaceSelection.setAllSelected(false);
        reportSelection.setAllSelected(false);
    }

    const reportColumns: IColumn[] = [
        {
            key: 'reportName',
            name: 'Name',
            minWidth: 75,
            maxWidth: 150,
            fieldName: 'reportName',
        },
    ];
    const workspaceColumns: IColumn[] = [
        {
            key: 'workspaceDescription',
            name: 'Name',
            minWidth: 75,
            maxWidth: 150,
            fieldName: 'workspaceDescription',
            onRender: (item: IWorkspace) => (
                <span>{`${item.workspaceName ?? ''}${item.workspaceDescription ? `_${item.workspaceDescription}` : ''}`}</span>
            ),
        },
    ];

    const reportSelection = useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    const selectedReport = _getReportSelected();
                    getSelectedReports(selectedReport);
                },
            }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    function _getReportSelected(): IWorkspaceReport[] {
        return reportSelection.getSelection() as IWorkspaceReport[];
    }
    const workspaceSelection = useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    const workSpaceReport = _getWorkspaceSelected();
                    getSelectedWorkspaces(workSpaceReport);
                },
            }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    function _getWorkspaceSelected(): IWorkspace[] {
        return workspaceSelection.getSelection() as IWorkspace[];
    }

    return (
        <ActivityPage
            title="Reports"
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Bulk Deploy',
                    iconProps: { iconName: 'Add' },
                    styles: { root: { height: 30 } },
                    disabled: disableButton,
                    onClick: batchReports,
                },
            ]}
            hasPageHeader
            hideFooter
        >
            {errors && (
                <MessageBar messageBarType={MessageBarType.error}>
                    {errors}
                    <Link onClick={getReportsAndWorkspaces}>{''}Retry</Link>
                </MessageBar>
            )}
            <MessageBar messageBarType={MessageBarType.warning}>
                Please note that to avoid performance issues and system timeouts, only 75 total reports can be deployed at one
                time.
            </MessageBar>
            <Stack style={{ padding: 12, display: 'flex', flex: 1, height: '90vh' }} grow wrap>
                <div style={{ position: 'relative', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility="auto">
                        {loadingWorkspacesAndReports ? (
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <ReportDetailList<IWorkspaceReport>
                                    columns={reportColumns}
                                    heading="Reports"
                                    list={reportList}
                                    searchKeys={['reportId', 'reportName']}
                                    sortBy="reportName"
                                    selection={reportSelection}
                                />
                                <ReportDetailList<IWorkspace>
                                    columns={workspaceColumns}
                                    heading="Power BI Workspaces"
                                    list={workspaceDetailList}
                                    sortBy="workspaceDescription"
                                    searchKeys={['workspaceDescription', 'workspaceId', 'workspaceName']}
                                    selection={workspaceSelection}
                                />
                            </Stack>
                        ) : (
                            <Spinner label="Loading Reports and Workspaces " />
                        )}
                    </ScrollablePane>
                </div>
            </Stack>
        </ActivityPage>
    );
}

export default Reporting;
