import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { TenantsState } from '../../state/tenants.state';
import {
    addTenantOnboarding,
    getTenantOnboardingById,
    getTenantOnboardingStepById,
    getTenantPracticeInfo,
    rerunTenantOnboardingStep,
    updateTenantOnboarding,
    verifyConfigurationAndRunOnboarding,
} from './onboarding.actions';
import { LoadingStatus } from 'interfaces/loadingStatus';

export const onBoardingExtraReducers = (builder: ActionReducerMapBuilder<TenantsState>): ActionReducerMapBuilder<TenantsState> =>
    builder

        // Get Tenant Onboarding By Id
        .addCase(getTenantOnboardingById.pending, (state) => {
            state.loadingTenantOnboarding = LoadingStatus.Pending;
        })
        .addCase(getTenantOnboardingById.fulfilled, (state, action) => {
            state.loadingTenantOnboarding = LoadingStatus.Completed;
            state.selectTenantOnboarding = action.payload;
        })
        .addCase(getTenantOnboardingById.rejected, (state, action) => {
            state.loadingTenantOnboarding = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        // Add Tenant Onboarding
        .addCase(addTenantOnboarding.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(addTenantOnboarding.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.errors = null;
            state.tenantOnboarding = action.payload;
        })
        .addCase(addTenantOnboarding.rejected, (state, action) => {
            state.loading = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        // rerun error steps
        .addCase(rerunTenantOnboardingStep.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(rerunTenantOnboardingStep.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.errors = null;
            state.tenantOnboarding = action.payload;
        })
        .addCase(rerunTenantOnboardingStep.rejected, (state, action) => {
            state.loading = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        // Update Tenant Onboarding
        .addCase(updateTenantOnboarding.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(updateTenantOnboarding.fulfilled, (state, action) => {
            if (state?.selectTenantOnboarding) {
                state.selectTenantOnboarding = action.payload;
            }
        })
        .addCase(updateTenantOnboarding.rejected, (state, action) => {
            state.loading = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        // Get Tenant Onboarding Step By Id
        .addCase(getTenantOnboardingStepById.pending, (state) => {
            state.loadingTenantOnboarding = LoadingStatus.Pending;
        })
        .addCase(getTenantOnboardingStepById.fulfilled, (state, action) => {
            state.loadingTenantOnboarding = LoadingStatus.Completed;
            state.selectTenantOnboardingStep = action.payload;
        })
        .addCase(getTenantOnboardingStepById.rejected, (state, action) => {
            state.loadingTenantOnboarding = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        .addCase(verifyConfigurationAndRunOnboarding.pending, (state) => {
            state.verifyTenantOnboarding = LoadingStatus.Pending;
        })
        .addCase(verifyConfigurationAndRunOnboarding.fulfilled, (state, action) => {
            const { tenant, onboarding } = action.payload;
            state.verifyTenantOnboarding = LoadingStatus.Completed;
            state.tenantOnboarding = onboarding;
            state.selectTenant = tenant;
            state.tenants = [
                ...state.tenants.map((t) => {
                    return t.id === tenant.id ? tenant : t;
                }),
            ];
        })
        .addCase(verifyConfigurationAndRunOnboarding.rejected, (state, action) => {
            state.verifyTenantOnboarding = LoadingStatus.Failed;
            state.errors = action.payload;
        });
