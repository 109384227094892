import { Dropdown, Spinner, Stack, TextField } from '@fluentui/react';
import { format, isValid } from 'date-fns';
import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import useUsers from 'hooks/state/useUsers';
import { getValidationError } from 'hooks/useValidation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUsers } from 'state/slices/users.slice';
import { TenantInfoType } from './About';
import {
    selectSourceSystemsAsOptions,
    selectSourceSystemsLoading,
    selectTenantConfig,
    selectedTenantValidationError,
} from './state/tenants.selectors';
import { updateSelectedTenantsProp } from './state/tenants.slice';

export default function TenantSourceSystem() {
    const dispatch = useDispatch();

    const { selectTenant } = useTenant();
    const { selectUsersData, selectUsersLoading } = useUsers();
    const loadingSourceSystems = useSelector(selectSourceSystemsLoading);
    const sourceSystemOptions = useSelector(selectSourceSystemsAsOptions);
    const validationError = useSelector(selectedTenantValidationError);
    const tenantConfig = useSelector(selectTenantConfig);

    useEffect(() => {
        dispatch(getUsers());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectTenant?.isUniversalKey) {
            dispatch(updateSelectedTenantsProp({ path: 'clientSecret', value: '' }));
            dispatch(updateSelectedTenantsProp({ path: 'clientId', value: '' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTenant]);

    const getUserName = () => {
        if (selectTenant?.verifiedPracticeInfoBy) {
            const user = selectUsersData?.find((u) => u.identity?.id === selectTenant.verifiedPracticeInfoBy);
            if (user) return user.identity?.firstName + ' ' + user.identity?.lastName;
        }
        return '';
    };

    if (loadingSourceSystems) return <Spinner label="Loading Source Systems..." labelPosition="left" />;

    const verifiedPracticeInfo = isValid(new Date(selectTenant?.verifiedPracticeInfoOn ?? ''));

    return (
        <Stack>
            <Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack grow={1}>
                        <Dropdown
                            selectedKey={selectTenant?.sourceSystem ?? ''}
                            label="Source System"
                            placeholder="(Select Source System)"
                            options={sourceSystemOptions}
                            disabled={verifiedPracticeInfo}
                            onChange={(ev, option) => {
                                if (option)
                                    dispatch(
                                        updateSelectedTenantsProp({
                                            path: 'sourceSystem',
                                            value: option.key,
                                        }),
                                    );
                            }}
                        />
                    </Stack>
                    {selectTenant?.sourceSystem && (
                        <Stack grow={1}>
                            <TextField
                                label="Source System Practice ID"
                                value={selectTenant?.practiceId ?? ''}
                                onChange={(ev, value) => {
                                    dispatch(
                                        updateSelectedTenantsProp({
                                            path: 'practiceId',
                                            value: value?.trim(),
                                        }),
                                    );
                                }}
                                disabled={verifiedPracticeInfo}
                                description={`${selectTenant?.practiceId?.length || 0} of 9 characters used`}
                                errorMessage={
                                    getValidationError(validationError, TenantInfoType.PracticeId)
                                        ? 'Practice Id length must be below 9'
                                        : ''
                                }
                            />
                        </Stack>
                    )}
                </Stack>
                {selectTenant?.sourceSystem && !selectTenant?.isUniversalKey && (
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack grow={1}>
                            <TextField
                                label="Client ID"
                                type="password"
                                canRevealPassword
                                value={selectTenant.clientId ? selectTenant.clientId : ''}
                                onChange={(ev, value) => {
                                    dispatch(updateSelectedTenantsProp({ path: 'clientId', value: value?.trim() ?? '' }));
                                }}
                                disabled={verifiedPracticeInfo}
                            />
                        </Stack>

                        <Stack grow={1}>
                            <TextField
                                label="Client Secret"
                                type="password"
                                canRevealPassword
                                value={selectTenant.clientSecret ? selectTenant.clientSecret : ''}
                                onChange={(ev, value) => {
                                    dispatch(updateSelectedTenantsProp({ path: 'clientSecret', value: value?.trim() ?? '' }));
                                }}
                                disabled={verifiedPracticeInfo}
                            />
                        </Stack>
                    </Stack>
                )}

                {verifiedPracticeInfo ? (
                    <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                        <Stack.Item grow={1}>
                            <TextField disabled label="Verified By" value={getUserName() ?? ''} />
                        </Stack.Item>
                        <Stack.Item grow={1}>
                            <TextField
                                disabled
                                label="Verified Date"
                                value={
                                    selectTenant?.verifiedPracticeInfoOn
                                        ? format(new Date(selectTenant.verifiedPracticeInfoOn), 'Pp')
                                        : ''
                                }
                            />
                        </Stack.Item>
                    </Stack>
                ) : null}
            </Stack>
        </Stack>
    );
}
