import {
    ITheme,
    IStyleFunctionOrObject,
    IFontStyles,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { useSelector } from 'hooks';
import React from 'react';

type Props = {
    styles?: IStyleFunctionOrObject<any, any>;
    theme?: ITheme;
    variant: keyof IFontStyles;
    appName?: string;
    appNameLink?: string;
};

const Logo = (props: Props) => {
    const theme = useTheme();

    const { neutralPrimary, white } = theme.palette;
    const { selectedTheme } = useSelector((state) => state.ui);

    const linksStyle = {
        color: selectedTheme === 'light' ? white : neutralPrimary,
        transition: 'background .15s',
        height: '100%',
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
    };

    const elwoodStyle = {
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        color: selectedTheme === 'light' ? white : neutralPrimary,
        height: '100%',
        background: '#172e4c',
        transition: 'background .15s',
    };

    return (
        <Stack
            className="nav-bar_logo"
            horizontal
            verticalAlign="center"
            style={{ color: selectedTheme === 'light' ? white : neutralPrimary, height: '100%' }}
        >
            <Text style={elwoodStyle}>elwood</Text>
            <Text style={linksStyle}>{props.appName}</Text>
        </Stack>
    );
};

export default Logo;
