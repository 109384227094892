import { DefaultButton, Stack, StackItem, Text } from '@fluentui/react';
import { SubscriptionType } from 'api/models/subscribeToChangesPayload.model';
import { Section } from 'components';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventingChangesetCard from '../EventingChangesetCard';
import EventingChangesetsActiveIndicator from '../EventingChangsetsActiveIndicator';
import { EventingChangesetAction } from '../state/EventingChangsetsState/eventingChangesets.state';
import { selectTenantConfigView } from '../state/tenants.selectors';
import { setSubscribeToChangesModalOpen } from '../state/tenants.slice';

export default function Changesets() {
    const dispatch = useDispatch();
    const config = useSelector(selectTenantConfigView);
    const hasChangeSetList: { displayName: string; changesetActive: boolean }[] = useMemo(
        () => [
            { displayName: 'Allergies', changesetActive: !!config?.hasAllergiesChangeSet },
            { displayName: 'Appointment', changesetActive: !!config?.hasAppointmentChangeSet },
            { displayName: 'Patients', changesetActive: !!config?.hasPatientsChangeSet },
            { displayName: 'Prescriptions', changesetActive: !!config?.hasPrescriptionChangeSet },
            { displayName: 'Problems', changesetActive: !!config?.hasProblemsChangeSet },
            {
                displayName: 'Provider',
                changesetActive: !!config?.hasProviderChangeSet,
            },
            { displayName: 'Medications', changesetActive: !!config?.hasMedicationsChangeSet },
        ],
        [config],
    );

    const changesetsActive = hasChangeSetList.some((item) => item.changesetActive);

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Section
                style={{
                    marginBottom: 0,
                }}
                headingRightContent={
                    <>
                        <DefaultButton
                            text="Unsubscribe from Changesets"
                            onClick={() => {
                                dispatch(
                                    setSubscribeToChangesModalOpen({
                                        action: EventingChangesetAction.Unsubscribe,
                                        type: SubscriptionType.Changesets,
                                    }),
                                );
                            }}
                        />

                        <DefaultButton
                            text="Subscribe to Changesets"
                            onClick={() => {
                                dispatch(
                                    setSubscribeToChangesModalOpen({
                                        action: EventingChangesetAction.Subscribe,
                                        type: SubscriptionType.Changesets,
                                    }),
                                );
                            }}
                        />
                    </>
                }
                heading={
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <StackItem>
                            <Text variant="large" block>
                                Changesets
                            </Text>
                        </StackItem>
                        <EventingChangesetsActiveIndicator changesetsActive={changesetsActive} />
                    </Stack>
                }
            ></Section>
            <Stack tokens={{ childrenGap: 10 }} horizontal wrap>
                {hasChangeSetList.map((changeSetData, index) => (
                    <EventingChangesetCard key={`${changeSetData.displayName}-${index}`} type="Changeset" {...changeSetData} />
                ))}
            </Stack>
        </Stack>
    );
}
