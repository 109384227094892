import { LoadingStatus } from 'interfaces/loadingStatus';
import { IUserTaskItemList } from './model';

export type UserTaskState = {
    tenantsTasks: IUserTaskItemList;
    tenantTasksLoading: LoadingStatus;
    userTasks: IUserTaskItemList;
    userTasksLoading: LoadingStatus;
};

export const initialState: UserTaskState = {
    tenantsTasks: [],
    tenantTasksLoading: LoadingStatus.Idle,
    userTasks: [],
    userTasksLoading: LoadingStatus.Idle,
};
