import { Dictionary } from '@reduxjs/toolkit';
import IAdminUser from 'models/adminUser.model';
import ApiClient, { IApiEnpointConfig } from './apiClient';
import authProvider from './auth/authProvider';
import IAccount, { ISystemUserAccount } from './models/account.model';
import IBenefitPlan, { IProcedureCategory } from './models/benefitPlan.model';
import { IDiagnosis } from './models/diagnosis.model';
import IPayer from './models/payer.model';
import IPovertyGuideline, { IFederalPovertyRegions } from './models/povertyGuidline.model';
import IProcedure from './models/procedure.model';
import IProduct from './models/product.model';
import SystemUser from './models/systemUser.model';
import IUser, { IUsersPermissions } from './models/user.model';
import IUserIdentity from './models/userIdentity.model';

export const isDev = process.env.NODE_ENV === 'development';

export const PlatformApiConfig: IApiEnpointConfig = {
    baseUrl: isDev ? `${process.env.REACT_APP_AppApiUri}/api/` : '#{AppApiUri}#/api/',
    scopes: isDev ? [`${process.env.REACT_APP_ApiAuthScope}`] : ['#{ApiAuthScope}#'],
    authProvider,
};

class PlatformApi extends ApiClient {
    constructor() {
        super(PlatformApiConfig);
    }

    // * Poverty Guidlines
    public getAllPovertyGuidelines() {
        return this.client.get<Dictionary<IPovertyGuideline>>('povertyGuidelines');
    }

    public getPovertyGuidelineById(id: string) {
        return this.client.get<IPovertyGuideline>(`povertyGuidelines/${id}`);
    }

    public updatePovertyGuideline(model: IPovertyGuideline) {
        return this.client.put<IPovertyGuideline>('povertyGuidelines', model);
    }

    public createPovertyGuideline(model: IPovertyGuideline) {
        return this.client.post<IPovertyGuideline>('povertyGuidelines', model);
    }

    public getFederalPovertyRegions() {
        return this.client.get<Dictionary<IFederalPovertyRegions>>('federalPovertyRegions');
    }

    // * Procedures
    public getAllProcedures() {
        return this.client.get<Dictionary<IProcedure>>('procedures');
    }

    public getProcedureById(id: string) {
        return this.client.get<IProcedure>(`procedures/${id}`);
    }

    public createProcedure(model: IProcedure) {
        return this.client.post<IProcedure>(`procedures`, model);
    }

    public updateProcedure(model: IProcedure) {
        return this.client.put<IProcedure>('procedures', model);
    }

    public searchProcedures(search: {
        code?: string;
        displayName?: string;
        description?: string;
        id?: string;
        name?: string;
        category?: string;
    }) {
        return this.client.post<Dictionary<IProcedure>>(`procedures`, search);
    }

    // * ProcedureCategory
    public getProcedureCategories() {
        return this.client.get<IProcedureCategory>('procedureCategories');
    }

    public createProcedureCategory(model: IProcedureCategory) {
        return this.client.post<IProcedureCategory>(`procedureCategories`, model);
    }

    public updateProcedureCategory(model: IProcedureCategory) {
        return this.client.put<IProcedureCategory>('procedureCategories', model);
    }

    // Diagnostics
    public getAllDiagnoses() {
        return this.client.get<Dictionary<IDiagnosis>>('diagnoses');
    }

    public getDiagnosisById(id: string) {
        return this.client.get<IDiagnosis>(`diagnoses/${id}`);
    }

    public createDiagnosis(model: IDiagnosis) {
        return this.client.post<IDiagnosis>(`diagnoses`, model);
    }

    public updateDiagnosis(model: IDiagnosis) {
        return this.client.put<IDiagnosis>('diagnoses', model);
    }

    public searchDiagnosis(search: { code?: string; snomedCode?: string; description?: string }) {
        return this.client.post<Dictionary<IProcedure>>(`diagnoses`, search);
    }
    // Benefits Plans
    public getAllBenefitPlans() {
        return this.client.get<Dictionary<IBenefitPlan>>('benefitPlan');
    }

    public getBenefitPlansById(id: string) {
        return this.client.get<IBenefitPlan>(`benefitPlan/${id}`);
    }

    public createBenefitPlans(model: IBenefitPlan) {
        return this.client.post<IBenefitPlan>(`benefitPlan`, model);
    }

    public updateBenefitPlans(model: IBenefitPlan) {
        return this.client.put<IBenefitPlan>('benefitPlan', model);
    }
    public getDefaultBenefitPlans() {
        return this.client.get<IBenefitPlan>('benefitPlan/defaults');
    }

    public searchBenefitPlans(search: { displayName?: string; category?: string }) {
        return this.client.post<Dictionary<IBenefitPlan>>(`benefitPlan`, search);
    }

    // * Payers
    public getPayers() {
        return this.client.get<Dictionary<IPayer>>('payers');
    }

    public getPayer(id: string) {
        return this.client.get<IPayer>(`payers/${id}`);
    }

    public addPayer(model: IPayer) {
        return this.client.post<IPayer>(`payers`, model);
    }

    public editPayer(model: IPayer) {
        return this.client.put<IPayer>('payers', model);
    }

    // * Products
    public getProducts() {
        return this.client.get<Dictionary<IProduct>>('products');
    }

    public getProduct(id: string) {
        return this.client.get<IProduct>(`products/${id}`);
    }

    public addProduct(model: IProduct) {
        return this.client.post<IProduct>(`products`, model);
    }

    public editProduct(model: IProduct) {
        return this.client.put<IProduct>('products', model);
    }

    // * Users
    public getUsers() {
        return this.client.get<Dictionary<ISystemUserAccount>>('users');
    }

    public getUser(id: string) {
        return this.client.get<ISystemUserAccount>(`users/${id}`);
    }

    public getSystemUser(id: string) {
        return this.client.get<SystemUser>(`SystemUsers/${id}`);
    }

    public getUserAccount(id: string) {
        return this.client.get<IUserIdentity>(`account/${id}`);
    }

    public getUserAccountNoCache(id: string) {
        return this.client.get(`account/${id}?v=${Math.floor(Date.now() / 1000)}`);
    }

    public addUser(model: IUser) {
        return this.client.post<IUser>('users', model);
    }

    public editUser(model: IUser) {
        return this.client.put<IUser>('users', model);
    }

    public addEditUserIdentity(model: IUserIdentity) {
        return this.client.put<IUserIdentity>('users/identities', model);
    }

    public addEditUserPermissions(id: string, model: IUsersPermissions) {
        return this.client.put<IUsersPermissions>(`users/permissions`, model);
    }

    public updateSystemRoles(id: string, roles: string[]) {
        return this.client.put<void>(`users/${id}/systemroles`, roles);
    }

    public getUsersPermissions(id: string) {
        return this.client.get<IUsersPermissions>(`users/permissions/${id}`);
    }

    // * Admin User
    public addAdminUser(id: string, model: IAdminUser) {
        return this.client.post<IAdminUser>(`tenants/${id}/adminusers`, model);
    }

    public getAdminUsers(id: string) {
        return this.client.get<IAdminUser[]>(`tenants/${id}/adminusers`);
    }
    public getAdminUsersByID(id: string, adminUserId: string) {
        return this.client.get<IAdminUser>(`tenants/${id}/adminusers/${adminUserId}`);
    }

    public editAdminUser(id: string, model: IAdminUser) {
        return this.client.put<IAdminUser>(`tenants/${id}/adminusers`, model);
    }

    // * Account
    public getAccount() {
        return this.client.get<IAccount>('Account');
    }
}

const platformApi = new PlatformApi();
export default platformApi;
