import {
    IColumn,
    IDetailsHeaderProps,
    IRenderFunction,
    Link,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    Spinner,
    Stack,
    Sticky,
    StickyPositionType,
    Toggle,
    Text,
    TooltipHost,
    ConstrainMode,
} from '@fluentui/react';
import IPayer from 'api/models/payer.model';
import { Section, SortableDetailsList } from 'components';
import Fuse from 'fuse.js';
import useBenefitPlan from 'hooks/state/useBenefit';
import usePayers from 'hooks/state/usePayers';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import PayerPanel from './PayerPanel';
import { format } from 'date-fns';

export default function Payers(): JSX.Element {
    const { getPayers, showPayersHistory, toggleShowPayerHistory, payersLoading, payersAsList, getPayer, loadingSelectedPayer } =
        usePayers();

    const { getBenefitPlans, benefitPlanCategories } = useBenefitPlan();

    const fuse = useMemo(
        () =>
            new Fuse<IPayer>([], {
                threshold: 0.2,
                keys: ['name', 'payerId', 'street1', 'street2', 'state', 'city', ' zip', 'id'],
            }),
        [],
    );

    const [search, setSearch] = useState<string>('');
    const [results, setResults] = useState<IPayer[]>([]);

    const fuseSearch = () => {
        const results = fuse.search(search);
        const mappedResults = results.map((proc) => proc.item);
        setResults(mappedResults);
    };

    const _onSearch = () => fuseSearch();
    const _onSearchChange = (e?: any, value?: string) => {
        setSearch(value ? value : '');
    };

    useEffect(() => {
        if (!isEqual(payersAsList, results)) {
            fuse.setCollection(payersAsList);
            fuseSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payersAsList]);

    useEffect(() => {
        fuseSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getPayers();
        getBenefitPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditPayer = (payerId?: string) => {
        if (loadingSelectedPayer !== 'pending' && payerId) getPayer(payerId);
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (headerProps, defaultRender) => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header}>
                <div>{defaultRender!(headerProps)}</div>
            </Sticky>
        );
    };

    const _onRenderAddress = (item: IPayer) => {
        const { street1, street2, zip, state, city } = item;
        const street1String = street1 ? `${street1}` : '';
        const street2String = street2 ? `, ${street2}` : '';
        const cityString = city ? `, ${city}` : '';
        const stateString = state ? `${city ? ', ' : ''}${state}` : '';
        const zipString = zip ? ` ${zip}` : '';

        const addressString = `${street1String}${street2String}${cityString}${stateString}${zipString}`;
        return (
            <TooltipHost content={addressString}>
                <span>{addressString}</span>
            </TooltipHost>
        );
    };

    const _onRenderName = (item: IPayer) => (
        <Link onClick={() => onEditPayer(item.id)}>{item.isDeleted ? <i>{item.name}</i> : item.name}</Link>
    );

    const _onRenderBenefitPlan = (item?: IPayer) => {
        if (item && item.benefitPlanId) {
            const benefitPlanID = benefitPlanCategories.find((benefitPlan) => benefitPlan.key === item.benefitPlanId);
            if (item?.benefitPlanId === benefitPlanID?.key) {
                return <span>{benefitPlanID?.text}</span>;
            }
        }
    };

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Name',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            fieldName: 'name',
            onRender: _onRenderName,
        },
        {
            key: 'payerId',
            name: 'Payer Id',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'payerId',
        },
        {
            key: 'address',
            name: 'Address',
            minWidth: 150,
            maxWidth: 170,
            isResizable: true,
            fieldName: 'street1',
            onRender: _onRenderAddress,
        },
        {
            key: 'insurancePackageId',
            name: 'aOne Package Id',
            minWidth: 100,
            fieldName: 'insurancePackageId',
        },
        {
            key: 'createdOn',
            name: 'Created',
            minWidth: 100,
            maxWidth: 250,
            fieldName: 'createdOn',
            onRender: (item: IPayer) => <span>{format(new Date(item.createdOn ?? ''), 'Pp')}</span>,
        },
        {
            key: 'modifiedOn',
            name: 'Last Modified',
            minWidth: 100,
            maxWidth: 250,
            fieldName: 'modifiedOn',
            onRender: (item: IPayer) => <span>{format(new Date(item.modifiedOn ?? ''), 'Pp')}</span>,
        },
        {
            key: 'id',
            name: 'ID',
            minWidth: 250,
            maxWidth: 250,
            fieldName: 'id',
        },
        {
            key: 'benefitPlan',
            name: 'Benefit Plan',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'benefitPlanId',
            onRender: _onRenderBenefitPlan,
        },
    ];

    return (
        <Stack grow>
            <PayerPanel />
            <Section
                heading={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Text variant="large">Payers ({search ? results.length : payersAsList.length})</Text>
                    </Stack>
                }
                style={{ marginBottom: 0 }}
                styleContent={{ display: 'flex', flex: 1 }}
                headingRightContent={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow verticalAlign="end">
                        <SearchBox
                            style={{ minWidth: 300 }}
                            placeholder="Search by name, payer id, address and Id"
                            autoComplete="off"
                            onChange={_onSearchChange}
                            onSearch={_onSearch}
                        />
                        <Toggle
                            label="Active Only"
                            inlineLabel
                            checked={!showPayersHistory}
                            styles={{ root: { marginBottom: 3 } }}
                            onClick={toggleShowPayerHistory}
                        />
                    </Stack>
                }
                grow
            >
                {payersLoading === 'pending' ? (
                    <div style={{ width: '100%' }}>
                        <Spinner label="Loading..." />
                    </div>
                ) : payersLoading === 'completed' && search && !results.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No Results Found.</MessageBar>
                    </div>
                ) : payersLoading === 'completed' && payersAsList.length ? (
                    <div style={{ position: 'relative', flex: 1 }}>
                        <ScrollablePane scrollbarVisibility="auto">
                            <SortableDetailsList
                                selectionMode={SelectionMode.none}
                                constrainMode={ConstrainMode.unconstrained}
                                columns={columns}
                                items={results.length ? results : payersAsList}
                                onItemInvoked={onEditPayer}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                stickyHeader
                                compact
                                sortOnMount
                                sortColumns={['column1']}
                                initialSortDirection={'asc'}
                            />
                        </ScrollablePane>
                    </div>
                ) : payersLoading === 'completed' && !payersAsList.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No payers have been added yet.</MessageBar>
                    </div>
                ) : (
                    payersLoading === 'failed' && (
                        <div style={{ width: '100%' }}>
                            <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>
                        </div>
                    )
                )}
            </Section>
        </Stack>
    );
}
