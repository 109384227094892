import { DefaultEffects, Label, Spinner, Stack, useTheme } from '@fluentui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { liveTenants, showIsLiveCustomer } from './state/tenants.selectors';
import { toggleShowLiveCustomer } from './state/tenants.slice';

function LiveCustomer() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const tenantList = useSelector(liveTenants);
    const showLiveCustomer = useSelector(showIsLiveCustomer);
    const boxStyles = {
        root: {
            background: theme.palette.green,
            width: showLiveCustomer ? 115 : 110,
            height: showLiveCustomer ? 115 : 100,
            color: '#FFF',
            border: showLiveCustomer ? '2px solid red' : '',

            selectors: { ':hover': { boxShadow: DefaultEffects.elevation8 } },
        },
    };
    const textStyles = {
        cursor: 'pointer',
        background: theme.palette.greenDark,
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: showLiveCustomer ? '2px solid red' : '',
    };

    return (
        <Stack
            styles={boxStyles}
            horizontalAlign="center"
            onClick={() => {
                dispatch(toggleShowLiveCustomer());
            }}
        >
            <Stack grow style={{ fontSize: 48 }} verticalAlign="center">
                {<span>{tenantList.length}</span>}
            </Stack>
            <Stack horizontalAlign="center" style={textStyles}>
                <Label style={{ color: 'white' }}>Live Customers</Label>
            </Stack>
        </Stack>
    );
}

export default LiveCustomer;
