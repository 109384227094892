import { MessageBarType } from '@fluentui/react';
import ILocationOfCare from 'api/models/locationOfCare.model';
import ISubscribeToChangesPayload from 'api/models/subscribeToChangesPayload.model';
import { LoadingStatus } from 'interfaces/loadingStatus';

export type EventingChangesetState = {
    subscriptionActionLoading: LoadingStatus;
    loadingLocationsOfCare: LoadingStatus;
    modalOpen: boolean;
    payload?: ISubscribeToChangesPayload;
    locationsOfCare?: Record<string, ILocationOfCare>
    modalAction?: EventingChangesetAction;
    messageBarType?: MessageBarType;
    messageBarMessage?: string;
};

export enum EventingChangesetAction {
    Subscribe = 'Subscribe',
    Unsubscribe = 'Unsubscribe',
}

const initialEventingChangesetsState: EventingChangesetState = {
    subscriptionActionLoading: LoadingStatus.Idle,
    loadingLocationsOfCare: LoadingStatus.Idle,
    modalOpen: false,
};

export default initialEventingChangesetsState;
