import {
    IColumn,
    IconButton,
    IDetailsHeaderProps,
    IRenderFunction,
    Link,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    SelectionMode,
    Spinner,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import IBenefitPlan from 'api/models/benefitPlan.model';

import { Section, SortableDetailsList } from 'components';
import useBenefitPlan from 'hooks/state/useBenefit';

import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDefaultBenefitPlans } from 'state/slices/benefit-plan/benefit-plan.slice';

import BenefitPlanPanel from './BenefitPlanPanel';

export default function BenefitPlan(): JSX.Element {
    const {
        toggleShowBenefitPlanHistory,
        showBenefitPlanHistory,
        getBenefitPlans,
        benefitPlanAsList,
        getBenefitPlanById,
        loading,
        loadingBenefitPlan,

        setBenefitPlanPropandSave,
        getProcedureCategories,
    } = useBenefitPlan();

    const _dispatch = useDispatch();

    useEffect(() => {
        getBenefitPlans();
        getProcedureCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBenfitClick = (item: IBenefitPlan) => {
        if (loadingBenefitPlan !== LoadingStatus.Pending) {
            getBenefitPlanById(item.id);
        }
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (headerProps, defaultRender) => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header}>
                <div>{defaultRender && defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    const _addNewBenefitPlan = () => {
        _dispatch(getDefaultBenefitPlans());
    };

    const columns: IColumn[] = [
        {
            key: 'displayName',
            name: 'Plan Name',
            minWidth: 75,

            fieldName: 'displayName',

            onRender: (item: IBenefitPlan) => (
                <Link onClick={() => onBenfitClick(item)}>{item.isDeleted ? <i>{item.displayName}</i> : item.displayName}</Link>
            ),
        },

        {
            key: 'isDeleted',
            name: 'Deactivated',
            minWidth: 80,

            fieldName: 'isDeleted',
            onRender: (item: IBenefitPlan) => (
                <Stack grow horizontalAlign="end">
                    <TooltipHost content="Toggle procedure deactivated.">
                        <Toggle
                            styles={{ root: { margin: 0 } }}
                            checked={item?.isDeleted}
                            onChange={() => setBenefitPlanPropandSave(item.id, 'isDeleted', !item?.isDeleted)}
                        />
                    </TooltipHost>
                </Stack>
            ),
        },
    ];

    return (
        <Stack grow>
            <BenefitPlanPanel />
            <Section
                heading={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Text variant="large">Benefit Plan</Text>
                        <TooltipHost content="Add New Benefit Plan">
                            <IconButton
                                onClick={_addNewBenefitPlan}
                                styles={{ root: { maxHeight: 25, maxWidth: 25 } }}
                                iconProps={{ iconName: 'Add' }}
                            />
                        </TooltipHost>
                    </Stack>
                }
                style={{ marginBottom: 0 }}
                styleContent={{ display: 'flex', flex: 1 }}
                headingRightContent={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow verticalAlign="end">
                        <Toggle
                            label="Active Only"
                            inlineLabel
                            checked={showBenefitPlanHistory}
                            styles={{ root: { marginBottom: 3 } }}
                            onClick={toggleShowBenefitPlanHistory}
                        />
                    </Stack>
                }
                grow
            >
                {loading === 'pending' ? (
                    <div style={{ width: '100%' }}>
                        <Spinner label="Loading..." />
                    </div>
                ) : loading === 'completed' && benefitPlanAsList.length ? (
                    <div style={{ position: 'relative', flex: 1 }}>
                        <ScrollablePane scrollbarVisibility="auto">
                            <SortableDetailsList
                                selectionMode={SelectionMode.none}
                                columns={columns}
                                items={benefitPlanAsList}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                compact
                                stickyHeader
                                sortOnMount
                                sortColumns={['code']}
                                initialSortDirection={['asc']}
                            />
                        </ScrollablePane>
                    </div>
                ) : loading === 'completed' && !benefitPlanAsList.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No benefit plan have been added yet.</MessageBar>
                    </div>
                ) : (
                    loading === 'failed' && (
                        <div style={{ width: '100%' }}>
                            <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>
                        </div>
                    )
                )}
            </Section>
        </Stack>
    );
}
