import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import IProduct from 'api/models/product.model';
import ErrorTypes from 'state/errorTypes';
import { PlatformApi } from 'api';
import axios, { AxiosError } from 'axios';
import { filter } from 'lodash';
import { LoadingStatus } from 'interfaces/loadingStatus';

const initialState: ProductsState = {
    data: [],
    loading: LoadingStatus.Idle,
    isAdding: false,
};

/**
 * Gets all products defined in the system.
 */
export const getProducts = createAsyncThunk<IProduct[], void, { rejectValue: string }>(
    'products/getProducts',
    async (_: void, { rejectWithValue }) => {
        try {
            const { data: products } = await PlatformApi.getProducts();
            //filter out potential undefined values (mainly for TypeScript)
            const result = filter(products, (product) => product !== undefined) as IProduct[];
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

/**
 * Gets a single product based on productId.
 */
export const getProduct = createAsyncThunk<
    IProduct,
    { productId: string },
    { rejectValue: string }
>('products/getProduct', async ({ productId }, { rejectWithValue }) => {
    try {
        const { data: result } = await PlatformApi.getProduct(productId);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

/**
 * Creates a new product.
 */
export const addProduct = createAsyncThunk<
    IProduct,
    { product: IProduct },
    { rejectValue: AxiosError | string }
>('products/addProduct', async ({ product }, { rejectWithValue }) => {
    try {
        const { data: result } = await PlatformApi.addProduct(product);
        return result;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            return rejectWithValue(err);
        } else {
            return rejectWithValue('Something went wrong with adding a product');
        }
    }
});

/**
 * Edit an existing product.
 */
export const editProduct = createAsyncThunk<
    IProduct,
    { product: IProduct },
    { rejectValue: string }
>('products/editProduct', async ({ product }, { rejectWithValue }) => {
    try {
        const { data: result } = await PlatformApi.editProduct(product);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //getProducts
            .addCase(getProducts.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.data = action.payload;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })

            //getProduct
            .addCase(getProduct.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.productData = action.payload;
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })

            //addProduct
            .addCase(addProduct.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                if (state.data) {
                    state.loading = LoadingStatus.Completed;
                    state.isAdding = false;
                    state.errors = null;
                    state.data = [...state.data, action.payload];
                }
            })
            .addCase(addProduct.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //editProduct
            .addCase(editProduct.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(editProduct.fulfilled, (state, action) => {
                if (state.data) {
                    state.loading = LoadingStatus.Completed;
                    state.errors = null;
                    state.isAdding = false;
                    state.data = [
                        ...state.data.map((product) => {
                            if (product.id === action.payload.id) return action.payload;
                            else return product;
                        }),
                    ];
                }
            })
            .addCase(editProduct.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

export default productsSlice.reducer;
export const productsState = (state: RootState) => state.products;
export const selectProductsData = createSelector(productsState, (state) => state.data);
export const selectProductsLoading = createSelector(productsState, (state) => state.loading);

type ProductsState = {
    data: IProduct[];
    loading: LoadingStatus;
    productData?: IProduct;
    errors?: any;
    isAdding: boolean;
};
