import { createAsyncThunk } from '@reduxjs/toolkit';
import ErrorTypes from 'state/errorTypes';
import { AppThunk } from 'state/store';
import reportsApi from './reports.api';
import { IReportBatch, IWorkspace, IWorkspaceReports, IWorkspaces } from './reports.model';

export const getWorkspaces = createAsyncThunk<IWorkspaces, void, { rejectValue: string }>(
    'reports/getWorkspaces',
    async (_, { rejectWithValue }) => {
        try {
            const { data: result } = await reportsApi.getWorkspaces();
            return result.items;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getWorkSpaceReport = createAsyncThunk<IWorkspaceReports, string | undefined, { rejectValue: string }>(
    'reports/getWorkSpaceReport',
    async (tenantId, { rejectWithValue }) => {
        try {
            const { data: result } = await reportsApi.getWorkSpaceReport(tenantId);

            return result.items;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const copyReportBatch = createAsyncThunk<IReportBatch, { model: IReportBatch }, { rejectValue: string }>(
    'reports/copyReportBatch',
    async ({ model }, { rejectWithValue }) => {
        try {
            const { data: result } = await reportsApi.copyReportBatch(model);

            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            }
            if (err.response && err.response.status === 500) {
                return rejectWithValue(err.response.data.message);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const updatedBatchReports = (): AppThunk<void> => (dispatch, getState) => {
    const reports = getState().reports.selectedReports.map((x) => x.reportId ?? '');
    const workspaces = getState().reports.selectedWorkspaces;

    if (reports.length && workspaces.length) {
        const model: IReportBatch = {
            tenantsReports: workspaces.map((x) => {
                return {
                    sourceReportIds: reports,
                    targetTenantId: x.workspaceName,
                };
            }),
        };

        dispatch(copyReportBatch({ model }));
    }
};
