import React, { useReducer, useEffect, useRef } from 'react';
import { IMaskedTextField, IRefObject, ITextFieldProps, MaskedTextField } from '@fluentui/react';

type Props = {
    handleError?: (error: string | undefined) => void;
    copyOneTime?: boolean;
} & ITextFieldProps;

type Actions = { type: 'setInternalValue'; value?: string };

type State = {
    value?: string;
};

function initialState(value: string): State {
    return { value };
}

const isValueEmpty = (value?: string) => value === '(___) ___-____';
const validatePhone = (phone: string | undefined) =>
    phone && /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone);

const reducer = (state: State, action: Actions) => {
    switch (action.type) {
        case 'setInternalValue':
            return { ...state, value: action.value };
        default:
            return state;
    }
};

const PhoneField = ({ value, onChange, handleError, ...props }: Props): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, value ? value : '', initialState);
    const ref = useRef<IMaskedTextField>(null);
    const event: any = new Event('input', {
        bubbles: true,
        cancelable: true,
    });

    useEffect(() => {
        if (value !== null && value !== '' && !isValueEmpty(state.value) && state.value != value) {
            dispatch({ type: 'setInternalValue', value });
        } else {
            dispatch({ type: 'setInternalValue', value }), handleChange(event, value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onGetErrorMessage = (value: string) => {
        if (isValueEmpty(value)) return undefined;
        const errorPhoneMessage = validatePhone(value) ? undefined : 'Invalid Phone Number';
        if (handleError) handleError(errorPhoneMessage);
        return errorPhoneMessage;
    };

    const handleChange = (
        e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
        value?: string,
    ) => {
        if (value && onChange) {
            if (validatePhone(value)) {
                onChange(event, ref.current?.value ? ref.current?.value : value);
            } else if (isValueEmpty(state.value)) {
                onChange(event, '');
            } else {
                onChange(event, value.replace(/([_()\s+-])/g, ''));
            }
        }
    };

    return (
        <MaskedTextField
            {...props}
            componentRef={ref}
            onChange={handleChange}
            value={state.value}
            mask="(999) 999-9999"
            onClick={(ev) => {
                if (!state.value || isValueEmpty(state.value))
                    ev.currentTarget.setSelectionRange(0, 0);
            }}
            onGetErrorMessage={onGetErrorMessage}
            validateOnFocusOut
            validateOnLoad={value ? true : false}
        />
    );
};

export default PhoneField;
