import { Checkbox, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, Stack, TextField } from '@fluentui/react';
import { ValidationBar } from 'components';
import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import useValidation, { IValidationConfig, getValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loadingStatus';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFederalPovertyRegions } from 'state/slices/poverty-guidelines/poverty-guidelines.slice';
import { errorDisplayNameMessageLookup, errorNameMessageLookup } from './About';
import { selectedTenantPanelIsOpen, selectedTenantValidationError } from './state/tenants.selectors';

const AddEditTenantsModal = () => {
    const {
        selectTenant,

        saving,

        addTenant,

        updateSelectedTenantsProp,
        setTenantPanelIsOpen,
    } = useTenant();

    const isPanelOpen = useSelector(selectedTenantPanelIsOpen);
    const validationError = useSelector(selectedTenantValidationError);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFederalPovertyRegions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'id',
            validation: ['required'],
            value: selectTenant?.id,
        },
        {
            fieldName: 'Tenant Name',
            validation: ['required', 'characterLimit'],
            value: selectTenant?.name,
            itemOptions: { characterLimit: 16 },
        },
        {
            fieldName: 'Tenant Display Name',
            validation: ['required', 'characterLimit'],
            value: selectTenant?.displayName,
            itemOptions: { characterLimit: 64 },
        },
    ];

    function _onDismiss() {
        setTenantPanelIsOpen(false);
    }

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onAddOrSave);

    const nameValidationError = getValidationError(errors, 'Tenant Name');
    const displayNameValidationError = getValidationError(errors, 'Tenant Display Name');

    const validNumberInput = (value?: string) => {
        const regex = value ? /^[0-9\b]+$/.test(value) : true;
        return regex;
    };

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z0-9\s-']*$/.test(value) : true;
        return res;
    };
    const validTextNoSpaceInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z0-9']*$/.test(value) : true;
        return res;
    };

    function onAddOrSave() {
        if (selectTenant) {
            addTenant(selectTenant);
        }
    }

    useEffect(() => {
        return () => {
            cleanupErrors();
        };
    }, [isPanelOpen]);

    return (
        <Panel
            headerText={`Add Tenant`}
            isOpen={isPanelOpen}
            isFooterAtBottom
            type={PanelType.medium}
            onDismiss={_onDismiss}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <ValidationBar errors={errors} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton text={'Save'} onClick={onSubmit} disabled={saving === LoadingStatus.Pending} />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                        <Stack grow horizontalAlign="end">
                            {saving === LoadingStatus.Pending && <Spinner label={'Saving...'} labelPosition="left" />}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack styles={{ root: { padding: 10 } }} tokens={{ childrenGap: 5 }} grow>
                <TextField
                    value={selectTenant?.id}
                    onChange={(ev, value: any) => {
                        if (validNumberInput(value)) updateSelectedTenantsProp('id', value);
                    }}
                    label="Tenant Id"
                    required
                    autoComplete="none"
                    errorMessage={getValidationError(errors, 'id') ? 'Tenant Id is required' : undefined}
                />
                <TextField
                    value={selectTenant?.name ?? ''}
                    onChange={(ev, value) => {
                        if (validTextNoSpaceInput(value)) {
                            updateSelectedTenantsProp('name', value);
                        }
                    }}
                    label={`${'Tenant Database Name (Internal Only)'}`}
                    required
                    autoComplete="none"
                    description={`${selectTenant?.name?.length || 0} of 16 characters used`}
                    errorMessage={nameValidationError ? errorNameMessageLookup[nameValidationError.errorTypes[0]] : undefined}
                />
                <TextField
                    value={selectTenant?.displayName}
                    onChange={(ev, value: any) => {
                        if (validTextInput(value)) updateSelectedTenantsProp('displayName', value);
                    }}
                    label="Tenant Display Name"
                    description={`${selectTenant?.displayName?.length || 0} of 64 characters used`}
                    required
                    errorMessage={
                        displayNameValidationError
                            ? errorDisplayNameMessageLookup[displayNameValidationError.errorTypes[0]]
                            : undefined
                    }
                    autoComplete="none"
                />
                <Checkbox
                    label="Net New"
                    checked={selectTenant?.isNetNew}
                    onChange={(ev, value) => updateSelectedTenantsProp('isNetNew', value)}
                />
            </Stack>
        </Panel>
    );
};

export default AddEditTenantsModal;
