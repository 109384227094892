import React from 'react';
import { IContextualMenuProps, Stack } from '@fluentui/react';
import { ActionButton } from '..';
import { PropsWithChildren, CSSProperties } from 'react';
import './ActivityPage.scss';
import { IActionButton } from 'components/ActionButton';
import { Helmet } from 'react-helmet';
import { IAsyncSearchComboField } from 'components/Field/TypeaheadSearchField';
import ActivityHeader from 'components/ActivityHeader';

type Props = {
    title: JSX.Element | string;
    mainButtons?: IActionButton[];
    typeaheadSearchField?: IAsyncSearchComboField;
    centerContent?: React.ReactNode;
    rightContent?: JSX.Element;
    leftContent?: JSX.Element;
    disableOverflow?: boolean;
    hideFooter?: boolean;
    farItems?: IContextualMenuProps;
    scrollPaneStyle?: CSSProperties;
    className?: string;
    hasPageHeader?: boolean;
};

/**
 * Activity Page
 *  @description Primary page layout component for a given route.
 *  @param {PropsWithChildren<Props>} props
 */
const ActivityPage = (props: PropsWithChildren<Props>): JSX.Element => {
    const _hasMainButtons = props.mainButtons && props.mainButtons.length;
    const _mainButtons = _hasMainButtons
        ? props.mainButtons?.map((button, index) => {
              return <ActionButton {...button} key={index} />;
          })
        : null;

    return (
        <div className={`activityPage ${props.className}`}>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            {props.hasPageHeader === true && (
                <div className="activityPage__header">
                    <ActivityHeader
                        mainButtons={props.mainButtons}
                        typeaheadSearchField={props.typeaheadSearchField}
                        farItems={props.farItems}
                        rightContent={props.rightContent}
                        leftContent={props.leftContent}
                        title={props.title}
                    >
                        {props.centerContent}
                    </ActivityHeader>
                </div>
            )}
            <Stack
                className="activityPage__body"
                style={{ overflowY: props.disableOverflow ? 'hidden' : 'auto' }}
            >
                {props.children}
                {_mainButtons && !props.hideFooter && (
                    <div className="activityPage__footer">
                        <Stack
                            horizontal
                            style={{
                                flex: 1,
                                justifyContent: 'flex-end',
                                display: 'flex',
                                marginRight: 10,
                            }}
                            tokens={{ childrenGap: 12 }}
                        >
                            {_mainButtons}
                        </Stack>
                    </div>
                )}
            </Stack>
        </div>
    );
};

export default ActivityPage;
