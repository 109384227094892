import { createSelector } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RootState } from 'state/store';

export const reportState = (state: RootState) => state.reports;

export const workspaceList = createSelector(reportState, (state) => state.workspaces ?? []);
export const workspaceListLoading = createSelector(reportState, (state) => state.loadingWorkSpaces);
export const selectedWorkspace = createSelector(reportState, (state) => state.selectedWorkspaces);

export const workspaceReportList = createSelector(reportState, (state) => state.reports ?? []);
export const workspaceReportListLoading = createSelector(reportState, (state) => state.loadingReports);
export const selectedReport = createSelector(reportState, (state) => state.selectedReports);

export const selectWorkspaceLoadingErrors = createSelector(reportState, (state) => state.errors);

export const workSpaceAndReportListLoading = createSelector(
    workspaceListLoading,
    workspaceReportListLoading,
    (workSpaceListLoading, workSpaceReportListLoading) =>
        workSpaceListLoading === LoadingStatus.Completed && workSpaceReportListLoading === LoadingStatus.Completed,
);
