import {
    DefaultButton,
    Dropdown,
    IconButton,
    IDropdownOption,
    Label,
    Link,
    MessageBar,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { Services } from 'views/pages/Tenants/state/tenants.model';
import ITenantProduct from 'api/models/tenantProduct.model';
import { Field } from 'components';
import { useSelector, useValidation } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import { getValidationError, IValidationConfig } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addTenantProduct, editTenantProduct } from 'views/pages/Tenants/state/tenants.actions';

import { doesDateRangeOverlap } from 'utils/doesDateRangeOverlap';
import { PanelSectionHeader } from 'views/component';
import {
    selectAvailableProductServices,
    selectSelectedProductServiceSubscriptions,
    selectedTenantProductPanelIsOpen,
} from 'views/pages/Tenants/state/tenants.selectors';
import {
    addProductServiceSubscription,
    removeProductServiceSubscription,
    setProductServiceSubscriptionProp,
} from 'views/pages/Tenants/state/tenants.slice';

const AddEditTenantProductModal = () => {
    const params = useParams<{ tenantId: string; path: string; itemId: string }>();
    const dispatch = useDispatch();
    const { palette } = useTheme();
    const { data: products } = useSelector((state) => state.products);
    const tenantSubscriptions = useSelector((state) => state.tenants.tenantProducts);
    const _selectAvailableProductServices = useSelector(selectAvailableProductServices);
    const _productServiceSubscriptions = useSelector(selectSelectedProductServiceSubscriptions);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const {
        isAdding,
        selectedTenantProduct,
        saving,
        cleanupSelectedProductTenants,
        updateSelectedTenantsProductsProp,
        setTenantProductPanelIsOpen,
    } = useTenant();

    const isPanelOpen = useSelector(selectedTenantProductPanelIsOpen);

    const serviceValidation: IValidationConfig = _productServiceSubscriptions.map((service, index) => ({
        fieldName: `Start Date ${index + 1}`,
        validation: ['required'],
        value: service.startDate,
    }));

    const validationConfig: IValidationConfig = [
        ...serviceValidation,
        {
            fieldName: 'Product',
            validation: ['required'],
            value: selectedTenantProduct?.productId,
        },
        {
            fieldName: 'Start Date',
            validation: ['required'],
            value: selectedTenantProduct?.startDate,
        },
    ];

    const validateSubscription = (subscription: ITenantProduct) => {
        let isValid = true;

        //validate that dates don't overlap
        if (tenantSubscriptions) {
            //todo: deal with disabled subscriptions?
            const subscriptions = isAdding
                ? tenantSubscriptions.filter((ts) => !ts.isDeleted && ts.productId === subscription.productId)
                : tenantSubscriptions.filter(
                      (ts) => !ts.isDeleted && !(ts.id === subscription.id || ts.productId === subscription.id),
                  );

            //can only have one indefinite product subscription
            if (!subscription.endDate) {
                const indefiniteSubscriptions = subscriptions.filter((s) => !s.endDate);
                if (indefiniteSubscriptions.length > 0) {
                    isValid = false;
                    setErrorMessage('There can only be one indefinite product subscription.');
                }
            }

            if (isValid) {
                for (let i = 0; i < subscriptions.filter((sub) => sub.productId === subscription.productId).length; i++) {
                    const s = subscriptions[i];
                    const hasOverlap = doesDateRangeOverlap(
                        new Date(subscription.startDate),
                        new Date(s.startDate),
                        subscription.endDate ? new Date(subscription.endDate) : undefined,
                        s.endDate ? new Date(s.endDate) : undefined,
                    );
                    if (hasOverlap) {
                        isValid = false;
                        setErrorMessage('This subscription cannot overlap with another subscription for the same product.');
                        break;
                    }
                }
            }
        }

        if (isValid && subscription.endDate && subscription.startDate >= subscription.endDate) {
            isValid = false;
            setErrorMessage('The end date must be empty or greater than the start date.');
        }

        return isValid;
    };

    const onFinish = () => {
        if (selectedTenantProduct) {
            const isValid = validateSubscription(selectedTenantProduct);
            if (isValid) {
                if (isAdding) {
                    dispatch(
                        addTenantProduct({
                            tenantId: params.tenantId,
                            product: selectedTenantProduct,
                        }),
                    );
                } else
                    dispatch(
                        editTenantProduct({
                            tenantId: params.tenantId,
                            product: selectedTenantProduct,
                        }),
                    );

                onCloseModal();
            }
        }
    };

    const onCloseModal = () => {
        cleanupSelectedProductTenants();

        setTenantProductPanelIsOpen(false);
    };

    const [errors, onSubmit] = useValidation(validationConfig, onFinish);

    const productOptions: IDropdownOption[] = products
        ? products
              .filter((p) => {
                  if (!isAdding) return true;

                  return !p.isDeleted;
              })
              .map((p) => {
                  return {
                      key: p.id,
                      text: p.displayName,
                      selected: p.id === selectedTenantProduct?.productId,
                  };
              })
        : [];

    return (
        <Panel
            headerText={isAdding ? 'Add Product Subscription' : 'Edit Product Subscription'}
            isOpen={isPanelOpen}
            onDismiss={onCloseModal}
            isFooterAtBottom
            type={PanelType.medium}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton
                            text={isAdding ? 'Add' : 'Save'}
                            onClick={onSubmit}
                            disabled={saving === LoadingStatus.Pending}
                        />
                        <DefaultButton text="Cancel" onClick={onCloseModal} />
                        <Stack grow horizontalAlign="end">
                            {saving === LoadingStatus.Pending && (
                                <Spinner label={isAdding ? 'Adding...' : 'Saving...'} labelPosition="left" />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Dropdown
                    label="Product"
                    required
                    disabled={!isAdding}
                    options={productOptions}
                    selectedKey={selectedTenantProduct?.productId}
                    onChange={(ev, option) => updateSelectedTenantsProductsProp('productId', option?.key as string)}
                    errorMessage={getValidationError(errors, `Product`) ? 'Product is required.' : undefined}
                />
                <Field.Date
                    label="Start Date"
                    required
                    value={selectedTenantProduct?.startDate ?? ''}
                    hasDatePicker
                    onChange={(ev, value) => updateSelectedTenantsProductsProp('startDate', value)}
                    errorMessage={getValidationError(errors, `Start Date`) ? 'Start date is required.' : undefined}
                />
                <Field.Date
                    label="End Date"
                    value={selectedTenantProduct?.endDate ?? ''}
                    hasDatePicker
                    onChange={(ev, value) => updateSelectedTenantsProductsProp('endDate', value)}
                    isReasonable
                />
                <PanelSectionHeader
                    text="Service Subscriptions"
                    rightContent={
                        <TooltipHost
                            content={
                                !_selectAvailableProductServices.length
                                    ? 'There are no applicable services available for this product subscription.'
                                    : undefined
                            }
                        >
                            <IconButton
                                iconProps={{ iconName: 'Add' }}
                                disabled={!_selectAvailableProductServices.length}
                                menuProps={{
                                    items: _selectAvailableProductServices.map((service) => ({
                                        key: service.id,
                                        text: service.displayName,
                                        onClick: () => {
                                            dispatch(addProductServiceSubscription(service.id as Services));
                                        },
                                    })),
                                }}
                                styles={{ menuIcon: { display: 'none !important' } }}
                            />
                        </TooltipHost>
                    }
                />
                {_productServiceSubscriptions.length ? (
                    _productServiceSubscriptions.map((service, index) => (
                        <Stack
                            style={{
                                paddingBottom: 15,
                                borderBottom: `1px solid ${palette.neutralLight}`,
                            }}
                            key={index}
                            grow
                            tokens={{ childrenGap: 5 }}
                        >
                            <Stack grow>
                                <Stack horizontalAlign="space-between" horizontal grow>
                                    <Label>{service.serviceName ?? ''}</Label>
                                    <Link
                                        onClick={() => {
                                            dispatch(removeProductServiceSubscription(service.id));
                                        }}
                                    >
                                        Remove
                                    </Link>
                                </Stack>
                                <Stack horizontal tokens={{ childrenGap: 5 }} grow>
                                    <Stack.Item grow>
                                        <Field.Date
                                            required
                                            label="Start Date"
                                            hasDatePicker
                                            value={service.startDate}
                                            errorMessage={
                                                getValidationError(errors, `Start Date ${index + 1}`)
                                                    ? 'Start date is required.'
                                                    : undefined
                                            }
                                            onChange={(ev, value) => {
                                                dispatch(
                                                    setProductServiceSubscriptionProp({
                                                        id: service.id,
                                                        path: 'startDate',
                                                        value,
                                                    }),
                                                );
                                            }}
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Field.Date
                                            label="End Date"
                                            hasDatePicker
                                            value={service.endDate}
                                            onChange={(ev, value) => {
                                                dispatch(
                                                    setProductServiceSubscriptionProp({
                                                        id: service.id,
                                                        path: 'endDate',
                                                        value,
                                                    }),
                                                );
                                            }}
                                            isReasonable
                                            minReasonableDate={service?.startDate ? new Date(service?.startDate) : undefined}
                                            minReasonableErrorMessage={'End date must be after start date.'}
                                            disabled={!service.startDate}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Stack>
                    ))
                ) : (
                    <MessageBar>No service subscriptions have been added.</MessageBar>
                )}
            </Stack>
        </Panel>
    );
};

export default AddEditTenantProductModal;
