import { IDiagnosis } from 'api/models/diagnosis.model';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    diagnosisAsList,
    diagnosisData,
    diagnosisState,
    selectDiagnosesCategories,
    selectDiagnosesSubcategories,
    showDiagnosisHistory,
} from 'state/slices/diagnosis/diagnosis.selectors';
import {
    addDiagnosis,
    addNewDiagnosis,
    cleanupSelectedDiagnosis,
    getDiagnoses,
    getDiagnosisById,
    toggleShowDiagnosisHistory,
    setDiagnosisPropandSave,
    updateSelectedDiagnosisProp,
    updateDiagnosis,
} from 'state/slices/diagnosis/diagnosis.slice';

function useDiagnosis() {
    const _dispatch = useDispatch();

    const { loading, loadingDiagnosis, saving, selectedDiagnosis, isAdding } =
        useSelector(diagnosisState);

    const _showDiagnosisHistory = useSelector(showDiagnosisHistory);
    const _diagnosisData = useSelector(diagnosisData);
    const _diagnosisAsList = useSelector(diagnosisAsList);
    const _selectDiagnosesCategories = useSelector(selectDiagnosesCategories);
    const _selectDiagnosesSubcategories = useSelector(selectDiagnosesSubcategories);

    const _getDiagnoses = useCallback(() => {
        _dispatch(getDiagnoses());
    }, [_dispatch]);

    const _getDiagnosisById = useCallback(
        (diagnosisId: string) => {
            _dispatch(getDiagnosisById({ diagnosisId }));
        },
        [_dispatch],
    );

    const _cleanupSelectedDiagnosis = useCallback(() => {
        _dispatch(cleanupSelectedDiagnosis());
    }, [_dispatch]);

    const _toggleShowDiagnosisHistory = useCallback(() => {
        _dispatch(toggleShowDiagnosisHistory());
    }, [_dispatch]);

    const _addDiagnosis = useCallback(
        (diagnosis: IDiagnosis) => {
            _dispatch(addDiagnosis({ diagnosis }));
        },
        [_dispatch],
    );

    const _addNewDiagnosis = useCallback(() => {
        _dispatch(addNewDiagnosis());
    }, [_dispatch]);

    const _setDiagnosisPropAndSave = useCallback(
        (id: string, path: keyof IDiagnosis, value: any) => {
            _dispatch(setDiagnosisPropandSave(id, path, value));
        },
        [_dispatch],
    );

    const _updateSelectedDiagnosisProp = useCallback(
        (path: keyof IDiagnosis, value: any) => {
            _dispatch(updateSelectedDiagnosisProp({ path, value }));
        },
        [_dispatch],
    );

    const _updateDiagnosis = useCallback(
        (diagnosis: IDiagnosis) => {
            _dispatch(updateDiagnosis({ diagnosis }));
        },
        [_dispatch],
    );
    return {
        // Selectors
        loading,
        loadingDiagnosis,
        selectedDiagnosis,
        saving,
        isAdding,
        showDiagnosisHistory: _showDiagnosisHistory,
        diagnosisData: _diagnosisData,
        diagnosisAsList: _diagnosisAsList,
        selectDiagnosesCategories: _selectDiagnosesCategories,
        selectDiagnosesSubcategories: _selectDiagnosesSubcategories,
        // Async Actions
        getDiagnoses: _getDiagnoses,
        getDiagnosisById: _getDiagnosisById,
        addDiagnosis: _addDiagnosis,
        setDiagnosisPropandSave: _setDiagnosisPropAndSave,
        updateDiagnosis: _updateDiagnosis,

        // Actions

        addNewDiagnosis: _addNewDiagnosis,
        toggleShowDiagnosisHistory: _toggleShowDiagnosisHistory,
        updateSelectedDiagnosisProp: _updateSelectedDiagnosisProp,
        cleanupSelectedDiagnosis: _cleanupSelectedDiagnosis,
    };
}

export default useDiagnosis;
