import {
    IColumn,
    Link,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    SelectionMode,
    Spinner,
    Stack,
    Text,
} from '@fluentui/react';
import IPovertyGuideline from 'api/models/povertyGuidline.model';
import { Section, SortableDetailsList } from 'components';
import useFPG from 'hooks/state/useFPG';
import { useEffect } from 'react';
import { payersAsList } from 'state/slices/payers/payers.slice';
import dateOnly from 'utils/dateOnly';
import FPGPanel from './FPGPanel';

export default function FPG() {
    const { getPovertyGuidlines, loading, povertyGuidlinesByUniqLocations, setSelectedLocation } =
        useFPG();

    useEffect(() => {
        getPovertyGuidlines();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onItemInvoked(item?: IPovertyGuideline) {
        if (item) setSelectedLocation(item.location);
    }

    const columns: IColumn[] = [
        {
            key: 'location',
            name: 'Location',
            minWidth: 75,
            maxWidth: 75,
            fieldName: 'location',
            onRender: (item: IPovertyGuideline) => (
                <Link onClick={() => onItemInvoked(item)}>{item.location}</Link>
            ),
        },
        {
            key: 'currentYear',
            name: 'Current Year',
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'year',
            onRender: (item: IPovertyGuideline) => <span>{item.year ?? item.year}</span>,
        },
        {
            key: 'effectiveDate',
            name: 'Effective Date',
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'effectiveDate',
            onRender: (item: IPovertyGuideline) => (
                <span>{item.effectiveDate ? dateOnly(item.effectiveDate) : ''}</span>
            ),
        },
        {
            key: 'endDate',
            name: 'End Date',
            minWidth: 75,
            maxWidth: 75,
            fieldName: 'endDate',
            onRender: (item: IPovertyGuideline) => (
                <span>{item.endDate ? dateOnly(item.endDate) : ''}</span>
            ),
        },
    ];

    return (
        <>
            <FPGPanel />
            <Section
                heading={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Text variant="large">Federal Poverty Guidelines</Text>
                    </Stack>
                }
                style={{ marginBottom: 0 }}
                styleContent={{ display: 'flex', flex: 1 }}
                grow
            >
                {loading === 'pending' ? (
                    <div style={{ width: '100%' }}>
                        <Spinner label="Loading..." />
                    </div>
                ) : loading === 'completed' && povertyGuidlinesByUniqLocations.length ? (
                    <div style={{ position: 'relative', flex: 1 }}>
                        <ScrollablePane scrollbarVisibility="auto">
                            <SortableDetailsList
                                selectionMode={SelectionMode.none}
                                columns={columns}
                                items={povertyGuidlinesByUniqLocations}
                                onItemInvoked={onItemInvoked}
                                compact
                                stickyHeader
                                sortOnMount
                                sortColumns={['location']}
                                initialSortDirection={['asc']}
                            />
                        </ScrollablePane>
                    </div>
                ) : loading === 'completed' && !payersAsList.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No payers have been added yet.</MessageBar>
                    </div>
                ) : (
                    loading === 'failed' && (
                        <div style={{ width: '100%' }}>
                            <MessageBar messageBarType={MessageBarType.error}>
                                Something went wrong.
                            </MessageBar>
                        </div>
                    )
                )}
            </Section>
        </>
    );
}
