import { LoadingStatus } from 'interfaces/loadingStatus';
import { IWorkspaces, IWorkspaceReports, IWorkspaceReport, IWorkspace } from './reports.model';

export const initialState: ReportsState = {
    loadingWorkSpaces: LoadingStatus.Idle,
    loadingReports: LoadingStatus.Idle,
    selectedReports: [],
    selectedWorkspaces: [],
};

export type ReportsState = {
    loadingWorkSpaces: LoadingStatus;
    loadingReports: LoadingStatus;
    workspaces?: IWorkspaces;
    reports?: IWorkspaceReports;
    errors?: string;
    selectedReports: IWorkspaceReport[];
    selectedWorkspaces: IWorkspace[];
};
