import { PropsWithChildren } from 'react';
import { IModalProps, Stack, Modal, IDragOptions, ContextualMenu } from '@fluentui/react';
import { ActivityHeader } from '.';
import ActionButton, { IActionButton } from './ActionButton';

type Props = {
    title: string;
    modalProps: IModalProps;
    subTitleContent?: JSX.Element;
    mainButtons?: IActionButton[];
    hideClose?: boolean;
    isDraggable?: boolean;
};

const TModal = ({
    title,
    modalProps,
    subTitleContent,
    mainButtons,
    hideClose,
    isDraggable,
    children,
}: PropsWithChildren<Props>): JSX.Element => {
    const _hasMainButtons = mainButtons && mainButtons.length;
    const _mainButtons = _hasMainButtons
        ? mainButtons?.map((button, index) => {
              return <ActionButton {...button} key={index} />;
          })
        : null;

    const activityHeaderMainButtons: IActionButton[] = [
        {
            type: 'IconButton',
            iconProps: {
                iconName: 'Cancel',
            },
            onClick: () => (modalProps.onDismiss ? modalProps.onDismiss() : {}),
        },
    ];

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Cancel',
        menu: ContextualMenu,
        dragHandleSelector: '#Header',
    };

    return (
        <Modal
            styles={{
                scrollableContent: { overflow: 'hidden', display: 'flex' },
                main: { overflow: 'hidden', display: 'flex' },
            }}
            {...modalProps}
            dragOptions={isDraggable ? dragOptions : undefined}
        >
            <Stack style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', flex: 1 }} grow>
                <div id="Header">
                    <ActivityHeader mainButtons={!hideClose ? activityHeaderMainButtons : undefined} title={title} />
                </div>
                {subTitleContent && <div>{subTitleContent}</div>}
                <div style={{ flex: 1, overflow: 'auto' }}>
                    <Stack
                        style={{
                            flex: 1,
                            display: 'flex',
                            overflow: 'auto',
                        }}
                        grow
                    >
                        {children}
                    </Stack>
                </div>
                {_hasMainButtons ? (
                    <Stack
                        styles={{ root: { paddingRight: 10, paddingBottom: 10, paddingTop: 10 } }}
                        tokens={{ childrenGap: 12 }}
                        horizontal
                        horizontalAlign="end"
                    >
                        {_mainButtons}
                    </Stack>
                ) : null}
            </Stack>
        </Modal>
    );
};

export default TModal;
