import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import IUserIdentity from 'api/models/userIdentity.model';
import { ValidationBar } from 'components';
import { useSelector } from 'hooks';
import useValidation, { IValidationConfig } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { selectedUser, userModalOpen } from 'state/slices/users.selectors';
import { addUser, cleanupSelectedUser, editUserIdentity, toggleModal, updateUserProps } from 'state/slices/users.slice';
import UserPermissions from './UserPermissions';
import { v4 as uuid } from 'uuid';

type UserPermission = {
    key: string;
    name: string;
    description: string;
    enabled: boolean;
};

const AddEditUsersModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const user = useSelector(selectedUser);
    const isOpen = useSelector(userModalOpen);

    const onDismiss = () => {
        dispatch(toggleModal());
    };

    const onDismissed = () => {
        dispatch(cleanupSelectedUser());
    };

    const onFinish = () => {
        if (user) {
            const userIdentity: IUserIdentity = {
                id: user.identity?.id,
                email: user.identity?.email ?? '',
                firstName: user.identity?.firstName ?? '',
                lastName: user.identity?.lastName ?? '',
                systemRoles: user.identity?.systemRoles,
            };

            if (!user.identity?.id) dispatch(addUser({ userAccount: user, userIdentity }));
            else {
                dispatch(editUserIdentity({ userAccount: user }));
            }
        }
        onDismiss();
    };

    const onTogglePermission = (key: string, checked: boolean) => {
        const existingRoles = user!.identity?.systemRoles ?? [];
        const permissions = checked ? [...existingRoles, key] : [...existingRoles.filter((sr) => sr !== key)];
        dispatch(updateUserProps({ path: 'systemRoles', value: permissions }));
    };

    const modalTitle = user && user.identity?.id ? `Edit User` : 'Add User';
    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Email',
            validation: ['required'],
            value: user?.identity?.email,
        },
        {
            fieldName: 'First Name',
            validation: ['required'],
            value: user?.identity?.firstName,
        },
        {
            fieldName: 'Last Name',
            validation: ['required'],
            value: user?.identity?.lastName,
        },
    ];
    const [validationErrors, onSubmit] = useValidation(validationConfig, onFinish);

    const getPermissions = () => {
        const permissions: UserPermission[] = [];
        permissions.push({
            key: 'global-admin',
            name: 'Global Admin',
            description: 'Allowed to access full permissions in membership and dental applications for all tenants.',
            enabled: user && user.identity?.systemRoles ? user.identity?.systemRoles.includes('global-admin') : false,
        });

        return permissions;
    };

    return (
        <Panel
            headerText={modalTitle}
            isOpen={isOpen}
            type={PanelType.medium}
            onDismiss={onDismiss}
            onDismissed={onDismissed}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <ValidationBar errors={validationErrors} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton text={!user?.identity?.id ? 'Add' : 'Save'} onClick={onSubmit} />
                        <DefaultButton text="Cancel" onClick={onDismiss} />
                    </Stack>
                </Stack>
            )}
        >
            <Stack styles={{ root: { padding: 10 } }} grow>
                <TextField
                    value={user?.identity?.email}
                    onChange={(ev, value: any) => dispatch(updateUserProps({ path: 'email', value }))}
                    label="Email"
                    required
                    autoComplete="none"
                    readOnly={!!user?.identity?.id}
                />
                <TextField
                    value={user?.identity?.firstName}
                    onChange={(ev, value: any) => dispatch(updateUserProps({ path: 'firstName', value }))}
                    label="First Name"
                    required
                    autoComplete="none"
                />
                <TextField
                    value={user?.identity?.lastName}
                    onChange={(ev, value: any) => dispatch(updateUserProps({ path: 'lastName', value }))}
                    label="Last Name"
                    required
                    autoComplete="none"
                />
                <br />
                <Label>Permissions</Label>
                {getPermissions().map((p) => {
                    return (
                        <div key={p.key}>
                            <Toggle
                                inlineLabel={true}
                                label={
                                    <div>
                                        {p.name}
                                        <br />
                                        <span style={{ fontWeight: 'normal' }}>{p.description}</span>
                                    </div>
                                }
                                checked={p.enabled}
                                onText=" "
                                offText=" "
                                onChange={() => onTogglePermission(p.key, !p.enabled)}
                            />
                        </div>
                    );
                })}
            </Stack>
            <UserPermissions />
        </Panel>
    );
};

export default AddEditUsersModal;
