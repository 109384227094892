import { INavLink, Nav, Stack, Text, useTheme } from '@fluentui/react';
import { ActivityPage } from 'components';
import useCustomerSuccessPage from 'hooks/useCustomerSuccessPage';
import { useHistory } from 'react-router-dom';
import FPG from './components/FPG/FPG';
import Payers from './components/Payers/Payers';
import Procedures from './components/Procedures/Procedures';
import Diagnoses from './components/Diagnosis/Diagnoses';
import BenefitPlan from './components/BenefitPlan/BenefitPlan';
import usePermissions, { Permission } from 'hooks/usePermissions';
import { AccessDenied } from 'components/AccessDenied';

export interface ICustomerSuccessLink {
    name: string;
    key: string;
    url: string;
    permission: Permission;
}

export default function CustomerSucess() {
    const theme = useTheme();
    const { push } = useHistory();
    const page = useCustomerSuccessPage();

    const _onLinkClick = (ev?: React.MouseEvent<HTMLElement | MouseEvent>, item?: INavLink) => {
        ev?.preventDefault();
        push(`/customer-success/${item?.key}`);
    };

    const [hasPayer] = usePermissions([Permission.ManagePayer]);
    const [hasBenefitPlan] = usePermissions([Permission.ManageBenefitPlans]);
    const [hasProcedures] = usePermissions([Permission.ManageProcedures]);
    const [hasDiagnoses] = usePermissions([Permission.ManageDiagnoses]);
    const [hasFPG] = usePermissions([Permission.ManageFPG]);

    const innerPages: { [key: string]: () => JSX.Element } = {
        payers: hasPayer ? Payers : AccessDenied,
        procedures: hasProcedures ? Procedures : AccessDenied,
        fpg: hasFPG ? FPG : AccessDenied,
        diagnoses: hasDiagnoses ? Diagnoses : AccessDenied,
        benefitPlan: hasBenefitPlan ? BenefitPlan : AccessDenied,
    };

    const customerSuccessLinks: ICustomerSuccessLink[] = [
        {
            name: 'Payers',
            key: 'payers',
            url: '/customer-success/payers',
            permission: Permission.ManagePayer,
        },
        {
            name: 'Benefit Plans',
            key: 'benefitPlan',
            url: '/customer-success/bp',
            permission: Permission.ManageBenefitPlans,
        },
        {
            name: 'Procedures',
            key: 'procedures',
            url: '/customer-success/procedures',
            permission: Permission.ManageProcedures,
        },
        {
            name: 'Federal Poverty Guidelines',
            key: 'fpg',
            url: '/customer-success/fpg',
            permission: Permission.ManageFPG,
        },
        {
            name: 'Diagnoses',
            key: 'diagnoses',
            url: '/customer-success/diagnoses',
            permission: Permission.ManageDiagnoses,
        },
    ];

    const Component = page ? innerPages[page] : null;

    return (
        <ActivityPage title="Customer Success" hasPageHeader hideFooter>
            <Stack grow horizontal>
                <Stack
                    style={{
                        background: theme.palette.neutralLighter,
                        borderRight: `1px solid ${theme.semanticColors.bodyDivider}`,
                        height: '100%',
                        paddingTop: 0,
                        maxWidth: 200,
                    }}
                    grow
                >
                    <Nav
                        groups={[
                            {
                                links: [...customerSuccessLinks],
                            },
                        ]}
                        onLinkClick={_onLinkClick}
                    />
                </Stack>
                <Stack style={{ padding: 12, flex: 1, display: 'flex' }} grow>
                    {Component ? (
                        <Component />
                    ) : (
                        <Stack horizontalAlign="center" verticalAlign="center" grow>
                            <Text variant="xLarge">
                                <b>404</b> - No page found
                            </Text>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </ActivityPage>
    );
}
