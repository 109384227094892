import { ITrackableEntity } from '../../../../api/models/entity.model';
import {
    ChangeAuditSupport,
    CodeSupport,
    EffectiveDateRange,
    ETagSupport,
    GeneralSupports,
    IdSupport,
    SoftDeleteSupport,
} from '../../../../api/models/support-types';

export interface ITentantUser extends ITrackableEntity {
    settings: any;
}

export interface ITentantGroup extends ITrackableEntity {
    settings: any;
    users: string[];
}
type TenantSupports = IdSupport & ChangeAuditSupport & CodeSupport & SoftDeleteSupport & ETagSupport & EffectiveDateRange;

type TenantServiceSupports = ChangeAuditSupport & SoftDeleteSupport & ETagSupport;

type TenantConfigView = ChangeAuditSupport & SoftDeleteSupport & ETagSupport & IdSupport;

export interface ITenantService extends TenantServiceSupports {
    startDate?: string;
    endDate?: string;
    serviceType: Services;
    serviceName?: string;
}

export enum Services {
    EnhancedClaimsResolution = 'enhancedClaimsResolution',
    ThirdPartyCoding = 'thirdPartyCoding',
}

export const serviceNameLookup: { [key in Services]: string } = {
    [Services.EnhancedClaimsResolution]: 'Enhanced Claims Resolution',
    [Services.ThirdPartyCoding]: 'Third Party Coding',
};

export default interface ITenant extends TenantSupports {
    id: string;
    displayName: string;
    name?: string;
    isDeleted: boolean;
    billingInformation?: ITenantBillingInformation;
    firstDateOfService?: string;
    lastDateOfService?: string;
    practiceId?: string;
    clientSecret?: string;
    clientId?: string;
    sourceSystem?: string;
    federalPovertyRegion?: string;
    verifiedPracticeInfo: boolean;
    verifiedPracticeInfoOn?: string;
    verifiedPracticeInfoBy?: string;

    isNetNew: boolean;
    startPatientDemographicsLoadBy?: string;
    startPatientDemographicsLoadOn?: string;
    completePatientDemographicsLoadBy?: string;
    completePatientDemographicsLoadOn?: string;
    startDeltaPatientDemographicsLoadBy?: string;
    startDeltaPatientDemographicsLoadOn?: string;
    completeDeltaPatientDemographicsLoadBy?: string;
    completeDeltaPatientDemographicsLoadOn?: string;

    settings?: Record<string, Record<string, unknown>>;

    isUniversalKey: boolean;
}

export interface ITenantData {
    [key: string]: ITenant;
}

export interface ITenantBillingInformation {
    name?: string;
    npi?: string;
    address?: IMailingAddress;
    payToAddress?: IMailingAddress;
    statementAddress?: IMailingAddress;
    tin?: string;
    phone?: string;
    licenseNumber?: string;
    paymentUrl?: string;
}

export interface IMailingAddress {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
    address?: IMailingAddress;
}

export interface ITenantOnboarding extends GeneralSupports {
    tenantId?: string;
    displayName?: string;
    practiceId?: number;
    athenaHealthClientId?: string;
    athenaHealthClientSecret?: string;
    keyVaultPrefix?: string;
    partitionKey?: string;
    lastRunOn?: string;
    completedOn?: string;
    isSales?: boolean;
    tenantOnboardingSteps?: ITenantOnboardingSteps[];
}

export interface ITenantOnboardingSteps extends GeneralSupports {
    stepKey?: string;
    stepType?: string;
    stepOrder: number;
    displayOrder: number;
    description?: string;
    displayName?: string;
    status?: string;
    processHistories?: ITenantOnboardingProcessHistory[]; // Check with Chuck to make sure this needs to be an array.
    lastRunDurationSeconds?: number;
    canBeRerun: boolean;
}

export interface ITenantOnboardingProcessHistory {
    exceptions?: ITenantOnboardingException[];
    steps?: ITenantOnboardingProcessSteps[];
}

export interface ITenantOnboardingException {
    message?: string;
    helpLink?: string;
    source?: string;
    hResult: number;
    stackTrace?: string;
}

export interface ITenantOnboardingProcessSteps {
    stepName?: string;
    message?: string;
    successful: boolean;
    createdOn: string;
}

export interface IPracticeInfo {
    tenantId?: string;
    practiceId?: string;
    name?: string;
    goLiveDate?: string;
    experienceMode?: string;
    publicNames?: string[];
}

export interface ITenantConfigView extends TenantConfigView {
    configId: string;
    tenantId: string;
    partitionKey: string;
    practiceId: string;
    hasAllergiesChangeSet: boolean;
    hasAppointmentChangeSet: boolean;
    hasMedicationsChangeSet: boolean;
    hasPatientsChangeSet: boolean;
    hasPatientEventing: boolean;
    hasPrescriptionChangeSet: boolean;
    hasProblemsChangeSet: boolean;
    hasProviderChangeSet: boolean;
    clientSecretKey?: string;
    clientIdKey?: string;
}

export interface ITenantConfigSetups {
    tenantId?: string;
    tenantName?: string;
    configId?: string;
    defaultDentalDepartmentId?: number;
    rescheduleCancellationReasonId?: number;
    isNetNew?: boolean;
    startPatientDemographicsLoad?: boolean;
    completePatientDemographicsLoad?: boolean;
    startDeltaPatientDemographicsLoad?: boolean;
    completeDeltaPatientDemographicsLoad?: boolean;
    clientSecret?: string;
    clientId?: string;
}

export interface ITenantConfigCopy extends GeneralSupports {
    tenantId?: string;
    tenantName?: string;
    sourceEnvironment: string;
    targetEnvironment: string;
    partitionKey: string;
    lastRunOn?: string;
    completedOn?: string;
    tenantConfigCopySteps?: ITenantConfigCopySteps[];
}

export interface ITenantConfigCopySteps extends GeneralSupports {
    displayName?: string;
    stepOrder: number;
    queryPartitionKey?: string;
    queryFilter?: string;
    service?: string;
    container?: string;
    deleteTargetFirst?: boolean;
    copyToAthenaPractice?: boolean;
    canBeRerun: boolean;
    lastRunDurationSeconds?: number;
    status?: string;
    processHistories?: ITenantOnboardingProcessHistory[]; //
}
