import React, { Component } from 'react';
import { ITextFieldProps, TextField, MaskedTextField } from '@fluentui/react';
import { debounce } from 'lodash';

type Props = {
    delay: number;
    textFieldProps: ITextFieldProps;
    onChange: (value: string) => void;
    mask?: string;
    disabled?: boolean;
};

type State = {
    value: string;
};

class DebounceInput extends Component<Props, State> {
    state: State = {
        value: this.props.textFieldProps.value ? this.props.textFieldProps.value : '',
    };

    debounceUpdate: any;

    constructor(props: Props) {
        super(props);
        this.debounceUpdate = debounce(this.props.onChange, this.props.delay);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.textFieldProps.value !== prevProps.textFieldProps.value) {
            this.setState({
                value: this.props.textFieldProps.value ? this.props.textFieldProps.value : '',
            });
        }
    }

    onInputChange = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        value: string | undefined,
    ) => {
        if (value !== undefined) {
            this.setState(
                {
                    value,
                },
                () => this.debounceUpdate(value),
            );
        }
    };

    render() {
        if (this.props.mask)
            return (
                <MaskedTextField
                    {...this.props.textFieldProps}
                    value={this.state.value}
                    onChange={this.onInputChange}
                    mask={this.props.mask}
                    disabled={this.props.disabled}
                />
            );

        return (
            <TextField
                {...this.props.textFieldProps}
                autoComplete="none"
                value={this.state.value}
                onChange={this.onInputChange}
                disabled={this.props.disabled}
            />
        );
    }
}

export default DebounceInput;
