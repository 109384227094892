import { ITheme, mergeStyles, MessageBar, MessageBarType, Shimmer, Stack, Text, useTheme } from '@fluentui/react';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { classicDateOnly } from 'utils/dateOnly';
import { getTenantHardCloseThroughDate } from './FinancialClosing/state/financialClosing.actions';
import {
    selectSelectedTenantHardCloseDate,
    selectSelectedTenantHardCloseDateLoading,
} from './FinancialClosing/state/financialClosing.selectors';
import { tenantsState } from './state/tenants.selectors';
import { cleanupTenantHardCloseThroughDate } from './state/tenants.slice';

function TenantHardClosedThroughDate() {
    const theme = useTheme();
    const dispatch = useDispatch();

    const hardCloseThroughDate = useSelector(selectSelectedTenantHardCloseDate);
    const hardCloseThroughDateLoading = useSelector(selectSelectedTenantHardCloseDateLoading);

    const hardCloseThroughDatePending = hardCloseThroughDateLoading === LoadingStatus.Pending;
    const hardCloseThroughDateFailed = hardCloseThroughDateLoading === LoadingStatus.Failed;

    const { selectTenant } = useSelector(tenantsState);
    const tenantId = selectTenant?.id;

    useEffect(() => {
        if (tenantId) {
            dispatch(getTenantHardCloseThroughDate({ tenantId }));
        }

        return () => {
            dispatch(cleanupTenantHardCloseThroughDate());
        };
    }, [tenantId, dispatch]);

    if (!tenantId) return null;

    const formattedDate = hardCloseThroughDate ? classicDateOnly(hardCloseThroughDate) : 'N/A';

    return (
        <Stack tokens={{ childrenGap: 5 }} className={getHardClosedThroughDateStyles(theme)} horizontal>
            <Text>System Hard Closed Through: </Text>
            {hardCloseThroughDatePending ? (
                <div style={{ width: 80 }}>
                    <Shimmer />
                </div>
            ) : hardCloseThroughDateFailed ? (
                <MessageBar style={{ width: '' }} messageBarType={MessageBarType.error}>
                    Failed to load hard closed through date.
                </MessageBar>
            ) : (
                <span>{formattedDate}</span>
            )}
        </Stack>
    );
}

const getHardClosedThroughDateStyles = (theme: ITheme) =>
    mergeStyles({
        backgroundColor: theme.palette.neutralLight,
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 8,
    });

export default TenantHardClosedThroughDate;
