import { MessageBarType } from '@fluentui/react';
import IBatchHardClosed from 'api/models/hardClosedHistory.model';
import IHardCloseBatchPayload from 'api/models/hardClosePayload.model';
import { LoadingStatus } from 'interfaces/loadingStatus';

export type FinanicialClosingState = {
    selectedTenantHardCloseDateLoading: LoadingStatus;
    selectedTenantHardCloseHistoryLoading: LoadingStatus;

    selectedTenantHardCloseDate?: string;
    selectedTenantHardCloseHistory?: IBatchHardClosed;
    savingHardCloseHistory: LoadingStatus;

    hardCloseModalOpen: boolean;
    savingHardCloseBatches: LoadingStatus;
    hardClosePayload?: IHardCloseBatchPayload;

    hardCloseMessageBarText?: string;
    hardCloseMessageBarType?: MessageBarType;
};

export const FinanicialClosingInitialState: FinanicialClosingState = {
    selectedTenantHardCloseDateLoading: LoadingStatus.Idle,
    selectedTenantHardCloseHistoryLoading: LoadingStatus.Idle,

    hardCloseModalOpen: false,
    savingHardCloseBatches: LoadingStatus.Idle,
    savingHardCloseHistory: LoadingStatus.Idle,
};
