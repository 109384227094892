import { createAsyncThunk } from '@reduxjs/toolkit';
import { PlatformApi } from 'api';
import IAccount from 'api/models/account.model';
import { differenceInMinutes } from 'date-fns';
import ErrorTypes from 'state/errorTypes';
import ApplicationCache from '../applicationCache';

export const getAccount = createAsyncThunk<IAccount, void, { rejectValue: string }>(
    'user/getAccount',
    async (_: void, { rejectWithValue }) => {
        try {
            const accountCache = ApplicationCache.getUserAccount;
            const accountCacheAge = ApplicationCache.getAccountCacheAgeData;
            if (
                accountCache &&
                accountCacheAge &&
                differenceInMinutes(new Date(), new Date(accountCacheAge)) < 1
            ) {
                return accountCache;
            }
            const response = await PlatformApi.getAccount();
            ApplicationCache.setStoreItem('account', response.data);
            ApplicationCache.setStoreItem('accountCacheAge', new Date().toISOString());
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);
