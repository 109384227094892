import { TModal } from 'components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { rerunTenantConfigCopy } from './state/tenants.actions';
import { RouteParams } from 'interfaces/route-params';
import { isRerunModalOpen } from './state/tenants.slice';

import { useSelector } from 'hooks';
import { _isRerunModalOpen } from './state/tenants.selectors';
import { Stack, Text } from '@fluentui/react';

type RerunModalProps = {
    itemId: string;
};

function RerunModal({ itemId }: RerunModalProps): JSX.Element {
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const isOpen = useSelector(_isRerunModalOpen);

    const rerunCopyConfigStep = () => {
        const model = {
            tenantId: tenantId ?? '',
            tenantConfigCopyStepIds: [itemId],
        };
        if (tenantId && itemId) {
            dispatch(rerunTenantConfigCopy({ model }));
            dispatch(isRerunModalOpen());
        }
    };

    const onDismiss = () => {
        dispatch(isRerunModalOpen());
    };
    return (
        <TModal
            title={'Confirm Action'}
            modalProps={{ isOpen, onDismiss, styles: { main: { maxWidth: 450 } } }}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    onClick: onDismiss,
                    text: 'Cancel',
                },
                {
                    type: 'DefaultButton',
                    onClick: rerunCopyConfigStep,
                    text: 'Delete and Copy',
                },
            ]}
        >
            <Stack styles={{ root: { padding: 10 } }} wrap>
                <Text>
                    WARNING: This will delete the existing data in production. and replace it with what is in sandbox for this
                    tenant. Are you sure you want to proceed?
                </Text>
            </Stack>
        </TModal>
    );
}

export default RerunModal;
