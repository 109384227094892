import { MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';

type Props = {
    message: string;
};

const ErrorMessageBar = ({ message }: Props) => {
    return (
        <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
        >
            {message}
        </MessageBar>
    );
};

export default ErrorMessageBar;
