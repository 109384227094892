import { createSelector } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { RootState } from 'state/store';
import { IFeatureFlag, IFeatureFlagTenant } from './model';
import { tenantReportList } from 'views/pages/Tenants/state/tenants.selectors';

export const featureFlagState = (state: RootState) => state.featureFlags;

export const featureFlagLoading = createSelector(featureFlagState, (state) => state.loadingFeatureFlag);
export const featureFlagData = createSelector(featureFlagState, (state) => state.featureFlags);
export const selectedFeatureFlag = createSelector(featureFlagState, (state) => state.selectedFeatureFlag);
export const showFeatureFlagHistory = createSelector(featureFlagState, (state) => state.showHistoryFeatureFlag);
export const isFeatureBarExpanded = createSelector(featureFlagState, (state) => state.isExpanded);

export const featureFlagDataList = createSelector(featureFlagData, (featureFlagData) => {
    return map(featureFlagData, (p) => p) as IFeatureFlag[];
});

export const featureFlagDataListHistory = createSelector(
    featureFlagData,
    showFeatureFlagHistory,
    (featureFlagData, showHistory) => {
        const list = map(featureFlagData, (p) => p).filter((p) => !p?.isDeleted) as IFeatureFlag[];
        return !showHistory ? list.filter((p) => !p?.isArchived) : list;
    },
);

export const openFeatureFlagModal = createSelector(featureFlagState, (state) => state.openFeatureFlagModal);

export const openFeatureFlagTenantModal = createSelector(featureFlagState, (state) => state.openFeatureFlagTenantModal);

export const selectedFeatureFlagTenant = createSelector(featureFlagState, (state) => state.selectedFeatureFlagTenant);
export const selectedFeatureFlagShowHistoryTenant = createSelector(featureFlagState, (state) => state.showHistoryTenant);

export const selectedFeatureFlagTenantList = createSelector(
    selectedFeatureFlagTenant,
    selectedFeatureFlagShowHistoryTenant,
    (selectedFeatureFlagTenant, showHistory) => {
        const list = map(selectedFeatureFlagTenant, (p) => p) as IFeatureFlagTenant[];
        return !showHistory ? list.filter((p) => p?.isActive) : list;
    },
);

export const selectedFeatureFlagTenantListHistory = createSelector(
    selectedFeatureFlagTenant,
    tenantReportList,
    (selectedFeatureFlagTenant, tenantReportList) => {
        const mappedTenant = selectedFeatureFlagTenant?.map((x) => x.tenantId);

        const filteredTenant = tenantReportList?.filter((x) => !mappedTenant?.includes(x.id ?? ''));
        return filteredTenant;
    },
);
