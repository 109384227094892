import {
    DefaultButton,
    IDetailsHeaderProps,
    IRenderFunction,
    Panel,
    PanelType,
    PrimaryButton,
    SelectionMode,
    Spinner,
    Stack,
    StackItem,
    Sticky,
    StickyPositionType,
    Text,
    TextField,
    Toggle,
} from '@fluentui/react';
import { IInsuranceClass } from 'api/models/benefitPlan.model';
import { SortableDetailsList, ValidationBar } from 'components';
import { useValidation, useSelector } from 'hooks';
import useBenefit from 'hooks/state/useBenefit';
import useBenefitPlan from 'hooks/state/useBenefit';

import { IValidationConfig } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect, FormEvent } from 'react';
import {
    selectedBenefitPlanCategories,
    selectedBenefitPlanInsuranceClasses,
} from 'state/slices/benefit-plan/benefit-plan.selectors';

export default function BenefitPlanPanel(): JSX.Element {
    const {
        selectedBenefitPlan,

        isAdding,
        saving,
        cleanupSelectedBenefitPlan,
        addBenefitPlan,
        updateBenefitPlan,
        updateSelectedBenefitPlanProp,

        getProcedureCategories,
    } = useBenefitPlan();

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'displayName',
            validation: ['required'],
            value: selectedBenefitPlan?.displayName,
        },
    ];

    const insuranceClasses = useSelector(selectedBenefitPlanInsuranceClasses);
    const _insuranceClassItems = insuranceClasses.length
        ? insuranceClasses.map((insuranceClass) => (
              <BenefitPlanInsuranceClass insuranceClass={insuranceClass} key={insuranceClass.id} />
          ))
        : null;

    const isOpen = !!selectedBenefitPlan;

    function _onDismiss() {
        cleanupSelectedBenefitPlan();
        cleanupErrors();
    }
    useEffect(() => {
        getProcedureCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onAddOrSave);

    function onAddOrSave() {
        if (selectedBenefitPlan) {
            cleanupErrors();
            if (!isAdding) {
                updateBenefitPlan(selectedBenefitPlan);
            } else {
                addBenefitPlan(selectedBenefitPlan);
            }
        }
    }

    function toggleIsDeleted() {
        updateSelectedBenefitPlanProp('isDeleted', !selectedBenefitPlan?.isDeleted);
    }

    return (
        <Panel
            isOpen={isOpen}
            isFooterAtBottom
            onDismiss={_onDismiss}
            type={PanelType.medium}
            headerText={isAdding ? `Add Benefit Plan` : `Edit Benefit Plan`}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <ValidationBar errors={errors} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton
                            text={isAdding ? 'Add' : 'Save'}
                            onClick={onSubmit}
                            disabled={saving === LoadingStatus.Pending}
                        />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                        <Stack grow horizontalAlign="end">
                            {saving === LoadingStatus.Pending && (
                                <Spinner
                                    label={isAdding ? 'Adding...' : 'Saving...'}
                                    labelPosition="left"
                                />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack grow horizontal horizontalAlign="end">
                {!isAdding && (
                    <Toggle
                        label="Deactivated"
                        checked={selectedBenefitPlan?.isDeleted}
                        inlineLabel
                        onClick={toggleIsDeleted}
                    />
                )}
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} grow horizontal>
                    <StackItem>
                        <TextField
                            styles={{ root: { maxWidth: 100 } }}
                            label="Name"
                            value={selectedBenefitPlan?.displayName}
                            onChange={(ev, value) =>
                                updateSelectedBenefitPlanProp('displayName', value)
                            }
                            required
                        />
                    </StackItem>
                </Stack>
                {_insuranceClassItems}
            </Stack>
        </Panel>
    );
}

function BenefitPlanInsuranceClass({
    insuranceClass,
}: {
    insuranceClass: IInsuranceClass;
}): JSX.Element {
    const { updateSelectedBenefitPlanPercentageProp } = useBenefit();
    const categories = useSelector(selectedBenefitPlanCategories);
    const _categoryItems = categories.length
        ? categories.filter((category) => category.insuranceClassId === insuranceClass.id)
        : [];

    const _handlePercentageChange = (e?: FormEvent, value?: string) => {
        const numberValue = value ? parseInt(value) : 0;
        if (numberValue > 100) {
            updateSelectedBenefitPlanPercentageProp(insuranceClass.id, 'percentage', 100);
        } else {
            updateSelectedBenefitPlanPercentageProp(insuranceClass.id, 'percentage', numberValue);
        }
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
        headerProps,
        defaultRender,
    ) => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header}>
                <div>{defaultRender && defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    return (
        <Stack>
            <Stack horizontal horizontalAlign="space-between">
                <Stack grow>
                    <Text variant="large">{insuranceClass.displayName}</Text>
                </Stack>
                <Stack grow horizontalAlign="end">
                    <TextField
                        suffix="%"
                        maxLength={3}
                        onChange={_handlePercentageChange}
                        value={insuranceClass.percentage.toString()}
                    />
                </Stack>
            </Stack>
            <Stack>
                <SortableDetailsList
                    selectionMode={SelectionMode.none}
                    compact
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    columns={[
                        {
                            key: 'display',
                            minWidth: 100,
                            fieldName: 'displayName',
                            name: 'Cat. name',
                        },
                        {
                            key: 'start',
                            minWidth: 100,
                            fieldName: 'start',
                            name: 'Start',
                        },
                        {
                            key: 'end',
                            minWidth: 100,
                            fieldName: 'end',
                            name: 'End',
                        },
                    ]}
                    items={_categoryItems}
                />
            </Stack>
        </Stack>
    );
}
