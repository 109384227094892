import {
    ComboBox,
    DefaultButton,
    IComboBoxOption,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    StackItem,
    TextField,
    Toggle,
} from '@fluentui/react';
import { Field, ValidationBar } from 'components';
import { useValidation } from 'hooks';
import useDiagnosis from 'hooks/state/useDiagnoses';
import { IValidationConfig } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useSelector } from 'react-redux';
import { selectedDiagnosisCategory } from 'state/slices/diagnosis/diagnosis.selectors';
import { classicDateOnly, convertDateTimeToBeforeMidnight, convertDateTimeToMidnight } from 'utils/dateOnly';

export default function ProcedurePanel() {
    const {
        selectedDiagnosis,
        selectDiagnosesCategories,
        selectDiagnosesSubcategories,
        isAdding,
        saving,
        cleanupSelectedDiagnosis,
        addDiagnosis,
        updateDiagnosis,
        updateSelectedDiagnosisProp,
    } = useDiagnosis();

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Code',
            validation: ['required'],
            value: selectedDiagnosis?.code,
        },

        {
            fieldName: 'Display Name',
            validation: ['required'],
            value: selectedDiagnosis?.displayName,
        },
        {
            fieldName: 'Description',
            validation: ['required'],
            value: selectedDiagnosis?.description,
        },
        {
            fieldName: 'Category',
            validation: ['required'],
            value: selectedDiagnosis?.category,
        },
    ];

    const isOpen = !!selectedDiagnosis;

    function _onDismiss() {
        cleanupSelectedDiagnosis();
        cleanupErrors();
    }

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onAddOrSave);

    function onAddOrSave() {
        if (selectedDiagnosis) {
            cleanupErrors();
            if (!isAdding) {
                updateDiagnosis(selectedDiagnosis);
            } else {
                addDiagnosis(selectedDiagnosis);
            }
        }
    }

    function toggleIsDeleted() {
        updateSelectedDiagnosisProp('isDeleted', !selectedDiagnosis?.isDeleted);
    }

    return (
        <Panel
            isOpen={isOpen}
            isFooterAtBottom
            onDismiss={_onDismiss}
            type={PanelType.medium}
            headerText={isAdding ? `Add Diagnosis` : `Edit Diagnosis`}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <ValidationBar errors={errors} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton
                            text={isAdding ? 'Add' : 'Save'}
                            onClick={onSubmit}
                            disabled={saving === LoadingStatus.Pending}
                        />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                        <Stack grow horizontalAlign="end">
                            {saving === LoadingStatus.Pending && (
                                <Spinner label={isAdding ? 'Adding...' : 'Saving...'} labelPosition="left" />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack grow horizontal horizontalAlign="end">
                {!isAdding && (
                    <Toggle label="Deactivated" checked={selectedDiagnosis?.isDeleted} inlineLabel onClick={toggleIsDeleted} />
                )}
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} grow horizontal>
                    <StackItem grow>
                        <TextField
                            label="Code"
                            value={selectedDiagnosis?.code}
                            onChange={(ev, value) => updateSelectedDiagnosisProp('code', value)}
                            disabled={!isAdding}
                            maxLength={10}
                            required
                        />
                    </StackItem>

                    <StackItem grow>
                        <TextField
                            label="SNOMED Code"
                            value={selectedDiagnosis?.snomedCode}
                            onChange={(ev, value) => updateSelectedDiagnosisProp('snomedCode', value)}
                            maxLength={200}
                        />
                    </StackItem>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <StackItem grow>
                        <TextField
                            label="Display Name"
                            value={selectedDiagnosis?.displayName}
                            onChange={(ev, value) => updateSelectedDiagnosisProp('displayName', value)}
                            maxLength={200}
                            required
                        />
                    </StackItem>
                    <StackItem grow>
                        <TextField
                            label="Description"
                            value={selectedDiagnosis?.description}
                            onChange={(ev, value) => updateSelectedDiagnosisProp('description', value)}
                            maxLength={200}
                            required
                        />
                    </StackItem>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <StackItem grow>
                        <Field.Date
                            label="Effective Date"
                            value={selectedDiagnosis?.effectiveDate ? classicDateOnly(selectedDiagnosis?.effectiveDate) : ''}
                            hasDatePicker
                            onChange={(ev, value) => {
                                updateSelectedDiagnosisProp('effectiveDate', convertDateTimeToMidnight(value));
                            }}
                        />
                    </StackItem>
                    <StackItem grow>
                        <Field.Date
                            label="Expiration Date"
                            value={selectedDiagnosis?.endDate ? classicDateOnly(selectedDiagnosis?.endDate) : ''}
                            hasDatePicker
                            onChange={(ev, value) =>
                                updateSelectedDiagnosisProp('endDate', convertDateTimeToBeforeMidnight(value))
                            }
                        />
                    </StackItem>
                </Stack>

                <StackItem>
                    <ComboBox
                        label="Category"
                        options={selectDiagnosesCategories}
                        required
                        placeholder="(Select category)"
                        selectedKey={selectedDiagnosis?.category as string}
                        onChange={(ev, option) => updateSelectedDiagnosisProp('category', option?.key)}
                        allowFreeform
                    />
                </StackItem>
                <StackItem>
                    <Field.SearchCombo
                        label="Subcategory"
                        options={selectDiagnosesSubcategories}
                        disabled={!selectDiagnosesSubcategories.length}
                        placeholder={'(Select subcategory)'}
                        selectedKey={(selectedDiagnosis?.subcategory as string) ?? undefined}
                        onChange={(ev, option) => updateSelectedDiagnosisProp('subcategory', option?.key)}
                    />
                </StackItem>
            </Stack>
        </Panel>
    );
}
