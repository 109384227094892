import { createSlice, createSelector, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import IUser, { IUsersAccount } from 'api/models/user.model';
import ErrorTypes from 'state/errorTypes';
import { PlatformApi } from 'api';
import IUserIdentity from 'api/models/userIdentity.model';
import { filter, map } from 'lodash';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { v4 as uuid } from 'uuid';
import { ISystemUserAccount } from 'api/models/account.model';

const initialState: UsersState = {
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    showHistory: false,
    isAdding: false,
    isModalOpen: false,
};

/**
 * Gets all users. Pulls account metadata (ex: DateCreated, IsDeleted, etc..) and display info (first, last, email) into single entity;
 */
export const getUsers = createAsyncThunk<ISystemUserAccount[], void, { rejectValue: string }>(
    'users/getUsers',
    async (_: void, { rejectWithValue }) => {
        try {
            const { data: usersData } = await PlatformApi.getUsers();
            //filter out undefined values
            const filteredUsers = filter(usersData, (user) => user !== undefined) as IUser[];

            return filteredUsers;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

/**
 * Gets a single user.
 */
export const getUser = createAsyncThunk<ISystemUserAccount, { userId: string }, { rejectValue: string }>(
    'users/getUser',
    async ({ userId }, { rejectWithValue }) => {
        try {
            const { data: userAccount } = await PlatformApi.getUser(userId);

            return userAccount;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const editUser = createAsyncThunk<ISystemUserAccount, { user: ISystemUserAccount }, { rejectValue: string }>(
    'users/editUser',
    async ({ user }, { rejectWithValue }) => {
        try {
            const deletedUser: IUser = {
                ...user,
                id: user.identity?.id ?? '',
                isDeleted: !user.isDeleted,
            };
            const { data: response } = await PlatformApi.editUser(deletedUser);
            const updatedUser: ISystemUserAccount = {
                isDeleted: response.isDeleted,
                identity: {
                    email: user.identity?.email ?? '',
                    firstName: user.identity?.firstName ?? '',
                    id: user.identity?.id ?? '',
                    lastName: user.identity?.lastName ?? '',
                    systemRoles: user.identity?.systemRoles ?? [],
                },
                userAccount: {
                    permissions: user.userAccount?.permissions ?? [],
                },
            };
            return updatedUser;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const addEditUserIdentity = createAsyncThunk<IUserIdentity, { userIdentity: IUserIdentity }, { rejectValue: string }>(
    'users/addEditUserIdentity',
    async ({ userIdentity }, { rejectWithValue }) => {
        try {
            const { data: response } = await PlatformApi.addEditUserIdentity(userIdentity);
            return response;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const addEditUsersPermissions = createAsyncThunk<
    IUsersAccount,
    { user: ISystemUserAccount; userAccount: IUsersAccount },
    { rejectValue: string }
>('users/addEditUsersPermissions', async ({ user, userAccount }, { rejectWithValue }) => {
    try {
        if (!user.identity?.id) return rejectWithValue('User does not have an id');
        const { data: response } = await PlatformApi.addEditUserPermissions(user.identity?.id, {
            id: user.identity?.id ?? '',
            isDeleted: user.isDeleted,
            permissions: userAccount.permissions,
        });
        return response;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

/**
 * Creates a new user.
 */

export const addUser = createAsyncThunk<
    ISystemUserAccount,
    { userAccount: ISystemUserAccount; userIdentity: IUserIdentity },
    { rejectValue: string }
>('users/addUser', async ({ userAccount, userIdentity }, { rejectWithValue }) => {
    try {
        const { data: identity } = await PlatformApi.addEditUserIdentity(userIdentity);
        const user: IUser = {
            id: identity.id!,

            isDeleted: userAccount.isDeleted,

            appRoles: [],

            createdBy: '',
            createdOn: new Date(),
            modifiedBy: '',
            modifiedOn: new Date(),
        };
        await PlatformApi.addUser(user);

        const updatedUser: ISystemUserAccount = {
            ...userAccount,
            identity: {
                email: identity.email,
                id: identity.id!,
                firstName: identity.firstName,
                lastName: identity.lastName,
                systemRoles: identity.systemRoles ?? [],
            },
        };

        if (userAccount.userAccount?.permissions?.length) {
            await PlatformApi.addEditUserPermissions(user.id, {
                id: identity.id!,
                permissions: userAccount.userAccount.permissions,
                isDeleted: user.isDeleted,
            });
        }

        return updatedUser;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

// /**
//  * Edit user identity information (first, last). Cannot update email.
//  */

export const editUserIdentity = createAsyncThunk<
    ISystemUserAccount,
    { userAccount: ISystemUserAccount },
    { rejectValue: string }
>('users/editUserIdentity', async ({ userAccount }, { rejectWithValue }) => {
    try {
        const userIdentity: IUserIdentity = {
            id: userAccount.identity?.id,
            email: userAccount.identity?.email ?? '',
            firstName: userAccount.identity?.firstName ?? '',
            lastName: userAccount.identity?.lastName ?? '',
            systemRoles: userAccount.identity?.systemRoles,
        };

        if (userAccount.identity?.id) {
            const { data: identity } = await PlatformApi.addEditUserIdentity(userIdentity);

            const user: ISystemUserAccount = {
                isDeleted: userAccount.isDeleted,
                identity: {
                    email: identity.email,

                    firstName: identity.firstName,
                    id: identity.id ?? '',
                    lastName: identity.lastName,
                    systemRoles: userIdentity.systemRoles ?? [],
                },
                userAccount: {
                    permissions: userAccount.userAccount?.permissions,
                },
            };

            if (userIdentity && userIdentity.id) {
                if (userAccount.userAccount?.permissions) {
                    await PlatformApi.addEditUserPermissions(userIdentity.id, {
                        id: userIdentity.id!,
                        permissions: userAccount.userAccount.permissions,
                        isDeleted: user.isDeleted,
                        identity: {
                            ...identity,
                        },
                    });
                }
                return user;
            }
        }
        return userAccount;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

const usersSlice = createSlice({
    name: 'users',
    initialState,

    reducers: {
        toggleShowHistory: (state) => {
            state.showHistory = !state.showHistory;
        },

        setUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        cleanupSelectedUser: (state) => {
            state.selectedUser = undefined;
        },
        updateUserProps: (state, action: PayloadAction<{ path: keyof IUserIdentity; value: any }>) => {
            const { path, value } = action.payload;
            if (state.selectedUser) {
                if (state.selectedUser?.identity) {
                    ((state.selectedUser.identity as IUserIdentity)[path] as unknown) = value;
                }
            }
        },
        updateUserAccountProps: (state, action: PayloadAction<{ path: keyof IUsersAccount; value: any }>) => {
            const { path, value } = action.payload;
            if (state.selectedUser) {
                if (!state.selectedUser?.userAccount)
                    state.selectedUser['userAccount'] = {
                        permissions: [],
                        claims: [],
                    };
                (state.selectedUser.userAccount[path] as unknown) = value;
            }
        },

        addSelectedUser: (state) => {
            state.selectedUser = {
                identity: {
                    id: uuid(),
                    email: '',
                    firstName: '',
                    lastName: '',
                    systemRoles: [],
                },
                userAccount: {
                    permissions: [],
                },
                isDeleted: false,
            };
        },
        toggleModal: (state) => {
            state.isModalOpen = !state.isModalOpen;
        },
    },

    extraReducers: (builder) => {
        builder
            //get users
            .addCase(getUsers.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.data = action.payload;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //get user
            .addCase(getUser.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.selectedUser = action.payload;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //add user
            .addCase(addUser.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                state.errors = null;
                state.data = [...state.data!, action.payload];
            })
            .addCase(addUser.rejected, (state, action) => {
                state.saving = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //add user identity

            // edit user identity
            .addCase(editUserIdentity.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(editUserIdentity.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                state.errors = null;
                if (state.data)
                    state.data = [
                        ...state.data!.map((user) => {
                            return user.identity?.id === action.payload.identity?.id ? action.payload : user;
                        }),
                    ];
            })
            .addCase(editUserIdentity.rejected, (state, action) => {
                state.saving = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //edit user
            .addCase(editUser.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(editUser.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                state.errors = null;

                if (state.data)
                    state.data = [
                        ...state.data!.map((user) => {
                            return user.identity?.id === action.payload.identity?.id ? action.payload : user;
                        }),
                    ];
            })
            .addCase(editUser.rejected, (state, action) => {
                state.saving = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

export const { toggleShowHistory } = usersSlice.actions;

export default usersSlice.reducer;

export const { addSelectedUser, cleanupSelectedUser, setUser, toggleModal, updateUserAccountProps, updateUserProps } =
    usersSlice.actions;

type UsersState = {
    data?: ISystemUserAccount[];
    selectedUser?: ISystemUserAccount;
    isModalOpen?: boolean;
    errors?: any;
    loading: LoadingStatus;
    saving: LoadingStatus;
    isAdding: boolean;
    showHistory: boolean;
};
