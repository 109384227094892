import { format, isValid } from 'date-fns';

/**
 * Date Only
 * @description Returns the date portion of a valid date string disregarding the local timezone
 * @param {string} dateToConvert
 * @param {string} [dateFormat='MM/dd/yyyy']
 * @returns {string} Formatted date only string
 *
 *
 *
 */
export function classicDateOnly(dateToConvert: string, dateFormat = 'MM/dd/yyyy'): string {
    const convertToLocal = new Date(dateToConvert).toString();
    const newDate = new Date(convertToLocal);
    if (dateToConvert && isValid(newDate)) {
        const dateOnly = new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000);
        return format(dateOnly, dateFormat);
    }

    throw new Error(`Date Error: (${dateToConvert}) is not a valid date string.`);
}
export default function dateOnly(dateToConvert: string, dateFormat = 'MM/dd/yyyy') {
    const newDate = new Date(dateToConvert);
    if (dateToConvert && isValid(newDate)) {
        const dateOnly = new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000);
        return format(dateOnly, dateFormat);
    }

    throw new Error(`Date Error: (${dateToConvert}) is not a valid date string.`);
}

export function convertDateTimeToMidnight(date: string | undefined): string {
    if (!date) {
        return '';
    }
    const newDate = new Date(date);
    if (isValid(newDate)) {
        newDate.setUTCHours(0, 0, 0, 0);
        return newDate.toISOString();
    }

    throw new Error(`Date Error: (${date}) is not a valid date string.`);
}

export function convertDateTimeToBeforeMidnight(date: string | undefined): string {
    if (!date) return '';
    const newDate = new Date(date);
    newDate.setUTCHours(23, 59, 59, 999);
    return newDate.toISOString();
}
