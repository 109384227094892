import React, { useEffect } from 'react';
import { IconButton, TooltipHost, styled, ITheme, IStyleFunctionOrObject } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { removeTooltipCard, ITooltipCard } from 'state/slices/ui.slice';
import { TText } from 'components';

interface IComponentProps {
    item: ITooltipCard;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
}

const TooltipCard = (props: IComponentProps) => {
    const dispatch = useDispatch();
    const { content, title, id, ttl } = props.item;
    const { theme } = props;
    const removeTooltip = () => dispatch(removeTooltipCard(id));

    useEffect(() => {
        if (ttl) {
            setTimeout(() => removeTooltip(), ttl);
        }
    });

    return (
        <div
            style={{
                backgroundColor: theme?.palette.white,
                marginBottom: 6,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                minWidth: 300,
                maxWidth: 300,
                minHeight: 100,
                maxHeight: 100,
                border: `1px solid ${props.theme?.semanticColors.bodyDivider}`,
            }}
            className="ms-depth-16"
        >
            <div
                style={{
                    height: 33,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: `1px solid ${props.theme?.semanticColors.bodyDivider}`,
                    paddingLeft: 5,
                }}
            >
                <TText
                    style={{
                        fontWeight: typeof title === 'string' ? 'bold' : 'normal',
                    }}
                >
                    {title}
                </TText>
                <TooltipHost content="Dismiss Notification">
                    <IconButton onClick={() => removeTooltip()} iconProps={{ iconName: 'Clear' }} />
                </TooltipHost>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    padding: 5,
                }}
            >
                {content}
            </div>
        </div>
    );
};

export default styled<IComponentProps, any, any>(TooltipCard, {});
