import { createSlice, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import ErrorTypes from 'state/errorTypes';
import { PlatformApi } from 'api';
import IUserIdentity from 'api/models/userIdentity.model';
import IAdminUser from 'models/adminUser.model';
import axios from 'axios';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RootState } from 'state/store';

const initialState: AdminUsersState = {
    data: [],
    loading: LoadingStatus.Idle,
    loadingUsers: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    showHistory: false,
    isAdding: false,
    selectedAdminPanelOpen: false,
};

/**
 * Saves a new admin user for a tenant.
 */
export const addAdminUser = createAsyncThunk<
    IAdminUser | null,
    { tenantId: string; identity: IUserIdentity }
>('adminUsers/addAdminUser', async ({ tenantId, identity }, { rejectWithValue }) => {
    try {
        const { data: savedIdentity } = await PlatformApi.addEditUserIdentity(identity);
        if (savedIdentity && savedIdentity.id) {
            const user: IAdminUser = {
                id: savedIdentity.id,
                email: savedIdentity.email,
                firstName: savedIdentity.firstName,
                lastName: savedIdentity.lastName,
                systemRoles: [],
                isDeleted: false,
                createdBy: '',
                createdOn: new Date(),
                modifiedBy: '',
                modifiedOn: new Date(),
            };
            await PlatformApi.addAdminUser(tenantId, user);
            if (identity.systemRoles) {
                await PlatformApi.updateSystemRoles(user.id, identity.systemRoles);
                user.systemRoles = identity.systemRoles;
            }
            return user;
        } else return null;
    } catch (err) {
        if (axios.isAxiosError(err))
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        return rejectWithValue('uknown error');
    }
});

/**
 * Edits an existing admin user for a tenant.
 */
export const editAdminUser = createAsyncThunk<
    IAdminUser | null,
    { tenantId: string; user: IAdminUser }
>('adminUsers/editAdminUser', async ({ tenantId, user }, { rejectWithValue }) => {
    try {
        await PlatformApi.addEditUserIdentity(user);
        await PlatformApi.editAdminUser(tenantId, user);

        if (user.systemRoles) await PlatformApi.updateSystemRoles(user.id, user.systemRoles);
        return user;
    } catch (err) {
        if (axios.isAxiosError(err))
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        return rejectWithValue('uknown error');
    }
});

/**
 * Gets all admin users for a tenant.
 *
 *
 */

export const getAdminUsers = createAsyncThunk<IAdminUser[], { tenantId: string }>(
    'adminUsers/getAdminUsers',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: adminUsersInfo } = await PlatformApi.getAdminUsers(tenantId);
            const adminUsers = await Promise.all(
                adminUsersInfo.map(async (user) => {
                    const { data: userAcct } = await PlatformApi.getUserAccountNoCache(user.id);
                    const updatedUser: IAdminUser = {
                        ...user,
                        firstName: userAcct.firstName,
                        lastName: userAcct.lastName,
                        email: userAcct.email,
                        systemRoles: userAcct.systemRoles,
                    };
                    return updatedUser;
                }),
            );
            return adminUsers;
        } catch (err) {
            if (axios.isAxiosError(err))
                if (err.response && err.response.status === 503) {
                    return rejectWithValue(ErrorTypes.ServiceUnavailable);
                } else {
                    return rejectWithValue(err.toString());
                }
            return rejectWithValue('uknown error');
        }
    },
);

export const getAdminUserById = createAsyncThunk<
    IAdminUser,
    { userId: string },
    { rejectValue: string }
>('adminUsers/getAdminUsersById', async ({ userId }, { rejectWithValue }) => {
    try {
        const { data: User } = await PlatformApi.getUserAccountNoCache(userId);
        return User;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

const adminUsersSlice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        cleanupSelectedUsers: (state: AdminUsersState) => {
            state.selectedUser = undefined;
            state.isAdding = false;
        },
        setAdminUserIsOpen: (state: AdminUsersState, action: { payload: boolean }) => {
            const IsOpen = action.payload;
            state.selectedAdminPanelOpen = IsOpen;
        },
        updateSelectedAdminUsersProp: (
            state: AdminUsersState,
            action: PayloadAction<{ path: keyof IAdminUser; value: any }>,
        ) => {
            const { path, value } = action.payload;
            if (state.selectedUser) (state.selectedUser as any)[path] = value;
        },

        addNewAdminUser: (state: AdminUsersState) => {
            state.selectedUser = {
                id: '',
                email: '',
                firstName: '',
                lastName: '',
                systemRoles: ['customer-admin'], //include by default
                createdBy: '',
                createdOn: new Date(),
                isDeleted: false,
                modifiedBy: '',
                modifiedOn: new Date(),
            };
            state.isAdding = true;
        },
    },
    extraReducers: (builder) => {
        builder
            //get admin users
            .addCase(getAdminUsers.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAdminUsers.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.data = action.payload;
            })
            .addCase(getAdminUsers.rejected, (state, action) => {
                state.loadingUsers = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(getAdminUserById.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAdminUserById.fulfilled, (state, action) => {
                state.loadingUsers = LoadingStatus.Completed;
                state.errors = null;
                state.selectedUser = action.payload;
            })
            .addCase(getAdminUserById.rejected, (state, action) => {
                state.loadingUsers = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //add admin user
            .addCase(addAdminUser.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(addAdminUser.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.isAdding = false;
                state.selectedAdminPanelOpen = false;
                state.selectedUser = undefined;
                state.data = [...state.data!, action.payload!];
            })
            .addCase(addAdminUser.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //edit admin user
            .addCase(editAdminUser.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(editAdminUser.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.saving = LoadingStatus.Completed;
                state.selectedAdminPanelOpen = false;
                state.errors = null;

                state.data = [
                    ...state.data!.map((user) => {
                        return user.id === action.payload?.id ? action.payload : user;
                    }),
                ];
            })
            .addCase(editAdminUser.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

export const adminUserState = (state: RootState) => state.adminUsers;

export const selectedUserPanelOpen = createSelector(
    adminUserState,
    (state) => state.selectedAdminPanelOpen,
);

export const tenantAdminUsers = createSelector(adminUserState, (state) => state.selectedUser);

export const {
    cleanupSelectedUsers,
    setAdminUserIsOpen,
    updateSelectedAdminUsersProp,
    addNewAdminUser,
} = adminUsersSlice.actions;

export default adminUsersSlice.reducer;

type AdminUsersState = {
    data?: IAdminUser[];
    selectedUser?: IAdminUser;
    errors?: any;
    loading: LoadingStatus;
    loadingUsers: LoadingStatus;
    saving: LoadingStatus;
    showHistory: boolean;
    isAdding: boolean;
    selectedAdminPanelOpen: boolean;
};
