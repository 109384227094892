import platformApi from 'api/platform.api';
import ITenant, {
    IPracticeInfo,
    ITenantConfigCopy,
    ITenantConfigCopySteps,
    ITenantConfigSetups,
    ITenantConfigView,
    ITenantOnboarding,
    ITenantOnboardingSteps,
} from './tenants.model';
import ITenantProduct from 'api/models/tenantProduct.model';
import { SourceSystem } from 'api/models/source-system.model';
import { Dictionary } from '@reduxjs/toolkit';
import ISubscribeToChangesPayload, { SubscriptionType } from 'api/models/subscribeToChangesPayload.model';
import ILocationOfCare from 'api/models/locationOfCare.model';

const tenantsApi = {
    getTenants,
    getTenant,
    addTenant,
    updateTenant,

    getTenantProducts,
    getTenantProductsByID,
    addTenantProduct,
    updateTenantProduct,

    getSourceSystems,
    getTenantOnboardingById,
    addTenantOnboarding,
    rerunTenantOnboardingStep,
    updateTenantOnboarding,
    getTenantOnboardingStepById,
    getTenantPracticeInfo,
    getTenantConfigView,
    updatedTenantSetups,

    getTenantConfigCopy,
    createTenantConfigCopy,
    rerunTenantConfigCopy,
    getTenantConfigCopySteps,
    getTenantConfigCopyStepsById,

    subscribeToChanges,
    unsubscribeToChanges,
    getTenantLocationsOfCare,
};

// * Tenants
function getTenants() {
    return platformApi.client.get<ITenant[]>('tenants');
}

function getTenant(id: string) {
    return platformApi.client.get<ITenant>(`tenants/${id}`);
}

function addTenant(model: ITenant) {
    return platformApi.client.post<ITenant>('tenants', model);
}

function updateTenant(model: ITenant) {
    return platformApi.client.put<ITenant>('tenants', model);
}

// * Tenant Subscriptions
function getTenantProducts(id: string) {
    return platformApi.client.get<ITenantProduct[]>(`tenants/${id}/subscriptions`);
}

function getTenantProductsByID(id: string, productId: string) {
    return platformApi.client.get<ITenantProduct>(`tenants/${id}/subscriptions/${productId}`);
}

function addTenantProduct(id: string, model: ITenantProduct) {
    return platformApi.client.post<ITenantProduct>(`tenants/${id}/subscriptions`, model);
}

function updateTenantProduct(id: string, model: ITenantProduct) {
    return platformApi.client.put<ITenantProduct>(`tenants/${id}/subscriptions`, model);
}

// * Source System
function getSourceSystems() {
    return platformApi.client.get<Record<string, SourceSystem>>(`SourceSystems`);
}

function getTenantOnboardingById(tenantId: string) {
    return platformApi.client.get<ITenantOnboarding>(`tenantonboarding/${tenantId}`);
}

function addTenantOnboarding(model: ITenantOnboarding) {
    return platformApi.client.post<ITenantOnboarding>(`tenantonboarding`, model);
}

function rerunTenantOnboardingStep(model: { tenantId: string; tenantOnboardingStepIds: string[] }) {
    return platformApi.client.post<ITenantOnboarding>(`tenantonboarding/executesteps`, model);
}

function updateTenantOnboarding(model: ITenantOnboarding) {
    return platformApi.client.put<ITenantOnboarding>(`tenantonboarding`, model);
}

function getTenantPracticeInfo(model: { tenantId: string; practiceId: number }) {
    return platformApi.client.post<IPracticeInfo>(`tenantonboarding/practiceinfo`, model);
}

function getTenantOnboardingStepById(step: string) {
    return platformApi.client.get<ITenantOnboardingSteps>(`tenantonboardingsteps/${step}`);
}

function getTenantConfigView(tenantId: string) {
    return platformApi.client.get<ITenantConfigView>(`TenantConfigViews/${tenantId}`);
}
function updatedTenantSetups(model: ITenantConfigSetups) {
    return platformApi.client.post<ITenantConfigSetups>(`tenantSetups/change`, model);
}

function getTenantConfigCopy(tenantId: string) {
    return platformApi.client.get<ITenantConfigCopy>(`TenantConfigCopy/${tenantId}`);
}
function createTenantConfigCopy(model: ITenantConfigCopy) {
    return platformApi.client.post<ITenantConfigCopy>(`TenantConfigViews`, model);
}

function rerunTenantConfigCopy(model: { tenantId: string; tenantConfigCopyStepIds: string[] }) {
    return platformApi.client.post<ITenantConfigCopy>(`TenantConfigCopy/executeSteps`, model);
}

function getTenantConfigCopySteps() {
    return platformApi.client.get<Dictionary<ITenantConfigCopySteps>>(`tenantConfigCopySteps`);
}

function getTenantConfigCopyStepsById(id: string) {
    return platformApi.client.get<Dictionary<ITenantConfigCopySteps>>(`tenantConfigCopySteps/${id}`);
}

//Tenant Manage Eventing/Changeset Subscriptions:
function subscribeToChanges(tenantId: string, model: ISubscribeToChangesPayload) {
    return platformApi.client.post(`tenantOnboarding/SubscribeToChanges?tenantId=${tenantId}`, model);
}

function unsubscribeToChanges(tenantId: string, subscription: SubscriptionType) {
    return platformApi.client.post(`tenantOnboarding/UnsubscribeToChanges?tenantId=${tenantId}&subscription=${subscription}`);
}

function getTenantLocationsOfCare(tenantId: string) {
    return platformApi.client.get<Record<string, ILocationOfCare>>(`Tenants/${tenantId}/LocationsOfCare`);
}
export default tenantsApi;
