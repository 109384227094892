import { LoadingStatus } from 'interfaces/loadingStatus';
import { IFeatureFlag, IFeatureFlagTenant } from './model';
import { Dictionary } from '@reduxjs/toolkit';

export const initialState: FeatureFlagState = {
    loadingFeatureFlag: LoadingStatus.Idle,
    savingFeatureFlag: LoadingStatus.Idle,
    featureFlags: {},
    isExpanded: true,
    openFeatureFlagModal: false,
    openFeatureFlagTenantModal: false,
    showHistoryTenant: false,
    showHistoryFeatureFlag: false,
};

export type FeatureFlagState = {
    loadingFeatureFlag: LoadingStatus;
    featureFlags: Dictionary<IFeatureFlag>;
    selectedFeatureFlag?: IFeatureFlag;
    selectedFeatureFlagTenant?: IFeatureFlagTenant[];
    showHistoryTenant: boolean;
    showHistoryFeatureFlag: boolean;
    loadingSelectedFeatureFlagTenant?: LoadingStatus;
    errors?: string;
    isExpanded: boolean;
    openFeatureFlagModal: boolean;
    openFeatureFlagTenantModal: boolean;
    savingFeatureFlag?: LoadingStatus;
};
