import { DefaultButton, Stack, Toggle } from '@fluentui/react';
import { ActivityPage } from 'components';
import { useSelector } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AddFeatureModal from './AddFeatureModal';
import AddTeanantModal from './AddTenantModal';
import FeatureFlagBar from './FeatureFlagBar';
import FeatureFlagDetails from './FeatureFlagDetails';
import { getAllFeatureFlags } from './state/actions';
import { showFeatureFlagHistory } from './state/selector';
import { addNewFeatureFlag, cleanupSelectedFeatureFlag, setFeatureFlagModal, toggleFeatureFlagHistory } from './state/slice';

function FeatureFlag() {
    const dispatch = useDispatch();
    const showHistory = useSelector(showFeatureFlagHistory);

    useEffect(() => {
        dispatch(getAllFeatureFlags());

        return () => {
            dispatch(cleanupSelectedFeatureFlag());
        };
    }, []);

    return (
        <ActivityPage
            title={`Feature Flags`}
            hasPageHeader
            hideFooter
            className="tenants__right-column"
            rightContent={<RenderFeatureFlagButtons />}
            // mainButtons={renderMainButtons()}
        >
            <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
                <FeatureFlagBar />
                <Stack style={{ padding: 12, flex: 1, display: 'flex' }} grow>
                    <Stack grow>
                        <Switch>
                            <Route path={`/feature-flags/:featureFlagId`} component={FeatureFlagDetails} />
                        </Switch>
                    </Stack>
                </Stack>
            </Stack>
            <AddFeatureModal />
            <AddTeanantModal />
        </ActivityPage>
    );

    function RenderFeatureFlagButtons(): JSX.Element | null {
        const onAddNewFeatureFlag = () => {
            dispatch(setFeatureFlagModal(true));
            dispatch(addNewFeatureFlag());
        };

        return (
            <Stack horizontal tokens={{ childrenGap: 10 }} grow={1} horizontalAlign="end">
                <Toggle
                    checked={!showHistory}
                    onClick={() => {
                        dispatch(toggleFeatureFlagHistory());
                    }}
                    styles={{ root: { marginBottom: 0 } }}
                    inlineLabel
                    label="Active Only"
                    id="tenant-profile-showHistory"
                />
                <DefaultButton iconProps={{ iconName: 'Add' }} primary text="Add Feature Flag" onClick={onAddNewFeatureFlag} />
            </Stack>
        );
    }
}

export default FeatureFlag;
