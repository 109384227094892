import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loadingStatus';

import { copyReportBatch, getWorkSpaceReport, getWorkspaces } from './reports.actions';

import { IWorkspace, IWorkspaceReport } from './reports.model';
import { initialState } from './reports.state';

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        cleanupReports: () => initialState,
        setSelectedReports: (state, action: PayloadAction<IWorkspaceReport[]>) => {
            const reports = action.payload;
            state.selectedReports = reports;
        },
        setSelectedWorkspaces: (state, action: PayloadAction<IWorkspace[]>) => {
            const workspaces = action.payload;
            state.selectedWorkspaces = workspaces;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorkspaces.pending, (state) => {
                state.loadingWorkSpaces = LoadingStatus.Pending;
            })
            .addCase(getWorkspaces.fulfilled, (state, action) => {
                state.loadingWorkSpaces = LoadingStatus.Completed;
                state.workspaces = action.payload;
            })
            .addCase(getWorkspaces.rejected, (state, action) => {
                state.loadingWorkSpaces = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(getWorkSpaceReport.pending, (state) => {
                state.loadingReports = LoadingStatus.Pending;
            })
            .addCase(getWorkSpaceReport.fulfilled, (state, action) => {
                state.loadingReports = LoadingStatus.Completed;
                state.reports = action.payload;
            })
            .addCase(getWorkSpaceReport.rejected, (state, action) => {
                state.loadingReports = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(copyReportBatch.rejected, (state, action) => {
                state.errors = action.payload;
            });
    },
});

const { actions } = reportsSlice;
export const { cleanupReports, setSelectedReports, setSelectedWorkspaces } = actions;

export default reportsSlice.reducer;
