import { IExtendedRouteProps } from '.';
import { Route } from 'react-router-dom';
import { SidebarLayout, NoSidebarLayout } from '../views/layout';
import usePermissions from 'hooks/usePermissions';
import { AccessDenied } from 'components/AccessDenied';

export const RouteWrapper = ({ component: Component, layout = 'noSideBar', permissions, ...rest }: IExtendedRouteProps) => {
    const [hasPermissions] = usePermissions(permissions);
    if (!Component) return null;
    const layouts = {
        noSideBar: NoSidebarLayout,
        sideBar: SidebarLayout,
    };
    const Layout = layouts[layout];
    return (
        <Route
            {...rest}
            render={(props) => <Layout {...props}>{hasPermissions ? <Component {...props} /> : <AccessDenied />}</Layout>}
        />
    );
};
