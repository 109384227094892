import IPovertyGuideline, {
    IPovertyIcomeLevel,
    PovertyLocation,
} from 'api/models/povertyGuidline.model';
import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    latestPovertyGuideline,
    povertyGuidelinesByUniqLocations,
    povertyGuildelinesData,
    poveryGuidelinesBySelectedLocation,
    selectPovertyGuidelinesState,
} from 'state/slices/poverty-guidelines/poverty-guidelines.selectors';
import {
    addGuidelineYear,
    cleanupSelectedPovertyGuidelinesData,
    createPovertyGuideline,
    getPovertyGuidelineById,
    getPovertyGuidelines,
    setSelectedLocation,
    updateIncomeLevel,
    updatePovertyGuideline,
    updateSelectedGuidelineProp,
} from 'state/slices/poverty-guidelines/poverty-guidelines.slice';

function useFPG() {
    const _dispatch = useDispatch();

    const _povertyGuildlinesData = useSelector(povertyGuildelinesData);
    const _povertyGuidlinesByUniqLocations = useSelector(povertyGuidelinesByUniqLocations);
    const _poveryGuidelinesBySelectedLocation = useSelector(poveryGuidelinesBySelectedLocation);
    const _latestPovertyGuideline = useSelector(latestPovertyGuideline);

    const {
        isAdding,
        saving,
        loading,
        loadingSelectedPovertyGuideline,
        selectedLocation,
        selectedPovertyGuildeline,
    } = useSelector(selectPovertyGuidelinesState);

    const _cleanupSelectedPovertyGuidlinesData = useCallback(() => {
        _dispatch(cleanupSelectedPovertyGuidelinesData());
    }, [_dispatch]);

    const _setSelectedLocation = useCallback(
        (location: PovertyLocation) => {
            _dispatch(setSelectedLocation(location));
        },
        [_dispatch],
    );

    const _getPovertyGuidlines = useCallback(() => {
        _dispatch(getPovertyGuidelines());
    }, [_dispatch]);

    const _addGuidelineYear = useCallback(() => {
        _dispatch(addGuidelineYear());
    }, [_dispatch]);

    const _getPovertyGuidlineById = useCallback(
        (id: string) => {
            _dispatch(getPovertyGuidelineById({ id }));
        },
        [_dispatch],
    );

    const _updateSelectedGuidelineProp = useCallback(
        (path: keyof IPovertyGuideline, value: any) => {
            _dispatch(updateSelectedGuidelineProp({ path, value }));
        },
        [_dispatch],
    );

    const _updateIncomeLevel = useCallback(
        (index: number, path: keyof IPovertyIcomeLevel, value: number) => {
            _dispatch(updateIncomeLevel({ index, path, value }));
        },
        [_dispatch],
    );

    const _updatePovertyGuidline = useCallback(
        (model: IPovertyGuideline) => {
            _dispatch(updatePovertyGuideline({ model }));
        },
        [_dispatch],
    );

    const _createPovertyGuidline = useCallback(
        (model: IPovertyGuideline) => {
            _dispatch(createPovertyGuideline({ model }));
        },
        [_dispatch],
    );

    return {
        // Selectors
        isAdding,
        saving,
        loading,
        loadingSelectedPovertyGuideline,
        selectedLocation,
        selectedPovertyGuildeline,
        povertyGuidlinesByUniqLocations: _povertyGuidlinesByUniqLocations,
        povertyGuildlinesData: _povertyGuildlinesData,
        poveryGuidelinesBySelectedLocation: _poveryGuidelinesBySelectedLocation,
        latestPovertyGuideline: _latestPovertyGuideline,

        // Async Actions
        getPovertyGuidlines: _getPovertyGuidlines,
        getPovertyGuidlineById: _getPovertyGuidlineById,
        updatePovertyGuidline: _updatePovertyGuidline,
        createPovertyGuidline: _createPovertyGuidline,

        //Actions
        setSelectedLocation: _setSelectedLocation,
        cleanupSelectedPovertyGuidlinesData: _cleanupSelectedPovertyGuidlinesData,
        updateSelectedGuidelineProp: _updateSelectedGuidelineProp,
        addGuidelineYear: _addGuidelineYear,
        updateIncomeLevel: _updateIncomeLevel,
    };
}

export default useFPG;
