import { getAllUserTasks, getUserTasksByTarget } from './actions';
import { UserTaskState } from './state';

import { PayloadAction } from '@reduxjs/toolkit';
import { IUserTaskItem, IUserTaskItemList, UserTaskStatus } from './model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { ExtraReducer } from 'state/store';

export const reducers = {
    setNewOrUpdatedUserTask: (state: UserTaskState, action: PayloadAction<IUserTaskItem>) => {
        const index = state.userTasks.findIndex((userTask) => userTask.id === action.payload.id);
        if (index !== -1) {
            state.userTasks[index] = action.payload;
        } else {
            state.userTasks.push(action.payload);
        }
    },
    removeCompletedUserTasks: (state: UserTaskState, action: PayloadAction<IUserTaskItem>) => {
        state.userTasks = state.userTasks.filter((userTask) => userTask.id !== action.payload.id);
    },
};

export const extraReducers: ExtraReducer<UserTaskState> = (builder) =>
    builder
        .addCase(getUserTasksByTarget.pending, (state, action) => {
            state.userTasksLoading = LoadingStatus.Pending;
        })
        .addCase(getUserTasksByTarget.fulfilled, (state, { payload: userTasks }) => {
            state.userTasksLoading = LoadingStatus.Completed;
            state.userTasks = userTasks;
        })
        .addCase(getUserTasksByTarget.rejected, (state, action) => {
            state.userTasksLoading = LoadingStatus.Failed;
        })
        .addCase(getAllUserTasks.pending, (state, action) => {
            state.tenantTasksLoading = LoadingStatus.Pending;
        })
        .addCase(getAllUserTasks.fulfilled, (state, { payload: userTasks }) => {
            state.tenantTasksLoading = LoadingStatus.Completed;
            state.tenantsTasks = userTasks;
        })
        .addCase(getAllUserTasks.rejected, (state, action) => {
            state.tenantTasksLoading = LoadingStatus.Failed;
        });
