import platformApi from 'api/platform.api';
import { IFeatureFlag, IFeatureFlagTenant, IFeatureFlagTenantModel } from './model';
import { Dictionary } from '@reduxjs/toolkit';

const featureFlagApi = {
    getAllFeatureFlag,
    getFeatureFlagById,
    createFeatureFlag,
    updateFeatureFlag,
    getFeatureFlagTenant,
    updateFeatureFlagTenant,
};

function getAllFeatureFlag() {
    return platformApi.client.get<Dictionary<IFeatureFlag>>('FeatureFlags');
}

function getFeatureFlagById(id: string) {
    return platformApi.client.get<IFeatureFlag>(`FeatureFlags/${id}`);
}

function createFeatureFlag(model: IFeatureFlag) {
    return platformApi.client.post<IFeatureFlag>('FeatureFlags', model);
}

function updateFeatureFlag(model: IFeatureFlag) {
    return platformApi.client.put<IFeatureFlag>('FeatureFlags', model);
}

function getFeatureFlagTenant(id: string) {
    return platformApi.client.get<IFeatureFlagTenant[]>(`FeatureFlags/FeatureFlag/${id}`);
}
function updateFeatureFlagTenant(id: string, model: IFeatureFlagTenantModel) {
    return platformApi.client.put<IFeatureFlagTenantModel>(`FeatureFlags/FeatureFlag/${id}`, model);
}

export default featureFlagApi;
