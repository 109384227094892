import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAccount } from 'state/slices/user/user.actions';
import { userAccountLoading } from 'state/slices/user/user.slice';
import { ErrorPage } from 'views/component';

type Props = Record<string, unknown>;

export default function AccountInitializer({ children }: PropsWithChildren<Props>): JSX.Element | null {
    const _dispatch = useDispatch();
    const _userAccountLoading = useSelector(userAccountLoading);

    useEffect(() => {
        _dispatch(getAccount());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (_userAccountLoading === LoadingStatus.Failed)
        return <ErrorPage errorText="Failed to get the current user account" iconName="Error" goBack={false} />;

    return <>{children}</>;
}
