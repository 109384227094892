import ITenant from 'views/pages/Tenants/state/tenants.model';
import { IValidationConfig } from 'hooks/useValidation';

import { BillingAddressType } from './Billing';
import { TenantInfoType } from './About';

export default function tenantValidationsConfig(tenant: ITenant | undefined): IValidationConfig {
    const validationConfig: IValidationConfig = [
        {
            fieldName: TenantInfoType.TenantDisplayName,
            validation: ['required', 'characterLimit'],
            value: tenant?.displayName,
            itemOptions: {
                characterLimit: 64,
            },
        },
    ];

    if (tenant?.practiceId?.length) {
        validationConfig.push({
            fieldName: TenantInfoType.PracticeId,
            validation: ['characterLimit'],
            value: tenant?.practiceId,
            itemOptions: {
                characterLimit: 9,
            },
        });
    }

    if (
        tenant?.billingInformation?.address?.street1?.length ||
        tenant?.billingInformation?.address?.city?.length ||
        tenant?.billingInformation?.address?.state?.length ||
        tenant?.billingInformation?.address?.zip?.length
    ) {
        validationConfig.push(
            {
                fieldName: BillingAddressType.PhysicalStreet1,
                validation: ['required'],
                value: tenant?.billingInformation?.address.street1,
            },
            {
                fieldName: BillingAddressType.PhysicalCity,
                validation: ['required'],
                value: tenant?.billingInformation?.address.city,
            },
            {
                fieldName: BillingAddressType.PhysicalState,
                validation: ['required'],
                value: tenant?.billingInformation?.address.state,
            },
            {
                fieldName: BillingAddressType.PhysicalZip,
                validation: ['required'],
                value: tenant?.billingInformation?.address.zip,
            },
        );
    }
    if (
        tenant?.billingInformation?.payToAddress?.street1?.length ||
        tenant?.billingInformation?.payToAddress?.city?.length ||
        tenant?.billingInformation?.payToAddress?.state?.length ||
        tenant?.billingInformation?.payToAddress?.zip?.length
    ) {
        validationConfig.push(
            {
                fieldName: BillingAddressType.InsuranceStreet1,
                validation: ['required'],
                value: tenant?.billingInformation?.payToAddress.street1,
            },

            {
                fieldName: BillingAddressType.InsuranceCity,
                validation: ['required'],
                value: tenant?.billingInformation?.payToAddress.city,
            },
            {
                fieldName: BillingAddressType.InsuranceState,
                validation: ['required'],
                value: tenant?.billingInformation?.payToAddress.state,
            },
            {
                fieldName: BillingAddressType.InsuranceZip,
                validation: ['required'],
                value: tenant?.billingInformation?.payToAddress.zip,
            },
        );
    }
    if (
        tenant?.billingInformation?.statementAddress?.street1 ||
        tenant?.billingInformation?.statementAddress?.city ||
        tenant?.billingInformation?.statementAddress?.state ||
        tenant?.billingInformation?.statementAddress?.zip
    ) {
        validationConfig.push(
            {
                fieldName: BillingAddressType.StatementRemitStreet1,
                validation: ['required'],
                value: tenant?.billingInformation?.statementAddress.street1,
            },
            {
                fieldName: BillingAddressType.StatementRemitCity,
                validation: ['required'],
                value: tenant?.billingInformation?.statementAddress.city,
            },
            {
                fieldName: BillingAddressType.StatementRemitState,
                validation: ['required'],
                value: tenant?.billingInformation?.statementAddress.state,
            },
            {
                fieldName: BillingAddressType.StatementRemitZip,
                validation: ['required'],
                value: tenant?.billingInformation?.statementAddress.zip,
            },
        );
    }

    return validationConfig;
}
