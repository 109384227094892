import {
    ContextualMenu,
    IContextualMenuItem,
    IContextualMenuItemProps,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { Field } from 'components';
import { useEffect, useState } from 'react';
import { ISearchFieldProps } from './SearchField';

export interface IAsyncSearchComboField extends ISearchFieldProps {
    menuItems?: IContextualMenuItem[];
    staticMenuItems?: IContextualMenuItem[];
    onClear?: () => void;
    loading?: string | boolean;
}

function TypeaheadSearchField({
    onSearch,
    onChangeSearch,
    onClear,
    loading,
    menuItems,
    staticMenuItems,
    ...props
}: IAsyncSearchComboField): JSX.Element {
    const isLoading = loading === 'pending' || loading === true;
    const [canOpen, setCanOpen] = useState<boolean>(false);
    const [lastSearched, setLastSearched] = useState<string>('');

    const id = useId();

    useEffect(() => {
        return () => {
            _onClear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function _onSearch(search: string) {
        if (onSearch) {
            setCanOpen(true);
            onSearch(search);
            setLastSearched(search);
        }
    }

    function _onChangeSearch(search: string) {
        if (onChangeSearch) {
            setCanOpen(true);
            onChangeSearch(search);
            setLastSearched(search);
        }
    }

    function _onClear() {
        if (onClear) onClear();
        setLastSearched('');
    }

    function onContextMenuDismissed() {
        setCanOpen(false);
    }

    function getFormattedText(text?: string): JSX.Element | null {
        if (text) {
            const search = lastSearched.toLowerCase();
            const startIndex = text.toLowerCase().indexOf(search);
            const endIndex = startIndex + search.length;

            const firstSection = <>{text.slice(0, startIndex)}</>;
            const middleSeciton = <strong>{text.slice(startIndex, endIndex)}</strong>;
            const lastSection = <>{text.slice(endIndex, text.length)}</>;

            return (
                <span title={text} style={{ textOverflow: 'ellipsis', display: 'inline-block' }}>
                    {firstSection}
                    {middleSeciton}
                    {lastSection}
                </span>
            );
        } else {
            return null;
        }
    }

    const newMenuItems = menuItems
        ? menuItems.map((item) => ({
              ...item,
              onRenderContent: (props: IContextualMenuItemProps) =>
                  getFormattedText(props.item.text),
          }))
        : [];

    const newStaticMenuItems = staticMenuItems
        ? staticMenuItems.map((item) => ({
              ...item,
          }))
        : [];

    return (
        <Stack>
            {canOpen && (
                <ContextualMenu
                    gapSpace={1}
                    onDismiss={onContextMenuDismissed}
                    useTargetWidth={true}
                    target={`#${id}`}
                    items={[...newMenuItems, ...newStaticMenuItems]}
                    styles={{ list: { maxHeight: 300 } }}
                    shouldFocusOnMount={false}
                />
            )}
            <Field.Search
                {...props}
                id={id}
                onFocus={() => setCanOpen(true)}
                onClick={() => setCanOpen(true)}
                onBlur={onContextMenuDismissed}
                onSearch={_onSearch}
                onChangeSearch={_onChangeSearch}
                onClear={_onClear}
                onRenderSuffix={
                    isLoading && canOpen ? () => <Spinner size={SpinnerSize.small} /> : undefined
                }
            />
        </Stack>
    );
}

export default TypeaheadSearchField;
