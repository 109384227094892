import {
    Checkbox,
    DefaultButton,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    ScrollbarVisibility,
    Stack,
    StackItem,
    Text,
    TextField,
    Toggle,
} from '@fluentui/react';
import { Field, Section, SubSection } from 'components';
import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import { getValidationError } from 'hooks/useValidation';
import { IPracticeInfo } from 'views/pages/Tenants/state/tenants.model';

import { federalPovertyRegionsAsOption } from 'state/slices/poverty-guidelines/poverty-guidelines.selectors';

import { classicDateOnly } from 'utils/dateOnly';

import { HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions } from '@microsoft/signalr';
import { RouteParams } from 'interfaces/route-params';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { darkTheme, getSelectedTheme, lightTheme } from 'state/slices/ui.slice';
import { selectedTenantValidationError } from 'views/pages/Tenants/state/tenants.selectors';
import { getTenantPracticeInfo, verifyConfigurationAndRunOnboarding } from './Onboarding/state/onboarding.actions';
import TenantSourceSystem from './TenantSourceSystem';

import { isDev } from 'api/platform.api';
import { isValid } from 'date-fns';

import { selectVerifyTenantOnboardingLoading } from './Onboarding/state/onboarding.selectors';
import TimezoneInput from 'views/component/TimezoneInput';
import { updateTenantSettings } from './state/tenants.slice';

export enum TenantInfoType {
    TenantName = 'Tenant Name',
    TenantDisplayName = 'Tenant Display Name',
    PracticeId = 'Practice ID',
}

export const errorNameMessageLookup: Record<string, string> = {
    required: 'Name is required',
    characterLimit: 'Name length must be below 16 characters.',
};
export const errorDisplayNameMessageLookup: Record<string, string> = {
    required: 'Display Name is required',
    characterLimit: ' Display Name length must be below 64',
};

const hubString = isDev ? `${process.env.REACT_APP_AppApiUri}/notifications` : `#{AppApiUri}#/notifications`;
const options: IHttpConnectionOptions = {};
const connection = new HubConnectionBuilder().withUrl(hubString, options).build();

export default function About() {
    const { selectTenant, updateSelectedTenantsProp } = useTenant();

    const validationError = useSelector(selectedTenantValidationError);
    const regionOption = useSelector(federalPovertyRegionsAsOption);
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();
    const theme = useSelector(getSelectedTheme);
    const loadingOnboarding = useSelector(selectVerifyTenantOnboardingLoading);

    const disableTenant = () => {
        if (!selectTenant) return;
        updateSelectedTenantsProp('isDeleted', !selectTenant.isDeleted);
    };

    const displayNameValidationError = getValidationError(validationError, TenantInfoType.TenantDisplayName);

    const [practiceInfo, setPracticeInfo] = useState<IPracticeInfo | null>(null);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const invalidTenantPracticeID = practiceInfo?.name?.includes('PracticeId not found!');

    useEffect(() => {
        setIsConfirmed(false);
    }, [selectTenant]);

    useEffect(() => {
        setTimeout(() => {
            connection
                .start()
                .then(() => {
                    const { connectionId } = connection;
                    connection
                        .invoke('AddConnectionToGroupAsync', connectionId, tenantId)
                        .then(() => {
                            console.log('Connected to tenant hub');
                        })
                        .catch((err) => console.error(err.toString()));

                    connection.on('PracticeInfo', (data) => {
                        setPracticeInfo(data);
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 150);

        return () => {
            connection.stop();
            setPracticeInfo(null);
        };
    }, [tenantId]);

    const disablePracticeInfo =
        isValid(new Date(selectTenant?.verifiedPracticeInfoOn ?? '')) ||
        (connection.state === HubConnectionState.Connected && !selectTenant?.practiceId) ||
        (selectTenant?.practiceId && selectTenant?.practiceId?.length ? selectTenant.practiceId.length > 9 : false) ||
        (!selectTenant?.isUniversalKey && !selectTenant?.clientId && !selectTenant?.clientSecret);

    function disableColorButton() {
        if (disablePracticeInfo) {
            if (theme === lightTheme) {
                return '#CACACA';
            }
            if (theme == darkTheme) {
                return '#454545';
            }

            return '';
        }
    }

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z0-9\s-']*$/.test(value) : true;
        return res;
    };

    const verifiedPracticeInfo = isValid(new Date(selectTenant?.verifiedPracticeInfoOn ?? ''));

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Stack style={{ flex: 1, display: 'flex', height: '95%', overflowY: 'auto' }}>
                    <Section
                        heading="Basic Tenant Information"
                        headingCenterContent={
                            <Checkbox
                                label="Net New"
                                checked={selectTenant?.isNetNew}
                                onChange={(ev, value) => updateSelectedTenantsProp('isNetNew', value)}
                            />
                        }
                        headingRightContent={
                            <Toggle label="Deactivated" checked={selectTenant?.isDeleted} onClick={disableTenant} inlineLabel />
                        }
                    >
                        <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                            <Stack.Item grow={1}>
                                <TextField
                                    value={selectTenant?.id}
                                    onChange={(ev, value: any) => updateSelectedTenantsProp('id', value)}
                                    label="Tenant Id"
                                    autoComplete="none"
                                    disabled
                                />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField
                                    value={selectTenant?.name}
                                    onChange={(ev, value: any) => {
                                        if (validTextInput(value)) updateSelectedTenantsProp('name', value);
                                    }}
                                    label={`${'Tenant Database Name (Internal Only)'}`}
                                    required
                                    autoComplete="none"
                                    description={`${selectTenant?.name?.length || 0} of 16 characters used`}
                                    disabled
                                />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField
                                    value={selectTenant?.displayName}
                                    onChange={(ev, value: any) => {
                                        if (validTextInput(value)) updateSelectedTenantsProp('displayName', value);
                                    }}
                                    label="Tenant Display Name"
                                    required
                                    autoComplete="none"
                                    description={`${selectTenant?.displayName?.length || 0} of 64 characters used`}
                                    errorMessage={
                                        displayNameValidationError
                                            ? errorDisplayNameMessageLookup[displayNameValidationError.errorTypes[0]]
                                            : undefined
                                    }
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                            <StackItem grow={1}>
                                <Field.Date
                                    label="First Date of Service/First Date of End User Training"
                                    value={
                                        selectTenant?.firstDateOfService ? classicDateOnly(selectTenant?.firstDateOfService) : ''
                                    }
                                    hasDatePicker
                                    onChange={(ev, value) => updateSelectedTenantsProp('firstDateOfService', value)}
                                />
                            </StackItem>
                            <StackItem grow={1}>
                                <Field.Date
                                    label="Last Date of Service/Last Date of Preview Connection"
                                    value={
                                        selectTenant?.lastDateOfService ? classicDateOnly(selectTenant?.lastDateOfService) : ''
                                    }
                                    hasDatePicker
                                    onChange={(ev, value) => updateSelectedTenantsProp('lastDateOfService', value)}
                                />
                            </StackItem>

                            <Stack.Item grow={1}>
                                <Field.Dropdown
                                    label="Federal Poverty Region"
                                    options={regionOption}
                                    selectedKey={selectTenant?.federalPovertyRegion ?? ''}
                                    onChange={(ev, option) => {
                                        if (option) {
                                            updateSelectedTenantsProp('federalPovertyRegion', option.key);
                                        }
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                            <Stack.Item grow>
                                <TimezoneInput
                                    selectedKey={(selectTenant?.settings?.dashboard?.timeZoneId as string) ?? ''}
                                    onChange={(ev, option) => {
                                        dispatch(
                                            updateTenantSettings({
                                                setting: 'dashboard',
                                                path: 'timeZoneId',
                                                value: option?.key,
                                            }),
                                        );
                                    }}
                                    label="Default Timezone"
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Toggle
                                    styles={{ root: { marginBottom: 0 } }}
                                    onText="Active"
                                    checked={!!selectTenant?.settings?.dashboard?.isActive}
                                    onChange={(ev, checked) => {
                                        dispatch(
                                            updateTenantSettings({
                                                setting: 'dashboard',
                                                path: 'isActive',
                                                value: checked,
                                            }),
                                        );
                                    }}
                                    offText="Deactivated"
                                    label="Worklist Dashboard Status"
                                />
                            </Stack.Item>
                        </Stack>
                    </Section>
                    <Stack style={{ display: 'flex', flex: 1 }}>
                        <Section
                            heading="Source System Information"
                            headingCenterContent={
                                <Checkbox
                                    label="Use Universal Key"
                                    checked={selectTenant?.isUniversalKey}
                                    onChange={(ev, value) => {
                                        updateSelectedTenantsProp('isUniversalKey', value);
                                    }}
                                    disabled={verifiedPracticeInfo}
                                />
                            }
                            headingRightContent={
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                    <DefaultButton
                                        onClick={() => {
                                            if (selectTenant?.id && selectTenant?.practiceId) {
                                                setPracticeInfo(null);
                                                dispatch(
                                                    getTenantPracticeInfo({
                                                        model: {
                                                            tenantId: selectTenant?.id,
                                                            practiceId: parseInt(selectTenant?.practiceId),
                                                            clientId: selectTenant?.clientId ?? '',
                                                            clientSecret: selectTenant?.clientSecret ?? '',
                                                            isUniversalKey: selectTenant?.isUniversalKey ?? false,
                                                        },
                                                    }),
                                                );
                                            }
                                        }}
                                        text="Confirm Practice ID"
                                        style={{
                                            color: disableColorButton(),
                                        }}
                                        disabled={disablePracticeInfo}
                                    />
                                </Stack>
                            }
                        >
                            <Stack tokens={{ childrenGap: 10 }} grow>
                                <TenantSourceSystem />

                                {practiceInfo && (
                                    <SubSection title="Test Results (Practice Info)">
                                        {!invalidTenantPracticeID ? (
                                            <Stack tokens={{ childrenGap: 5 }}>
                                                <pre>{JSON.stringify(practiceInfo, null, 2)}</pre>

                                                <Stack horizontal tokens={{ childrenGap: 3 }}>
                                                    <Checkbox
                                                        checked={isConfirmed}
                                                        onChange={() => {
                                                            setIsConfirmed(!isConfirmed);
                                                        }}
                                                    />
                                                    <Text> I confirm that the practice information above is correct.</Text>
                                                </Stack>
                                                <Stack.Item>
                                                    <DefaultButton
                                                        onClick={() => {
                                                            dispatch(verifyConfigurationAndRunOnboarding());
                                                        }}
                                                        text={loadingOnboarding ? 'Starting...' : 'Start Integration'}
                                                        disabled={loadingOnboarding || !isConfirmed}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        ) : (
                                            <MessageBar messageBarType={MessageBarType.error}>Practice ID not found!</MessageBar>
                                        )}
                                    </SubSection>
                                )}
                            </Stack>
                        </Section>
                    </Stack>
                </Stack>
            </ScrollablePane>
        </Stack>
    );
}
