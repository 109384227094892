import IProcedure from 'api/models/procedure.model';
import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    proceduresAsList,
    proceduresData,
    proceduresState,
    showProceduresHistory,
} from 'state/slices/procedures/procedures.selectors';
import {
    addNewProcedure,
    addProcedure,
    cleanupSelectedProcedure,
    getProcedureById,
    getProcedures,
    setProcedurePropAndSave,
    toggleShowProcedureHistory,
    updateProcedure,
    updateSelectedProcedureProp,
} from 'state/slices/procedures/procedures.slice';

function useProcedures() {
    const _dispatch = useDispatch();

    const { loading, loadingSelectedProcedure, saving, selectedProcedure, isAdding } =
        useSelector(proceduresState);
    const _showProceduresHistory = useSelector(showProceduresHistory);
    const _proceduresData = useSelector(proceduresData);
    const _proceduresAsList = useSelector(proceduresAsList);

    const _toggleShowProviderHistory = useCallback(() => {
        _dispatch(toggleShowProcedureHistory());
    }, [_dispatch]);

    const _addNewProcedure = useCallback(() => {
        _dispatch(addNewProcedure());
    }, [_dispatch]);

    const _setProcedurePropAndSave = useCallback(
        (id: string, path: keyof IProcedure, value: any) => {
            _dispatch(setProcedurePropAndSave(id, path, value));
        },
        [_dispatch],
    );

    const _updateSelectedProcedureProp = useCallback(
        (path: keyof IProcedure, value: any) => {
            _dispatch(updateSelectedProcedureProp({ path, value }));
        },
        [_dispatch],
    );

    const _cleanupSelectedProcedure = useCallback(() => {
        _dispatch(cleanupSelectedProcedure());
    }, [_dispatch]);

    const _getProcedures = useCallback(() => {
        _dispatch(getProcedures());
    }, [_dispatch]);

    const _getProcedureById = useCallback(
        (procedureId: string) => {
            _dispatch(getProcedureById({ procedureId }));
        },
        [_dispatch],
    );

    const _updateProcedure = useCallback(
        (procedure: IProcedure) => {
            _dispatch(updateProcedure({ procedure }));
        },
        [_dispatch],
    );

    const _addProcedure = useCallback(
        (procedure: IProcedure) => {
            _dispatch(addProcedure({ procedure }));
        },
        [_dispatch],
    );

    return {
        // Selectors
        loading,
        loadingSelectedProcedure,
        selectedProcedure,
        saving,
        isAdding,
        proceduresData: _proceduresData,
        proceduresAsList: _proceduresAsList,
        showProceduresHistory: _showProceduresHistory,

        // Async Actions
        getProcedures: _getProcedures,
        getProcedureById: _getProcedureById,
        updateProcedure: _updateProcedure,
        addProcedure: _addProcedure,
        setProcedurePropAndSave: _setProcedurePropAndSave,

        //Actions
        toggleShowProviderHistory: _toggleShowProviderHistory,
        cleanupSelectedProcedure: _cleanupSelectedProcedure,
        updateSelectedProcedureProp: _updateSelectedProcedureProp,
        addNewProcedure: _addNewProcedure,
    };
}

export default useProcedures;
