import { createSelector } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { RootState } from 'state/store';

export const selectEventingChangesetsState = (state: RootState) => state.tenants.eventingChangesetState;

export const selectEventingChangesetsPayload = createSelector(selectEventingChangesetsState, (state) => state.payload);
export const selectEventingChangesetsMessageBar = createSelector(selectEventingChangesetsState, (state) => ({
    messageBarMessage: state.messageBarMessage,
    messageBarType: state.messageBarType,
}));
export const selectEventingChangesetsLocationsOfCareData = createSelector(
    selectEventingChangesetsState,
    (state) => state.locationsOfCare ?? {},
);
export const selectEventingChangesetsLocationsOfCareLoading = createSelector(
    selectEventingChangesetsState,
    (state) => state.loadingLocationsOfCare,
);
export const selectEventingChangesetsLocationsOfCareAsList = createSelector(
    selectEventingChangesetsLocationsOfCareData,
    (lookup) => map(lookup),
);
export const selectEventingChangesetsDentalLocationsOfCare = createSelector(
    selectEventingChangesetsLocationsOfCareAsList,
    (locs) => locs.filter((item) => item.departmentType === 'dental'),
);

export const selectEventingChangesetsActionLoading = createSelector(
    selectEventingChangesetsState,
    (state) => state.subscriptionActionLoading,
);
export const selectEventingChangesetsModalOpen = createSelector(selectEventingChangesetsState, (state) => state.modalOpen);
export const selectEventingChangesetsModalAction = createSelector(selectEventingChangesetsState, (state) => state.modalAction);
