import { useLocation } from 'react-router';
import { Stack, IContextualMenuItem, useTheme, ITheme } from '@fluentui/react';
import './NavBar.scss';
import AvatarContainer from '../Avatar/Avatar.container';
import Logo from './Logo';
import { PropsWithChildren } from 'react';

type Props = {
    avatarMenuItems?: IContextualMenuItem[];
    appName?: string;
    appNameLink?: string;
};
type CustomTheme = ITheme & { palette: { navbar: string } };

function NavBar(props: PropsWithChildren<Props>): JSX.Element {
    const location = useLocation();
    const isTenantSelected = location.pathname !== '/';
    const theme = useTheme();

    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className="app-nav-bar"
            style={{ background: (theme as CustomTheme).palette.navbar }}
        >
            <Stack
                horizontal
                horizontalAlign="start"
                tokens={{ childrenGap: 10 }}
                verticalAlign="center"
                style={{ height: '100%' }}
            >
                <Logo appName={props.appName} appNameLink={props.appNameLink} variant="medium" />
                {isTenantSelected && props.children}
            </Stack>
            <Stack
                tokens={{ childrenGap: 10 }}
                horizontal
                horizontalAlign="end"
                verticalAlign="center"
            >
                <AvatarContainer menuItems={props.avatarMenuItems} />
            </Stack>
        </Stack>
    );
}

export default NavBar;
