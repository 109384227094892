import { Icon, Stack, useTheme, Text, Spinner, SpinnerSize } from '@fluentui/react';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { userAccount, userAccountLoading } from 'state/slices/user/user.slice';

export function AccessDenied() {
    const theme = useTheme();
    const account = useSelector(userAccount);
    const loading = useSelector(userAccountLoading);

    if (!account || loading === LoadingStatus.Pending) return <Spinner size={SpinnerSize.large} />;

    return (
        <Stack style={{ height: '100%' }} verticalAlign="center" horizontalAlign="center">
            <Stack
                className="ms-depth-16"
                horizontal
                horizontalAlign="center"
                style={{
                    overflow: 'hidden',
                    borderRadius: 5,
                }}
            >
                <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{
                        width: 100,
                        height: '100%',
                        background: theme.palette.red,
                    }}
                >
                    <Icon
                        iconName="Lock"
                        styles={{
                            root: { fontSize: 40, color: '#FFF', fontWeight: 'bold', textShadow: '0 3px 5px rgba(0,0,0,0.25)' },
                        }}
                    />
                </Stack>
                <Stack style={{ background: theme.palette.neutralLighterAlt, padding: '10px 20px' }} tokens={{ childrenGap: 10 }}>
                    <Text variant="xLargePlus">Access Denied</Text>
                    <Stack.Item>
                        <Text variant="medium" block>
                            You do not currently have access to this page.
                        </Text>
                        <Text variant="medium">Please contact your administrator for more information.</Text>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
    );
}
