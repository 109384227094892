import { IconButton, SearchBox, Stack, useTheme } from '@fluentui/react';

import 'components/ActivityBar/ActivityBar.scss';
import { useSelector } from 'hooks';

import { useDispatch } from 'react-redux';

import { useFuseSearch } from 'hooks/useFuseSearch';
import { orderBy } from 'lodash';
import FeatureFlagBarLink from './FeatureFlagBarLink';
import { IFeatureFlag } from './state/model';
import { featureFlagDataList, featureFlagDataListHistory, isFeatureBarExpanded } from './state/selector';
import { setFeatureFlag, toggleExpanded } from './state/slice';
import { useParams } from 'react-router-dom';
import ActivityBarLink from 'components/ActivityBar/ActivityBarLink';

interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;
    notifications?: number;
    subMenuProps?: IActivity[];
    isDeleted?: boolean;
    featureFlag?: IFeatureFlag;
}

function FeatureFlagBar() {
    const isExpanded = useSelector(isFeatureBarExpanded);
    const dispatch = useDispatch();
    const theme = useTheme();
    const featureFlagsList = useSelector(featureFlagDataListHistory);

    const { results, onSearch, search, onSearchChange } = useFuseSearch<IFeatureFlag>({
        fuseOptions: { keys: ['displayName'], threshold: 0.1 },
        list: featureFlagsList,
    });

    const activities: IActivity[] = orderBy(
        results.length
            ? results.map((featureFlag: IFeatureFlag) => {
                  return {
                      keyString: featureFlag.id,
                      path: `/featureflags/${featureFlag.id}`,
                      tooltip: featureFlag.displayName ? `${featureFlag.displayName}  ` : 'N/A',
                      exact: false,
                      isDeleted: featureFlag.isDeleted,
                      isArchived: featureFlag.isArchived,
                      featureFlag,
                  };
              })
            : featureFlagsList.map((featureFlag: IFeatureFlag) => {
                  return {
                      keyString: featureFlag.id,
                      path: `/feature-flags/${featureFlag.id}`,
                      tooltip: featureFlag.displayName ? `${featureFlag.displayName}  ` : 'N/A',
                      exact: false,
                      isDeleted: featureFlag.isDeleted,
                      isArchived: featureFlag.isArchived,
                      featureFlag,
                  };
              }),
        'tooltip',
    );

    const { neutralLighterAlt } = theme.palette;

    const activityLinks = activities.map((activity) => (
        <FeatureFlagBarLink key={activity.keyString} expanded={isExpanded} {...activity} />
    ));

    const wrapperClassName = isExpanded ? 'activityBar activityBar--expanded' : 'activityBar';

    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
        minWidth: isExpanded ? '250px' : '70px',
    };

    const footerStyle = {
        borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}`,
    };

    const expanderIcon = {
        iconName: isExpanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };

    if (activityLinks.length > 0) {
        return (
            <Stack className={wrapperClassName} style={wrapperStyle}>
                <Stack style={{ flex: 1, ...footerStyle }}>
                    <Stack style={{ padding: 10, borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}` }}>
                        <SearchBox
                            placeholder="Search by display name"
                            value={search}
                            onSearch={onSearch}
                            onChange={onSearchChange}
                        />
                    </Stack>
                    <Stack
                        style={{
                            height: '80vh',
                            overflowY: 'auto',
                        }}
                        grow
                    >
                        {activityLinks}
                    </Stack>
                </Stack>

                <div className={'activityBar__footer'}>
                    <IconButton onClick={() => dispatch(toggleExpanded(!isExpanded))} iconProps={expanderIcon} />
                </div>
            </Stack>
        );
    }

    return <></>;
}

export default FeatureFlagBar;
