import {
    IDetailsRowProps,
    DetailsRow,
    IDetailsHeaderProps,
    DetailsHeader,
    Stack,
    MessageBar,
    MessageBarType,
    IStyle,
    SelectionMode,
    IColumn,
    DetailsListLayoutMode,
    ConstrainMode,
    Text,
    Tooltip,
    TooltipHost,
} from '@fluentui/react';
import { ITenantOnboardingProcessSteps } from 'views/pages/Tenants/state/tenants.model';
import SortableDetailsList from 'components/SortableDetailsList/SortableDetailsList';
import { format } from 'date-fns';
import { useSelector } from 'hooks';
import SaveStatusIcon, { SyncStatus } from 'views/component/SavingStatusIcon';

export default function OnBoardingStepsList({ items }: { items?: ITenantOnboardingProcessSteps[] }) {
    const { selectedTheme } = useSelector((state) => state.ui);

    const onRenderProcedureRow = (props: IDetailsRowProps | undefined): JSX.Element | null => {
        if (props) {
            return (
                <DetailsRow
                    {...props}
                    styles={{
                        root: {
                            color: selectedTheme === 'light' ? 'black' : props.theme?.palette.neutralLight,
                            backgroundColor: props.theme?.palette.neutralLight,
                        },
                    }}
                />
            );
        }
        return null;
    };

    const onRenderProcedureHeader = (props: IDetailsHeaderProps | undefined): JSX.Element | null => {
        if (props) {
            return (
                <DetailsHeader
                    {...props}
                    styles={{ root: { padding: 0, borderTop: `1px solid ${props.theme?.palette.neutralTertiaryAlt}` } }}
                />
            );
        }
        return null;
    };

    const onLastRunRender = (item: ITenantOnboardingProcessSteps) => {
        if (item.createdOn) {
            return format(new Date(item.createdOn), 'Pp');
        }
        return null;
    };

    const onboardingStepColumns: IColumn[] = [
        {
            key: 'message',
            name: 'Message',
            fieldName: 'message',
            minWidth: 100,
            maxWidth: 800,

            onRender: (item) => {
                return (
                    <TooltipHost content={item.message} calloutProps={{ gapSpace: 0 }}>
                        <Stack wrap>
                            <Text variant="small">{item.message}</Text>
                        </Stack>
                    </TooltipHost>
                );
            },
        },
        {
            key: 'successful',
            name: 'Successful',
            fieldName: 'successful',
            minWidth: 100,
            onRender: (item) => {
                if (item.successful === false) {
                    return <SaveStatusIcon saveStatus={SyncStatus.Failed} />;
                } else {
                    return <SaveStatusIcon saveStatus={SyncStatus.Completed} />;
                }
            },
        },
        {
            key: 'createdOn',
            name: 'Created On',
            fieldName: 'createdOn',
            minWidth: 400,
            onRender: onLastRunRender,
        },
    ];

    const hasStep = items?.length ? true : false;

    const spacingStyle: IStyle = { marginBottom: 15 };

    if (!hasStep)
        return (
            <Stack styles={{ root: spacingStyle }}>
                <MessageBar messageBarType={MessageBarType.info}>This step does not have any associated task</MessageBar>
            </Stack>
        );

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <SortableDetailsList
                items={items ?? []}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={onRenderProcedureHeader}
                onRenderRow={onRenderProcedureRow}
                // compact
                columns={onboardingStepColumns}
                styles={{ root: spacingStyle }}
                sortOnMount={true}
            />
        </Stack>
    );
}
