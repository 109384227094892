import { createSelector } from '@reduxjs/toolkit';
import { tenantsState } from '../../state/tenants.selectors';

export const selectSelectedTenantHardCloseDate = createSelector(tenantsState, (state) => state.selectedTenantHardCloseDate ?? '');
export const selectSelectedTenantHardCloseDateLoading = createSelector(
    tenantsState,
    (state) => state.selectedTenantHardCloseDateLoading,
);

export const selectSelectedTenantHardCloseLoading = createSelector(
    tenantsState,
    (state) => state.selectedTenantHardCloseHistoryLoading,
);

export const selectSelectedTenantHardCloseHistory = createSelector(tenantsState, (state) => state.selectedTenantHardCloseHistory);
export const selectSelectedTenantHardCloseHistoryLoading = createSelector(
    tenantsState,
    (state) => state.selectedTenantHardCloseHistoryLoading,
);

export const selectSelectedTenantHardCloseHistorySaving = createSelector(tenantsState, (state) => state.savingHardCloseHistory);

export const financialClosingMaxCloseThroughDate = createSelector(
    selectSelectedTenantHardCloseHistory,
    (data) => data?.maxHardClosedThroughDate ?? '',
);

export const financialClosingDayAfterMonthEnd = createSelector(
    selectSelectedTenantHardCloseHistory,
    (data) => data?.hardCloseDayAfterMonthEnd ?? '',
);

export const financialClosingAutoClose = createSelector(
    selectSelectedTenantHardCloseHistory,
    (data) => data?.autoHardClose ?? false,
);

export const financialClosingHardCloseHistory = createSelector(
    selectSelectedTenantHardCloseHistory,
    (data) => data?.history ?? [],
);

export const savingHardCloseBatches = createSelector(tenantsState, (state) => state.savingHardCloseBatches);
export const hardCloseBatchesPayload = createSelector(tenantsState, (state) => state.hardClosePayload);
export const hardCloseBatchModalOpen = createSelector(tenantsState, (state) => state.hardCloseModalOpen);
export const hardCloseBatchesMessageBar = createSelector(tenantsState, (state) => ({
    messageBarType: state.hardCloseMessageBarType,
    messageBarText: state.hardCloseMessageBarText,
}));
