import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { useSelector } from 'hooks';
import React, { useEffect } from 'react';
import { featureFlagDataList, openFeatureFlagModal, selectedFeatureFlag } from './state/selector';
import { Field } from 'components';
import { useDispatch } from 'react-redux';
import { setFeatureFlagModal, updateSelectedFeatureFlagProp } from './state/slice';
import { createFeatureFlag } from './state/actions';
import useValidation, { IValidationConfig, getValidationError } from 'hooks/useValidation';

function AddFeatureModal() {
    const dispatch = useDispatch();
    const isPanelOpen = useSelector(openFeatureFlagModal);
    const selectFeatureFlag = useSelector(selectedFeatureFlag);
    const featureFlagList = useSelector(featureFlagDataList);

    const _onDismiss = () => {
        dispatch(setFeatureFlagModal(false));
    };

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z0-9\s-']*$/.test(value) : true;
        return res;
    };

    const onSave = () => {
        if (selectFeatureFlag) {
            dispatch(setFeatureFlagModal(false));
            dispatch(createFeatureFlag({ featureFlag: selectFeatureFlag }));
        }
    };

    const noDuplicateName = featureFlagList.filter((item) => item.displayName === selectFeatureFlag?.displayName).length > 0;

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'displayName',
            validation: ['required'],
            value: selectFeatureFlag?.displayName,
        },
        {
            fieldName: 'description',
            validation: ['required'],
            value: selectFeatureFlag?.description,
        },
    ];

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onSave);

    useEffect(() => {
        return () => {
            cleanupErrors();
        };
    }, [isPanelOpen]);

    return (
        <Panel
            headerText={`Add Feature Flag`}
            isOpen={isPanelOpen}
            isFooterAtBottom
            type={PanelType.medium}
            onDismiss={_onDismiss}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton text={'Save'} onClick={onSubmit} />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                    </Stack>
                </Stack>
            )}
        >
            <Stack styles={{ root: { padding: 10 } }} grow>
                <TextField
                    value={selectFeatureFlag?.displayName}
                    onChange={(ev, value: any) => {
                        if (validTextInput(value)) dispatch(updateSelectedFeatureFlagProp({ path: 'displayName', value }));
                    }}
                    label="Feature Display Name"
                    required
                    autoComplete="none"
                    errorMessage={
                        noDuplicateName
                            ? 'Duplicate name, enter a different value'
                            : getValidationError(errors, 'displayName')
                            ? 'Display Name is required'
                            : undefined
                    }
                />
                <TextField
                    value={selectFeatureFlag?.description}
                    onChange={(ev, value: any) => {
                        if (validTextInput(value)) dispatch(updateSelectedFeatureFlagProp({ path: 'description', value }));
                    }}
                    label="Feature Description"
                    autoComplete="none"
                />
                <Field.Date
                    value={selectFeatureFlag?.originalStartDate}
                    onChange={(ev, value: any) => {
                        dispatch(updateSelectedFeatureFlagProp({ path: 'originalStartDate', value }));
                    }}
                    label="Feature Original Start Date"
                    hasDatePicker
                    autoComplete="none"
                />
            </Stack>
        </Panel>
    );
}

export default AddFeatureModal;
