import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loadingStatus';
import {
    createFeatureFlag,
    getAllFeatureFlags,
    getFeatureFlagById,
    getFeatureFlagTenant,
    updateFeatureFlag,
    updateFeatureFlagTenant,
} from './actions';
import featureFlagReducer from './reducers';
import { initialState } from './state';

const featureFlagSlice = createSlice({
    name: 'featureFlag',
    initialState,
    reducers: { ...featureFlagReducer },
    extraReducers: (builder) => {
        builder
            //get all feature flag
            .addCase(getAllFeatureFlags.pending, (state) => {
                state.loadingFeatureFlag = LoadingStatus.Pending;
            })
            .addCase(getAllFeatureFlags.fulfilled, (state, action) => {
                state.loadingFeatureFlag = LoadingStatus.Completed;
                state.featureFlags = action.payload;
            })
            .addCase(getAllFeatureFlags.rejected, (state, action) => {
                state.loadingFeatureFlag = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //get feature flag by id
            .addCase(getFeatureFlagById.pending, (state) => {
                state.loadingFeatureFlag = LoadingStatus.Pending;
            })
            .addCase(getFeatureFlagById.fulfilled, (state, action) => {
                state.loadingFeatureFlag = LoadingStatus.Completed;
                state.selectedFeatureFlag = action.payload;
            })
            .addCase(getFeatureFlagById.rejected, (state, action) => {
                state.loadingFeatureFlag = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(createFeatureFlag.pending, (state) => {
                state.savingFeatureFlag = LoadingStatus.Pending;
            })
            .addCase(createFeatureFlag.fulfilled, (state, action) => {
                state.savingFeatureFlag = LoadingStatus.Completed;

                state.selectedFeatureFlag = action.payload;
                state.featureFlags[action.payload.id] = action.payload;
            })
            .addCase(createFeatureFlag.rejected, (state, action) => {
                state.savingFeatureFlag = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(updateFeatureFlag.pending, (state) => {
                state.savingFeatureFlag = LoadingStatus.Pending;
            })
            .addCase(updateFeatureFlag.fulfilled, (state, action) => {
                state.savingFeatureFlag = LoadingStatus.Completed;
                state.featureFlags = {
                    ...state.featureFlags,
                    [action.payload.id]: action.payload,
                };
                state.selectedFeatureFlag = action.payload;
            })
            .addCase(updateFeatureFlag.rejected, (state, action) => {
                state.savingFeatureFlag = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(getFeatureFlagTenant.pending, (state, action) => {
                state.loadingSelectedFeatureFlagTenant = LoadingStatus.Pending;
            })
            .addCase(getFeatureFlagTenant.fulfilled, (state, action) => {
                state.loadingSelectedFeatureFlagTenant = LoadingStatus.Completed;
                state.selectedFeatureFlagTenant = action.payload;
            })
            .addCase(getFeatureFlagTenant.rejected, (state, action) => {
                state.loadingSelectedFeatureFlagTenant = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

export const {
    toggleExpanded,
    setFeatureFlagModal,
    updateSelectedFeatureFlagProp,
    addNewFeatureFlag,
    setFeatureFlag,
    setFeatureFlagTenantModal,
    toggleTenantHistory,
    toggleFeatureFlagHistory,
    cleanupSelectedFeatureFlag,
} = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
