import { useDispatch } from 'react-redux';
import { msalProvider } from 'api/auth/authProvider';
import { useSelector } from 'hooks';
import Avatar from './Avatar';
import {
    ContextualMenuItemType,
    Toggle,
    IContextualMenuItem,
} from '@fluentui/react';
import { setTheme } from 'state/slices/ui.slice';
import { useAccount } from '@azure/msal-react';

type Props = {
    menuItems?: IContextualMenuItem[];
};

export default function AvatarContainer({ menuItems }: Props): JSX.Element {
    const dispatch = useDispatch();
    const account = useAccount();

    const { photo, profile } = useSelector((state) => state.user);
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);

    const _logout = async () => await msalProvider.logoutRedirect({ account: account ?? undefined});
    const _onThemeChange = () => {
        if (selectedTheme === 'light') {
            dispatch(setTheme('dark'));
        } else {
            dispatch(setTheme('light'));
        }
    };

    const menuItemList = menuItems ? menuItems : [];

    const contextMenu: any = {
        items: [
            {
                key: 'userName',
                name: 'userName',
                text: account ? account.name : 'User Name',
                itemType: ContextualMenuItemType.Header,
            },
            {
                key: 'divider1',
                itemType: ContextualMenuItemType.Divider,
            },
            ...menuItemList,
            {
                key: 'theme',
                onRender: function RenderThemeSwitcher() {
                    return (
                        <Toggle
                            styles={{ root: { marginLeft: 10, marginTop: 8 } }}
                            onChange={_onThemeChange}
                            label="Theme"
                            inlineLabel
                            checked={selectedTheme === 'light' ? true : false}
                            onText="Light"
                            offText="Dark"
                        />
                    );
                },
            },
            {
                key: 'divider2',
                itemType: ContextualMenuItemType.Divider,
            },
            {
                key: 'logout',
                text: 'Logout',
                iconProps: { iconName: 'SignOut' },
                onClick: () => _logout(),
            },
        ],
    };

    return <Avatar {...{ photo, profile, selectedTheme, contextMenu }} />;
}
