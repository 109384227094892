import { Stack, Text } from '@fluentui/react';
import { Field, TModal } from 'components';
import { useValidation } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedTenantId } from '../state/tenants.selectors';
import { cleanupHardCloseModal, setHardCloseModalOpen, setHardCloseThroughDate } from '../state/tenants.slice';
import { hardCloseBatches } from './state/financialClosing.actions';
import {
    hardCloseBatchesPayload,
    hardCloseBatchModalOpen,
    savingHardCloseBatches,
    selectSelectedTenantHardCloseDate,
} from './state/financialClosing.selectors';
import { getValidationError } from 'hooks/useValidation';
import { classicDateOnly } from 'utils/dateOnly';

//Fields
const HARD_ClOSE_THROUGH_DATE = 'Hard-Close Through Date';

export default function HardCloseModal() {
    const dispatch = useDispatch();

    const saving = useSelector(savingHardCloseBatches);
    const savingPending = saving === LoadingStatus.Pending;

    const isOpen = useSelector(hardCloseBatchModalOpen);
    const payload = useSelector(hardCloseBatchesPayload);

    const maxHardCloseThroughDate = useSelector(selectSelectedTenantHardCloseDate);

    const [errors, submit, cleanupValidationErrors] = useValidation(
        [
            {
                value: payload?.hardClosedThroughDate,
                fieldName: HARD_ClOSE_THROUGH_DATE,
                validation: ['required'],
            },
        ],
        onSubmit,
    );

    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const hardClosedThroughDateErrorMessage = getValidationError(errors, HARD_ClOSE_THROUGH_DATE)
        ? `${HARD_ClOSE_THROUGH_DATE} is a required field.`
        : undefined;

    function onSubmit() {
        setShowConfirmationModal(true);
    }

    function onDismiss() {
        dispatch(setHardCloseModalOpen(false));
    }

    function onDismissed() {
        dispatch(cleanupHardCloseModal());
        cleanupValidationErrors();
    }

    function onDismissConformation() {
        setShowConfirmationModal(false);
    }

    function onChangeHardCloseThroughDate(_?: React.FormEvent, value?: string) {
        dispatch(setHardCloseThroughDate(value));
    }

    function getMinHardCloseThroughDate() {
        const minDateAllowed = maxHardCloseThroughDate ? new Date(maxHardCloseThroughDate) : undefined;
        if (minDateAllowed) minDateAllowed.setDate(minDateAllowed.getDate() + 1);

        return minDateAllowed;
    }

    const formattedMaxHardCloseThroughDate = maxHardCloseThroughDate ? classicDateOnly(maxHardCloseThroughDate) : "Unknown Date";
    const formattedHardCloseThroughDate = payload?.hardClosedThroughDate ? classicDateOnly(payload.hardClosedThroughDate) : '';

    return (
        <TModal
            title={'Hard-Close Batches'}
            isDraggable
            modalProps={{ isOpen, onDismiss, onDismissed }}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Hard-Close Batches',
                    onClick: submit,
                    iconProps: { iconName: 'Lock' },
                    disabled: savingPending,
                    styles: { root: { marginLeft: 10 } },
                },
                {
                    type: 'DefaultButton',
                    text: 'Cancel',
                    onClick: onDismiss,
                    disabled: savingPending,
                },
            ]}
        >
            <Stack styles={{ root: { padding: 10 } }} wrap>
                <Field.Date
                    errorMessage={hardClosedThroughDateErrorMessage}
                    label="Hard-Close Through Date"
                    required
                    value={formattedHardCloseThroughDate}
                    onChange={onChangeHardCloseThroughDate}
                    hasDatePicker
                    isReasonable
                    minReasonableErrorMessage={`Hard-close through date must be after the previous hard-close through date for this tenant, which is ${formattedMaxHardCloseThroughDate}.`}
                    minReasonableDate={getMinHardCloseThroughDate()}
                />
            </Stack>
            <HardCloseConfirmation isOpen={showConfirmationModal} onDismiss={onDismissConformation} />
        </TModal>
    );
}

type HardCloseConfirmationProps = {
    isOpen: boolean;
    onDismiss: () => void;
};

function HardCloseConfirmation(props: HardCloseConfirmationProps) {
    const dispatch = useDispatch();

    const tenantId = useSelector(selectedTenantId);
    const payload = useSelector(hardCloseBatchesPayload);

    function onConfirm() {
        if (payload && tenantId) dispatch(hardCloseBatches({ tenantId, payload }));
        props.onDismiss();
    }

    return (
        <TModal
            title={'Hard-Close Confirmation'}
            modalProps={{ ...props, isBlocking: true }}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Confirm',
                    onClick: onConfirm,
                },
                {
                    type: 'DefaultButton',
                    text: 'Cancel',
                    onClick: props.onDismiss,
                },
            ]}
        >
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 10 } }} wrap>
                <Text variant="mediumPlus">
                    Are you sure you want to close all batches through the given date of:{' '}
                    {payload?.hardClosedThroughDate ? classicDateOnly(payload.hardClosedThroughDate) : 'Unknown Date'}
                </Text>
                <Text styles={{ root: { fontWeight: 'bold' } }} variant="mediumPlus">
                    This is action is irreversible!
                </Text>
            </Stack>
        </TModal>
    );
}
