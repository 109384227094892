import {
    Icon,
    IStackTokens,
    IStyleFunctionOrObject,
    ITheme,
    Stack,
    styled,
    TooltipHost,
} from '@fluentui/react';
import { LoadingStatus } from 'interfaces/loadingStatus';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ErrorTypes from 'state/errorTypes';

type Props = {
    saving: LoadingStatus | undefined;
    ttl: number;
    error?: string;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
};

function SavedIndicator({ saving, ttl, theme, error }: Props) {
    const [element, setElement] = useState<JSX.Element | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const { pathname } = useLocation();

    useEffect(() => {
        return () => {
            setElement(null);
        };
    }, []);

    useEffect(() => {
        setInitialized(true);
        if (initialized) {
            setElement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        setElement(getElement());
        setTimeout(() => {
            setElement(null);
        }, ttl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saving]);

    function getErrorContent() {
        switch (error) {
            case ErrorTypes.itemModifiedOutOfContext:
                return 'This item has already been modified and saved by another user.';
            default:
                return undefined;
        }
    }

    const stackTokens: IStackTokens = {
        childrenGap: 10,
    };

    function getElement() {
        if (saving === 'pending') return null;
        if (saving === 'completed')
            return (
                <TooltipHost content="Saved">
                    <Stack
                        tokens={stackTokens}
                        style={{
                            color: theme?.palette.themeSecondary,
                            fontWeight: 400,
                            cursor: 'pointer',
                        }}
                        horizontal
                        verticalAlign="center"
                    >
                        <div
                            style={{
                                position: 'relative',
                            }}
                        >
                            <Icon
                                iconName="CircleRing"
                                style={{ position: 'absolute', top: -1, left: -3, fontSize: 22 }}
                            ></Icon>
                            <Icon iconName="CheckMark"></Icon>
                        </div>
                    </Stack>
                </TooltipHost>
            );
        if (saving === 'failed')
            return (
                <TooltipHost content={getErrorContent()}>
                    <Stack
                        tokens={stackTokens}
                        style={{
                            color: theme?.palette.red,
                            cursor: 'pointer',
                            position: 'relative',
                        }}
                        horizontal
                        verticalAlign="center"
                    >
                        <div>
                            <Icon
                                iconName="CircleRing"
                                style={{ position: 'absolute', top: -1, left: -4, fontSize: 22 }}
                            ></Icon>
                            <Icon style={{ fontSize: 14 }} iconName="Cancel"></Icon>
                        </div>
                    </Stack>
                </TooltipHost>
            );
        return null;
    }
    return element;
}

export default styled<Props, any, any>(SavedIndicator, {});
