import { useState, KeyboardEvent, useEffect } from 'react';
import ActionButton from 'components/ActionButton';
import { TextField, ITextFieldProps, Stack, TooltipHost } from '@fluentui/react';

export interface ISearchFieldProps extends ITextFieldProps {
    onSearch?: (value: string) => void;
    onChangeSearch?: (value: string) => void;
    onClear?: () => void;
    canClear?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
}

export default function SearchField(props: ISearchFieldProps): JSX.Element {
    const [focus, setFocus] = useState<boolean>(false);
    const [value, setValue] = useState<string>();

    function _onEnterSearch() {
        if (focus && props.onSearch && value) props.onSearch(value);
    }

    function _onSearch() {
        if (props.onSearch && value) props.onSearch(value);
    }

    function _onClear() {
        setValue('');
        if (props.onClear) props.onClear();
    }

    function _onChange(ev: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>, _value: string | undefined) {
        setValue(_value);
        if (props.onChange) {
            props.onChange(ev, _value);
        }
        if (props.onChangeSearch && _value) {
            props.onChangeSearch(_value);
        }
    }

    function onKeyUp(e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (e.key === 'Enter') _onEnterSearch();
    }

    useEffect(() => {
        if (!value) {
            _onClear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <TextField
            {...props}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onKeyUp={onKeyUp}
            value={value}
            onChange={_onChange}
            onRenderSuffix={() => (
                <Stack horizontal>
                    {props.canClear && value && (
                        <TooltipHost content="Clear Search">
                            <ActionButton
                                type="IconButton"
                                iconProps={{ iconName: 'Cancel' }}
                                onClick={_onClear}
                                style={{
                                    maxHeight: 30,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        </TooltipHost>
                    )}
                    <ActionButton
                        type="IconButton"
                        iconProps={{ iconName: 'Search' }}
                        onClick={_onSearch}
                        disabled={!value}
                        style={{
                            maxHeight: 30,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                </Stack>
            )}
        />
    );
}
