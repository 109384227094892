import {
    DefaultButton,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Stack,
} from '@fluentui/react';
import IBatchHardClosed, { IBatchHardClosedHistory } from 'api/models/hardClosedHistory.model';
import { Section, SortableDetailsList } from 'components';
import SystemUserAccountDisplayName from 'components/SystemAccountUserDisplayName';
import useHub, { hubConnection, SignalRMessage, useSignalR } from 'hooks/signalR/useSignalr';
import usePermissions, { Permission } from 'hooks/usePermissions';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classicDateOnly } from 'utils/dateOnly';
import { selectedTenantId } from '../state/tenants.selectors';
import {
    cleanupTenantHardCloseHistory,
    cleanupTenantHardCloseMessageBar,
    setHardCloseHistoryData,
    setHardCloseModalOpen,
} from '../state/tenants.slice';
import CloseDaySelector from './CloseDaySelector';
import FinancialAutoCloseToggle from './FinancialAutoCloseToggle';
import HardCloseModal from './HardCloseModal';
import { getTenantHardCloseHistory } from './state/financialClosing.actions';
import {
    financialClosingHardCloseHistory,
    hardCloseBatchesMessageBar,
    selectSelectedTenantHardCloseHistoryLoading,
} from './state/financialClosing.selectors';

function FinancialClosing() {
    const dispatch = useDispatch();

    useHub(hubConnection);
    const { registerSignalRMessage } = useSignalR();

    const tenantId = useSelector(selectedTenantId);

    const [hasFinancialClosing, isGlobalAdmin] = usePermissions([Permission.TenantFinancialClosing]);
    const readOnly = !hasFinancialClosing && !isGlobalAdmin;

    const handleHardCloseBatchesMessage = useCallback(
        (data: unknown) => {
            if (data) dispatch(setHardCloseHistoryData(data as IBatchHardClosed | undefined));
        },
        [dispatch],
    );

    useEffect(() => {
        if (tenantId) {
            dispatch(getTenantHardCloseHistory({ tenantId }));
            registerSignalRMessage(SignalRMessage.HardCloseHistory, handleHardCloseBatchesMessage);
        }

        return () => {
            dispatch(cleanupTenantHardCloseHistory());
        };
    }, [tenantId, dispatch]);

    function hardCloseBatches() {
        dispatch(setHardCloseModalOpen(true));
    }

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <Section
                heading="Financial Closing"
                headingRightContent={
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center">
                        <FinancialAutoCloseToggle disabled={readOnly} />
                        <CloseDaySelector disabled={readOnly} />
                        <DefaultButton disabled={readOnly} iconProps={{ iconName: 'Lock' }} onClick={hardCloseBatches}>
                            Hard-Close Batches
                        </DefaultButton>
                    </Stack>
                }
            />
            <Stack grow tokens={{ childrenGap: 10 }}>
                <HardCloseBatchesMessageBar />
                <HardCloseBatchesHistoryList />
            </Stack>
        </Stack>
    );
}

function HardCloseBatchesMessageBar() {
    const dispatch = useDispatch();
    const { messageBarType, messageBarText } = useSelector(hardCloseBatchesMessageBar);

    function onDismiss() {
        dispatch(cleanupTenantHardCloseMessageBar());
    }

    if (!messageBarText) return null;

    return (
        <MessageBar onDismiss={onDismiss} messageBarType={messageBarType}>
            {messageBarText}
        </MessageBar>
    );
}

function HardCloseBatchesHistoryList() {
    const historyItems = useSelector(financialClosingHardCloseHistory);

    const loading = useSelector(selectSelectedTenantHardCloseHistoryLoading);

    const loadingCompleted = loading === LoadingStatus.Completed;
    const loadingPending = loading === LoadingStatus.Pending;
    const loadingFailed = loading === LoadingStatus.Failed;

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <HardCloseModal />
                {loadingCompleted && !historyItems.length ? (
                    <MessageBar>No history of hard closes.</MessageBar>
                ) : loadingFailed ? (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Something went wrong while fetching hard close history.
                    </MessageBar>
                ) : (
                    <SortableDetailsList<IBatchHardClosedHistory>
                        enableShimmer={loadingPending}
                        shimmerLines={5}
                        selectionMode={SelectionMode.none}
                        initialSortDirection={['desc']}
                        sortColumns={['hardClosedThroughDate']}
                        stickyHeader
                        sortOnMount
                        columns={[
                            {
                                key: 'hardClosedThroughDate',
                                name: 'Hard-Closed Through Date',
                                fieldName: 'hardClosedThroughDate',
                                minWidth: 150,
                                maxWidth: 190,
                                onRender: (item) => {
                                    return item?.hardClosedThroughDate ? classicDateOnly(item.hardClosedThroughDate) : 'N/A';
                                },
                            },
                            {
                                key: 'savedOnDatec',
                                name: 'Saved on Date',
                                fieldName: 'closedOn',
                                minWidth: 150,
                                maxWidth: 175,
                                onRender: (item) => {
                                    return item?.closedOn ? classicDateOnly(item.closedOn) : 'N/A';
                                },
                            },
                            {
                                key: 'user',
                                name: 'User',
                                minWidth: 150,
                                onRender: (item) => {
                                    return <SystemUserAccountDisplayName userId={item?.closedBy} />;
                                },
                            },
                        ]}
                        items={historyItems}
                    />
                )}
            </ScrollablePane>
        </Stack>
    );
}

export default FinancialClosing;
