import { DirectionalHint, Icon, Stack, Text, TooltipHost, useTheme } from '@fluentui/react';

type Props = {
    changesetsActive: boolean;
    tooltipText?: string;
};

export default function EventingChangesetsActiveIndicator({ changesetsActive, tooltipText }: Props) {
    const { palette } = useTheme();
    const activeText = changesetsActive ? 'Active' : 'Inactive';
    return (
        <TooltipHost
            directionalHint={DirectionalHint.bottomCenter}
            content={tooltipText ? `${tooltipText} is ${activeText.toLowerCase()}.` : undefined}
        >
            <Stack styles={{ root: { cursor: 'pointer' } }} horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
                {changesetsActive ? (
                    <Icon styles={{ root: { color: palette.green } }} iconName="SkypeCircleCheck" />
                ) : (
                    <Icon styles={{ root: { color: palette.redDark } }} iconName="SkypeCircleMinus" />
                )}
                <Stack.Item>
                    <Text styles={{ root: { fontWeight: 'bold', marginBottom: 0 } }}>{activeText}</Text>
                </Stack.Item>
            </Stack>
        </TooltipHost>
    );
}
