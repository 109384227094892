import { Stack, IconButton, useTheme, SearchBox, ScrollablePane, ScrollbarVisibility, TooltipHost } from '@fluentui/react';
import 'components/ActivityBar/ActivityBar.scss';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import useTenant from 'hooks/state/useTenant';
import ITenant from './state/tenants.model';
import { isTenantBarExpanded, showIsLiveCustomer, showIsUpcomingCustomer } from './state/tenants.selectors';
import { cleanupShowCustomer, toggleExpanded } from './state/tenants.slice';
import TenantsBarLink from './TenantsBarLinks';
import { orderBy } from 'lodash';
import { useFuseSearch } from 'hooks/useFuseSearch';
import TenantTiles from './TenantTiles';

interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;
    notifications?: number;
    subMenuProps?: IActivity[];
    isDeleted?: boolean;
}

function TenantsBar() {
    const isExpanded = useSelector(isTenantBarExpanded);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { tenantAsList } = useTenant();
    const showUpcomingCustomer = useSelector(showIsUpcomingCustomer);
    const showLiveCustomer = useSelector(showIsLiveCustomer);

    const { results, onSearch, search, onSearchChange } = useFuseSearch<ITenant>({
        fuseOptions: { keys: ['displayName', 'id', 'name', 'liveCustomer'], threshold: 0.1 },
        list: tenantAsList,
    });

    const disableClearFilters = !search && !showUpcomingCustomer && !showLiveCustomer;

    const _clearFilters = () => {
        onSearchChange(undefined);
        dispatch(cleanupShowCustomer());
    };

    const activities: IActivity[] = orderBy(
        results.length
            ? results.map((tenant: ITenant) => {
                  return {
                      keyString: tenant.id,
                      path: `/tenants/${tenant.id}`,
                      tooltip: tenant.displayName ? `${tenant.displayName} (${tenant.id}) ` : 'N/A',
                      exact: false,
                      isDeleted: tenant.isDeleted,
                  };
              })
            : tenantAsList.map((tenant: ITenant) => {
                  return {
                      keyString: tenant.id,
                      path: `/tenants/${tenant.id}`,
                      tooltip: tenant.displayName ? `${tenant.displayName} (${tenant.id}) ` : 'N/A',
                      exact: false,
                      isDeleted: tenant.isDeleted,
                  };
              }),
        'tooltip',
    );

    const { neutralLighterAlt } = theme.palette;

    const activityLinks = activities.map((activity) => (
        <TenantsBarLink key={activity.keyString} expanded={isExpanded} {...activity} />
    ));

    const wrapperClassName = isExpanded ? 'activityBar activityBar--expanded' : 'activityBar';

    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
        minWidth: isExpanded ? '275px' : '70px',
    };

    const footerStyle = {
        borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}`,
    };

    const expanderIcon = {
        iconName: isExpanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };

    return (
        <Stack className={wrapperClassName} style={wrapperStyle}>
            <Stack style={{ flex: 1, ...footerStyle }}>
                {isExpanded && (
                    <>
                        <Stack style={{ padding: 10, borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}` }}>
                            <TenantTiles />
                        </Stack>

                        <Stack
                            horizontal
                            tokens={{ childrenGap: 5 }}
                            style={{ padding: 10, borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}` }}
                        >
                            <Stack.Item grow>
                                <SearchBox
                                    placeholder="Search by name or tenant id"
                                    value={search}
                                    onSearch={onSearch}
                                    onChange={onSearchChange}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <TooltipHost content="Clear Filters">
                                    <IconButton
                                        disabled={disableClearFilters}
                                        iconProps={{ iconName: 'Cancel' }}
                                        onClick={_clearFilters}
                                    ></IconButton>
                                </TooltipHost>
                            </Stack.Item>
                        </Stack>
                    </>
                )}
                <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{activityLinks}</ScrollablePane>
                </Stack>
            </Stack>

            <div className={'activityBar__footer'}>
                <IconButton onClick={() => dispatch(toggleExpanded(!isExpanded))} iconProps={expanderIcon} />
            </div>
        </Stack>
    );
}

export default TenantsBar;
