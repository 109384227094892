import './Loading.scss';
import { Spinner, SpinnerSize, useTheme } from '@fluentui/react';
import TText from '../TText';

type Props = {
    spinnerText: string;
};

export function Loading(props: Props): JSX.Element {
    const theme = useTheme();

    const { themePrimary, themeTertiary, neutralLighterAlt, neutralLight } = theme.palette;
    return (
        <div
            className="loading-wrapper"
            style={{
                background: `linear-gradient(43deg, ${themeTertiary} 0%, ${themePrimary} 50%`,
            }}
        >
            <div className="loading-text bg-light" style={{ color: neutralLighterAlt }}>
                <TText variant="xxLargePlus" color="neutralLight">
                    <strong style={{ color: neutralLight }}>ELWOOD</strong> | PLATFORM MANAGEMENT
                </TText>
                <br />
                <br />
                <br />
                <Spinner
                    size={SpinnerSize.large}
                    styles={{ label: { color: neutralLight } }}
                    label={props.spinnerText}
                />
            </div>
        </div>
    );
}

export default Loading;
