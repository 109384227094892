import {
    IColumn,
    IconButton,
    IDetailsHeaderProps,
    IRenderFunction,
    Link,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    Spinner,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import { IDiagnosis } from 'api/models/diagnosis.model';
import { Section, SortableDetailsList } from 'components';
import useDiagnoses from 'hooks/state/useDiagnoses';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect } from 'react';

import DiagnosisPanel from './DiagnosisPanel';

import { classicDateOnly } from 'utils/dateOnly';

import { useFuseSearch } from 'hooks/useFuseSearch';

export default function Diagnoses(): JSX.Element {
    const {
        toggleShowDiagnosisHistory,
        getDiagnoses,
        diagnosisAsList,
        loading,
        loadingDiagnosis,
        getDiagnosisById,
        addNewDiagnosis,
        showDiagnosisHistory,
        setDiagnosisPropandSave,
    } = useDiagnoses();

    useEffect(() => {
        getDiagnoses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { results, onSearch, search, onSearchChange } = useFuseSearch<IDiagnosis>({
        fuseOptions: { keys: ['code', 'description', 'snomedCode'], threshold: 0.1, ignoreLocation: true },
        list: diagnosisAsList,
    });

    const onProcedureClick = (item: IDiagnosis) => {
        if (loadingDiagnosis !== LoadingStatus.Pending) {
            getDiagnosisById(item.id);
        }
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (headerProps, defaultRender) => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header}>
                <div>{defaultRender && defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    const columns: IColumn[] = [
        {
            key: 'code',
            name: 'Code',
            minWidth: 75,
            maxWidth: 75,

            getValueKey: (item: IDiagnosis) => (item.code ? item.code?.toString() : ''),
            onRender: (item: IDiagnosis) => <span>{item.isDeleted ? <i>{item.code}</i> : item.code}</span>,
        },
        {
            key: 'description',
            name: 'Description',
            minWidth: 75,
            fieldName: 'description',
            onRender: (item: IDiagnosis) => (
                <Link title={item.description} onClick={() => onProcedureClick(item)}>
                    {item.isDeleted ? <i>{item.description}</i> : item.description}
                </Link>
            ),
        },
        {
            key: 'snomedCode',
            name: 'SNOMED Code',
            minWidth: 130,
            fieldName: 'snomedCode',

            onRender: (item: IDiagnosis) => <span>{item.isDeleted ? <i>{item.snomedCode}</i> : item.snomedCode}</span>,
        },
        {
            key: 'effectiveDate',
            name: 'Effective Date',
            minWidth: 130,
            maxWidth: 230,
            fieldName: 'effectiveDate',
            onRender: (item: IDiagnosis) => <span>{item.effectiveDate ? classicDateOnly(item.effectiveDate) : ''}</span>,
        },
        {
            key: 'endDate',
            name: 'Expiration  Date',
            minWidth: 130,
            maxWidth: 230,
            fieldName: 'endDate',
            onRender: (item: IDiagnosis) => <span>{item.endDate ? classicDateOnly(item.endDate) : ''}</span>,
        },

        {
            key: 'isDeleted',
            name: 'Deactivated',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'isDeleted',
            onRender: (item: IDiagnosis) => (
                <Stack grow horizontalAlign="center">
                    <TooltipHost content="Toggle procedure deactivated.">
                        <Toggle
                            styles={{ root: { margin: 0 } }}
                            checked={item?.isDeleted}
                            onChange={() => setDiagnosisPropandSave(item.id, 'isDeleted', !item?.isDeleted)}
                        />
                    </TooltipHost>
                </Stack>
            ),
        },
    ];

    return (
        <Stack grow>
            <DiagnosisPanel />
            <Section
                heading={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Text variant="large">Diagnoses ({search ? results.length : diagnosisAsList.length})</Text>
                        <TooltipHost content="Add new diagnoses">
                            <IconButton
                                onClick={addNewDiagnosis}
                                styles={{ root: { maxHeight: 25, maxWidth: 25 } }}
                                iconProps={{ iconName: 'Add' }}
                            />
                        </TooltipHost>
                    </Stack>
                }
                style={{ marginBottom: 0 }}
                styleContent={{ display: 'flex', flex: 1 }}
                headingRightContent={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow verticalAlign="end">
                        <SearchBox
                            style={{ minWidth: 235 }}
                            placeholder="Search by description, code"
                            autoComplete="off"
                            value={search}
                            onSearch={onSearch}
                            onChange={onSearchChange}
                        />
                        <Toggle
                            label="Active Only"
                            inlineLabel
                            checked={!showDiagnosisHistory}
                            styles={{ root: { marginBottom: 3 } }}
                            onClick={toggleShowDiagnosisHistory}
                        />
                    </Stack>
                }
                grow
            >
                {loading === 'pending' ? (
                    <div style={{ width: '100%' }}>
                        <Spinner label="Loading..." />
                    </div>
                ) : loading === 'completed' && search && !results.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No Results Founds.</MessageBar>
                    </div>
                ) : loading === 'completed' && diagnosisAsList.length ? (
                    <div style={{ position: 'relative', flex: 1 }}>
                        <ScrollablePane scrollbarVisibility="auto">
                            <SortableDetailsList
                                selectionMode={SelectionMode.none}
                                columns={columns}
                                items={results.length ? results : diagnosisAsList}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                compact
                                stickyHeader
                                sortOnMount
                                sortColumns={['code']}
                                initialSortDirection={['asc']}
                            />
                        </ScrollablePane>
                    </div>
                ) : loading === 'completed' && !diagnosisAsList.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No diagnosis have been added yet.</MessageBar>
                    </div>
                ) : (
                    loading === 'failed' && (
                        <div style={{ width: '100%' }}>
                            <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>
                        </div>
                    )
                )}
            </Section>
        </Stack>
    );
}
