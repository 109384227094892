export default interface ISubscribeToChangesPayload {
    subscription: SubscriptionType;
    pamiDepartmentIds: string[];
    appointmentDepartmentIds: string[];
}

export enum SubscriptionType {
    Events = 'Events',
    Changesets = 'Changesets',
}
