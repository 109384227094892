/**
 * Checks if 2 separate date ranges overlap and returns true if they do.
 * @param a_start Start date for date range "a".
 * @param b_start Start date for date range "b".
 * @param a_end End date for date range "a".
 * @param b_end End date for date range "b".
 * @returns
 */
export const doesDateRangeOverlap = (
    a_start: Date,
    b_start: Date,
    a_end?: Date,
    b_end?: Date,
): boolean => {
    if (a_end && b_end) {
        if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
        if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
        if (b_start < a_start && a_end < b_end) return true; // a in b
    } else {
        //todo: clean up code here
        if (!a_end) {
            const definiteStart = b_start;
            const definiteEnd = b_end;
            const indefiniteStart = a_start;

            if (definiteStart >= indefiniteStart || definiteEnd! >= indefiniteStart) return true;
        } else {
            const definiteStart = a_start;
            const definiteEnd = a_end;
            const indefiniteStart = b_start;

            if (definiteStart >= indefiniteStart || definiteEnd! >= indefiniteStart) return true;
        }
    }
    return false;
};
