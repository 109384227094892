import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'hooks';

import { getUsers, editUser, setUser, toggleModal, toggleShowHistory } from 'state/slices/users.slice';

import { ActivityPage, Section, SortableDetailsList } from 'components';
import IUser from 'api/models/user.model';
import AddEditUsersModal from './components/AddEditUsersModal';
import {
    DetailsListLayoutMode,
    IColumn,
    IconButton,
    Link,
    MessageBar,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import useUsers from 'hooks/state/useUsers';

import { useFuseSearch } from 'hooks/useFuseSearch';
import { filteredUsersData, showUsersHistory } from 'state/slices/users.selectors';

import { ISystemUserAccount } from 'api/models/account.model';
import { getProducts, selectProductsData } from 'state/slices/products.slice';

const Users = () => {
    const productsList = useSelector(selectProductsData);

    const dispatch = useDispatch();

    const userList = useSelector(filteredUsersData);
    const isActive = useSelector(showUsersHistory);

    useEffect(() => {
        dispatch(getProducts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const roles = productsList?.map((prod) => prod.roles?.filter((role) => role.productId === 'platform')).flat();

    const { selectUsersData, selectUsersLoading } = useUsers();

    //local state

    useEffect(() => {
        dispatch(getUsers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const { results, onSearch, search, onSearchChange } = useFuseSearch({
        fuseOptions: {
            keys: ['identity.firstName', 'identity.lastName', 'identity.email'],
            threshold: 0.1,
            ignoreLocation: true,
        },
        list: userList,
    });

    //handlers
    const onClickAddUser = () => {
        dispatch(setUser(addUser()));
        dispatch(toggleModal());
    };

    const editUserIdentity = (user: ISystemUserAccount) => {
        dispatch(setUser(user));
        dispatch(toggleModal());
    };

    const toggleUser = (user: ISystemUserAccount) => {
        dispatch(editUser({ user }));
    };

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: 'First Name',
            fieldName: 'first',
            isResizable: true,
            minWidth: 150,
            maxWidth: 150,

            onRender: (item: ISystemUserAccount) => (
                <Link
                    onClick={() => {
                        editUserIdentity(item);
                    }}
                    style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}
                >
                    {item.identity?.firstName}
                </Link>
            ),
            getValueKey: (item: ISystemUserAccount) => item.identity?.firstName ?? '',
        },
        {
            key: 'last',
            name: 'Last Name',
            fieldName: 'last',
            isResizable: true,
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: ISystemUserAccount) => (
                <Link
                    onClick={() => {
                        if (!item.isDeleted) editUserIdentity(item);
                    }}
                    style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}
                >
                    {item.identity?.lastName}
                </Link>
            ),
            getValueKey: (item: ISystemUserAccount) => item.identity?.lastName ?? '',
        },
        {
            key: 'column3',
            name: 'Email',
            data: 'string',
            fieldName: 'email',
            isResizable: true,
            minWidth: 200,
            maxWidth: 200,

            onRender: (item: ISystemUserAccount) => (
                <div style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}>{item.identity?.email}</div>
            ),
            getValueKey: (item: ISystemUserAccount) => item.identity?.email ?? '',
        },
        {
            key: 'column4',
            name: 'Id',
            data: 'string',
            fieldName: 'id',
            isResizable: true,
            minWidth: 150,

            maxWidth: 350,
            onRender: (item: ISystemUserAccount) => (
                <div style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}>{item.identity?.id}</div>
            ),
            getValueKey: (item: ISystemUserAccount) => item.identity?.id ?? '',
        },
        {
            key: 'column5',
            name: 'Role(s)',
            fieldName: 'Permissions',
            isResizable: true,
            minWidth: 200,
            onRender: (item: ISystemUserAccount) => {
                // combine all system roles and permission into one arrray
                const systemRoles = item.identity?.systemRoles?.map((role) => {
                    const globalRole = role === 'global-admin' ? 'Global Admin' : '';
                    return globalRole;
                });

                const roleDisplayName = item.userAccount?.permissions
                    ?.map((perm) => {
                        const role = roles?.find((role) => role?.id === perm);
                        return role?.displayName ?? '';
                    })
                    .join(', ');

                return (
                    <div style={{ position: 'relative', fontStyle: item.isDeleted ? 'italic' : '' }}>
                        <TooltipHost
                            content={`${systemRoles ?? ''} ${roleDisplayName ?? ''}`}
                            calloutProps={{ gapSpace: 0 }}
                            styles={{ root: { display: 'inline-block' } }}
                        >
                            {systemRoles} {systemRoles?.length && roleDisplayName?.length ? ', ' : ''} {roleDisplayName}
                        </TooltipHost>
                    </div>
                );
            },
            getValueKey: (item: ISystemUserAccount) => {
                const systemRoles =
                    item.identity?.systemRoles?.map((role) => {
                        const globalRole = role === 'global-admin' ? 'Global Admin' : '';
                        return globalRole;
                    }) ?? '';
                const roleDisplayName = item.userAccount?.permissions
                    ?.map((perm) => {
                        const role = roles?.find((role) => role?.id === perm);
                        return role?.displayName ?? '';
                    })
                    .join(', ');

                return `${systemRoles && roleDisplayName}}`;
            },
        },
        {
            key: 'column6',
            name: '',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IUser) => (
                <IconButton
                    id={`user-editButton-${item.id}`}
                    title={`${item.isDeleted ? 'Activate' : 'Deactivate'} User`}
                    iconProps={{ iconName: item.isDeleted ? 'Undo' : 'Delete' }}
                    onClick={() => toggleUser(item)}
                />
            ),
        },
    ];

    return (
        <ActivityPage
            mainButtons={[
                {
                    type: 'DefaultButton',
                    text: 'Add User',
                    iconProps: { iconName: 'Add' },
                    styles: { root: { height: 30 } },
                    onClick: onClickAddUser,
                },
            ]}
            title="User Management"
            hasPageHeader
            hideFooter
        >
            <Stack style={{ padding: 12, flex: 1, display: 'flex' }} grow>
                <Section
                    style={{ marginBottom: 0 }}
                    styleContent={{ display: 'flex', flex: 1 }}
                    heading={`Users (${search ? results.length : userList.length})`}
                    headingRightContent={
                        <Stack tokens={{ childrenGap: 10 }} horizontal grow verticalAlign="end">
                            <SearchBox
                                style={{ minWidth: 235 }}
                                placeholder="Search by First Name, Last Name, Email"
                                autoComplete="off"
                                value={search}
                                onSearch={onSearch}
                                onChange={onSearchChange}
                            />
                            <Toggle
                                styles={{ root: { marginBottom: 0 } }}
                                inlineLabel
                                label="Active Only"
                                checked={!isActive}
                                onClick={() => {
                                    dispatch(toggleShowHistory());
                                }}
                                id="tenant-profile-showHistory"
                            />
                        </Stack>
                    }
                    grow
                >
                    <Stack grow>
                        {selectUsersLoading === 'pending' || selectUsersLoading === 'idle' ? (
                            <Spinner label="Loading Users" size={SpinnerSize.large} />
                        ) : search && !results.length ? (
                            <MessageBar>No Results Found.</MessageBar>
                        ) : !userList ? (
                            <MessageBar>No users have been added yet.</MessageBar>
                        ) : (
                            <div style={{ position: 'relative', flex: 1 }}>
                                <ScrollablePane scrollbarVisibility="auto">
                                    <SortableDetailsList
                                        layoutMode={DetailsListLayoutMode.justified}
                                        selectionMode={SelectionMode.none}
                                        stickyHeader
                                        items={search ? results : userList}
                                        columns={columns}
                                        onItemInvoked={(item?: IUser) => {
                                            if (item && !item.isDeleted) editUserIdentity(item);
                                        }}
                                        sortColumns={['column0']}
                                        initialSortDirection={['asc']}
                                        sortOnMount
                                        onActiveItemChanged={undefined}
                                        isHeaderVisible={true}
                                    />
                                </ScrollablePane>
                            </div>
                        )}
                    </Stack>
                </Section>
            </Stack>

            <AddEditUsersModal />
        </ActivityPage>
    );
};

function addUser(): ISystemUserAccount {
    return {
        isDeleted: false,
        identity: {
            email: '',
            firstName: '',
            lastName: '',
            id: '',
            systemRoles: [],
        },

        userAccount: {
            permissions: [],
        },
    };
}

export default Users;
