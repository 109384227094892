import React, { useRef } from 'react';
import {
    Persona,
    PersonaSize,
    ContextualMenu,
    DirectionalHint,
    IContextualMenuProps,
} from '@fluentui/react';

type Props = {
    photo: string;
    contextMenu: IContextualMenuProps;
};

const Avatar = (props: Props): JSX.Element => {
    const { photo, contextMenu } = props;
    const linkRef = useRef(null);

    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const _toggleShowContextMenu = () => setShowContextualMenu(!showContextualMenu);
    const _onHideContextualMenu = () => setShowContextualMenu(false);

    return (
        <div style={{ marginRight: '10px' }}>
            <div ref={linkRef} style={{ cursor: 'pointer' }}>
                <Persona
                    imageUrl={photo ? photo : ''}
                    size={PersonaSize.size32}
                    hidePersonaDetails
                    onClick={_toggleShowContextMenu}
                />
            </div>
            <ContextualMenu
                directionalHint={DirectionalHint.topCenter}
                isBeakVisible
                hidden={!showContextualMenu}
                target={linkRef.current}
                onItemClick={_onHideContextualMenu}
                onDismiss={_onHideContextualMenu}
                {...contextMenu}
            />
        </div>
    );
};

export default Avatar;
