import { Shimmer } from '@fluentui/react';
import { ISystemUserAccount } from 'api/models/account.model';
import SystemUser from 'api/models/systemUser.model';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMissingSystemUserAccountIdentities } from 'state/slices/user-identities/user-identities.actions';
import { selectUserIdentitiesData, selectUserIdentitiesState } from 'state/slices/user-identities/user-identities.selectors';
import { validate as validUUID } from 'uuid';

export type UserDisplayNameProps = { userId?: string; useSuffix?: boolean };

export function isSystemUserAccountIdentity(
    userObject: ISystemUserAccount | SystemUser | undefined,
): userObject is ISystemUserAccount {
    return !!(userObject as ISystemUserAccount)?.identity;
}

export function useSystemAccountUserAccountIdentity({ userId, useSuffix }: UserDisplayNameProps) {
    const dispatch = useDispatch();

    const userIdentitiesData = useSelector(selectUserIdentitiesData);

    useEffect(() => {
        if (userId && validUUID(userId) && !userIdentitiesData[userId]) {
            dispatch(getMissingSystemUserAccountIdentities([userId]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    if (!userId) return { userName: 'N/A', user: undefined };

    const userData = userIdentitiesData[userId];

    if (isSystemUserAccountIdentity(userData)) {
        const userIdentity = userData.identity;
        const userName = useSuffix && userData.identity?.professionalSuffix
            ? `${userData.identity.lastName} ${userData.identity.professionalSuffix}, ${userData.identity.firstName}`
            : userData.identity?.displayName ?? 'N/A';

        return {
            userName,
            user: userIdentity,
            firstName: userIdentity?.firstName,
            lastName: userIdentity?.lastName,
            professionalSuffix: userIdentity?.professionalSuffix,
        };
    } else {
        return {
            userName: userData?.displayName ?? userId,
            user: userData,
        };
    }
}

export default function SystemUserAccountDisplayName({ userId, useSuffix }: UserDisplayNameProps) {
    const { loading } = useSelector(selectUserIdentitiesState);
    const { userName, user } = useSystemAccountUserAccountIdentity({ userId, useSuffix });

    // No user yet, but it's a valid UUID assume we're still loading identities
    if (!user && validUUID(userId ?? '') && loading === LoadingStatus.Pending) {
        return <Shimmer />;
    }

    return <span title={userName}>{userName}</span>;
}
