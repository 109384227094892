import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

const initialState: AuthState = {
    initializing: false,
    initialized: false,
    idToken: null,
    accessToken: null,
};

const accountSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
});

const { reducer } = accountSlice;

export default reducer;

export const authStateSelector = (state: RootState) => state.auth;

type AuthState = {
    initializing: boolean;
    initialized: boolean;
    idToken: string | null;
    accessToken: string | null;
};
