import {
    AnyAction,
    createAsyncThunk,
    createSlice,
    Dictionary,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import { PlatformApi } from 'api';
import { IDiagnosis } from 'api/models/diagnosis.model';
import axios from 'axios';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { v4 as uuid } from 'uuid';
import ErrorTypes from 'state/errorTypes';
import { RootState } from 'state/store';
import { isEqual } from 'lodash';

const initialState: DiagnosisState = {
    diagnoses: {},
    saving: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    loadingDiagnosis: LoadingStatus.Idle,
    showHistory: false,
    isAdding: false,
};

export const getDiagnoses = createAsyncThunk<
    Dictionary<IDiagnosis>,
    undefined,
    { rejectValue: string }
>('diagnoses/getDiagnoses', async (_, { rejectWithValue }) => {
    try {
        const { data: diagnoses } = await PlatformApi.getAllDiagnoses();
        return diagnoses;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getDiagnosisById = createAsyncThunk<
    IDiagnosis,
    { diagnosisId: string },
    { rejectValue: string }
>('diagnoses/getDiagnosisById', async ({ diagnosisId }, { rejectWithValue }) => {
    try {
        const { data: Diagnosis } = await PlatformApi.getDiagnosisById(diagnosisId);
        return Diagnosis;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});
export const updateDiagnosis = createAsyncThunk<
    IDiagnosis,
    { diagnosis: IDiagnosis },
    { rejectValue: string }
>('diagnoses/updateDiagnosis', async ({ diagnosis }, { rejectWithValue }) => {
    try {
        const { data: updateDiagnosis } = await PlatformApi.updateDiagnosis(diagnosis);
        return updateDiagnosis;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});
export const updateDiagnoses = createAsyncThunk<
    IDiagnosis[],
    { diagnoses: IDiagnosis[] },
    { rejectValue: string }
>('diagnoses/updateDiagnoses', async ({ diagnoses }, { rejectWithValue }) => {
    try {
        const requests = diagnoses.map((diagnosis) => PlatformApi.updateDiagnosis(diagnosis));
        const responses = await axios.all(requests);
        return responses.map((r) => r.data);
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const addDiagnosis = createAsyncThunk<
    IDiagnosis,
    { diagnosis: IDiagnosis },
    { rejectValue: string }
>('diagnoses/addDiagnosis', async ({ diagnosis }, { rejectWithValue }) => {
    try {
        const { data: addDiagnosis } = await PlatformApi.createDiagnosis(diagnosis);
        return addDiagnosis;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

let timer: NodeJS.Timeout | null = null;
let diagnosisToUpdate: IDiagnosis[] = [];

export const autoUpdateDiagnoses =
    (diagnosis: IDiagnosis) =>
    (dispatch: ThunkDispatch<RootState, null, AnyAction>): void => {
        const indexOfDiagnosis = diagnosisToUpdate.findIndex((p) => p.id === diagnosis.id);
        if (indexOfDiagnosis === -1) {
            diagnosisToUpdate.push(diagnosis);
        } else {
            diagnosisToUpdate[indexOfDiagnosis] = diagnosis;
        }
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (diagnosisToUpdate.length) {
                dispatch(updateDiagnoses({ diagnoses: diagnosisToUpdate }));
                diagnosisToUpdate = [];
            }
        }, 2000);
    };

export const setDiagnosisPropandSave =
    (id: string, path: keyof IDiagnosis, value: string | number | boolean | undefined) =>
    async (
        dispatch: ThunkDispatch<RootState, null, AnyAction>,
        getState: () => RootState,
    ): Promise<void> => {
        await dispatch(updateDiagnosisProp({ id, path, value }));
        const diagnosis = getState().diagnoses.diagnoses[id];
        if (diagnosis) {
            dispatch(autoUpdateDiagnoses(diagnosis));
        }
    };

const diagnosis = createSlice({
    name: 'diagnoses',
    initialState,
    reducers: {
        cleanupSelectedDiagnosis: (state: DiagnosisState) => {
            state.selectedDiagnosis = undefined;
            state.isAdding = false;
        },
        toggleShowDiagnosisHistory: (state: DiagnosisState) => {
            state.showHistory = !state.showHistory;
        },

        updateSelectedDiagnosisProp: (
            state: DiagnosisState,
            action: PayloadAction<{ path: keyof IDiagnosis; value: any }>,
        ) => {
            const { path, value } = action.payload;
            if (state.selectedDiagnosis) (state.selectedDiagnosis as any)[path] = value;
        },
        updateDiagnosisProp: (
            state: DiagnosisState,
            action: PayloadAction<{ id: string; path: keyof IDiagnosis; value: any }>,
        ) => {
            const { path, value, id } = action.payload;
            if (state.diagnoses) (state.diagnoses[id] as any)[path] = value;
        },
        addNewDiagnosis: (state: DiagnosisState) => {
            state.selectedDiagnosis = {
                id: uuid(),
                displayName: '',
                isDeleted: false,
                code: '',
                description: '',
                snomedCode: '',
                isCritical: false,
                category: '',
                subcategory: '',
            };
            state.isAdding = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDiagnoses.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getDiagnoses.fulfilled, (state, action) => {
                state.diagnoses = action.payload;
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getDiagnoses.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getDiagnosisById.pending, (state) => {
                state.loadingDiagnosis = LoadingStatus.Pending;
            })
            .addCase(getDiagnosisById.fulfilled, (state, action) => {
                state.selectedDiagnosis = action.payload;
                state.loadingDiagnosis = LoadingStatus.Completed;
            })
            .addCase(getDiagnosisById.rejected, (state) => {
                state.loadingDiagnosis = LoadingStatus.Failed;
            })
            .addCase(addDiagnosis.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(addDiagnosis.fulfilled, (state, action) => {
                const diagnosisId = action.payload.id;
                state.diagnoses[diagnosisId] = action.payload;
                state.selectedDiagnosis = undefined;
                state.isAdding = false;
                state.saving = LoadingStatus.Completed;
            })
            .addCase(addDiagnosis.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(updateDiagnosis.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateDiagnosis.fulfilled, (state, action) => {
                const diagnosisId = action.payload.id;
                state.diagnoses[diagnosisId] = action.payload;
                state.selectedDiagnosis = undefined;
                state.isAdding = false;
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateDiagnosis.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(updateDiagnoses.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateDiagnoses.fulfilled, (state, action) => {
                const diagnoses = action.payload;
                diagnoses.forEach((diagnosis) => {
                    const diagnosisId = diagnosis.id;
                    if (!isEqual(diagnosis, state.diagnoses[diagnosisId])) {
                        if (state.diagnoses[diagnosisId]) {
                            (state.diagnoses[diagnosisId] as IDiagnosis)._etag = diagnosis._etag;
                            (state.diagnoses[diagnosisId] as IDiagnosis).modifiedOn =
                                diagnosis.modifiedOn;
                            (state.diagnoses[diagnosisId] as IDiagnosis).modifiedBy =
                                diagnosis.modifiedBy;
                        }
                    }
                });
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateDiagnoses.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            });
    },
});

export const {
    cleanupSelectedDiagnosis,
    addNewDiagnosis,
    toggleShowDiagnosisHistory,
    updateDiagnosisProp,
    updateSelectedDiagnosisProp,
} = diagnosis.actions;

export default diagnosis.reducer;

export type DiagnosisState = {
    diagnoses: Dictionary<IDiagnosis>;
    selectedDiagnosis?: IDiagnosis;
    saving: LoadingStatus;
    loading: LoadingStatus;
    loadingDiagnosis: LoadingStatus;
    isAdding: boolean;
    showHistory: boolean;
};
