import { PayloadAction } from '@reduxjs/toolkit';
import { FeatureFlagState } from './state';
import { v4 as uuid } from 'uuid';
import { IFeatureFlag } from './model';

const featureFlagReducer = {
    toggleExpanded(state: FeatureFlagState, action: PayloadAction<boolean>) {
        state.isExpanded = action.payload;
    },
    setFeatureFlagModal(state: FeatureFlagState, action: PayloadAction<boolean>) {
        state.openFeatureFlagModal = action.payload;
    },
    setFeatureFlagTenantModal(state: FeatureFlagState, action: PayloadAction<boolean>) {
        state.openFeatureFlagTenantModal = action.payload;
    },
    toggleTenantHistory(state: FeatureFlagState) {
        state.showHistoryTenant = !state.showHistoryTenant;
    },
    toggleFeatureFlagHistory(state: FeatureFlagState) {
        state.showHistoryFeatureFlag = !state.showHistoryFeatureFlag;
    },
    updateSelectedFeatureFlagProp: (
        state: FeatureFlagState,
        action: PayloadAction<{
            path: keyof IFeatureFlag;
            value: any;
        }>,
    ) => {
        const { path, value } = action.payload;
        if (state.selectedFeatureFlag) {
            (state.selectedFeatureFlag as any)[path] = value;
        }
    },

    addNewFeatureFlag(state: FeatureFlagState) {
        state.selectedFeatureFlag = {
            id: uuid(),
            displayName: '',
            description: '',
            isDeleted: false,
            isArchived: false,
            isBeta: true,
        };
        state.selectedFeatureFlagTenant = undefined;
    },
    setFeatureFlag(state: FeatureFlagState, action: PayloadAction<IFeatureFlag>) {
        state.selectedFeatureFlag = action.payload;
    },
    cleanupSelectedFeatureFlag(state: FeatureFlagState) {
        state.selectedFeatureFlag = undefined;
        state.selectedFeatureFlagTenant = undefined;
    },
};

export default featureFlagReducer;
