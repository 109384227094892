import { CSSProperties, ReactNode } from 'react';
import { Stack, StackItem, Label, useTheme } from '@fluentui/react';

type Props = {
    title: string | JSX.Element;
    headerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    headingCenterContent?: ReactNode;
    headingEndContent?: ReactNode;
    isRequired?: boolean;
};

const SubSectionHeader = (props: Props): JSX.Element => {
    const theme = useTheme();
    const headerStyles: CSSProperties = {
        padding: '5px 5px 5px 5px',
        borderBottom: '1px solid' + theme.palette.neutralQuaternaryAlt,
        display: 'flex',
        alignItems: 'center',
        zIndex: 20,
        ...props.headerStyle,
    };

    return (
        <Stack style={{ ...headerStyles }} horizontal tokens={{ childrenGap: 20 }} grow>
            <StackItem grow>
                {typeof props.title === 'string' ? (
                    <Label
                        style={{ ...props.labelStyle, padding: 0, fontSize: 18 }}
                        required={props.isRequired}
                    >
                        {props.title}
                    </Label>
                ) : (
                    <>{props.title}</>
                )}
            </StackItem>
            {props.headingCenterContent && (
                <StackItem grow={1}>
                    <Stack>{props.headingCenterContent}</Stack>
                </StackItem>
            )}
            {props.headingEndContent && (
                <StackItem>
                    <Stack
                        horizontal
                        style={{ justifyContent: 'flex-end', display: 'flex' }}
                        tokens={{ childrenGap: 10 }}
                    >
                        {props.headingEndContent}
                    </Stack>
                </StackItem>
            )}
        </Stack>
    );
};

export default SubSectionHeader;
