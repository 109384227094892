import { createSelector } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RootState } from 'state/store';
import { UserTaskTarget } from './model';

const selectUserTasksState = (state: RootState) => state.userTasks;

export const selectTenantTasks = createSelector(selectUserTasksState, (state) => state.tenantsTasks);
export const selectTenantTasksLoading = createSelector(
    selectUserTasksState,
    (state) => state.tenantTasksLoading === LoadingStatus.Pending,
);

export const selectUserTasksLoading = createSelector(
    selectUserTasksState,
    (state) => state.userTasksLoading === LoadingStatus.Pending,
);
export const selectUserTasksByTarget = createSelector(
    selectUserTasksState,
    (_: RootState, target?: UserTaskTarget) => target,
    (state, target) => {
        if (!target) return state.userTasks;
        return state.userTasks?.filter((task) => task.target === target);
    },
);

export enum TaskType {
    StartPatientDemographicLoad = 'start-patient-demographics-load',
    CompletePatientDemographicLoad = 'complete-patient-demographics-load',
    StartDeltaPatientDemographicLoad = 'start-delta-patient-demographics-load',
    CompleteDeltaPatientDemographicLoad = 'complete-delta-patient-demographics-load',
}
