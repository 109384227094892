import platformApi from 'api/platform.api';
import { IReportBatch, IWorkspace, IWorkspaceReport } from './reports.model';

const reportsApi = {
    getWorkspaces,
    getWorkSpaceReport: getWorkspaceReport,
    copyReportBatch,
};

function getWorkspaces() {
    return platformApi.client.get<WorkspacesPayload>('tenantpowerbi/workspaces');
}

function getWorkspaceReport(query = 'Template') {
    return platformApi.client.get<WorkspaceReportsPayload>(`tenantpowerbi/workspace/reports?tenantId=${query}`);
}

function copyReportBatch(model: IReportBatch) {
    return platformApi.client.post<IReportBatch>('tenantpowerbi/reportsbatch', model);
}

export default reportsApi;

export type WorkspacesPayload = {
    items: IWorkspace[];
};

export type WorkspaceReportsPayload = {
    items: IWorkspaceReport[];
};
