import {
    IdSupport,
    ChangeAuditSupport,
    DisplayNameSupport,
    CodeSupport,
    SoftDeleteSupport,
    ETagSupport,
    EffectiveDateRange,
} from './support-types';

export type ProcedureSupports = IdSupport &
    ChangeAuditSupport &
    DisplayNameSupport &
    CodeSupport &
    SoftDeleteSupport &
    ETagSupport &
    EffectiveDateRange;

export default interface IProcedure extends ProcedureSupports {
    description: string;
    applicableArea?: ApplicableArea;
    conflictRules?: ConflictRuleType[];
    class?: string;
    renderRule?: ProcedureRenderRule;
    parentId?: string;
    category?: ProcedureCategory;
    fee?: number;
    diagnoses?: IProcedureDiagnosis[];
    template?: string;
}

export interface IProcedureDiagnosis {
    code: string;
    mode?: string;
}

export enum ConflictRuleType {
    anteriorOnly = 'anterior-only',
    posteriorOnly = 'posterior-only',

    molarOnly = 'molar-only',
    bicuspidOnly = 'bicuspid-only',

    onePerArea = 'one-per-area',
    onePerTooth = 'one-per-tooth',
    onePerQuad = 'one-per-quad',

    threeSurfacesOnly = 'three-surfaces-only',
    fourSurfacesOnly = 'four-surfaces-only',

    primaryToothOnly = 'primary-tooth-only',
    permanentToothOnly = 'permanent-tooth-only',
}

export enum ApplicableArea {
    Translate = 'translate',
    Rotate = 'rotate',
    Surface = 'surface',
    Tooth = 'tooth',
    Mouth = 'mouth',
    MaxillaryArch = 'maxillaryArch',
    MandibularArch = 'mandibularArch',
    Arch = 'arch',
    Quadrant = 'quadrant',
    Crown = 'crown',
    Root = 'root',
    Exam = 'exam',
}

export enum ProcedureRenderRule {
    PostCore = 'post-core',
    LabialVeneer = 'labial-veneer',
    PostRemoval = 'post-removal',
    PartialDenture = 'partial-denture',
    MaxillaryDenture = 'maxillary-denture',
    MandibularDenture = 'mandibular-denture',
    SealantPerTooth = 'sealant-per-tooth',
    StainlessSteelCrown = 'stainless-steel-crown',
    RootCanal = 'root-canal',
    Restoration = 'restoration',
    GoldFoil = 'gold-foil',
    CrownResinBasedCompositeIndirect = 'crown-resin-based-composite-indirect',
    Pontic = 'pontic',
    AbutmentSupportedCrown = 'abutment-supported-crown',
    SealantRepairPerTooth = 'sealant-repair-per-tooth',
    Extraction = 'extraction',
    FixedPartialDenturesRetainerCrown = 'fixed-partial-dentures-retainer-crown',
    ImplantSupportedCrown = 'implant-supported-crown',
    Implant = 'implant',
    ClassV = 'class-v',
}

export enum ProcedureCategory {
    All = 'All',
    Diagnostic = 'Diagnostic',
    Preventive = 'Preventive',
    Restorative = 'Restorative',
    Endodontics = 'Endodontics',
    Periodontics = 'Periodontics',
    ProsthodonticsRemovable = 'Prosthodontics, removable',
    MaxillofacialProsthetics = 'Maxillofacial Prosthetics',
    ImplantServices = 'Implant Services',
    ProsthodonticsFixed = 'Prosthodontics, fixed',
    OralMaxillofacialSurgery = 'Oral & Maxillofacial Surgery',
    Orthodontics = 'Orthodontics',
    AdjunctiveGeneralServices = 'Adjunctive General Services',
    SleepApnea = 'Sleep Apnea Services',
    System = 'System',
}
