import { DefaultButton, Stack, Toggle } from '@fluentui/react';
import { ActivityPage } from 'components';
import useTenant from 'hooks/state/useTenant';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { getProducts } from 'state/slices/products.slice';

import Tenant from './Tenant';
import './Tenants.scss';

import AddTenantModal from './AddTenantModal';
import { getSourceSystems } from './state/tenants.actions';
import TenantsBar from './TenantsBar';
import TenantHardClosedThroughDate from './HardClosedThroughDate';

export default function Tenants() {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const {
        getTenants,

        selectTenant,
        addNewTenant,
        showTeneantDataHistory,
        setTenantPanelIsOpen,
        toggleShowTenantsHistory,
        cleanupSelectedTenants,
    } = useTenant();

    useEffect(() => {
        getTenants();
        dispatch(getProducts());
        dispatch(getSourceSystems());

        return () => {
            cleanupSelectedTenants();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ActivityPage
            title={`Tenant Management ${selectTenant ? `/ ${selectTenant?.displayName} (${selectTenant.id})` : ''}`}
            hasPageHeader
            hideFooter
            className="tenants__right-column"
            rightContent={
                <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                    <TenantHardClosedThroughDate />
                    <RenderTenantButtons />
                </Stack>
            }
        >
            <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
                <TenantsBar />
                <Stack styles={{ root: { padding: 10, paddingTop: 0, display: 'flex' } }} grow>
                    <Stack grow>
                        <Switch>
                            <Route path={`/tenants/:tenantId`} component={Tenant} />
                        </Switch>
                    </Stack>
                </Stack>
            </Stack>
            <AddTenantModal />
        </ActivityPage>
    );

    function RenderTenantButtons(): JSX.Element | null {
        const onAddNewTenant = () => {
            addNewTenant();
            setTenantPanelIsOpen(true);
            push(`/tenants`);
        };
        return (
            <Stack horizontal tokens={{ childrenGap: 10 }} grow={1} horizontalAlign="end">
                <Toggle
                    styles={{ root: { marginBottom: 0 } }}
                    inlineLabel
                    label="Active Only"
                    onClick={toggleShowTenantsHistory}
                    checked={showTeneantDataHistory}
                    id="tenant-profile-showHistory"
                />
                <DefaultButton iconProps={{ iconName: 'Add' }} primary text="Add Tenant" onClick={onAddNewTenant} />
            </Stack>
        );
    }
}
