import React, { PropsWithChildren } from 'react';
import './Blade.scss';
import {
    ScrollablePane,
    ScrollbarVisibility,
    styled,
    ITheme,
    Stack,
    TooltipHost,
    DirectionalHint,
} from '@fluentui/react';
import { TText } from 'components';

interface Props {
    open?: boolean;
    title?: string;
    onOpen?: (isOpen: boolean) => void;
    closedBody?: JSX.Element;
    openButton?: JSX.Element;
    closeButton?: JSX.Element;
    theme?: ITheme;
}

function Blade({
    open,
    title,
    onOpen,
    closedBody,
    openButton,
    closeButton,
    theme,
    children,
}: PropsWithChildren<Props>) {
    const toggleOpen = () => {
        if (onOpen) onOpen(!open);
    };

    const renderToggleButton = () => {
        if (open) {
            return openButton ? openButton : '<';
        } else {
            return closeButton ? closeButton : '>';
        }
    };

    const bladeClasses = open ? 'blade blade--expanded' : 'blade';

    return (
        <Stack
            className={bladeClasses}
            style={{
                background: theme?.palette.neutralLighterAlt,
                borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
                height: '100%',
                display: 'flex',
            }}
            grow
        >
            {open ? (
                <>
                    <Stack
                        className="blade_expandButton"
                        style={{
                            backgroundColor: theme?.palette.neutralLighterAlt,
                            borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}`,
                        }}
                        horizontal
                    >
                        <TText variant="mediumPlus">{title}</TText>
                        <TooltipHost
                            directionalHint={DirectionalHint.rightCenter}
                            content={`Collapse ${title?.toLocaleLowerCase()}`}
                        >
                            <div onClick={toggleOpen}>{renderToggleButton()}</div>
                        </TooltipHost>
                    </Stack>
                    <Stack grow style={{ position: 'relative' }}>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            {children}
                        </ScrollablePane>
                    </Stack>
                </>
            ) : (
                <>
                    {closedBody ? (
                        <Stack grow>
                            <TooltipHost
                                directionalHint={DirectionalHint.rightCenter}
                                content={`Expand ${title?.toLocaleLowerCase()}`}
                            >
                                <div
                                    style={{
                                        backgroundColor: theme?.palette.neutralLighterAlt,
                                        borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}`,
                                        justifyContent: 'center',
                                    }}
                                    className="blade_expandButton"
                                    onClick={toggleOpen}
                                >
                                    {renderToggleButton()}
                                </div>
                            </TooltipHost>
                            <div style={{ overflowY: 'auto' }}>{closedBody}</div>
                        </Stack>
                    ) : null}
                </>
            )}
        </Stack>
    );
}

export default styled<any, any, any>(Blade, {});
