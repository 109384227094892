import { MessageBar, MessageBarType, Stack } from '@fluentui/react';

import { useSelector, useValidation } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTenantData, getTenantConfigView, updatedTenantSetups } from 'views/pages/Tenants/state/tenants.actions';
import TenantPivot from './TenantPivot';
import TenantRoutes from './TenantRoutes';
import { selectedTenantValidationError } from './state/tenants.selectors';
import { setEditTenantError } from './state/tenants.slice';
import tenantValidationsConfig from './tenant-validation-config';

function Tenant() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<{ tenantId: string }>();
    const { selectTenant, editTenant } = useTenant();

    const validationError = useSelector(selectedTenantValidationError);

    useEffect(() => {
        if (tenantId) {
            dispatch(fetchTenantData(tenantId));
            dispatch(getTenantConfigView({ tenantId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    function onSave() {
        if (selectTenant) {
            editTenant(selectTenant);
            if (selectTenant.clientId && selectTenant.clientId) {
                dispatch(
                    updatedTenantSetups({
                        model: {
                            clientId: selectTenant.clientId,
                            clientSecret: selectTenant.clientSecret,
                            tenantId: selectTenant?.id,
                            tenantName: selectTenant?.displayName,
                        },
                    }),
                );
            }
        }
    }

    const [errors, submit, cleanupErrors] = useValidation(tenantValidationsConfig(selectTenant), onSave);

    useEffect(() => {
        dispatch(setEditTenantError(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        return () => {
            cleanupErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTenant]);

    if (!selectTenant) return null;

    return (
        <Stack style={{ display: 'flex', flex: 1 }} grow>
            <TenantPivot submit={submit} />
            <TenantRoutes />
            {validationError.length ? (
                <Stack>
                    <MessageBar messageBarType={MessageBarType.error}>
                        Error(s) on the Tenant About Tab or Billing Tab. Unable to save.
                    </MessageBar>
                </Stack>
            ) : null}
        </Stack>
    );
}

export default Tenant;
