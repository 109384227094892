import {
    Callout,
    IconButton,
    Link,
    MessageBar,
    MessageBarType,
    Position,
    Shimmer,
    SpinButton,
    Stack,
    Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedTenantId } from '../state/tenants.selectors';
import { updateHardCloseBatchProperty } from './state/financialClosing.actions';
import {
    financialClosingAutoClose,
    financialClosingDayAfterMonthEnd,
    selectSelectedTenantHardCloseHistorySaving,
    selectSelectedTenantHardCloseLoading,
} from './state/financialClosing.selectors';

const MAX_DAYS = 14;

export default function CloseDaySelector({ disabled }: { disabled?: boolean }) {
    const dispatch = useDispatch();

    const buttonId = useId('close-day-button');

    const tenantId = useSelector(selectedTenantId);
    const daysAfterMonthEnd = useSelector(financialClosingDayAfterMonthEnd);
    const autoClose = useSelector(financialClosingAutoClose);

    const saving = useSelector(selectSelectedTenantHardCloseHistorySaving);
    const savePending = saving === LoadingStatus.Pending;

    const loading = useSelector(selectSelectedTenantHardCloseLoading);
    const loadingPending = loading === LoadingStatus.Pending;
    const loadingFailed = loading === LoadingStatus.Failed;

    const [showCallout, setShowCallout] = useState<boolean>(false);
    const [days, setDays] = useState<number>(0);

    useEffect(() => {
        if (showCallout) setDays(Number(daysAfterMonthEnd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCallout]);

    if (!autoClose) return null;

    function onDismiss() {
        setShowCallout(false);
    }

    function onToggleCallout() {
        setShowCallout(!showCallout);
    }

    function onChange(_?: React.SyntheticEvent, value?: string | undefined) {
        let numericValue = value ? parseInt(value) : 0;
        if (numericValue > MAX_DAYS) numericValue = MAX_DAYS;
        if (numericValue <= 0) numericValue = 1;

        if (_?.type === 'keydown') {
            onSave(numericValue.toString());
        } else {
            setDays(numericValue);
        }
    }

    function onSave(days: string) {
        if (tenantId) {
            dispatch(updateHardCloseBatchProperty({ tenantId, prop: 'hardCloseDayAfterMonthEnd', value: days }));
            setShowCallout(false);
        }
    }

    return (
        <>
            {showCallout && (
                <Callout role="dialog" onDismiss={onDismiss} target={`#${buttonId}`} isBeakVisible={false} setInitialFocus>
                    <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="end" styles={{ root: { padding: 5 } }}>
                        <SpinButton
                            labelPosition={Position.top}
                            label="Days after month-end"
                            value={days.toString()}
                            onChange={onChange}
                            prefix="Days"
                            step={1}
                            onKeyDownCapture={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.currentTarget.blur();
                                    //onSave();
                                }
                            }}
                            title="Days after month-end"
                            incrementButtonAriaLabel="Increase days by 1"
                            decrementButtonAriaLabel="Decrease days by 1"
                        />
                        <IconButton onClick={() => onSave(days.toString())} title="Save" iconProps={{ iconName: 'CheckMark' }} />
                    </Stack>
                </Callout>
            )}
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Text styles={{ root: { marginBottom: 1 } }}>{"Tenant's Auto Close Day:"}</Text>
                {loadingPending || savePending ? (
                    <div style={{ width: 150 }}>
                        <Shimmer />
                    </div>
                ) : loadingFailed ? (
                    <MessageBar messageBarType={MessageBarType.error}>Error</MessageBar>
                ) : (
                    <Link
                        disabled={disabled}
                        onClick={onToggleCallout}
                        id={buttonId}
                        title="Edit Tenant's Auto Close Day After Month End"
                    >
                        {` ${daysAfterMonthEnd ? daysAfterMonthEnd : 0} days after month-end`}
                    </Link>
                )}
            </Stack>
        </>
    );
}
