import { Toggle } from '@fluentui/react';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useDispatch, useSelector } from 'react-redux';
import { selectedTenantId } from '../state/tenants.selectors';
import { updateHardCloseBatchProperty } from './state/financialClosing.actions';
import { financialClosingAutoClose, selectSelectedTenantHardCloseHistorySaving } from './state/financialClosing.selectors';

export default function FinancialAutoCloseToggle({ disabled }: { disabled: boolean }) {
    const dispatch = useDispatch();
    const autoClose = useSelector(financialClosingAutoClose);
    const tenantId = useSelector(selectedTenantId);

    const saving = useSelector(selectSelectedTenantHardCloseHistorySaving);
    const savePending = saving === LoadingStatus.Pending;

    const onSave = (_: React.MouseEvent<HTMLElement, MouseEvent>, checked: boolean | undefined) => {
        if (tenantId) dispatch(updateHardCloseBatchProperty({ tenantId, prop: 'autoHardClose', value: !!checked }));
    };

    return (
        <Toggle
            disabled={savePending || disabled}
            onChange={onSave}
            styles={{ root: { marginBottom: 0, marginRight: 20 } }}
            checked={autoClose}
            label="Auto Close"
            inlineLabel
        />
    );
}
