import React, { Component } from 'react';
import './NoMatch.scss';
import { History } from 'history';

interface Props {
    history: History;
}

export class NoMatch extends Component<Props> {
    componentDidMount() {
        document.title = `Invalid URL - ELWOOD | PLATFORM MANAGEMENT`;
    }

    render() {
        return (
            <div className="no-match-wrapper">
                <div className="no-match-container bg-light">
                    <div className="no-match_upper bg-primary">Unknown URL</div>
                    <div className="no-match_mid">
                        Please provide a valid URL.
                        {/* <hr /> */}
                    </div>
                    <div className="no-match_lower">
                        404 <span className="badge badge-pill badge-dark">{}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default NoMatch;
