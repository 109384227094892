import IAccount from 'api/models/account.model';
import store from 'store';

export default class ApplicationCache {
    private static LOCAL_STORE = {
        accountCacheAge: 'epm.user.accountCacheAge',
        account: 'epm.user.account',
        theme: 'epm.ui.theme',
        taxonomyData: 'epm.taxonomies.data',
        activityBarExpanded: 'epm.ui.activityBarExpanded',
        organizationBarExpanded: 'epm.ui.organizationBarExpanded',
        providerBarExpanded: 'epm.ui.providerBarExpanded',
    };

    public static get getUiTheme() {
        return store.get(ApplicationCache.LOCAL_STORE.theme) === 'dark'
            ? store.get(ApplicationCache.LOCAL_STORE.theme)
            : 'light';
    }

    public static get getActivityBarExpanded() {
        return store.get(ApplicationCache.LOCAL_STORE.activityBarExpanded) === true;
    }

    public static get getOrganizationBarExpanded() {
        return store.get(ApplicationCache.LOCAL_STORE.organizationBarExpanded) === true;
    }

    public static get getProviderBarExpanded() {
        return store.get(ApplicationCache.LOCAL_STORE.providerBarExpanded) === true;
    }

    public static get getUserAccount() {
        return store.get(ApplicationCache.LOCAL_STORE.account) as IAccount | undefined;
    }

    public static get getAccountCacheAgeData() {
        return new Date(store.get(ApplicationCache.LOCAL_STORE.accountCacheAge));
    }

    public static setStoreItem(storeItem: keyof typeof ApplicationCache.LOCAL_STORE, value: any) {
        store.set(ApplicationCache.LOCAL_STORE[storeItem], value);
    }
}
