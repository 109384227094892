import { PayloadAction } from '@reduxjs/toolkit';
import ITenantProduct from 'api/models/tenantProduct.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import ITenant, {
    IMailingAddress,
    ITenantBillingInformation,
    ITenantConfigSetups,
    ITenantService,
    serviceNameLookup,
    Services,
} from './tenants.model';

import { TenantsState } from './tenants.state';

const tenantReducers = {
    cleanupSelectedTenants: (state: TenantsState) => {
        state.selectTenant = undefined;

        state.isAdding = false;
    },
    cleanupSelectedProductTenants: (state: TenantsState) => {
        state.selectedTenantProduct = undefined;
        state.isAdding = false;
    },

    toggleShowTenantsHistory: (state: TenantsState) => {
        state.showHistory = !state.showHistory;
    },
    toggleShowLiveCustomer: (state: TenantsState) => {
        state.isLiveCustomer = !state.isLiveCustomer;
        state.isUpcomingCustomer = false;
    },
    toggleShowUpcomingCustomer: (state: TenantsState) => {
        state.isUpcomingCustomer = !state.isUpcomingCustomer;
        state.isLiveCustomer = false;
    },
    cleanupShowCustomer: (state: TenantsState) => {
        state.isLiveCustomer = false;
        state.isUpcomingCustomer = false;
    },
    updateTenantSettings: (
        state: TenantsState,
        action: PayloadAction<{ setting: 'dashboard'; path: string; value: string | number | boolean | undefined }>,
    ) => {
        const { path, setting, value } = action.payload;
        if (state.selectTenant && !state.selectTenant.settings) state.selectTenant.settings = {};

        if (state.selectTenant?.settings) {
            if (!state.selectTenant.settings[setting]) {
                state.selectTenant.settings[setting] = {};
            }

            state.selectTenant.settings[setting][path] = value;
        }
    },
    updateSelectedBillingTenantsProp: (
        state: TenantsState,
        action: PayloadAction<{ path: keyof ITenantBillingInformation; value: any }>,
    ) => {
        const { path, value } = action.payload;
        const tenant = state.selectTenant?.billingInformation;

        if (tenant) {
            if (tenant) {
                tenant[path] = value;
            }
        }
    },
    updateSelectedAddressTenantsProp: (
        state: TenantsState,
        action: PayloadAction<{ path: keyof IMailingAddress; value: any }>,
    ) => {
        const { path, value } = action.payload;
        const billingInfo = state.selectTenant?.billingInformation;

        if (billingInfo) {
            if (billingInfo.address) {
                billingInfo.address[path] = value;
            } else {
                billingInfo['address'] = {
                    [path]: value,
                };
            }
        }
    },
    updateSelectedStatementAddressTenantsProp: (
        state: TenantsState,
        action: PayloadAction<{ path: keyof IMailingAddress; value: any }>,
    ) => {
        const { path, value } = action.payload;
        const billingInfo = state.selectTenant?.billingInformation;

        if (billingInfo) {
            if (billingInfo.statementAddress) {
                billingInfo.statementAddress[path] = value;
            } else {
                billingInfo['statementAddress'] = {
                    [path]: value,
                };
            }
        }
    },
    updateSelectedPayToAddressTenantsProp: (
        state: TenantsState,
        action: PayloadAction<{ path: keyof IMailingAddress; value: any }>,
    ) => {
        const { path, value } = action.payload;
        const billingInfo = state.selectTenant?.billingInformation;

        if (billingInfo) {
            if (billingInfo.payToAddress) {
                billingInfo.payToAddress[path] = value;
            } else {
                billingInfo['payToAddress'] = {
                    [path]: value,
                };
            }
        }
    },
    updateSelectedTenantsProp: (state: TenantsState, action: PayloadAction<{ path: keyof ITenant; value: any }>) => {
        const { path, value } = action.payload;

        if (state.selectTenant) (state.selectTenant as any)[path] = value;
    },
    updateTenantsProp: (state: TenantsState, action: PayloadAction<{ id: string; path: keyof ITenant; value: any }>) => {
        const { path, value, id } = action.payload;
        const tenant = cloneDeep(state.tenants?.find((tenant) => tenant.id === id));
        if (tenant) {
            (tenant as any)[path] = value;
        }
        if (state.tenants && state.tenants.length) {
            const indexOfTenant = state.tenants.findIndex((tenant) => tenant.id === id);
            if (indexOfTenant !== -1) {
                (state.tenants as any)[indexOfTenant] = tenant;
            }
        }
    },
    updateSelectedTenantsProductsProp: (
        state: TenantsState,
        action: PayloadAction<{ path: keyof ITenantProduct; value: any }>,
    ) => {
        const { path, value } = action.payload;
        if (state.selectedTenantProduct) (state.selectedTenantProduct as any)[path] = value;
    },
    updateTenantsConfigProp: (state: TenantsState, action: PayloadAction<{ path: keyof ITenantConfigSetups; value: any }>) => {
        const { path, value } = action.payload;
        if (state.tenantConfigView) (state.tenantConfigView as any)[path] = value;
    },

    setTenantPanelIsOpen: (state: TenantsState, action: PayloadAction<boolean>) => {
        const IsOpen = action.payload;
        state.selectedTenantPanelOpen = IsOpen;
    },
    setServicePanelIsOpen: (state: TenantsState, action: PayloadAction<boolean>) => {
        const isOpen = action.payload;
        state.servicePanelOpen = isOpen;
    },
    setTenantProductPanelIsOpen: (state: TenantsState, action: PayloadAction<boolean>) => {
        const IsOpen = action.payload;
        state.selectedTenantProductPanelOpen = IsOpen;
    },

    addNewTenant: (state: TenantsState) => {
        state.selectTenant = {
            id: '',
            displayName: '',
            isDeleted: false,
            verifiedPracticeInfo: false,
            isNetNew: false,
            name: '',
            billingInformation: {
                name: '',
                npi: '',
                tin: '',
                phone: '',
                licenseNumber: '',

                address: {
                    city: '',
                    state: '',
                    zip: '',
                    street1: '',
                    street2: '',
                },
            },

            isUniversalKey: true,
        };
        state.isAdding = true;
    },
    addNewTenantProduct: (state: TenantsState) => {
        state.selectedTenantProduct = {
            id: uuid(),
            productId: '',
            displayName: '',
            licenseCount: 0,
            startDate: new Date().toISOString(),

            isDeleted: false,
        };
        state.isAdding = true;
    },
    setSelectedServiceDataProp: (
        state: TenantsState,
        action: PayloadAction<{ path: 'startDate' | 'endDate' | 'service'; value: unknown }>,
    ) => {
        const { path, value } = action.payload;
        if (!state.selectedTenantService) state.selectedTenantService = { id: uuid() };
        if (state.selectedTenantService) state.selectedTenantService[path] = value as any;
    },
    setSelectedServiceAndOpenPanel: (
        state: TenantsState,
        action: PayloadAction<{
            id: string;
            service: Services;
            startDate?: string;
            endDate?: string;
        }>,
    ) => {
        const service = action.payload;
        state.selectedTenantService = service;
        state.servicePanelOpen = true;
    },
    cleanupSelectedService: (state: TenantsState) => {
        state.selectedTenantService = undefined;
    },
    addProductServiceSubscription: (state: TenantsState, action: PayloadAction<Services>) => {
        if (state.selectedTenantProduct) {
            const newService: ITenantService = {
                serviceType: action.payload,
                serviceName: serviceNameLookup[action.payload],
                isDeleted: false,
            };

            if (!state.selectedTenantProduct.services) state.selectedTenantProduct.services = {};
            state.selectedTenantProduct.services[uuid()] = newService;
        }
    },
    removeProductServiceSubscription: (state: TenantsState, action: PayloadAction<string>) => {
        if (state.selectedTenantProduct) {
            if (state.selectedTenantProduct.services) {
                const services = state.selectedTenantProduct.services;
                delete services[action.payload];
                state.selectedTenantProduct.services = services;
            }
        }
    },
    setProductServiceSubscriptionProp: (
        state: TenantsState,
        action: PayloadAction<{
            id: string;
            path: keyof ITenantService;
            value: string | undefined;
        }>,
    ) => {
        const { path, id, value } = action.payload;
        if (state.selectedTenantProduct) {
            if (state.selectedTenantProduct.services && state.selectedTenantProduct.services[id]) {
                ((state.selectedTenantProduct.services[id] as ITenantService)[path] as unknown) = value;
            }
        }
    },
    setEditTenantError(state: TenantsState, action: PayloadAction<IValidationError[]>) {
        state.validationErrors = action.payload;
    },
    toggleExpanded(state: TenantsState, action: PayloadAction<boolean>) {
        state.isExpanded = action.payload;
    },
    isRerunModalOpen(state: TenantsState) {
        state.rerunModalOpen = !state.rerunModalOpen;
    },
};

export default tenantReducers;
