import IAccount from 'api/models/account.model';
import useSelector from 'hooks/useSelector';

import { intersection, map, some } from 'lodash';
import { selectIsAccountGlobalAdmin, userAccount } from 'state/slices/user/user.slice';

export enum GlobalPermissions {
    GlobalAdmin = 'global-admin',
}

export enum Permission {
    TenantAbout = 'Tenant.About',
    TenantBilling = 'Tenant.Billing',
    TenantFinancialClosing = 'Tenant.FinancialClosing',
    TenantSubscriptions = 'Tenant.Subscriptions',
    TenantIntegration = 'Tenant.Integration',
    ManageFPG = 'Manage.FPG',
    ManageFeature = 'Manage.Features',
    ManageProduct = 'Manage.Products',
    ManageReporting = 'Manage.Reporting',

    ManagePayer = 'Manage.Payers',
    ManageBenefitPlans = 'Manage.BenefitPlans',
    ManageProcedures = 'Manage.Procedures',
    ManageDiagnoses = 'Manage.Diagnoses',
    ManageUsers = 'Manage.User',
}

export const permissionsAsList = map(Permission);

/**
 * usePermissions
 *
 * A hook used to check to see if the passed permissions exist for a given user.
 *
 * @param {Permission[]} permissions Array of permissions to check
 * @param {boolean} [exact] If the list of permissions for a user has to match the permissions arg
 * @return {boolean[]} [requestedPermissions, isGlobalAdmin]
 */
function usePermissions(permissions?: Permission[], exact?: boolean): boolean[] {
    const account = useSelector(userAccount);
    const currentUser: IAccount | undefined | null = account ?? null;

    const isGlobalAdmin = useSelector(selectIsAccountGlobalAdmin);
    let hasPermissions = false;

    if (permissions?.length) {
        if (exact) {
            hasPermissions = intersection(currentUser?.userAccount?.claims).length === permissions.length;
        } else {
            hasPermissions = some(currentUser?.userAccount?.claims, (perm) => permissions.find((p) => perm === p) !== undefined);
        }
    }

    return [hasPermissions, isGlobalAdmin];
}

export default usePermissions;
