import { IDropdownOption } from '@fluentui/react';
import { IDiagnosis } from 'api/models/diagnosis.model';
import { map, orderBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const diagnosisState = (state: RootState) => state.diagnoses;
export const selectedDiagnosisCategory = (state: RootState) =>
    state.diagnoses.selectedDiagnosis?.category;

export const showDiagnosisHistory = createSelector(diagnosisState, (state) => state.showHistory);

export const diagnosisData = createSelector(diagnosisState, (state) => state.diagnoses);
export const diagnosisAsList = createSelector(
    [diagnosisData, showDiagnosisHistory],
    (diagnosesData, showHistory) => {
        const list = map(diagnosesData, (p) => p) as IDiagnosis[];
        return showHistory ? list : list.filter((p) => !p?.isDeleted);
    },
);

export const selectDiagnosesCategories = createSelector(diagnosisAsList, (diagnoses) => {
    if (diagnoses && diagnoses.length) {
        const options: IDropdownOption[] = orderBy(
            uniqBy(
                diagnoses.map((diagnosis) => ({
                    key: diagnosis.category,
                    text: diagnosis.category,
                })),
                'key',
            ),
            'key',
            'asc',
        );
        return options;
    } else {
        return [];
    }
});

export const selectDiagnosesSubcategories = createSelector(
    diagnosisAsList,
    selectedDiagnosisCategory,
    (diagnoses, selectedCategory) => {
        if (diagnoses && diagnoses.length && selectedCategory) {
            const categories = diagnoses.filter(
                (diagnosis) => diagnosis.category === selectedCategory,
            );
            if (categories.length) {
                const options: IDropdownOption[] = orderBy(
                    uniqBy(
                        categories.map((diagnosis) => ({
                            key: diagnosis.subcategory,
                            text: diagnosis.subcategory,
                        })),
                        'key',
                    ),
                    'key',
                    'asc',
                );
                return options.filter((option) => option.key !== undefined);
            } else {
                return [];
            }
        } else {
            return [];
        }
    },
);
