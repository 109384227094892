import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { ISystemUserAccount } from 'api/models/account.model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { getSystemUserAccountIdentities, getSystemUsers } from './user-identities.actions';
import { UsersState } from './user-identities.slice';

export const usersReducers = {
    cleanupUserIdentitiesData: (state: UsersState): void => {
        state.data = {};
        state.userIdsLoading = [];
    },
    setUsersLoading: (state: UsersState, action: PayloadAction<string[]>): void => {
        state.userIdsLoading = [...state.userIdsLoading, ...action.payload];
    },
};

export const usersExtraReducers = (builder: ActionReducerMapBuilder<UsersState>): ActionReducerMapBuilder<UsersState> =>
    builder
        .addCase(getSystemUserAccountIdentities.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getSystemUserAccountIdentities.fulfilled, (state, { payload }) => {
            state.loading = LoadingStatus.Completed;
            const mappedIdenties: Record<string, ISystemUserAccount> = {};
            payload.accounts.forEach((account) => {
                if (account.identity?.id) {
                    mappedIdenties[account.identity.id] = account;

                    const indexOfId = state.userIdsLoading.indexOf(account.identity.id);
                    if (indexOfId > -1) {
                        state.userIdsLoading = [
                            ...state.userIdsLoading.slice(0, indexOfId),
                            ...state.userIdsLoading.slice(indexOfId + 1),
                        ];
                    }
                }
            });
            state.data = { ...state.data, ...mappedIdenties };
        })
        .addCase(getSystemUserAccountIdentities.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(getSystemUsers.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getSystemUsers.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            const mappedIdenties: Record<string, ISystemUserAccount> = {};
            action.payload.forEach((sysUser) => {
                if (sysUser.id) {
                    mappedIdenties[sysUser.id] = sysUser;

                    const indexOfId = state.userIdsLoading.indexOf(sysUser.id);
                    if (indexOfId > -1) {
                        state.userIdsLoading = [
                            ...state.userIdsLoading.slice(0, indexOfId),
                            ...state.userIdsLoading.slice(indexOfId + 1),
                        ];
                    }
                }
            });
            state.data = { ...state.data, ...mappedIdenties };
        })
        .addCase(getSystemUsers.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        });
