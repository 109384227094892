import ITenant, { IMailingAddress, ITenantBillingInformation } from 'views/pages/Tenants/state/tenants.model';
import ITenantProduct from 'api/models/tenantProduct.model';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getTenants,
    getTenantById,
    addTenant,
    updateTenant,
    getTenantProducts,
    addTenantProduct,
    editTenantProduct,
} from 'views/pages/Tenants/state/tenants.actions';
import {
    tenantsState,
    showTeneantDataHistory,
    tenantsData,
    tenantsAsList,
    tenantBillingData,
    tenantSubscriptionProducts,
    selectedTenantProductPanelIsOpen,
} from 'views/pages/Tenants/state/tenants.selectors';
import {
    addNewTenant,
    toggleShowTenantsHistory,
    cleanupSelectedTenants,
    updateSelectedBillingTenantsProp,
    updateSelectedTenantsProp,
    updateSelectedAddressTenantsProp,
    updateSelectedStatementAddressTenantsProp,
    updateSelectedPayToAddressTenantsProp,
    setTenantPanelIsOpen,
    setTenantProductPanelIsOpen,
    updateSelectedTenantsProductsProp,
    cleanupSelectedProductTenants,
    addNewTenantProduct,
} from 'views/pages/Tenants/state/tenants.slice';

function useTenant() {
    const _dispatch = useDispatch();

    const {
        loading,
        selectTenant,
        data,
        errors,
        saving,
        tenantProducts,
        tenants,
        userHasAccess,
        loadingTenants,
        loadingTenantProducts,
        selectedTenantProduct,
        selectedTenantProductPanelOpen,

        isAdding,
    } = useSelector(tenantsState);

    const _showTeneantDataHistory = useSelector(showTeneantDataHistory);

    const _tenantsData = useSelector(tenantsData);
    const _tenantAsList = useSelector(tenantsAsList);
    const _tenantBillingData = useSelector(tenantBillingData);
    const _tenantSubscriptionProducts = useSelector(tenantSubscriptionProducts);
    const _billingInformation = useSelector(tenantBillingData);
    const _selectedTenantProductPanelIsOpen = useSelector(selectedTenantProductPanelIsOpen);

    const _productSubscriptions = useSelector(tenantSubscriptionProducts);

    const _getTenants = useCallback(() => {
        _dispatch(getTenants());
    }, [_dispatch]);

    const _getTenantById = useCallback(
        (tenantId: string) => {
            _dispatch(getTenantById({ tenantId }));
        },
        [_dispatch],
    );

    const _addTenant = useCallback(
        (tenant: ITenant) => {
            _dispatch(addTenant({ tenant }));
        },
        [_dispatch],
    );

    const _addNewTenant = useCallback(() => {
        _dispatch(addNewTenant());
    }, [_dispatch]);

    const _editTenant = useCallback(
        (tenant: ITenant) => {
            _dispatch(updateTenant({ tenant }));
        },
        [_dispatch],
    );

    const _getTenantProducts = useCallback(
        (tenantId: string) => {
            _dispatch(getTenantProducts({ tenantId }));
        },
        [_dispatch],
    );

    const _addTenantProduct = useCallback(
        (tenantId: string, product: ITenantProduct) => {
            _dispatch(addTenantProduct({ tenantId, product }));
        },
        [_dispatch],
    );
    const _editTenantProduct = useCallback(
        (tenantId: string, product: ITenantProduct) => {
            _dispatch(editTenantProduct({ tenantId, product }));
        },
        [_dispatch],
    );

    const _toggleShowTenantsHistory = useCallback(() => {
        _dispatch(toggleShowTenantsHistory());
    }, [_dispatch]);

    const _cleanupSelectedTenants = useCallback(() => {
        _dispatch(cleanupSelectedTenants());
    }, [_dispatch]);

    const _updateSelectedBillingTenantsProp = useCallback(
        (path: keyof ITenantBillingInformation, value: any) => {
            _dispatch(updateSelectedBillingTenantsProp({ path, value }));
        },
        [_dispatch],
    );

    const _updateSelectedTenantsProp = useCallback(
        (path: keyof ITenant, value: any) => {
            _dispatch(updateSelectedTenantsProp({ path, value }));
        },
        [_dispatch],
    );
    const _updateSelectedAddressTenantsProp = useCallback(
        (path: keyof IMailingAddress, value: any) => {
            _dispatch(updateSelectedAddressTenantsProp({ path, value }));
        },
        [_dispatch],
    );
    const _updateSelectedStatementAddressTenantsProp = useCallback(
        (path: keyof IMailingAddress, value: any) => {
            _dispatch(updateSelectedStatementAddressTenantsProp({ path, value }));
        },
        [_dispatch],
    );
    const _updateSelectedPayToAddressTenantsProp = useCallback(
        (path: keyof IMailingAddress, value: any) => {
            _dispatch(updateSelectedPayToAddressTenantsProp({ path, value }));
        },
        [_dispatch],
    );

    const _setTenantPanelIsOpen = useCallback(
        (isOpen: boolean) => {
            _dispatch(setTenantPanelIsOpen(isOpen));
        },
        [_dispatch],
    );

    const _setTenantProductPanelIsOpen = useCallback(
        (isOpen: boolean) => {
            _dispatch(setTenantProductPanelIsOpen(isOpen));
        },
        [_dispatch],
    );

    const _updateSelectedTenantsProductsProp = useCallback(
        (path: keyof ITenantProduct, value: any) => {
            _dispatch(updateSelectedTenantsProductsProp({ path, value }));
        },
        [_dispatch],
    );

    const _cleanupSelectedProductTenants = useCallback(() => {
        _dispatch(cleanupSelectedProductTenants());
    }, [_dispatch]);

    const _addNewTenantProduct = useCallback(() => {
        _dispatch(addNewTenantProduct());
    }, [_dispatch]);

    return {
        loading,
        isAdding,
        loadingTenants,
        selectTenant,
        data,
        errors,
        saving,
        tenantProducts,
        tenants,
        userHasAccess,
        loadingTenantProducts,
        selectedTenantProduct,
        selectedTenantProductPanelOpen,

        tenantsData: _tenantsData,
        tenantAsList: _tenantAsList,
        showTeneantDataHistory: _showTeneantDataHistory,
        tenantSubscriptionProducts: _tenantSubscriptionProducts,
        productSubscriptions: _productSubscriptions,
        tenantBillingData: _tenantBillingData,
        billingInformation: _billingInformation,
        selectedTenantProductPanelIsOpen: _selectedTenantProductPanelIsOpen,

        getTenants: _getTenants,
        getTenantById: _getTenantById,
        addTenant: _addTenant,
        addNewTenant: _addNewTenant,
        editTenant: _editTenant,
        getTenantProducts: _getTenantProducts,
        addTenantProduct: _addTenantProduct,
        editTenantProduct: _editTenantProduct,
        toggleShowTenantsHistory: _toggleShowTenantsHistory,
        cleanupSelectedTenants: _cleanupSelectedTenants,
        cleanupSelectedProductTenants: _cleanupSelectedProductTenants,

        updateSelectedTenantsProp: _updateSelectedTenantsProp,
        updateSelectedBillingTenantsProp: _updateSelectedBillingTenantsProp,
        updateSelectedAddressTenantsProp: _updateSelectedAddressTenantsProp,
        updateSelectedPayToAddressTenantsProp: _updateSelectedPayToAddressTenantsProp,
        updateSelectedTenantsProductsProp: _updateSelectedTenantsProductsProp,
        updateSelectedStatementAddressTenantsProp: _updateSelectedStatementAddressTenantsProp,

        setTenantPanelIsOpen: _setTenantPanelIsOpen,
        setTenantProductPanelIsOpen: _setTenantProductPanelIsOpen,
        addNewTenantProduct: _addNewTenantProduct,
    };
}

export default useTenant;
