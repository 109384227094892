import { Card } from 'views/component';
import EventingChangesetsActiveIndicator from './EventingChangsetsActiveIndicator';

type Props = {
    changesetActive: boolean;
    displayName: string;
    type: 'Changeset' | "Eventing"
};

export default function EventingChangesetCard({ changesetActive, displayName, type }: Props) {
    return (
        <Card title={displayName}>
            <EventingChangesetsActiveIndicator tooltipText={`${type} for ${displayName?.toLowerCase()}`} changesetsActive={changesetActive} />
        </Card>
    );
}
