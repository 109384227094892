import { useSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editProduct, getProducts, selectProductsData } from 'state/slices/products.slice';
import IProduct from 'api/models/product.model';
import { ActivityPage } from 'components';
import ProductTile from './components/ProductTile';
import AddEditProductModal, { ModalMode } from './components/AddEditProductPanel';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    Toggle,
} from '@fluentui/react';

const Products = () => {
    const dispatch = useDispatch();
    const productsList = useSelector(selectProductsData);

    //local state
    const [showDeactivated, setShowDeactivated] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
    const [requiresConfirmation, setConfirmation] = useState<boolean>(false);
    const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
    const [productModalMode, setProductModalMode] = useState<ModalMode>(ModalMode.AddRecord);

    useEffect(() => {
        dispatch(getProducts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //handlers
    const onAddProductClick = () => {
        setProductModalMode(ModalMode.AddRecord);

        const defaultProduct: IProduct = {
            id: '',
            displayName: '',
            description: '',
            roles: [],
            services: [],
            createdOn: new Date().toISOString(),
            createdBy: '',
            modifiedOn: new Date().toISOString(),
            modifiedBy: '',
            isDeleted: false,
        };

        setSelectedProduct(defaultProduct);
        setIsProductModalOpen(true);
    };

    const OnBeginProductEdit = (product: IProduct) => {
        setProductModalMode(ModalMode.EditRecord);
        setSelectedProduct(product);
        setIsProductModalOpen(true);
    };

    const OnBeginProductRemoval = () => {
        if (!selectedProduct?.isDeleted) {
            // The product is being deleted, get confirmation
            setConfirmation(true);
            return;
        }

        OnCompleteProductRemoval();
    };

    const OnCancelProductRemoval = () => {
        setConfirmation(false);
        setIsProductModalOpen(true);
    };

    const OnCompleteProductRemoval = () => {
        if (selectedProduct != null) {
            const updatedProduct: IProduct = {
                ...selectedProduct,
                isDeleted: !selectedProduct.isDeleted,
            };
            dispatch(editProduct({ product: updatedProduct }));
            setConfirmation(false);
        }
    };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Confirm Product Removal',
        closeButtonAriaLabel: 'Close',
    };

    const modalProps = React.useMemo(() => ({ isBlocking: true }), []);

    return (
        <ActivityPage
            title="Product Management"
            hasPageHeader
            hideFooter
            mainButtons={[
                {
                    type: 'DefaultButton',
                    text: 'Add Product',
                    iconProps: { iconName: 'Add' },
                    styles: { root: { height: 30 } },
                    onClick: onAddProductClick,
                },
            ]}
            centerContent={
                <Toggle
                    styles={{ root: { marginBottom: 0 } }}
                    inlineLabel
                    label="Active Only"
                    checked={!showDeactivated}
                    onClick={() => setShowDeactivated(!showDeactivated)}
                    id="tenant-showHistory"
                />
            }
        >
            <Dialog
                hidden={!requiresConfirmation}
                onDismiss={OnCancelProductRemoval}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                    You are removing <b>{selectedProduct?.displayName}</b>. Please confirm that you wish to complete this action.
                </MessageBar>

                <DialogFooter>
                    <DefaultButton onClick={OnCancelProductRemoval} text="Don't Remove" />
                    <PrimaryButton onClick={OnCompleteProductRemoval} text="Remove Product" />
                </DialogFooter>
            </Dialog>
            <Stack style={{ padding: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} grow>
                    <Stack horizontal tokens={{ childrenGap: 5 }} wrap grow>
                        {productsList && productsList.length
                            ? productsList
                                  .filter((product) => {
                                      return showDeactivated ? true : !product.isDeleted;
                                  })
                                  .map((product: IProduct) => {
                                      return (
                                          <ProductTile
                                              key={product.id}
                                              product={product}
                                              onEdit={() => OnBeginProductEdit(product)}
                                          />
                                      );
                                  })
                            : null}
                    </Stack>
                </Stack>
            </Stack>
            {isProductModalOpen && (
                <AddEditProductModal
                    isOpen={isProductModalOpen}
                    modalMode={productModalMode}
                    productData={selectedProduct as IProduct}
                    onDismiss={() => setIsProductModalOpen(false)}
                    onRemoveProduct={OnBeginProductRemoval}
                />
            )}
        </ActivityPage>
    );
};

export default Products;
