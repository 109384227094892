import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISystemUserAccount } from 'api/models/account.model';
import SystemUser from 'api/models/systemUser.model';
import platformApi from 'api/platform.api';
import axios from 'axios';
import { uniq } from 'lodash';
import { AppThunk } from 'state/store';
import { setUsersLoading } from './user-identities.slice';

export const getSystemUserAccountIdentities = createAsyncThunk<
    { accounts: ISystemUserAccount[]; unknownUserIds: string[] },
    {
        userIds: string[];
    }
>('getUserIdentities', async ({ userIds }) => {
    const requests = userIds.map((id) => platformApi.getUser(id));
    const response = await axios.all(requests);

    //List if userids with no identity/account.
    const unknownUserIds: string[] = [];
    const accounts = response.map((d, i) => {
        if (!d.data) {
            unknownUserIds.push(userIds[i]);
        }
        return d.data;
    });

    return { accounts, unknownUserIds };
});

export const getSystemUsers = createAsyncThunk<
    SystemUser[],
    {
        ids: string[];
    }
>('getSystemUsers', async ({ ids }) => {
    const requests = ids.map((id) => platformApi.getSystemUser(id));
    const response = await axios.all(requests);
    return response.map((d) => d.data);
});

export const getMissingSystemUserAccountIdentities =
    (userIds: string[]): AppThunk<void> =>
        async (dispatch, getState): Promise<void> => {
            const { data, userIdsLoading } = getState().userIdentities;
            const usersToFetch = uniq(userIds.filter((id) => id !== '')).filter((id) => !data[id] && !userIdsLoading.includes(id));

            if (usersToFetch.length) {
                dispatch(setUsersLoading(usersToFetch));
                const userAccountResponse = await dispatch(getSystemUserAccountIdentities({ userIds: usersToFetch })).unwrap();

                //Attempt to fetch unknownUserIds from the system user endpoint.
                if (userAccountResponse.unknownUserIds.length) {
                    await dispatch(getSystemUsers({ ids: userAccountResponse.unknownUserIds })).unwrap();
                }
            }
        };
