import { createAsyncThunk } from '@reduxjs/toolkit';
import ErrorTypes from 'state/errorTypes';
import tenantsApi from '../../state/tenants.api';
import ITenant, { IPracticeInfo, ITenantOnboarding } from '../../state/tenants.model';
import { AppDispatch, AppThunk, RootState } from 'state/store';
import { updateTenant } from '../../state/tenants.actions';
import { v4 as uuid } from 'uuid';
import { push } from 'connected-react-router';

export const getTenantOnboardingById = createAsyncThunk<ITenantOnboarding, { tenantId: string }, { rejectValue: string }>(
    'tenants/getTenantOnboardingById',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.getTenantOnboardingById(tenantId);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const addTenantOnboarding = createAsyncThunk<ITenantOnboarding, { model: ITenantOnboarding }, { rejectValue: string }>(
    'tenants/addTenantOnboarding',
    async ({ model }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.addTenantOnboarding(model);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const updateTenantOnboarding = createAsyncThunk<ITenantOnboarding, { model: ITenantOnboarding }, { rejectValue: string }>(
    'tenants/updateTenantOnboarding',
    async ({ model }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.updateTenantOnboarding(model);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const rerunTenantOnboardingStep = createAsyncThunk<
    ITenantOnboarding,
    { model: { tenantId: string; tenantOnboardingStepIds: string[] } },
    { rejectValue: string }
>('tenants/rerunTenantOnboardingStep', async ({ model }, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.rerunTenantOnboardingStep(model);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getTenantOnboardingStepById = createAsyncThunk<ITenantOnboarding, { step: string }, { rejectValue: string }>(
    'tenants/getTenantOnboardingStepById',
    async ({ step }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.getTenantOnboardingStepById(step);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getTenantPracticeInfo = createAsyncThunk<
    IPracticeInfo,
    { model: { tenantId: string; practiceId: number; clientId: string; clientSecret: string; isUniversalKey: boolean } },
    { rejectValue: string }
>('tenants/getTenantPraticeInfo', async ({ model }, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.getTenantPracticeInfo(model);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

type VerifyConfigurationResponse = { tenant: ITenant; onboarding: ITenantOnboarding };
export const verifyConfigurationAndRunOnboarding = createAsyncThunk<
    VerifyConfigurationResponse,
    void,
    { state: RootState; dispatch: AppDispatch; rejectValue: string }
>('tenants/verifyConfigurationAndRunOnboarding', async (_, { dispatch, getState, rejectWithValue }) => {
    const selectTenant = getState().tenants.selectTenant;
    const selectUser = getState().user.account?.identity.id;

    if (selectTenant) {
        try {
            // Save tenant before running onboarding
            const { data: tenant } = await tenantsApi.updateTenant({
                ...selectTenant,
                verifiedPracticeInfo: true,
                verifiedPracticeInfoBy: selectUser,
                verifiedPracticeInfoOn: new Date().toISOString(),
            });

            // Save onboarding before switching to onboarding page
            const { data: onboarding } = await tenantsApi.addTenantOnboarding({
                tenantId: selectTenant?.id,
                id: uuid(),
                displayName: selectTenant?.displayName,
                practiceId: selectTenant?.practiceId ? parseInt(selectTenant?.practiceId) : 0,
                isDeleted: false,
            });

            // Switch to onboarding page
            dispatch(push(`/tenants/${tenant.id}/onboarding`));

            return { tenant, onboarding };
        } catch (err: any) {
            return rejectWithValue(err.toString());
        }
    }
    return rejectWithValue('No tenant selected');
});
