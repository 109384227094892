import { MessageBar, Stack } from '@fluentui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantById, getTenantConfigView } from './state/tenants.actions';
import { HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { isDev } from 'api/platform.api';
import { getUserTasksByTarget } from 'state/slices/task/actions';
import { UserTaskTarget } from 'state/slices/task/model';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import Changesets from './Changesets/Changesets';
import Eventing from './Eventing/Eventing';
import EventingChangesetCheckStatusButton from './EventingChangesetCheckStatusButton';
import EventingChangesetsModal from './EventingChangesetsModal';
import { selectEventingChangesetsMessageBar } from './state/EventingChangsetsState/eventingChangesets.selector';
import { dismissEventingChangesetsMessageBar } from './state/tenants.slice';

const hubString = isDev ? `${process.env.REACT_APP_AppApiUri}/notifications` : `#{AppApiUri}#/notifications`;
const options: IHttpConnectionOptions = {};
const connection = new HubConnectionBuilder().withUrl(hubString, options).build();

function EventingChangesets() {
    const { tenantId } = useParams<RouteParams>();

    const dispatch = useDispatch();

    const messageBar = useSelector(selectEventingChangesetsMessageBar);

    useEffect(() => {
        if (tenantId) dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (tenantId) {
            setTimeout(() => {
                connection
                    .start()
                    .then(() => {
                        const { connectionId } = connection;

                        // Connect to tenant group
                        connection
                            .invoke('AddConnectionToGroupAsync', connectionId, 'OnboardingBeacon')
                            .then(() => console.log('Connected to tenant task...'))
                            .catch((err) => console.error(err.toString()));

                        // Listen to tenant onboarding message
                        connection.on('UserTask', (data) => {
                            console.log('UserTask fired');
                            dispatch(getUserTasksByTarget({ tenantId: tenantId, target: UserTaskTarget.TenantSetup }));
                            dispatch(getTenantById({ tenantId }));
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }, 150);
        }
        return () => {
            connection.stop();
        };
    }, [tenantId]);

    useEffect(() => {
        if (tenantId) dispatch(getTenantConfigView({ tenantId }));
    }, [tenantId]);

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack grow>
                {messageBar.messageBarMessage ? (
                    <MessageBar
                        onDismiss={() => {
                            dispatch(dismissEventingChangesetsMessageBar());
                        }}
                        messageBarType={messageBar.messageBarType}
                    >
                        {messageBar.messageBarMessage}
                    </MessageBar>
                ) : null}
                <EventingChangesetCheckStatusButton />
            </Stack>
            <Changesets />
            <Eventing />
            <EventingChangesetsModal />
        </Stack>
    );
}

export default EventingChangesets;
