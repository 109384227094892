import { Separator, Stack, Text, Toggle } from '@fluentui/react';
import { TText } from 'components';
import { useSelector } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProducts, selectProductsData } from 'state/slices/products.slice';
import { selectedUser } from 'state/slices/users.selectors';
import { updateUserAccountProps } from 'state/slices/users.slice';

function UserPermissions(): JSX.Element {
    const productsList = useSelector(selectProductsData);
    const user = useSelector(selectedUser);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProducts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const roles = productsList?.map((prod) => prod.roles?.filter((role) => role.productId === 'platform')).flat();

    const _permissionItems = roles?.map((perm) => {
        //get claims that match the permission id and remove them from the user
        const handleOnClick = () => {
            if (user?.userAccount?.permissions?.length && perm) {
                if (user.userAccount.permissions.indexOf(perm.id) > -1) {
                    dispatch(
                        updateUserAccountProps({
                            path: 'permissions',
                            value: user.userAccount.permissions.filter((p: any) => {
                                return p !== perm.id;
                            }),
                        }),
                    );
                } else {
                    dispatch(updateUserAccountProps({ path: 'permissions', value: [...user.userAccount.permissions, perm?.id] }));
                }
            } else {
                dispatch(updateUserAccountProps({ path: 'permissions', value: [perm?.id] }));
            }
        };

        const permissionChecked =
            user && user.userAccount && user.userAccount.permissions
                ? user.userAccount.permissions.indexOf(perm?.id ?? '') > -1
                : false;

        return (
            <Stack key={perm?.id}>
                <Stack horizontal verticalAlign="center" styles={{ root: { cursor: 'pointer' } }} onClick={handleOnClick}>
                    <Stack.Item grow={1}>
                        <Text variant="mediumPlus">{perm?.displayName ?? ''}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle checked={permissionChecked} />
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Text variant="medium">{perm?.description}</Text>
                </Stack>
                <Separator />
            </Stack>
        );
    });

    return (
        <Stack styles={{ root: { width: '100%' } }}>
            <Stack>
                <Separator>
                    <TText variant="mediumPlus" block>
                        <b>Subscription Role(s)</b>
                    </TText>
                </Separator>
            </Stack>

            {_permissionItems}
        </Stack>
    );
}

export default UserPermissions;
