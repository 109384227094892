import ErrorTypes from 'state/errorTypes';
import featureFlagApi from './api';
import { IFeatureFlag, IFeatureFlagTenant, IFeatureFlagTenantModel } from './model';
import { Dictionary, createAsyncThunk } from '@reduxjs/toolkit';

export const getAllFeatureFlags = createAsyncThunk<Dictionary<IFeatureFlag>, undefined, { rejectValue: string }>(
    'featureFlag/getAllFeatureFlag',
    async (_, { rejectWithValue }) => {
        try {
            const { data: FeatureFlag } = await featureFlagApi.getAllFeatureFlag();
            return FeatureFlag;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getFeatureFlagById = createAsyncThunk<IFeatureFlag, string, { rejectValue: string }>(
    'featureFlag/getFeatureFlagById',
    async (id, { rejectWithValue }) => {
        try {
            const { data: FeatureFlag } = await featureFlagApi.getFeatureFlagById(id);
            return FeatureFlag;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const createFeatureFlag = createAsyncThunk<IFeatureFlag, { featureFlag: IFeatureFlag }, { rejectValue: string }>(
    'featureFlag/createFeatureFlag',
    async ({ featureFlag }, { rejectWithValue }) => {
        try {
            const { data: FeatureFlag } = await featureFlagApi.createFeatureFlag(featureFlag);
            return FeatureFlag;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const updateFeatureFlag = createAsyncThunk<IFeatureFlag, { featureFlag: IFeatureFlag }, { rejectValue: string }>(
    'featureFlag/updateFeatureFlag',
    async ({ featureFlag }, { rejectWithValue }) => {
        try {
            const { data: FeatureFlag } = await featureFlagApi.updateFeatureFlag(featureFlag);
            return FeatureFlag;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getFeatureFlagTenant = createAsyncThunk<IFeatureFlagTenant[], { featureFlagId: string }, { rejectValue: string }>(
    'featureFlag/getFeatureFlagTenant',
    async ({ featureFlagId }, { rejectWithValue }) => {
        try {
            const { data: FeatureFlagTenant } = await featureFlagApi.getFeatureFlagTenant(featureFlagId);
            return FeatureFlagTenant;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const updateFeatureFlagTenant = createAsyncThunk<
    IFeatureFlagTenantModel,
    { featureFlagId: string; model: IFeatureFlagTenantModel },
    { rejectValue: string }
>('featureFlag/createFeatureFlagTenant', async ({ featureFlagId, model }, { dispatch, rejectWithValue }) => {
    try {
        const { data: FeatureFlagTenant } = await featureFlagApi.updateFeatureFlagTenant(featureFlagId, model);
        dispatch(getFeatureFlagTenant({ featureFlagId }));
        return FeatureFlagTenant;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});
