import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ITenant from 'views/pages/Tenants/state/tenants.model';
import { RootState } from 'state/store';

const initialState: AdminState = {};

const adminSlice = createSlice({
    name: 'admin',
    reducers: {
        setAdminOrganization: (state, action: PayloadAction<ITenant>) => {
            state.tenantId = action.payload?.id;
        },
    },
    initialState,
});

export default adminSlice.reducer;

export const { setAdminOrganization } = adminSlice.actions;

export function adminOrganizationSelector(state: RootState) {
    return state.admin.tenantId;
}

type AdminState = {
    tenantId?: string;
};
