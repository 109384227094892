import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Section } from 'components';
import { useEffect } from 'react';

import useTenant from 'hooks/state/useTenant';

import { CategoryCard, ICategoryCard } from './components/CategoryCard';
import TaskBeacon from './TaskBeacon';
import usePermissions, { Permission } from 'hooks/usePermissions';

const Overview = () => {
    //todo: update

    const { loading } = useTenant();

    useEffect(() => {
        document.title = 'Overview - ELWOOD | PLATFORM MANAGEMENT';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cardConfigs: ICategoryCard[] = [
        {
            displayName: 'Customer Success',
            path: '/customer-success/payers',
            permissions: [
                Permission.ManageBenefitPlans,
                Permission.ManagePayer,
                Permission.ManageFPG,
                Permission.ManageDiagnoses,
            ],
            icon: 'Trophy2',
        },
        {
            displayName: 'Feature Flags',
            path: '/feature-flags',
            permissions: [Permission.ManageFeature],
            icon: 'Flag',
        },
        {
            displayName: 'Products',
            path: '/products',
            icon: 'ProductList',
            permissions: [Permission.ManageProduct],
        },
        {
            displayName: 'Reports',
            path: '/reports',
            icon: 'ReportDocument',
            permissions: [Permission.ManageReporting],
        },
        {
            displayName: 'Tenants',
            path: '/tenants',
            icon: 'WorkforceManagement',
            permissions: [
                Permission.TenantAbout,
                Permission.TenantBilling,
                Permission.TenantIntegration,
                Permission.TenantSubscriptions,
            ],
        },
        {
            displayName: 'Users',
            path: '/users',
            icon: 'CRMServices',
            permissions: [Permission.ManageUsers],
        },
    ];

    const [hasTasks] = usePermissions([Permission.TenantIntegration]);

    const cards = cardConfigs.map((c) => <CategoryCard key={c.path} {...c} />);

    return (
        <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
            {loading === 'pending' ? (
                <Stack verticalAlign="center" horizontalAlign="center" grow>
                    <Spinner size={SpinnerSize.large} label="Loading..." />
                </Stack>
            ) : (
                <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                    {hasTasks && <TaskBeacon />}
                    <Section>
                        <Stack horizontal style={{ padding: 10 }} tokens={{ childrenGap: 10 }} wrap>
                            {cards}
                        </Stack>
                    </Section>
                </Stack>
            )}
        </Stack>
    );
};

export default Overview;
