import { ITheme, Stack, IconButton, useTheme } from '@fluentui/react';
import ActivityBarLink from './ActivityBarLink';
import './ActivityBar.scss';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { setActivityBarExpanded } from 'state/slices/ui.slice';
import { Permission, permissionsAsList } from 'hooks/usePermissions';

type Props = {
    theme?: ITheme;
};

interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;
    notifications?: number;
    permissions?: Permission[];
}

function ActivityBar(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const theme = useTheme();

    const activities: IActivity[] = [
        {
            iconName: 'HomeSolid',
            keyString: 'overview',
            path: `/`,
            tooltip: 'Overview',
            exact: true,
            permissions: permissionsAsList,
        },
        {
            iconName: 'Trophy2',
            keyString: 'customer-success',
            path: `/customer-success/payers`,
            tooltip: 'Customer Success',
            exact: false,
            permissions: [
                Permission.ManageBenefitPlans,
                Permission.ManageProcedures,
                Permission.ManageBenefitPlans,
                Permission.ManagePayer,
                Permission.ManageFPG,
            ],
        },
        {
            iconName: 'Flag',
            keyString: 'feature-flags',
            path: `/feature-flags`,
            tooltip: 'Feature Flags',
            exact: false,
            permissions: [Permission.ManageFeature],
        },

        {
            iconName: 'ProductList',
            keyString: 'products',
            path: `/products`,
            tooltip: 'Products',
            exact: false,
            permissions: [Permission.ManageProduct],
        },
        {
            iconName: 'ReportDocument',
            keyString: 'report',
            path: `/reports`,
            tooltip: 'Reports',
            exact: false,
            permissions: [Permission.ManageReporting],
        },
        {
            iconName: 'WorkforceManagement',
            keyString: 'tenants',
            path: `/tenants`,
            tooltip: 'Tenants',
            exact: false,
            permissions: [
                Permission.TenantAbout,
                Permission.TenantBilling,
                Permission.TenantSubscriptions,
                Permission.TenantIntegration,
            ],
        },
        {
            iconName: 'CRMServices',
            keyString: 'users',
            path: `/users`,
            tooltip: 'Users',
            exact: false,
            permissions: [Permission.ManageUsers],
        },
    ];

    const { activityBarExpanded } = useSelector((state) => state.ui);

    const { neutralLighterAlt } = theme.palette;

    const activityLinks = activities.map((activity) => (
        <ActivityBarLink
            key={activity.keyString}
            permissions={activity.permissions}
            expanded={activityBarExpanded}
            {...activity}
        />
    ));
    const wrapperClassName = activityBarExpanded ? 'activityBar activityBar--expanded' : 'activityBar';
    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
    };
    const expanderIcon = {
        iconName: activityBarExpanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };
    const _setExpanded = () => {
        dispatch(setActivityBarExpanded(!activityBarExpanded));
    };
    if (activityLinks.length > 0) {
        return (
            <Stack className={wrapperClassName} style={wrapperStyle}>
                <Stack.Item align={activityBarExpanded ? 'end' : 'center'}>
                    <IconButton onClick={_setExpanded} iconProps={expanderIcon} styles={{ root: { padding: '0px 24px' } }} />
                </Stack.Item>
                <div style={{ flex: 1 }}>{activityLinks}</div>
            </Stack>
        );
    }
    return <></>;
}

export default ActivityBar;
