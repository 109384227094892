import store from 'store';

export const STORE_NAME = 'elwood-platform-managment';

type PlatformLocalStorage = {
    ui: UiLocalStorage;
};
type UiLocalStorage = {
    theme: 'dark' | 'light';
    activityBarExpanded: boolean;
};

const newLocalStorage: PlatformLocalStorage = {
    ui: {
        theme: 'light',
        activityBarExpanded: true,
    },
};

class AppStorage {
    store: PlatformLocalStorage;
    constructor() {
        this.store = store.get(STORE_NAME, newLocalStorage);
    }

    private save() {
        store.set(STORE_NAME, this.store);
    }

    get theme() {
        return this.store.ui.theme;
    }
    set theme(theme: 'dark' | 'light') {
        this.store.ui.theme = theme;
        this.save();
    }

    get activityBarExpanded() {
        return this.store.ui.activityBarExpanded;
    }
    set activityBarExpanded(expanded: boolean) {
        this.store.ui.activityBarExpanded = expanded;
        this.save();
    }
}

const appLocalStorage = new AppStorage();
export default appLocalStorage;
