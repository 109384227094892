import { ComboBox, IComboBoxOption, IComboBoxProps } from '@fluentui/react';
import { useMemo } from 'react';

type TimezoneInputProps = Omit<IComboBoxProps, 'options'>;

export default function TimezoneInput(props: TimezoneInputProps) {
    const timezoneInputOptions: IComboBoxOption[] = useMemo(() => {
        return (new Intl.Locale('en-US', { region: 'US' }) as any).timeZones
            ?.map((zone: string) => {
                const zoneAbbr = new Date().toLocaleString('en-US', { timeZone: zone, timeZoneName: 'short' }).split(' ');
                return {
                    zone,
                    zoneAbbr: zoneAbbr[zoneAbbr.length - 1],
                };
            })
            ?.map(({ zone, zoneAbbr }: { zone: string; zoneAbbr: string }) => ({
                text: `${zone} (${zoneAbbr})`,
                key: zone,
            }));
    }, []);

    return <ComboBox placeholder="(Select Timezone)" {...props} options={timezoneInputOptions} label={props.label} />;
}
