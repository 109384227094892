import { DefaultButton, Pivot, PivotItem, PrimaryButton, Stack } from '@fluentui/react';
import { useSelector } from 'hooks';
import { map } from 'lodash';
import { matchPath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { hasTenantsInfoChanged, selectTenantConfigCopy } from './state/tenants.selectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTenantConfigCopy } from './state/tenants.actions';
import { getTenantOnboardingById } from './Onboarding/state/onboarding.actions';
import { selectTenantOnboarding } from './Onboarding/state/onboarding.selectors';

export enum EditTenantMenuItem {
    About = 'about',
    Subscriptions = 'subscriptions',
    OnBoarding = 'onboarding',
    Billing = 'billing',
    FinancialClosing = 'financialClosing',
    EventingChangesets = 'eventingChangesets',
    ConfigCopy = 'configCopy',
}

type Props = {
    submit: () => void;
};

export default function TenantPivot({ submit }: Props) {
    const { tenantId } = useParams<{ tenantId: string; path: string }>();
    const { url, path } = useRouteMatch();
    const { pathname } = useLocation();
    const { push } = useHistory();
    const copyConfigStep = useSelector(selectTenantConfigCopy);
    const onboarding = useSelector(selectTenantOnboarding);
    const dispatch = useDispatch();

    useEffect(() => {
        if (tenantId) {
            dispatch(getTenantOnboardingById({ tenantId }));
            dispatch(getTenantConfigCopy({ tenantId }));
        }
    }, [tenantId, dispatch]);

    const menuItemDisplayNameLookup: Partial<Record<EditTenantMenuItem, string>> = {
        [EditTenantMenuItem.About]: 'About',
        [EditTenantMenuItem.Subscriptions]: 'Subscriptions',
        [EditTenantMenuItem.OnBoarding]: 'Integration',
        [EditTenantMenuItem.Billing]: 'Billing',
        [EditTenantMenuItem.FinancialClosing]: 'Financial Closing',
        [EditTenantMenuItem.EventingChangesets]: 'Eventing/Changesets',
        [EditTenantMenuItem.ConfigCopy]: 'Config Copy',
    };

    const getPaths = (path: string) =>
        map(EditTenantMenuItem, (page) => ({
            path: `${path}/${page}`,
            displayName: menuItemDisplayNameLookup[page],
            show: !onboarding ? (page !== EditTenantMenuItem.EventingChangesets && page !== EditTenantMenuItem.ConfigCopy) : !copyConfigStep ? page !== EditTenantMenuItem.ConfigCopy : true
        }));

    const hasChanged = useSelector(hasTenantsInfoChanged);

    if (!tenantId) return null;

    const pivotItems = getPaths(url).filter(({ show }) => show).map(({ path, displayName }) => {
        return <PivotItem key={path} itemKey={path} headerText={displayName} />;
    });

    const selectedKey = matchPath(pathname, { path: getPaths(path).map(({ path }) => path), exact: false })?.url ?? '';

    function _onPivotLinkClick(item?: PivotItem | undefined) {
        if (item?.props.itemKey) {
            push(item.props.itemKey);
        }
    }

    //make a onboarding button that will run all the tasks

    return (
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
            <Pivot selectedKey={selectedKey} onLinkClick={_onPivotLinkClick}>
                {pivotItems}
            </Pivot>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
                <DefaultButton onClick={submit} disabled={hasChanged}>
                    Save
                </DefaultButton>
            </Stack>
        </Stack>
    );
}
