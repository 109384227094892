import IUser from 'api/models/user.model';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const usersState = (state: RootState) => state.users;

export const showUsersHistory = createSelector(usersState, (state) => state.showHistory);
export const selectedUser = createSelector(usersState, (state) => state.selectedUser);
export const userModalOpen = createSelector(usersState, (state) => state.isModalOpen);
export const selectUsersData = createSelector(usersState, (state) => state.data);

export const filteredUsersData = createSelector(selectUsersData, showUsersHistory, (state, showHistory) => {
    const list = map(state, (p) => p) as IUser[];
    return showHistory ? list : list.filter((p) => !p?.isDeleted);
});
export const selectUsersLoading = createSelector(usersState, (state) => state.loading);
