import { DefaultButton, PrimaryButton, Stack, TooltipHost } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedTenant } from '../state/tenants.selectors';
import { rerunTenantOnboardingStep } from './state/onboarding.actions';
import { selectTenantOnboarding } from './state/onboarding.selectors';

export default function RunTaskButton() {
    const dispatch = useDispatch();
    const selectTenant = useSelector(selectSelectedTenant);
    const onboardingSteps = useSelector(selectTenantOnboarding);

    const failedSteps = onboardingSteps?.tenantOnboardingSteps?.filter((step) => step.status === 'failed');

    const rerunFailedOnboardingTask = () => {
        const failed = failedSteps ? failedSteps.map((step) => step.id) : undefined;

        if (selectTenant && onboardingSteps && failed) {
            dispatch(
                rerunTenantOnboardingStep({
                    model: {
                        tenantId: selectTenant?.id,
                        tenantOnboardingStepIds: failed,
                    },
                }),
            );
        }
    };

    if (!onboardingSteps) {
        return null;
    }

    const failedTaskButton = () => {
        return (
            <TooltipHost content={'Rerun all failed step'}>
                <DefaultButton onClick={rerunFailedOnboardingTask}>{`Run Failed Step(s)`}</DefaultButton>
            </TooltipHost>
        );
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            {failedSteps?.length ? failedTaskButton() : null}
        </Stack>
    );
}
