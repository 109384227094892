import { IDropdownOption } from '@fluentui/react';
import IProduct, { IProductService } from 'api/models/product.model';
import { SourceSystem } from 'api/models/source-system.model';
import ITenant, { Services } from 'views/pages/Tenants/state/tenants.model';
import ITenantProduct from 'api/models/tenantProduct.model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { filter, flatten, isEqual, map, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { selectProductsData, productsState } from 'state/slices/products.slice';
import { isDateBetween } from 'utils/isDateBetween';

export const tenantsState = (state: RootState) => state.tenants;

export const selectedTenantId = createSelector(tenantsState, state => state.selectTenant?.id);

export const tenantSaving = createSelector(tenantsState, (state) => state.saving);


export const showTeneantDataHistory = createSelector(tenantsState, (state) => state.showHistory);

export const tenantsData = createSelector(tenantsState, (state) => state.tenants);

export const tenantBillingData = createSelector(tenantsState, (state) => state.selectTenant?.billingInformation);

export const selectTenantConfig = createSelector(tenantsState, (state) => state.tenantConfigView);

export const selectTenantConfigCopy = createSelector(tenantsState, (state) => state.selectedTenantConfigCopy);
export const selectLoadingTenantConfigView = createSelector(tenantsState, (state) => state.loadingTenantConfigView);

export const selectTenantCopyConfig = createSelector(selectTenantConfigCopy, (copyConfig) => {
    return flatten(copyConfig?.tenantConfigCopySteps) ?? [];
});

export const selectTenantConfigCopySteps = createSelector(tenantsState, (state) => state.selectedTenantConfigCopyStep);
export const selectTenantConfigCopyStepsList = createSelector(selectTenantConfigCopySteps, (tenantConfigCopy) => {
    return map(tenantConfigCopy) ?? [];
});
export const configCopy = createSelector(tenantsState, (state) => state.configCopySteps);
export const configCopyStepsList = createSelector(selectTenantConfigCopySteps, (tenantConfigCopy) => {
    return map(tenantConfigCopy) ?? [];
});

export const _isRerunModalOpen = createSelector(tenantsState, (state) => state.rerunModalOpen);
export const selectTenantConfigCopyLoading = createSelector(tenantsState, (state) => state.loadingTenantConfigCopy);
export const hasClientIdAndSecret = createSelector(tenantsState, (state) => {
    if (!state.tenants) return false;
    if (!state.selectTenant) return false;

    const matchingTenant = state.tenants.find((tenant) => tenant.id === state.selectTenant?.id);
    if (matchingTenant && matchingTenant.clientId && matchingTenant.clientSecret) {
        return true;
    } else {
        return false;
    }
});

export const hasTenantsInfoChanged = createSelector(tenantsState, (state) => {
    if (!state.tenants) return false;
    if (!state.selectTenant) return false;
    const matchingTenant = state.tenants.find((tenant) => tenant.id === state.selectTenant?.id);
    if (matchingTenant && isEqual(matchingTenant, state.selectTenant)) {
        return true;
    } else {
        return false;
    }
});

export const tenantToEditLoading = createSelector(tenantsState, (state) => state.loadingEditTenant);

export const tenantData = (state: RootState, id: string) =>
    tenantsData(state) ? ((tenantsData(state) as any)[id] as ITenant) : undefined;

export const tenantReportList = createSelector(tenantsData, (tenantsData) => {
    return map(tenantsData, (p) => p) as ITenant[];
});

export const liveTenants = createSelector(tenantsData, (tenantsData) => {
    return filter(
        tenantsData,
        (p) =>
            isDateBetween({
                dateToCheck: new Date().toDateString(),
                start: p.firstDateOfService,
                end: p.lastDateOfService,
            }) && !p?.isDeleted,
    ) as ITenant[];
});

export const showIsLiveCustomer = createSelector(tenantsState, (state) => state.isLiveCustomer);

export const upcomingTenants = createSelector(tenantsData, (tenantsData) => {
    return filter(tenantsData, (p) => new Date(p.firstDateOfService ?? '') > new Date() && !p.isDeleted) as ITenant[];
});

export const showIsUpcomingCustomer = createSelector(tenantsState, (state) => state.isUpcomingCustomer);

export const tenantsAsList = createSelector(
    [tenantsData, showTeneantDataHistory, showIsLiveCustomer, liveTenants, upcomingTenants, showIsUpcomingCustomer],
    (allTenants, showHistory, showLiveCustomer, liveTenants, upcomingTenants, showUpcomingCustomer) => {
        const isNotDeleted = (tenant: ITenant) => !tenant.isDeleted;

        const results = showLiveCustomer ? liveTenants : showUpcomingCustomer ? upcomingTenants : allTenants;

        if (!results.length) return [];
        if (showHistory) return results.filter(isNotDeleted);
        return results;
    },
);

export const selectTenantServicePanelOpen = createSelector(tenantsState, (state) => state.servicePanelOpen);
export const selectedTenantPanelIsOpen = createSelector(tenantsState, (state) => state.selectedTenantPanelOpen);
export const selectedTenantProductPanelIsOpen = createSelector(tenantsState, (state) => state.selectedTenantProductPanelOpen);

export const isTenantBarExpanded = createSelector(tenantsState, (state) => state.isExpanded);

export const selectTenantConfigView = createSelector(tenantsState, (state) => state.tenantConfigView);

export type CombinedSubscriptionProduct = ITenantProduct & { productName?: string };

export const selectSelectedTenant = createSelector(tenantsState, (state) => {
    return state.selectTenant;
});
export const selectSelectedTenantSubscriptions = createSelector(tenantsState, (state) => {
    return state.tenantProducts?.filter((product) => !product.isDeleted) ?? [];
});

export const selectedTenantValidationError = (state: RootState) => state.tenants.validationErrors;

export const selectSelectedTenantProducts = createSelector(
    selectProductsData,
    selectSelectedTenantSubscriptions,
    (productsData, selectedTenantSubscriptions) => {
        return (
            (selectedTenantSubscriptions
                ?.map((sub) => productsData.find((data) => data.id === sub.productId))
                .filter((data) => data !== undefined) as IProduct[]) ?? []
        );
    },
);

export const selectSelectedTenantAvailableServices = createSelector(selectSelectedTenantProducts, (products) => {
    return uniqBy(
        flatten(
            products
                .filter((product) => !product.isDeleted)
                .map((product) => product.services?.filter((service) => !service.isDeleted) ?? []),
        ),
        (service: IProductService) => service.id,
    );
});

export const selectSelectedTenantProduct = createSelector(tenantsState, (state) => {
    return state.selectedTenantProduct;
});

export const selectAvailableTenantServices = createSelector(selectSelectedTenantAvailableServices, (activeServicesList) => {
    return map(Services).filter((service) => activeServicesList.findIndex((s) => s.id === service) === -1);
});

export const selectSelectedProductServiceSubscriptions = createSelector(selectSelectedTenantProduct, (product) => {
    return product?.services
        ? map(product?.services, (service, key) => ({
              ...service,
              id: key,
              startDate: service?.startDate,
              endDate: service?.endDate,
          }))
        : [];
});

//NOTE Ensure that the data is relevant to the product that is selected
export const selectAvailableProductServices = createSelector(
    selectSelectedTenantAvailableServices,
    selectSelectedProductServiceSubscriptions,
    selectSelectedTenantProducts,
    selectSelectedTenantProduct,
    (activeServicesList, productServices, tenantProducts, tenantProduct) => {
        const productServicesList =
            tenantProducts
                .find((product) => product.id === tenantProduct?.productId)
                ?.services?.filter((service) => !service.isDeleted) ?? [];

        return activeServicesList
            .filter((service) => productServicesList.findIndex((s: IProductService) => s.id === service.id) > -1)
            .filter((service) => productServices.findIndex((productService) => productService.serviceType === service.id) === -1);
    },
);

export const tenantSubscriptionProducts = createSelector([tenantsState, productsState], (tenants, products) => {
    if (tenants.tenantProducts && tenants.tenantProducts.length && products.data && products.data.length) {
        return tenants.tenantProducts.map((t) => ({
            ...t,
            productName: products.data?.find((p) => p.id === t.productId)?.displayName,
        })) as CombinedSubscriptionProduct[];
    } else return [] as CombinedSubscriptionProduct[];
});

export const selectSourceSystemsLookup = createSelector(tenantsState, (state) => state.sourceSystems ?? {});
export const selectSourceSystemsLoading = createSelector(
    tenantsState,
    (state) => state.loadingSourceSystems === LoadingStatus.Pending,
);
export const selectSourceSystemsAsList = createSelector(selectSourceSystemsLookup, (lookup) => {
    const sourceSystems: SourceSystem[] = (
        map(lookup).filter((sourceSystem) => sourceSystem !== undefined) as SourceSystem[]
    ).filter((sourceSystem) => !sourceSystem?.isDeleted);

    return sourceSystems;
});
export const selectSourceSystemsAsOptions = createSelector(selectSourceSystemsAsList, (sourceSystems) => {
    const options: IDropdownOption[] = [
        { key: '', text: '(Select Source System)' },
        ...sourceSystems.map((sourceSystem) => ({
            key: sourceSystem.id,
            text: sourceSystem.id,
        })),
    ];
    return options;
});
