import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loadingStatus';
import {
    getTenantHardCloseHistory,
    getTenantHardCloseThroughDate,
    hardCloseBatches,
    updateHardCloseBatches,
} from './financialClosing.actions';
import { FinanicialClosingState } from './financialClosing.state';
import { v4 as uuid } from 'uuid';
import { classicDateOnly } from 'utils/dateOnly';
import { MessageBarType } from '@fluentui/react';
import IBatchHardClosed, { IBatchHardClosedHistory } from 'api/models/hardClosedHistory.model';

const financialClosingReducers = {
    cleanupTenantHardCloseThroughDate(state: FinanicialClosingState) {
        state.selectedTenantHardCloseDate = undefined;
        state.selectedTenantHardCloseDateLoading = LoadingStatus.Idle;
    },
    cleanupTenantHardCloseHistory(state: FinanicialClosingState) {
        state.selectedTenantHardCloseHistory = undefined;
        state.selectedTenantHardCloseHistoryLoading = LoadingStatus.Idle;

        state.savingHardCloseBatches = LoadingStatus.Idle;
        state.savingHardCloseHistory = LoadingStatus.Idle;

        state.hardCloseMessageBarType = undefined;
        state.hardCloseMessageBarText = undefined;
    },
    cleanupHardCloseModal(state: FinanicialClosingState) {
        state.hardClosePayload = undefined;
        state.savingHardCloseBatches = LoadingStatus.Idle;
    },
    cleanupTenantHardCloseMessageBar(state: FinanicialClosingState) {
        state.hardCloseMessageBarType = undefined;
        state.hardCloseMessageBarText = undefined;
    },
    setHardCloseHistoryData(state: FinanicialClosingState, action: PayloadAction<IBatchHardClosed | undefined>) {
        if (action.payload) {
            state.selectedTenantHardCloseHistory = action.payload;
            if (state.selectedTenantHardCloseDate) {
                state.selectedTenantHardCloseDate = action.payload.maxHardClosedThroughDate;
            }
        }
    },
    setHardCloseThroughDate(state: FinanicialClosingState, action: PayloadAction<string | undefined>) {
        if (state.hardClosePayload) {
            state.hardClosePayload.hardClosedThroughDate = action.payload;
        }
    },
    setHardCloseModalOpen(state: FinanicialClosingState, action: PayloadAction<boolean>) {
        state.hardCloseModalOpen = action.payload;

        if (action.payload) {
            state.hardClosePayload = {
                id: uuid(),
                hardClosedThroughDate: '',
            };
        }
    },
};

export const financialClosingExtraReducers = (
    builder: ActionReducerMapBuilder<FinanicialClosingState>,
): ActionReducerMapBuilder<FinanicialClosingState> =>
    builder
        .addCase(getTenantHardCloseThroughDate.pending, (state) => {
            state.selectedTenantHardCloseDateLoading = LoadingStatus.Pending;
        })
        .addCase(getTenantHardCloseThroughDate.fulfilled, (state, action) => {
            state.selectedTenantHardCloseDateLoading = LoadingStatus.Completed;
            state.selectedTenantHardCloseDate = action.payload;
        })
        .addCase(getTenantHardCloseThroughDate.rejected, (state) => {
            state.selectedTenantHardCloseDateLoading = LoadingStatus.Failed;
        })
        .addCase(getTenantHardCloseHistory.pending, (state) => {
            state.selectedTenantHardCloseHistoryLoading = LoadingStatus.Pending;
        })
        .addCase(getTenantHardCloseHistory.fulfilled, (state, action) => {
            state.selectedTenantHardCloseHistoryLoading = LoadingStatus.Completed;
            state.selectedTenantHardCloseHistory = action.payload;
        })
        .addCase(getTenantHardCloseHistory.rejected, (state) => {
            state.selectedTenantHardCloseHistoryLoading = LoadingStatus.Failed;
        })
        .addCase(hardCloseBatches.pending, (state) => {
            state.savingHardCloseBatches = LoadingStatus.Pending;
        })
        .addCase(hardCloseBatches.fulfilled, (state, action) => {
            state.savingHardCloseBatches = LoadingStatus.Completed;
            const date = action.payload.hardClosedThroughDate;
            if (!state.selectedTenantHardCloseDate && date) state.selectedTenantHardCloseDate = date;
            if (state.selectedTenantHardCloseDate && date && new Date(date) > new Date(state.selectedTenantHardCloseDate))
                state.selectedTenantHardCloseDate = date;

            state.hardCloseModalOpen = false;

            state.hardCloseMessageBarText = `Successfully hard closed batches through ${date ? classicDateOnly(date as string) : 'Unkown Date'}.`;
            state.hardCloseMessageBarType = MessageBarType.success;
        })
        .addCase(hardCloseBatches.rejected, (state) => {
            state.savingHardCloseBatches = LoadingStatus.Failed;

            state.hardCloseModalOpen = false;

            state.hardCloseMessageBarText = 'Failed to hard close batches.';
            state.hardCloseMessageBarType = MessageBarType.error;
        })
        .addCase(updateHardCloseBatches.pending, (state) => {
            state.savingHardCloseHistory = LoadingStatus.Pending;
        })
        .addCase(updateHardCloseBatches.fulfilled, (state, action) => {
            state.savingHardCloseHistory = LoadingStatus.Completed;
            state.selectedTenantHardCloseHistory = action.payload;

            if (!action.meta.arg.updatedProperty) state.hardCloseMessageBarText = 'Successfully saved hard close.';

            if (action.meta.arg.updatedProperty === 'hardCloseDayAfterMonthEnd')
                state.hardCloseMessageBarText = "Successfully saved Tenant's Close Day";
            if (action.meta.arg.updatedProperty === 'autoHardClose')
                state.hardCloseMessageBarText = 'Successfully updated Auto Close.';

            if (state.hardCloseMessageBarText) state.hardCloseMessageBarType = MessageBarType.success;
        })
        .addCase(updateHardCloseBatches.rejected, (state, action) => {
            state.savingHardCloseHistory = LoadingStatus.Failed;

            if (!action.meta.arg.updatedProperty) state.hardCloseMessageBarText = 'Failed to save Hard Close.';

            if (action.meta.arg.updatedProperty === 'hardCloseDayAfterMonthEnd')
                state.hardCloseMessageBarText = "Failed to save Tenant's Close Day";
            if (action.meta.arg.updatedProperty === 'autoHardClose')
                state.hardCloseMessageBarText = 'Failed to update Auto Close.';

            if (state.hardCloseMessageBarText) {
                state.hardCloseMessageBarType = MessageBarType.error;
                const code = action.payload?.response?.status;
                if (code) state.hardCloseMessageBarText = state.hardCloseMessageBarText + ` (${code})`;
            }

            state.hardCloseMessageBarType = MessageBarType.error;
        });

export default financialClosingReducers;
