import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IColumn,
    IContextualMenuProps,
    PrimaryButton,
    ScrollablePane,
    SelectionMode,
    Stack,
    TextField,
    Toggle,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Field, Section, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import useUsers from 'hooks/state/useUsers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { userAccount } from 'state/slices/user/user.slice';
import { getUsers } from 'state/slices/users.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { getFeatureFlagById, getFeatureFlagTenant, updateFeatureFlag, updateFeatureFlagTenant } from './state/actions';
import { IFeatureFlagTenant } from './state/model';
import { selectedFeatureFlag, selectedFeatureFlagShowHistoryTenant, selectedFeatureFlagTenantList } from './state/selector';
import { setFeatureFlagTenantModal, toggleTenantHistory, updateSelectedFeatureFlagProp } from './state/slice';

function FeatureFlagDetails() {
    const dispatch = useDispatch();
    const selectFeatureFlag = useSelector(selectedFeatureFlag);
    const { selectUsersData, selectUsersLoading } = useUsers();
    const selectFeatureFlagTenant = useSelector(selectedFeatureFlagTenantList);
    const showHistory = useSelector(selectedFeatureFlagShowHistoryTenant);
    const selectUser = useSelector(userAccount);
    const { featureFlagId } = useParams<{ featureFlagId: string }>();
    const [hideFeatureFlagDialog, { toggle: toggleFeatureFlagHideDialog }] = useBoolean(true);

    useEffect(() => {
        if (featureFlagId) {
            dispatch(getFeatureFlagById(featureFlagId));
            dispatch(getFeatureFlagTenant({ featureFlagId }));
        }
    }, [featureFlagId, dispatch]);

    useEffect(() => {
        dispatch(getUsers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserName = () => {
        if (selectFeatureFlag?.archivedBy) {
            const user = selectUsersData?.find((u) => u.identity?.id === selectFeatureFlag?.archivedBy);
            if (user) return user.identity?.firstName + ' ' + user.identity?.lastName;
        }
        return '';
    };

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z0-9\s-']*$/.test(value) : true;
        return res;
    };

    const onSave = () => {
        if (selectFeatureFlag) {
            dispatch(
                updateFeatureFlag({
                    featureFlag: selectFeatureFlag,
                }),
            );
        }
    };

    const onArchive = () => {
        if (selectFeatureFlag) {
            dispatch(
                updateFeatureFlag({
                    featureFlag: {
                        ...selectFeatureFlag,
                        isArchived: true,
                        archivedDate: new Date().toISOString(),
                        archivedBy: selectUser?.identity.id,
                    },
                }),
            );
        }
    };

    const onUnArchive = () => {
        if (selectFeatureFlag) {
            dispatch(
                updateFeatureFlag({
                    featureFlag: {
                        ...selectFeatureFlag,
                        isArchived: false,
                    },
                }),
            );
        }
    };

    const onArchiveAction = () => {
        if (selectFeatureFlag) {
            if (selectFeatureFlag.isArchived) {
                onUnArchive();
            } else {
                onArchive();
            }
        }
    };

    const onDelete = () => {
        if (selectFeatureFlag) {
            dispatch(
                updateFeatureFlag({
                    featureFlag: {
                        ...selectFeatureFlag,
                        isDeleted: true,
                    },
                }),
            );
        }
        toggleFeatureFlagHideDialog();
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Trash' },
                onClick: () => {
                    toggleFeatureFlagHideDialog();
                },
            },
        ],
    };

    const columns: IColumn[] = [
        {
            key: 'displayName',
            name: 'Display Name',
            fieldName: 'displayName',
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: 'tenantId',
            name: 'Tenant Id',
            fieldName: 'tenantId',
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: 'isActive',
            name: 'Active',
            fieldName: 'isActive',
            minWidth: 100,

            onRender: (item: IFeatureFlagTenant) => (
                <Toggle
                    checked={item.isActive}
                    onClick={() => {
                        if (selectFeatureFlag)
                            dispatch(
                                updateFeatureFlagTenant({
                                    featureFlagId: selectFeatureFlag?.id,
                                    model: {
                                        tenantIds: [item.tenantId],
                                        featureFlag: {
                                            isActive: !item.isActive,
                                            isDeleted: false,
                                        },
                                    },
                                }),
                            );
                    }}
                />
            ),
        },
    ];

    return (
        <div style={{ position: 'relative', flex: 1 }}>
            <ScrollablePane scrollbarVisibility="auto">
                <Section heading={'Feature Setting'}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack grow>
                                <TextField
                                    value={selectFeatureFlag?.displayName}
                                    onChange={(ev, value: any) => {
                                        if (validTextInput(value))
                                            dispatch(updateSelectedFeatureFlagProp({ path: 'displayName', value }));
                                    }}
                                    label="Display Name"
                                    disabled
                                />
                            </Stack>
                            <Stack grow>
                                <TextField
                                    value={selectFeatureFlag?.description}
                                    onChange={(ev, value: any) => {
                                        if (validTextInput(value))
                                            dispatch(updateSelectedFeatureFlagProp({ path: 'description', value }));
                                    }}
                                    required
                                    label="Description"
                                />
                            </Stack>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10, maxWidth: 430 }}>
                            <Stack grow>
                                <Field.Date
                                    value={
                                        selectFeatureFlag?.originalStartDate
                                            ? classicDateOnly(selectFeatureFlag?.originalStartDate)
                                            : ''
                                    }
                                    onChange={(ev, value: any) => {
                                        dispatch(updateSelectedFeatureFlagProp({ path: 'originalStartDate', value }));
                                    }}
                                    label="Original Start Date"
                                    hasDatePicker
                                    autoComplete="none"
                                />
                            </Stack>
                        </Stack>
                        {selectFeatureFlag?.isArchived && (
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack grow>
                                    <TextField value={getUserName()} label="Archived By" disabled />
                                </Stack>
                                <Stack grow>
                                    <TextField
                                        value={classicDateOnly(selectFeatureFlag?.archivedDate ?? '')}
                                        label="Archived Date"
                                        disabled
                                    />
                                </Stack>
                            </Stack>
                        )}
                        <Stack.Item align="end">
                            <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <DefaultButton text="Save" onClick={onSave} disabled={!selectFeatureFlag?.description} />
                                <DefaultButton
                                    text={!selectFeatureFlag?.isArchived ? 'Archive' : 'Reactivate'}
                                    split
                                    onClick={onArchiveAction}
                                    menuProps={menuProps}
                                />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Section>

                <Section
                    heading={`Participating Tenants ${''}(${selectFeatureFlagTenant?.length})`}
                    headingRightContent={
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Toggle
                                inlineLabel
                                label="Active Only"
                                checked={!showHistory}
                                onClick={() => {
                                    if (selectFeatureFlag) dispatch(toggleTenantHistory());
                                }}
                            />
                            <DefaultButton
                                onClick={() => {
                                    dispatch(setFeatureFlagTenantModal(true));
                                }}
                                text="Add Tenants"
                            />
                        </Stack>
                    }
                >
                    <SortableDetailsList
                        selectionMode={SelectionMode.none}
                        sortOnMount={true}
                        sortColumns={['displayName']}
                        initialSortDirection={['asc']}
                        columns={columns}
                        items={selectFeatureFlagTenant || []}
                    />
                </Section>
                <Dialog
                    hidden={hideFeatureFlagDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete Feature Flag',
                        subText: `Please confirm you want to delete the selected feature flag.`,
                    }}
                    onDismiss={toggleFeatureFlagHideDialog}
                    modalProps={{ isBlocking: true }}
                >
                    <DialogFooter>
                        <PrimaryButton text="Delete" onClick={onDelete} />
                        <DefaultButton text="Cancel" onClick={toggleFeatureFlagHideDialog} />
                    </DialogFooter>
                </Dialog>
            </ScrollablePane>
        </div>
    );
}

export default FeatureFlagDetails;
