import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import { PlatformApi } from 'api';
import IPayer from 'api/models/payer.model';
import ErrorTypes from 'state/errorTypes';

export const getPayers = createAsyncThunk<Dictionary<IPayer>, undefined, { rejectValue: string }>(
    'payers/getPayers',
    async (_, { rejectWithValue }) => {
        try {
            const { data: payers } = await PlatformApi.getPayers();
            return payers;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getPayer = createAsyncThunk<IPayer, { payerId: string }, { rejectValue: string }>(
    'payers/getPayer',
    async ({ payerId }, { rejectWithValue }) => {
        try {
            const { data: payer } = await PlatformApi.getPayer(payerId);
            return payer;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const updatePayer = createAsyncThunk<IPayer, { payer: IPayer }, { rejectValue: string }>(
    'payers/updatePayer',
    async ({ payer }, { rejectWithValue }) => {
        try {
            const { data: updatedPayer } = await PlatformApi.editPayer(payer);
            return updatedPayer;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const addPayer = createAsyncThunk<IPayer, { payer: IPayer }, { rejectValue: string }>(
    'payers/addPayer',
    async ({ payer }, { rejectWithValue }) => {
        try {
            const { data: addedPayer } = await PlatformApi.addPayer(payer);
            return addedPayer;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);
