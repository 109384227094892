import { MessageBarType } from '@fluentui/react';
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionType } from 'api/models/subscribeToChangesPayload.model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { TenantsState } from '../tenants.state';
import {
    getLocationsOfCareForChangesetEventSubscriptions,
    tenantSubscribeToChanges,
    tenantUnsubscribeToChanges,
} from './eventingChangesets.action';
import { EventingChangesetAction } from './eventingChangesets.state';

const eventingChangesetsReducer = {
    setSubscribeToChangesModalOpen: (
        state: TenantsState,
        action: PayloadAction<{ action: EventingChangesetAction; type: SubscriptionType }>,
    ) => {
        state.eventingChangesetState.payload = {
            subscription: action.payload.type,
            pamiDepartmentIds: [],
            appointmentDepartmentIds: [],
        };
        state.eventingChangesetState.modalOpen = true;
        state.eventingChangesetState.modalAction = action.payload.action;
    },
    dismissSubscribeToChangesModal: (state: TenantsState) => {
        state.eventingChangesetState.modalOpen = false;
    },
    dismissEventingChangesetsMessageBar: (state: TenantsState) => {
        state.eventingChangesetState.messageBarMessage = undefined;
        state.eventingChangesetState.messageBarType = undefined;
    },
    updateSubscribeToChangesAppointmentDepartmentIds: (
        state: TenantsState,
        { payload }: PayloadAction<{ appointmentDepartmentIds: string[] }>,
    ) => {
        if (state.eventingChangesetState.payload)
            state.eventingChangesetState.payload = {
                ...state.eventingChangesetState.payload,
                appointmentDepartmentIds: payload.appointmentDepartmentIds,
            };
    },
    updateSubscribeToChangesPAMIDepartmentIds: (
        state: TenantsState,
        { payload }: PayloadAction<{ pamiDepartmentIds: string[] }>,
    ) => {
        if (state.eventingChangesetState.payload)
            state.eventingChangesetState.payload = {
                ...state.eventingChangesetState.payload,
                pamiDepartmentIds: payload.pamiDepartmentIds,
            };
    },
    cleanupSubscribeToChangesModal: (state: TenantsState) => {
        state.eventingChangesetState.payload = undefined;
        state.eventingChangesetState.locationsOfCare = undefined;
    },
};

export default eventingChangesetsReducer;

export const eventingChangsetsExtraReducers = (
    builder: ActionReducerMapBuilder<TenantsState>,
): ActionReducerMapBuilder<TenantsState> =>
    builder
        .addCase(tenantSubscribeToChanges.pending, (state) => {
            state.eventingChangesetState.subscriptionActionLoading = LoadingStatus.Pending;
        })
        .addCase(tenantSubscribeToChanges.fulfilled, (state, { meta }) => {
            state.eventingChangesetState.subscriptionActionLoading = LoadingStatus.Completed;
            state.eventingChangesetState.modalOpen = false;

            state.eventingChangesetState.messageBarType = MessageBarType.success;
            state.eventingChangesetState.messageBarMessage = `Successfully began subscribing to ${meta.arg.data.subscription.toLowerCase()}. Please check periodically for changes to reflect.`;
        })
        .addCase(tenantSubscribeToChanges.rejected, (state, { meta }) => {
            state.eventingChangesetState.subscriptionActionLoading = LoadingStatus.Failed;

            state.eventingChangesetState.messageBarType = MessageBarType.error;
            state.eventingChangesetState.messageBarMessage = `Something went wrong while subscribing to ${meta.arg.data.subscription.toLowerCase()}`;
        })
        .addCase(tenantUnsubscribeToChanges.pending, (state) => {
            state.eventingChangesetState.subscriptionActionLoading = LoadingStatus.Pending;
        })
        .addCase(tenantUnsubscribeToChanges.fulfilled, (state, { meta }) => {
            state.eventingChangesetState.subscriptionActionLoading = LoadingStatus.Completed;
            state.eventingChangesetState.modalOpen = false;

            state.eventingChangesetState.messageBarType = MessageBarType.success;
            state.eventingChangesetState.messageBarMessage = `Successfully began usubscribing from ${meta.arg.subscription.toLowerCase()}. Please check periodically for changes to reflect.`;
        })
        .addCase(tenantUnsubscribeToChanges.rejected, (state, { meta }) => {
            state.eventingChangesetState.subscriptionActionLoading = LoadingStatus.Failed;

            state.eventingChangesetState.messageBarType = MessageBarType.error;
            state.eventingChangesetState.messageBarMessage = `Something went wrong while subscribing to ${meta.arg.subscription.toLowerCase()}`;
        })
        .addCase(getLocationsOfCareForChangesetEventSubscriptions.pending, (state) => {
            state.eventingChangesetState.loadingLocationsOfCare = LoadingStatus.Pending;
        })
        .addCase(getLocationsOfCareForChangesetEventSubscriptions.fulfilled, (state, { payload }) => {
            state.eventingChangesetState.loadingLocationsOfCare = LoadingStatus.Completed;
            state.eventingChangesetState.locationsOfCare = payload;
        })
        .addCase(getLocationsOfCareForChangesetEventSubscriptions.rejected, (state) => {
            state.eventingChangesetState.loadingLocationsOfCare = LoadingStatus.Failed;
        });
