import { useEffect, useReducer } from 'react';
import { TextField, ITextFieldProps } from '@fluentui/react';

type Props = { handleError?: (error: string | undefined) => void } & ITextFieldProps;

type State = {
    value?: string;
};

type Actions = { type: 'setInternalValue'; value?: string };

function initialState(value: string): State {
    return { value };
}

const isValueEmpty = (value?: string) => value?.trim() === '';
const formatZipCode = (zip: string | undefined) => zip && /^[0-9]{5}(?:-[0-9]{4})?$/;

const reducer = (state: State, action: Actions) => {
    switch (action.type) {
        case 'setInternalValue':
            return { ...state, value: action.value };
        default:
            return state;
    }
};

const zipRegex = /(\d{5})(\d)/;

export const getValidZip = (value?: string) => {
    if (!value) return true;
    const rawValue = value ? value.split('-').join('') : value;
    return rawValue && (rawValue.length === 5 || rawValue.length === 9) && !isNaN(+rawValue)
        ? true
        : false;
};

export default function ZipCodeField({
    value,
    onChange,
    handleError,
    ...props
}: Props): JSX.Element {
    const [state, dispatch] = useReducer(reducer, value ? value : '', initialState);

    useEffect(() => {
        if (value !== null && value !== '') {
            const rawValue = value ? value.split('-').join('') : value;
            let newValue = rawValue ? rawValue : '';
            if (rawValue && rawValue.length > 5) newValue = rawValue.replace(zipRegex, '$1-$2');
            dispatch({ type: 'setInternalValue', value: newValue });
        }
        if (value === '') {
            dispatch({ type: 'setInternalValue', value: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function onGetErrorMessage(value: string) {
        let message = '';
        if (!value) {
            message = 'Zip Required';
        } else if (!getValidZip(value)) {
            message = 'Invalid Zip';
        }
        if (message && handleError) handleError(message);
        return message;
    }

    const handleChange = (
        e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
        value?: string | undefined,
    ) => {
        if (!/^[0-9.-]+$/.test(value!) && value?.length) {
            return;
        }

        dispatch({ type: 'setInternalValue', value });
        if (value && onChange) {
            if (formatZipCode(value)) {
                onChange(e, value);
            } else {
                onChange(e, '');
            }
            if (isValueEmpty(state.value)) {
                onChange(e, '');
            }
        } else if (onChange && !value) {
            onChange(e, '');
        }
    };

    return (
        <TextField
            label="Zip Code"
            {...props}
            onChange={handleChange}
            onGetErrorMessage={onGetErrorMessage}
            maxLength={10}
            value={state.value}
            validateOnFocusOut
            validateOnLoad={value ? true : false}
        />
    );
}
