import IPayer from 'api/models/payer.model';
import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addPayer, getPayer, getPayers, updatePayer } from 'state/slices/payers/payers.actions';
import {
    addNewPayer,
    cleanupSelectedPayer,
    loadingSelectedPayer,
    payersAsList,
    payersData,
    payersLoading,
    selectedPayer,
    showPayersHistory,
    toggleShowHistory,
    updateSelectedPayerField,
    payersState,
} from 'state/slices/payers/payers.slice';

function usePayers() {
    const _dispatch = useDispatch();
    const { loading, saving, isAdding } = useSelector(payersState);
    const _payersData = useSelector(payersData);
    const _payersAsList = useSelector(payersAsList);
    const _payersLoading = useSelector(payersLoading);
    const _loadingSelectedPayer = useSelector(loadingSelectedPayer);
    const _selectedPayer = useSelector(selectedPayer);
    const _showPayerHistory = useSelector(showPayersHistory);

    const _toggleShowPayerHistory = useCallback(() => {
        _dispatch(toggleShowHistory());
    }, [_dispatch]);

    const _cleanupSelectedPayer = useCallback(() => {
        _dispatch(cleanupSelectedPayer());
    }, [_dispatch]);

    const _updateSelectedPayerField = useCallback(
        (path: keyof IPayer, value: any) => {
            _dispatch(updateSelectedPayerField({ path, value }));
        },
        [_dispatch],
    );
    const _addNewPayer = useCallback(() => {
        _dispatch(addNewPayer());
    }, [_dispatch]);

    const _getPayers = useCallback(() => {
        _dispatch(getPayers());
    }, [_dispatch]);

    const _getPayer = useCallback(
        (payerId: string) => {
            _dispatch(getPayer({ payerId }));
        },
        [_dispatch],
    );

    const _updatePayer = useCallback(
        (payer: IPayer) => {
            _dispatch(updatePayer({ payer }));
        },
        [_dispatch],
    );

    const _addPayer = useCallback(
        (payer: IPayer) => {
            _dispatch(addPayer({ payer }));
        },
        [_dispatch],
    );

    return {
        // Selectors
        payersData: _payersData,
        payersAsList: _payersAsList,
        payersLoading: _payersLoading,
        loadingSelectedPayer: _loadingSelectedPayer,
        selectedPayer: _selectedPayer,
        showPayersHistory: _showPayerHistory,
        loading,
        saving,
        isAdding,

        // Async Actions
        getPayers: _getPayers,
        getPayer: _getPayer,
        updatePayer: _updatePayer,
        addPayer: _addPayer,

        //Actions
        toggleShowPayerHistory: _toggleShowPayerHistory,
        updateSelectedPayerField: _updateSelectedPayerField,
        cleanupSelectedPayer: _cleanupSelectedPayer,
        addNewPayer: _addNewPayer,
    };
}

export default usePayers;
