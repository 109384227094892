import React, { ReactNode } from 'react';
import { Stack } from '@fluentui/react';
import { ActivityBar, ActivityPage, ContentPane } from 'components';

type Props = {
    children: ReactNode;
};

const SidebarLayout = (props: Props) => {
    return (
        <ActivityPage title="" disableOverflow hideFooter>
            <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
                <ActivityBar />
                <ContentPane>{props.children}</ContentPane>
            </Stack>
        </ActivityPage>
    );
};

export default SidebarLayout;
