import { ITextFieldProps, TextField } from '@fluentui/react';
import { useState, useEffect, FormEvent } from 'react';

export type CurrentFieldProps = {
    value?: number;
    onChange?: (
        ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: number | undefined,
    ) => void;
} & Omit<ITextFieldProps, 'value' | 'onChange'>;

export default function CurrencyField(props: CurrentFieldProps): JSX.Element {
    const [fieldValue, setFieldValue] = useState<string>();
    const { value } = props;

    useEffect(() => {
        setFieldValue(value?.toString());
    }, [value]);

    const _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, val?: string) => {
        if ((val === undefined || val === '') && props.onChange) {
            props.onChange(ev, 0);
            return;
        }

        const value = val?.replace(/,/g, '');
        const isDecimalNumber =
            value !== undefined ? RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/).test(value) : false;
        const isDecimalGreaterThanTwo = value ? value.split('').reverse().indexOf('.') > 2 : false;

        if (isDecimalNumber && !isDecimalGreaterThanTwo) {
            const numericalValue = value !== undefined ? parseFloat(value) : 0;
            const isNumber = value !== undefined ? !isNaN(parseFloat(value)) : false;
            if (isNumber && props.onChange) {
                props.onChange(ev, numericalValue);
            }
            setFieldValue(value);
        }
    };

    function numberWithCommas(x?: string) {
        return x?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <TextField
            prefix="$"
            {...props}
            autoComplete="off"
            value={numberWithCommas(fieldValue)}
            onChange={_onChange}
        />
    );
}
