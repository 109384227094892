import { Permission, permissionsAsList } from 'hooks/usePermissions';
import { NoMatch, Overview, Tenants, Users, Products, CustomerSuccess, Reports, FeatureFlag } from '../views/pages';
import { RouteProps } from 'react-router';

export interface IExtendedRouteProps extends RouteProps {
    routes?: IExtendedRouteProps[];
    layout?: 'noSideBar' | 'sideBar';
    permissions?: Permission[];
}

const routes: IExtendedRouteProps[] = [
    {
        path: '/',
        component: Overview,
        exact: true,
        layout: 'noSideBar',
        permissions: permissionsAsList,
    },
    {
        path: '/feature-flags',
        component: FeatureFlag,
        layout: 'sideBar',
        permissions: [Permission.ManageFeature],
    },
    {
        path: '/tenants',
        component: Tenants,
        layout: 'sideBar',
        permissions: [
            Permission.TenantAbout,
            Permission.TenantBilling,
            Permission.TenantIntegration,
            Permission.TenantSubscriptions,
        ],
    },
    {
        path: '/customer-success',
        component: CustomerSuccess,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.ManageBenefitPlans, Permission.ManagePayer, Permission.ManageFPG, Permission.ManageDiagnoses],
    },
    {
        path: '/customer-success/:customerSuccessPage',
        component: CustomerSuccess,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.ManageBenefitPlans, Permission.ManagePayer, Permission.ManageFPG, Permission.ManageDiagnoses],
    },
    {
        path: '/users',
        component: Users,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.ManageUsers],
    },
    {
        path: '/products',
        component: Products,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.ManageProduct],
    },
    {
        path: '/reports',
        component: Reports,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.ManageReporting],
    },
    {
        path: '*',
        component: NoMatch,
        layout: 'noSideBar',
    },
];

export default routes;
