import { IDropdownOption } from '@fluentui/react';
import IBenefitPlan from 'api/models/benefitPlan.model';
import { map, orderBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const benefitPlanState = (state: RootState) => state.benefitPlans;

export const showBenefitPlanDataHistory = createSelector(benefitPlanState, (state) => state.showHistory);

export const benefitPlanData = createSelector(benefitPlanState, (state) => state.benefitPlans);

export const selectedBenefitPlan = (state: RootState): IBenefitPlan | undefined => state.benefitPlans.selectedBenefitPlan;
export const selectedBenefitPlanInsuranceClasses = createSelector(selectedBenefitPlan, (benefitPlan) => {
    return benefitPlan?.insuranceClasses ?? [];
});

export const selectedBenefitPlanCategories = createSelector(selectedBenefitPlan, (benefitPlan) => {
    return benefitPlan?.categories ?? [];
});

export const benefitPlanAsList = createSelector([benefitPlanData, showBenefitPlanDataHistory], (benefitPlanData, showHistory) => {
    const list = map(benefitPlanData, (p) => p) as IBenefitPlan[];
    return !showHistory ? list : list.filter((p) => !p?.isDeleted);
});

export const benefitPlanCategories = createSelector(benefitPlanAsList, (benefitPlans) => {
    if (benefitPlans && benefitPlans.length) {
        const options: IDropdownOption[] = orderBy(
            uniqBy(
                benefitPlans.map((benefitPlan) => ({
                    key: benefitPlan.id,
                    text: benefitPlan.displayName,
                })),
                'key',
            ),
            'key',
            'asc',
        );
        return options;
    } else {
        return [];
    }
});
