import IBatchHardClosed from "api/models/hardClosedHistory.model";
import IHardCloseBatchPayload from "api/models/hardClosePayload.model";
import platformApi from "api/platform.api";


const financialClosingApi = {
    getTenantHardCloseThroughDate,
    getTenantHardCloseHistory,
    hardCloseBatches,
    updateHardClose
}

function getTenantHardCloseThroughDate(tenantId: string) {
    return platformApi.client.get<string>(`Batch/HardCloseThroughDate?tenantId=${tenantId}`)
}

function getTenantHardCloseHistory(tenantId: string) {
    return platformApi.client.get<IBatchHardClosed>(`Batch/GetHardClose?tenantId=${tenantId}`)
}

function hardCloseBatches(tenantId: string, payload: IHardCloseBatchPayload) {
    return platformApi.client.post<IBatchHardClosed>(`Batch/HardClose?tenantId=${tenantId}`, payload)
}

function updateHardClose(tenantId: string, payload: IHardCloseBatchPayload) {
    return platformApi.client.put<IBatchHardClosed>(`Batch/HardClosed?tenantId=${tenantId}`, payload)
}

export default financialClosingApi
