import { Stack } from '@fluentui/react';
import { Section } from 'components';
import { useDispatch } from 'react-redux';

import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import { useEffect } from 'react';
import { getAllUserTasks, getUserTasksByTarget } from 'state/slices/task/actions';
import { UserTaskTarget } from 'state/slices/task/model';
import { selectTenantTasks } from 'state/slices/task/selectors';
import { TaskCard } from './TaskCard';
import { isDev } from 'api/platform.api';
import { HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';

const hubString = isDev ? `${process.env.REACT_APP_AppApiUri}/notifications` : `#{AppApiUri}#/notifications`;
const options: IHttpConnectionOptions = {};
const connection = new HubConnectionBuilder().withUrl(hubString, options).build();

export default function TaskBeacon() {
    const dispatch = useDispatch();
    const { selectTenant } = useTenant();

    const tasks = useSelector(selectTenantTasks);

    useEffect(() => {
        dispatch(getAllUserTasks());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        setTimeout(() => {
            connection
                .start()
                .then(() => {
                    const { connectionId } = connection;

                    // Connect to tenant group
                    connection
                        .invoke('AddConnectionToGroupAsync', connectionId, 'OnboardingBeacon')
                        .then(() => console.log('Connected to OnboardingBeacon task...'))
                        .catch((err) => console.error(err.toString()));

                    // Listen to tenant onboarding message
                    connection.on('UserTask', (data) => {
                        console.log('UserTask fired');
                        dispatch(getAllUserTasks());
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 150);

        return () => {
            connection.stop();
        };
    }, []);

    if (!tasks.length) return null;

    const taskCards = tasks.map((t) => <TaskCard key={t.id} {...t} />);

    return (
        <Section heading="Beacon">
            <Stack horizontal style={{ padding: 10 }} tokens={{ childrenGap: 10 }} wrap>
                {taskCards}
            </Stack>
        </Section>
    );
}
