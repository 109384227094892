import { useDispatch, useSelector } from 'react-redux';
import { selectUsersData, selectUsersLoading, showUsersHistory, usersState } from 'state/slices/users.selectors';

function useUsers() {
    const _dispatch = useDispatch();

    const { isAdding, selectedUser, showHistory } = useSelector(usersState);

    const _showUsersHistory = useSelector(showUsersHistory);
    const _selectUsersData = useSelector(selectUsersData);
    const _selectUsersLoading = useSelector(selectUsersLoading);

    return {
        isAdding,
        selectedUser,
        selectUsersData: _selectUsersData,
        selectUsersLoading: _selectUsersLoading,
        showUsersHistory: _showUsersHistory,
    };
}

export default useUsers;
