import { DefaultEffects, Label, Stack, useTheme } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { showIsUpcomingCustomer, upcomingTenants } from './state/tenants.selectors';
import { toggleShowUpcomingCustomer } from './state/tenants.slice';

function UpcomingGoLive() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const tenantList = useSelector(upcomingTenants);
    const showUpcomingCustomer = useSelector(showIsUpcomingCustomer);

    const boxStyles = {
        root: {
            background: theme.palette.yellow,
            width: showUpcomingCustomer ? 115 : 110,
            height: showUpcomingCustomer ? 115 : 100,
            color: '#FFF',
            border: showUpcomingCustomer ? '2px solid red' : '',

            selectors: { ':hover': { boxShadow: DefaultEffects.elevation8 } },
        },
    };
    const textStyles = {
        cursor: 'pointer',
        background: theme.palette.yellowDark,
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: showUpcomingCustomer ? '2px solid red' : '',
    };

    return (
        <Stack
            styles={boxStyles}
            horizontalAlign="center"
            onClick={() => {
                dispatch(toggleShowUpcomingCustomer());
            }}
        >
            <Stack grow style={{ fontSize: 48 }} verticalAlign="center">
                {<span>{tenantList.length}</span>}
            </Stack>
            <Stack horizontalAlign="center" style={textStyles}>
                <Label style={{ color: 'white', fontSize: 12 }}>Upcoming Go Lives</Label>
            </Stack>
        </Stack>
    );
}

export default UpcomingGoLive;
