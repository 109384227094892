import { AnyAction, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';
import { ActionReducerMapBuilder, configureStore } from '@reduxjs/toolkit';
import * as reducersFromSlices from './slices';
import { useDispatch } from 'react-redux';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
    ...reducersFromSlices,
    router: connectRouter(history),
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware(history)),
    devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export type ExtraReducer<State> = (builder: ActionReducerMapBuilder<State>) => ActionReducerMapBuilder<State>;
export type CreateAsyncThunkOptions<RejectValueType = string> = {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: RejectValueType;
};

export default store;
