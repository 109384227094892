import { IStackTokens, Stack } from '@fluentui/react';
import React from 'react';
import LiveCustomer from './LiveCustomer';
import UpcomingGoLive from './UpcomingGoLive';

function TenantTiles() {
    const stackTokens: IStackTokens = {
        childrenGap: 10,
    };

    return (
        <Stack wrap horizontal tokens={stackTokens}>
            <Stack.Item>
                <LiveCustomer />
            </Stack.Item>
            <Stack.Item>
                <UpcomingGoLive />
            </Stack.Item>
        </Stack>
    );
}

export default TenantTiles;
