import { ITheme, memoizeFunction, mergeStyleSets, Stack, Text, useTheme } from '@fluentui/react';

import { useHistory } from 'react-router-dom';
import { IUserTaskItem, UserTaskSeverity } from 'state/slices/task/model';

export function TaskCard({ note, severity, references }: IUserTaskItem): JSX.Element | null {
    const theme = useTheme();
    const { push } = useHistory();

    const { root, text } = getClasses(theme);

    const severityColors = {
        [UserTaskSeverity.Normal]: theme.palette.neutralTertiary,
        [UserTaskSeverity.Medium]: theme.semanticColors.warningIcon,
        [UserTaskSeverity.High]: theme.semanticColors.errorIcon,
    };

    const taskOnClick = () => {
        push(`/tenants/${references.tenantId}/dataConversion`);
    };

    const displayName = `${references.tenantName} (${references.tenantId})` as string;

    return (
        <Stack
            className={root}
            style={{ borderLeft: `5px solid ${severity ? severityColors[severity] : 'inherit'}` }}
            horizontalAlign="center"
            verticalAlign="center"
            onClick={taskOnClick}
        >
            <Stack verticalAlign="center" tokens={{ childrenGap: 5 }}>
                <Text variant="medium">{displayName}</Text>
                <Text className={text}>{note}</Text>
            </Stack>
        </Stack>
    );
}

const classNames = {
    root: 'card',
    text: 'card_text',
    icon: 'card_icon',
};

const getClasses = memoizeFunction((theme: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                width: 250,
                height: 100,
                padding: 25,
                background: theme.palette.white,
                border: `1px solid ${theme.palette.neutralTertiary}`,
                cursor: 'pointer',
                borderRadius: 3,
                transition: 'box-shadow .15s',
                [`:hover`]: {
                    background: theme.palette.white,
                    boxShadow: theme.effects.elevation16,
                },
            },
        ],
        text: [
            classNames.text,
            {
                fontSize: 12,
                color: theme.semanticColors.link,
                [`.${classNames.root}:hover &`]: {
                    color: theme.semanticColors.linkHovered,
                    textDecoration: 'underline',
                },
            },
        ],
    }),
);
