import { ScrollablePane, ScrollbarVisibility, Stack, StackItem, TextField } from '@fluentui/react';
import { Field, Section, SubSection } from 'components';
import states from 'components/Field/StateOptions';
import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import { getValidationError } from 'hooks/useValidation';
import { selectedTenantValidationError } from 'views/pages/Tenants/state/tenants.selectors';

export enum BillingAddressType {
    PhysicalStreet1 = 'Physical Street 1',
    PhysicalCity = 'Physical City',
    PhysicalState = 'Physical State',
    PhysicalZip = 'Physical Zip',
    StatementRemitStreet1 = 'Statement Remit Street 1',
    StatementRemitCity = 'Statement Remit City',
    StatementRemitState = 'Statement Remit State',
    StatementRemitZip = 'Statement Remit Zip',
    InsuranceStreet1 = 'Insurance Street 1',
    InsuranceCity = 'Insurance City',
    InsuranceState = 'Insurance State',
    InsuranceZip = 'Insurance Zip',
}

export default function Billing() {
    const {
        tenantBillingData,

        updateSelectedBillingTenantsProp,
        updateSelectedPayToAddressTenantsProp,
        updateSelectedAddressTenantsProp,
        updateSelectedStatementAddressTenantsProp,
    } = useTenant();

    const validationError = useSelector(selectedTenantValidationError);

    const validNumberInput = (value?: string) => {
        const regex = value ? /^[0-9\b]+$/.test(value) : true;
        return regex;
    };

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z\s-']*$/.test(value) : true;
        return res;
    };

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Section heading=" Tenant Billing Information">
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        <Stack tokens={{ childrenGap: 10 }} grow horizontal>
                            <StackItem grow>
                                <TextField
                                    label="Checks payable to"
                                    value={tenantBillingData?.name ?? ''}
                                    onChange={(ev, value) => updateSelectedBillingTenantsProp('name', value)}
                                />
                            </StackItem>
                            <StackItem grow>
                                <TextField
                                    label="NPI"
                                    value={tenantBillingData?.npi ?? ''}
                                    onChange={(ev, value) => updateSelectedBillingTenantsProp('npi', value)}
                                />
                            </StackItem>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }} grow horizontal>
                            <StackItem grow>
                                <TextField
                                    label="License Number"
                                    value={tenantBillingData?.licenseNumber ?? ''}
                                    onChange={(ev, value) => updateSelectedBillingTenantsProp('licenseNumber', value)}
                                />
                            </StackItem>
                            <StackItem grow>
                                <TextField
                                    label="TIN"
                                    value={tenantBillingData?.tin ?? ''}
                                    maxLength={200}
                                    onChange={(ev, value) => {
                                        if (validNumberInput(value)) updateSelectedBillingTenantsProp('tin', value);
                                    }}
                                />
                            </StackItem>
                        </Stack>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <StackItem grow>
                                <Field.Phone
                                    label="Phone"
                                    value={tenantBillingData?.phone ?? ''}
                                    onChange={(ev, value) => {
                                        updateSelectedBillingTenantsProp('phone', value);
                                    }}
                                />
                            </StackItem>
                            <StackItem grow>
                                <TextField
                                    label="Payment Url"
                                    value={tenantBillingData?.paymentUrl}
                                    onChange={(ev, value) => updateSelectedBillingTenantsProp('paymentUrl', value)}
                                />
                            </StackItem>
                        </Stack>

                        <Stack tokens={{ childrenGap: 10 }}>
                            <SubSection title="Physical Address">
                                <Stack tokens={{ childrenGap: 10 }} grow>
                                    <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                                        <Stack.Item grow>
                                            <TextField
                                                label="Address 1"
                                                value={tenantBillingData?.address?.street1 ?? ''}
                                                autoComplete="off"
                                                onChange={(ev, value) => updateSelectedAddressTenantsProp('street1', value)}
                                                errorMessage={
                                                    getValidationError(validationError, BillingAddressType.PhysicalStreet1)
                                                        ? ' Physical address 1 is required'
                                                        : undefined
                                                }
                                            />
                                        </Stack.Item>
                                        <Stack.Item grow>
                                            <TextField
                                                label="Address 2"
                                                value={tenantBillingData?.address?.street2 ?? ''}
                                                autoComplete="off"
                                                onChange={(ev, value) => updateSelectedAddressTenantsProp('street2', value)}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                                        <Stack.Item grow>
                                            <TextField
                                                label="City"
                                                placeholder=""
                                                value={tenantBillingData?.address?.city ?? ''}
                                                autoComplete="off"
                                                onChange={(ev, value) => {
                                                    if (validTextInput(value)) {
                                                        updateSelectedAddressTenantsProp('city', value);
                                                    }
                                                }}
                                                errorMessage={
                                                    getValidationError(validationError, BillingAddressType.PhysicalCity)
                                                        ? ' Physical city is required'
                                                        : undefined
                                                }
                                            />
                                        </Stack.Item>
                                        <Field.SearchCombo
                                            label="State"
                                            placeholder="(Select State)"
                                            onChange={(e, option) => {
                                                const value = option ? (option.key as string).toUpperCase() : '';
                                                updateSelectedAddressTenantsProp('state', value);
                                            }}
                                            selectedKey={
                                                tenantBillingData?.address?.state
                                                    ? tenantBillingData?.address?.state.toUpperCase()
                                                    : undefined
                                            }
                                            options={states}
                                            errorMessage={
                                                getValidationError(validationError, BillingAddressType.PhysicalState)
                                                    ? ' Physical State is required'
                                                    : undefined
                                            }
                                        />
                                        <Field.ZipCode
                                            label="Zip"
                                            value={tenantBillingData?.address?.zip ?? ''}
                                            autoComplete="off"
                                            onChange={(ev, value) => updateSelectedAddressTenantsProp('zip', value)}
                                            errorMessage={
                                                getValidationError(validationError, BillingAddressType.PhysicalZip)
                                                    ? ' Physical Zip is required'
                                                    : undefined
                                            }
                                        />
                                    </Stack>
                                </Stack>
                            </SubSection>

                            <SubSection title="Statement Remit Address">
                                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                                    <Stack.Item grow>
                                        <TextField
                                            label="Address 1"
                                            value={tenantBillingData?.statementAddress?.street1 ?? ''}
                                            autoComplete="off"
                                            onChange={(ev, value) => updateSelectedStatementAddressTenantsProp('street1', value)}
                                            errorMessage={
                                                getValidationError(validationError, BillingAddressType.StatementRemitStreet1)
                                                    ? ' Statement address 1 is required'
                                                    : undefined
                                            }
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <TextField
                                            label="Address 2"
                                            value={tenantBillingData?.statementAddress?.street2 ?? ''}
                                            autoComplete="off"
                                            onChange={(ev, value) => updateSelectedStatementAddressTenantsProp('street2', value)}
                                        />
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                                    <Stack.Item grow>
                                        <TextField
                                            label="City"
                                            placeholder=""
                                            value={tenantBillingData?.statementAddress?.city ?? ''}
                                            autoComplete="off"
                                            onChange={(ev, value) => {
                                                if (validTextInput(value))
                                                    updateSelectedStatementAddressTenantsProp('city', value);
                                            }}
                                            errorMessage={
                                                getValidationError(validationError, BillingAddressType.StatementRemitCity)
                                                    ? ' Statement city is required'
                                                    : undefined
                                            }
                                        />
                                    </Stack.Item>
                                    <Field.SearchCombo
                                        label="State"
                                        placeholder="(Select State)"
                                        onChange={(e, option) => {
                                            const value = option ? (option.key as string).toUpperCase() : '';
                                            updateSelectedStatementAddressTenantsProp('state', value);
                                        }}
                                        selectedKey={
                                            tenantBillingData?.statementAddress?.state
                                                ? tenantBillingData?.statementAddress?.state.toUpperCase()
                                                : undefined
                                        }
                                        options={states}
                                        errorMessage={
                                            getValidationError(validationError, BillingAddressType.StatementRemitState)
                                                ? ' Statement State is required'
                                                : undefined
                                        }
                                    />
                                    <Field.ZipCode
                                        label="Zip"
                                        value={tenantBillingData?.statementAddress?.zip ?? ''}
                                        autoComplete="off"
                                        onChange={(ev, value) => updateSelectedStatementAddressTenantsProp('zip', value)}
                                        errorMessage={
                                            getValidationError(validationError, BillingAddressType.StatementRemitZip)
                                                ? ' Statement Zip is required'
                                                : undefined
                                        }
                                    />
                                </Stack>
                            </SubSection>

                            <SubSection title="Insurance Address">
                                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                                    <Stack.Item grow>
                                        <TextField
                                            label="Address Line 1"
                                            value={tenantBillingData?.payToAddress?.street1 ?? ''}
                                            autoComplete="off"
                                            onChange={(ev, value) => updateSelectedPayToAddressTenantsProp('street1', value)}
                                            errorMessage={
                                                getValidationError(validationError, BillingAddressType.InsuranceStreet1)
                                                    ? ' Insurance street 1 is required'
                                                    : undefined
                                            }
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <TextField
                                            label="Address Line 2"
                                            value={tenantBillingData?.payToAddress?.street2 ?? ''}
                                            autoComplete="off"
                                            onChange={(ev, value) => updateSelectedPayToAddressTenantsProp('street2', value)}
                                        />
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                                    <Stack.Item grow>
                                        <TextField
                                            label="City"
                                            placeholder=""
                                            value={tenantBillingData?.payToAddress?.city ?? ''}
                                            autoComplete="off"
                                            onChange={(ev, value) => {
                                                if (validTextInput(value)) updateSelectedPayToAddressTenantsProp('city', value);
                                            }}
                                            errorMessage={
                                                getValidationError(validationError, BillingAddressType.InsuranceCity)
                                                    ? ' Insurance city is required'
                                                    : undefined
                                            }
                                        />
                                    </Stack.Item>
                                    <Field.SearchCombo
                                        label="State"
                                        placeholder="(Select State)"
                                        onChange={(e, option) => {
                                            const value = option ? (option.key as string).toUpperCase() : '';
                                            updateSelectedPayToAddressTenantsProp('state', value);
                                        }}
                                        selectedKey={
                                            tenantBillingData?.payToAddress?.state
                                                ? tenantBillingData?.payToAddress?.state.toUpperCase()
                                                : undefined
                                        }
                                        options={states}
                                        errorMessage={
                                            getValidationError(validationError, BillingAddressType.InsuranceState)
                                                ? ' Insurance State is required'
                                                : undefined
                                        }
                                    />
                                    <Field.ZipCode
                                        label="Zip"
                                        value={tenantBillingData?.payToAddress?.zip ?? ''}
                                        autoComplete="off"
                                        onChange={(ev, value) => updateSelectedPayToAddressTenantsProp('zip', value)}
                                        errorMessage={
                                            getValidationError(validationError, BillingAddressType.InsuranceZip)
                                                ? ' Insurance Zip is required'
                                                : undefined
                                        }
                                    />
                                </Stack>
                            </SubSection>
                        </Stack>
                    </Stack>
                </Section>
            </ScrollablePane>
        </Stack>
    );
}
