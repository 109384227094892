import { DirectionalHint, Icon, IIconStyleProps, IIconStyles, keyframes, memoizeFunction, TooltipHost } from '@fluentui/react';
import { DeepPartial } from '@fluentui/merge-styles';

export enum SyncStatus {
    Idle = 'idle',
    Pending = 'pending',
    Completed = 'completed',
    Failed = 'failed',
}

export type SyncStatuses = typeof SyncStatus[keyof typeof SyncStatus];

const _saveStatucIconStyles = memoizeFunction(
    ({ theme, saveStatus }: IIconStyleProps & { saveStatus: SyncStatuses }): DeepPartial<IIconStyles> => {
        if (!theme) return {};
        const _iconColor = () => {
            switch (saveStatus) {
                case SyncStatus.Pending: {
                    return theme.semanticColors.menuIcon;
                }
                case SyncStatus.Failed: {
                    return theme.semanticColors.errorIcon;
                }
                case SyncStatus.Completed: {
                    return theme.semanticColors.successIcon;
                }
                default:
                    return theme.semanticColors.warningIcon;
            }
        };
        const rotation = keyframes({
            from: {
                transform: 'rotate(0deg)',
            },
            to: {
                transform: 'rotate(359deg)',
            },
        });
        const _saving = saveStatus === SyncStatus.Pending;
        return {
            root: {
                color: _iconColor(),
                fontSize: 20,
                animation: _saving ? `${rotation} 1.5s infinite` : '',
                ':hover': {
                    pointer: 'default',
                },
            },
        };
    },
);

export default function SaveStatusIcon({
    saveStatus,
    itemName,
    style,
}: {
    saveStatus: SyncStatus;
    itemName?: string;
    style?: React.CSSProperties;
}): JSX.Element | null {
    const _icon = () => {
        switch (saveStatus) {
            case SyncStatus.Pending: {
                return 'SyncStatusSolid';
            }
            case SyncStatus.Failed: {
                return 'StatusErrorFull';
            }
            default:
                return 'SkypeCircleCheck';
        }
    };
    const _tooltipContent = () => {
        switch (saveStatus) {
            case SyncStatus.Pending: {
                return `Syncing${itemName ? ` ${itemName.toLowerCase()}` : ''}...`;
            }
            case SyncStatus.Failed: {
                return `Failed to save${itemName ? ` ${itemName.toLowerCase()}` : ''}.`;
            }
            default:
                return itemName ? `${itemName} saved` : 'Saved';
        }
    };

    const icon = _icon();

    if (!icon) return null;

    return (
        <TooltipHost content={_tooltipContent()} directionalHint={DirectionalHint.rightCenter}>
            <Icon iconName={icon} style={style} styles={(props) => _saveStatucIconStyles({ ...props, saveStatus: saveStatus })} />
        </TooltipHost>
    );
}
