import platformApi from 'api/platform.api';
import { IUserTaskItem, UserTaskTarget } from './model';

export const tasksApi = {
    getByTarget,
    getUserTask,
};

function getUserTask() {
    return platformApi.client.get<IUserTaskItem[]>('UserTasks');
}

function getByTarget({ tenantId, target }: GetTasksByTargetRequest) {
    return platformApi.client.get<IUserTaskItem[]>(`UserTasks/Tenants/${tenantId}/Targets/${target}`);
}

export type GetTasksByTargetRequest = {
    tenantId: string;
    target: UserTaskTarget;
};
