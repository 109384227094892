import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk, CreateAsyncThunkOptions } from 'state/store';
import { tasksApi } from './api';
import { IUserTaskItemList, UserTaskTarget } from './model';

export const getAllUserTasks = createAsyncThunk<IUserTaskItemList, undefined, CreateAsyncThunkOptions>(
    'tasks/getAllUserTasks',
    async (_, { rejectWithValue }) => {
        try {
            const { data: userTasks } = await tasksApi.getUserTask();
            return userTasks;
        } catch (error) {
            return rejectWithValue(JSON.stringify(error));
        }
    },
);

export const getUserTasksByTarget = createAsyncThunk<IUserTaskItemList, { tenantId: string; target: UserTaskTarget }>(
    'tasks/getUserTasksByTarget',
    async ({ tenantId, target }, { rejectWithValue }) => {
        try {
            const { data: userTasks } = await tasksApi.getByTarget({ tenantId, target });
            return userTasks;
        } catch (error) {
            return rejectWithValue(JSON.stringify(error));
        }
    },
);

export const getTenantUserTasksByTarget = (): AppThunk<void> => (dispatch, getState) => {
    const tenantId = getState().tenants.selectTenant?.id;

    if (tenantId) dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
};
