import { PropsWithChildren } from 'react';
import '@fluentui/react/dist/sass/Fabric.scss';
import { initializeIcons, ThemeProvider } from '@fluentui/react';
import { useSelector } from 'hooks';
import { getSelectedTheme } from 'state/slices/ui.slice';

initializeIcons();

const ThemeInitializer = (props: PropsWithChildren<any>): JSX.Element => {
    const theme = useSelector(getSelectedTheme);

    return (
        <ThemeProvider
            theme={theme}
            style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        >
            {props.children}
        </ThemeProvider>
    );
};

export default ThemeInitializer;
