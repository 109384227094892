import React, { useRef, useEffect, useState } from 'react';
import { Switch, RouteProps } from 'react-router-dom';
import { TooltipCards } from '../component';
import { NavBar } from 'components';
import './App.scss';
import routes from 'routes';
import { RouteWrapper } from 'routes/RouteWrapper';
import AccountInitializer from 'AccountInitializer';

const App: React.FC = () => {
    const routeList = routes.map((route: RouteProps, index: number) => <RouteWrapper key={index} {...route} />);
    const tooltipRef = useRef(null);
    const [tooltipNode, setTooltipNode] = useState(null);

    useEffect(() => {
        setTooltipNode(tooltipRef.current);
    }, []);

    return (
        <AccountInitializer>
            <NavBar appName="Platform Management" appNameLink="/"></NavBar>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    position: 'relative',
                }}
            >
                <Switch>{routeList}</Switch>
                <div style={{ position: 'absolute', right: 15, bottom: 3 }} ref={tooltipRef} />
                {tooltipNode ? <TooltipCards domNode={tooltipNode} /> : null}
            </div>
        </AccountInitializer>
    );
};

export default App;
