import { DefaultButton, Stack, StackItem, Text } from '@fluentui/react';
import { SubscriptionType } from 'api/models/subscribeToChangesPayload.model';
import { Section } from 'components';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventingChangesetCard from '../EventingChangesetCard';
import EventingChangesetsActiveIndicator from '../EventingChangsetsActiveIndicator';
import { EventingChangesetAction } from '../state/EventingChangsetsState/eventingChangesets.state';
import { selectTenantConfigView } from '../state/tenants.selectors';
import { setSubscribeToChangesModalOpen } from '../state/tenants.slice';

export default function Eventing() {
    const config = useSelector(selectTenantConfigView);
    const dispatch = useDispatch();
    const hasChangeSetList: { displayName: string; changesetActive: boolean }[] = useMemo(
        () => [{ displayName: 'Patients', changesetActive: !!config?.hasPatientEventing }],
        [config],
    );


    const changesetsActive = hasChangeSetList.some((item) => item.changesetActive);

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Section
                style={{
                    marginBottom: 0,
                }}
                headingRightContent={
                    <>
                        <DefaultButton
                            text="Unsubscribe from Events"
                            onClick={() => {
                                dispatch(
                                    setSubscribeToChangesModalOpen({
                                        action: EventingChangesetAction.Unsubscribe,
                                        type: SubscriptionType.Events,
                                    }),
                                );
                            }}
                        />
                        <DefaultButton
                            onClick={() => {
                                dispatch(
                                    setSubscribeToChangesModalOpen({
                                        action: EventingChangesetAction.Subscribe,
                                        type: SubscriptionType.Events,
                                    }),
                                );
                            }}
                            text="Subscribe to Events"
                        />
                    </>
                }
                heading={
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <StackItem>
                            <Text variant="large" block>
                                Eventing
                            </Text>
                        </StackItem>
                        <EventingChangesetsActiveIndicator changesetsActive={changesetsActive} />
                    </Stack>
                }
            ></Section>
            <Stack tokens={{ childrenGap: 10 }} horizontal wrap>
                {hasChangeSetList.map((changeSetData, index) => (
                    <EventingChangesetCard key={`${changeSetData.displayName}-${index}`} type="Eventing" {...changeSetData} />
                ))}
            </Stack>
        </Stack>
    );
}
