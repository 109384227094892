import IBenefitPlan, { IInsuranceClass } from 'api/models/benefitPlan.model';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    benefitPlanAsList,
    benefitPlanData,
    benefitPlanState,
    showBenefitPlanDataHistory,
    benefitPlanCategories,
} from 'state/slices/benefit-plan/benefit-plan.selectors';
import {
    addBenefitPlan,
    cleanupSelectedBenefitPlan,
    getBenefitPlanById,
    getBenefitPlans,
    getProcedureCategories,
    setBenefitPlanPropandSave,
    toggleShowBenefitPlanHistory,
    updateBenefitPlan,
    updateSelectedBenefitPlanPercentageProp,
    updateSelectedBenefitPlanProp,
} from 'state/slices/benefit-plan/benefit-plan.slice';

function useBenefitPlan() {
    const _dispatch = useDispatch();
    const { loading, loadingBenefitPlan, saving, isAdding, selectedBenefitPlan } =
        useSelector(benefitPlanState);

    const _showBenefitPlanHistory = useSelector(showBenefitPlanDataHistory);
    const _benefitPlanData = useSelector(benefitPlanData);
    const _benefitPlanAsList = useSelector(benefitPlanAsList);
    const _benefitPlanCategories = useSelector(benefitPlanCategories);

    const _getBenefitPlans = useCallback(() => {
        _dispatch(getBenefitPlans());
    }, [_dispatch]);

    const _getProcedureCategories = useCallback(() => {
        _dispatch(getProcedureCategories());
    }, [_dispatch]);

    const _getBenefitPlanById = useCallback(
        (benefitPlanId: string) => {
            _dispatch(getBenefitPlanById({ benefitPlanId }));
        },
        [_dispatch],
    );

    const _cleanupSelectedBenefitPlan = useCallback(() => {
        _dispatch(cleanupSelectedBenefitPlan());
    }, [_dispatch]);

    const _toggleShowBenefitPlanHistory = useCallback(() => {
        _dispatch(toggleShowBenefitPlanHistory());
    }, [_dispatch]);

    const _addBenefitPlan = useCallback(
        (benefitPlan: IBenefitPlan) => {
            _dispatch(addBenefitPlan({ benefitPlan }));
        },
        [_dispatch],
    );

    const _setBenefitPlanPropandSave = useCallback(
        (id: string, path: keyof IBenefitPlan, value: any) => {
            _dispatch(setBenefitPlanPropandSave(id, path, value));
        },
        [_dispatch],
    );

    const _updateSelectedBenefitPlanProp = useCallback(
        (path: keyof IBenefitPlan, value: any) => {
            _dispatch(updateSelectedBenefitPlanProp({ path, value }));
        },
        [_dispatch],
    );

    const _updateSelectedBenefitPlanPercentageProp = useCallback(
        (id: string, path: keyof IInsuranceClass, value: number) => {
            _dispatch(updateSelectedBenefitPlanPercentageProp({ id, path, value }));
        },
        [_dispatch],
    );

    const _updateBenefitPlan = useCallback(
        (benefitPlan: IBenefitPlan) => {
            _dispatch(updateBenefitPlan({ benefitPlan }));
        },
        [_dispatch],
    );

    return {
        //Selectors
        loading,
        loadingBenefitPlan,
        saving,
        isAdding,
        selectedBenefitPlan,
        showBenefitPlanHistory: _showBenefitPlanHistory,
        benefitPlanData: _benefitPlanData,
        benefitPlanAsList: _benefitPlanAsList,
        benefitPlanCategories: _benefitPlanCategories,

        // Async Actions
        getBenefitPlans: _getBenefitPlans,
        getBenefitPlanById: _getBenefitPlanById,
        getProcedureCategories: _getProcedureCategories,
        addBenefitPlan: _addBenefitPlan,
        setBenefitPlanPropandSave: _setBenefitPlanPropandSave,
        updateBenefitPlan: _updateBenefitPlan,

        // Actions
        cleanupSelectedBenefitPlan: _cleanupSelectedBenefitPlan,
        toggleShowBenefitPlanHistory: _toggleShowBenefitPlanHistory,
        updateSelectedBenefitPlanProp: _updateSelectedBenefitPlanProp,
        updateSelectedBenefitPlanPercentageProp: _updateSelectedBenefitPlanPercentageProp,
    };
}

export default useBenefitPlan;
