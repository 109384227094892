import { createAsyncThunk, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { PlatformApi } from 'api';
import IPovertyGuideline, {
    IFederalPovertyRegions,
    IPovertyIcomeLevel,
    PovertyLocation,
} from 'api/models/povertyGuidline.model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import ErrorTypes from 'state/errorTypes';
import { v4 as uuid } from 'uuid';

const initialState: PovertyGuidlinesState = {
    saving: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    loadingSelectedPovertyGuideline: LoadingStatus.Idle,
};

export const getPovertyGuidelines = createAsyncThunk<
    Dictionary<IPovertyGuideline>,
    undefined,
    { rejectValue: string }
>('povertyGuidlines/getPovertyGuidelines', async (_, { rejectWithValue }) => {
    try {
        const { data: guidlines } = await PlatformApi.getAllPovertyGuidelines();
        return guidlines;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getPovertyGuidelineById = createAsyncThunk<
    IPovertyGuideline,
    { id: string },
    { rejectValue: string }
>('povertyGuidlines/getPovertyGuidelineById', async ({ id }, { rejectWithValue }) => {
    try {
        const { data: guidlines } = await PlatformApi.getPovertyGuidelineById(id);
        return guidlines;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const updatePovertyGuideline = createAsyncThunk<
    IPovertyGuideline,
    { model: IPovertyGuideline },
    { rejectValue: string }
>('povertyGuidlines/updatePovertyGuideline', async ({ model }, { rejectWithValue }) => {
    try {
        const { data: guidlines } = await PlatformApi.updatePovertyGuideline(model);
        return guidlines;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const createPovertyGuideline = createAsyncThunk<
    IPovertyGuideline,
    { model: IPovertyGuideline },
    { rejectValue: string }
>('povertyGuidlines/createPovertyGuideline', async ({ model }, { rejectWithValue }) => {
    try {
        const { data: guidlines } = await PlatformApi.createPovertyGuideline(model);
        return guidlines;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getFederalPovertyRegions = createAsyncThunk<
    Dictionary<IFederalPovertyRegions>,
    undefined,
    { rejectValue: string }
>('povertyGuidlines/getFederalPovertyRegions', async (_, { rejectWithValue }) => {
    try {
        const { data: regions } = await PlatformApi.getFederalPovertyRegions();
        return regions;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

const povertyGuidelinesSlice = createSlice({
    name: 'povertyGuidelines',
    initialState,
    reducers: {
        cleanupSelectedPovertyGuidelinesData: (state) => {
            state.selectedLocation = undefined;
            state.selectedPovertyGuildeline = undefined;
            state.isAdding = false;
        },
        setSelectedLocation: (state, action: PayloadAction<PovertyLocation>) => {
            state.selectedLocation = action.payload;
        },
        updateSelectedGuidelineProp: (
            state,
            action: PayloadAction<{ path: keyof IPovertyGuideline; value: any }>,
        ) => {
            const { path, value } = action.payload;
            if (state.selectedPovertyGuildeline)
                (state.selectedPovertyGuildeline as any)[path] = value;
        },
        updateIncomeLevel: (
            state,
            action: PayloadAction<{ index: number; path: keyof IPovertyIcomeLevel; value: number }>,
        ) => {
            if (state.selectedPovertyGuildeline?.incomeLevels?.length) {
                const { index, path, value } = action.payload;
                if (value > 0 || value < 9)
                    state.selectedPovertyGuildeline.incomeLevels[index][path] = value;
            }
        },
        addGuidelineYear: (state) => {
            if (state.selectedLocation) {
                state.isAdding = true;
                state.selectedPovertyGuildeline = {
                    id: uuid(),
                    displayName: '',
                    year: '',
                    effectiveDate: '',
                    endDate: '',
                    isDeleted: false,
                    location: state.selectedLocation,
                    incomeLevels: [],
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPovertyGuidelines.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getPovertyGuidelines.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getPovertyGuidelines.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(updatePovertyGuideline.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updatePovertyGuideline.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                if (state.data) state.data[action.payload.id] = action.payload;
                if (state.selectedPovertyGuildeline)
                    state.selectedPovertyGuildeline = action.payload;

                state.selectedLocation = undefined;
                state.selectedPovertyGuildeline = undefined;
            })
            .addCase(updatePovertyGuideline.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(createPovertyGuideline.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(createPovertyGuideline.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                if (state.data) state.data[action.payload.id] = action.payload;
                if (state.selectedPovertyGuildeline)
                    state.selectedPovertyGuildeline = action.payload;
                state.isAdding = false;
            })
            .addCase(createPovertyGuideline.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(getPovertyGuidelineById.pending, (state) => {
                state.loadingSelectedPovertyGuideline = LoadingStatus.Pending;
            })
            .addCase(getPovertyGuidelineById.fulfilled, (state, action) => {
                state.loadingSelectedPovertyGuideline = LoadingStatus.Completed;
                state.selectedPovertyGuildeline = action.payload;
                if (state.data) state.data[action.payload.id] = action.payload;
                state.isAdding = false;
            })
            .addCase(getPovertyGuidelineById.rejected, (state) => {
                state.loadingSelectedPovertyGuideline = LoadingStatus.Failed;
            })
            .addCase(getFederalPovertyRegions.pending, (state) => {
                state.loadingFederalPovertyRegions = LoadingStatus.Pending;
            })
            .addCase(getFederalPovertyRegions.fulfilled, (state, action) => {
                state.loadingFederalPovertyRegions = LoadingStatus.Completed;
                state.federalPovertyRegions = action.payload;
            })
            .addCase(getFederalPovertyRegions.rejected, (state) => {
                state.loadingFederalPovertyRegions = LoadingStatus.Failed;
            });
    },
});

export const {
    setSelectedLocation,
    cleanupSelectedPovertyGuidelinesData,
    updateSelectedGuidelineProp,
    addGuidelineYear,
    updateIncomeLevel,
} = povertyGuidelinesSlice.actions;

export default povertyGuidelinesSlice.reducer;

type PovertyGuidlinesState = {
    data?: Dictionary<IPovertyGuideline>;
    saving: LoadingStatus;
    loading: LoadingStatus;
    loadingSelectedPovertyGuideline: LoadingStatus;
    isAdding?: boolean;
    selectedLocation?: PovertyLocation;
    selectedPovertyGuildeline?: IPovertyGuideline;
    federalPovertyRegions?: Dictionary<IFederalPovertyRegions>;
    loadingFederalPovertyRegions?: LoadingStatus;
};
