import { LoadingStatus } from 'interfaces/loadingStatus';
import { ITenantOnboarding } from '../../state/tenants.model';

export type OnboardingState = {
    selectTenantOnboarding?: ITenantOnboarding;
    loadingTenantOnboarding: LoadingStatus;
    tenantOnboarding?: ITenantOnboarding;
    selectTenantOnboardingStep?: ITenantOnboarding;
    verifyTenantOnboarding?: LoadingStatus;
};

export const onboardingInitialState: OnboardingState = {
    loadingTenantOnboarding: LoadingStatus.Idle,
    verifyTenantOnboarding: LoadingStatus.Idle,
};
