import { AnyAction, createAsyncThunk, createSlice, Dictionary, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PlatformApi } from 'api';
import IBenefitPlan, { IInsuranceClass, IProcedureCategory } from 'api/models/benefitPlan.model';
import axios from 'axios';
import { LoadingStatus } from 'interfaces/loadingStatus';
import ErrorTypes from 'state/errorTypes';
import { v4 as uuid } from 'uuid';
import { RootState } from 'state/store';
import { cloneDeep, isEqual } from 'lodash';

const initialState: BenefitPlansState = {
    benefitPlans: {},
    procedureCategories: undefined,
    saving: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    loadingBenefitPlan: LoadingStatus.Idle,
    showHistory: true,
    isAdding: false,
};

export const getBenefitPlans = createAsyncThunk<Dictionary<IBenefitPlan>, undefined, { rejectValue: string }>(
    'benefitPlan/getBenefitPlans',
    async (_, { rejectWithValue }) => {
        try {
            const { data: benefitPlans } = await PlatformApi.getAllBenefitPlans();
            return benefitPlans;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);
export const getDefaultBenefitPlans = createAsyncThunk<IBenefitPlan, undefined, { rejectValue: string }>(
    'benefitPlan/getDefaultBenefitPlans',
    async (_, { rejectWithValue }) => {
        try {
            const { data: benefitPlans } = await PlatformApi.getDefaultBenefitPlans();
            return benefitPlans;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getBenefitPlanById = createAsyncThunk<IBenefitPlan, { benefitPlanId: string }, { rejectValue: string }>(
    'benefitPlan/getBenefitPlansbyID',
    async ({ benefitPlanId }, { rejectWithValue }) => {
        try {
            const { data: benefitPlan } = await PlatformApi.getBenefitPlansById(benefitPlanId);
            return benefitPlan;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);
export const updateBenefitPlan = createAsyncThunk<IBenefitPlan, { benefitPlan: IBenefitPlan }, { rejectValue: string }>(
    'benefitPlan/updateBenefitPlan',
    async ({ benefitPlan }, { rejectWithValue }) => {
        try {
            const { data: updateBenefitPlan } = await PlatformApi.updateBenefitPlans(benefitPlan);
            return updateBenefitPlan;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);
export const updateBenefitPlans = createAsyncThunk<IBenefitPlan[], { benefitPlans: IBenefitPlan[] }, { rejectValue: string }>(
    'benefitPlan/updateBenefitPlans',
    async ({ benefitPlans }, { rejectWithValue }) => {
        try {
            const requests = benefitPlans.map((benefitPlan) => PlatformApi.updateBenefitPlans(benefitPlan));
            const responses = await axios.all(requests);
            return responses.map((r) => r.data);
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const addBenefitPlan = createAsyncThunk<IBenefitPlan, { benefitPlan: IBenefitPlan }, { rejectValue: string }>(
    'benefitPlan/addBenefitPlan',
    async ({ benefitPlan }, { rejectWithValue }) => {
        try {
            const { data: addBenefitPlan } = await PlatformApi.createBenefitPlans(benefitPlan);
            return addBenefitPlan;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

let timer: NodeJS.Timeout | null = null;
let benefitPlanToUpdate: IBenefitPlan[] = [];

export const autoUpdatebenefitPlan =
    (benefitPlan: IBenefitPlan) =>
    (dispatch: ThunkDispatch<RootState, null, AnyAction>): void => {
        const indexOfBenfitPlan = benefitPlanToUpdate.findIndex((p) => p.id === benefitPlan.id);
        if (indexOfBenfitPlan === -1) {
            benefitPlanToUpdate.push(benefitPlan);
        } else {
            benefitPlanToUpdate[indexOfBenfitPlan] = benefitPlan;
        }
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (benefitPlanToUpdate.length) {
                dispatch(updateBenefitPlans({ benefitPlans: benefitPlanToUpdate }));
                benefitPlanToUpdate = [];
            }
        }, 2000);
    };

export const setBenefitPlanPropandSave =
    (id: string, path: keyof IBenefitPlan, value: string | number | boolean | undefined) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): Promise<void> => {
        await dispatch(updateBenefitPlansProp({ id, path, value }));
        const benefitPlan = getState().benefitPlans.benefitPlans[id];
        if (benefitPlan) {
            dispatch(autoUpdatebenefitPlan(benefitPlan));
        }
    };

export const getProcedureCategories = createAsyncThunk<IProcedureCategory, undefined, { rejectValue: string }>(
    'procedureCategories/getProcedureCategories',
    async (_, { rejectWithValue }) => {
        try {
            const { data: procedureCategories } = await PlatformApi.getProcedureCategories();
            return procedureCategories;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

const benefitPlans = createSlice({
    name: 'benefitPlans',
    initialState,
    reducers: {
        cleanupSelectedBenefitPlan: (state: BenefitPlansState) => {
            state.selectedBenefitPlan = undefined;
            state.isAdding = false;
        },
        toggleShowBenefitPlanHistory: (state: BenefitPlansState) => {
            state.showHistory = !state.showHistory;
        },

        updateSelectedBenefitPlanProp: (
            state: BenefitPlansState,
            action: PayloadAction<{ path: keyof IBenefitPlan; value: any }>,
        ) => {
            const { path, value } = action.payload;
            if (state.selectedBenefitPlan) (state.selectedBenefitPlan as any)[path] = value;
        },
        updateSelectedBenefitPlanPercentageProp: (
            state: BenefitPlansState,
            action: PayloadAction<{
                id: string;
                path: keyof IInsuranceClass;
                value: number;
            }>,
        ) => {
            const { path, value, id } = action.payload;

            if (
                state.selectedBenefitPlan &&
                state.selectedBenefitPlan.insuranceClasses &&
                state.selectedBenefitPlan.insuranceClasses.length
            ) {
                const insuranceClassToEdit = state.selectedBenefitPlan.insuranceClasses.find(
                    (insuranceClass) => insuranceClass.id === id,
                );
                if (insuranceClassToEdit) {
                    (insuranceClassToEdit as any)[path] = value;
                }
            }
        },
        updateBenefitPlansProp: (
            state: BenefitPlansState,
            action: PayloadAction<{ id: string; path: keyof IBenefitPlan; value: any }>,
        ) => {
            const { path, value, id } = action.payload;
            if (state.benefitPlans) (state.benefitPlans[id] as any)[path] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBenefitPlans.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getBenefitPlans.fulfilled, (state, action) => {
                state.benefitPlans = action.payload;
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getBenefitPlans.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getProcedureCategories.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProcedureCategories.fulfilled, (state, action) => {
                state.procedureCategories = action.payload;
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getProcedureCategories.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getBenefitPlanById.pending, (state) => {
                state.loadingBenefitPlan = LoadingStatus.Pending;
            })
            .addCase(getBenefitPlanById.fulfilled, (state, action) => {
                state.selectedBenefitPlan = action.payload;
                state.loadingBenefitPlan = LoadingStatus.Completed;
            })
            .addCase(getBenefitPlanById.rejected, (state) => {
                state.loadingBenefitPlan = LoadingStatus.Failed;
            })
            .addCase(addBenefitPlan.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(addBenefitPlan.fulfilled, (state, action) => {
                const benefitPlanId = action.payload.id;
                state.benefitPlans[benefitPlanId] = action.payload;
                state.selectedBenefitPlan = undefined;
                state.isAdding = false;
                state.saving = LoadingStatus.Completed;
            })
            .addCase(addBenefitPlan.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(updateBenefitPlan.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateBenefitPlan.fulfilled, (state, action) => {
                const benefitPlanId = action.payload.id;
                state.benefitPlans[benefitPlanId] = action.payload;
                state.selectedBenefitPlan = undefined;
                state.isAdding = false;
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateBenefitPlan.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(updateBenefitPlans.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateBenefitPlans.fulfilled, (state, action) => {
                const benefitPlans = action.payload;
                benefitPlans.forEach((benefitPlan) => {
                    const benefitPlanId = benefitPlan.id;
                    if (!isEqual(benefitPlan, state.benefitPlans[benefitPlanId])) {
                        if (state.benefitPlans[benefitPlanId]) {
                            (state.benefitPlans[benefitPlanId] as IBenefitPlan)._etag = benefitPlan._etag;
                            (state.benefitPlans[benefitPlanId] as IBenefitPlan).modifiedOn = benefitPlan.modifiedOn;
                            (state.benefitPlans[benefitPlanId] as IBenefitPlan).modifiedBy = benefitPlan.modifiedBy;
                        }
                    }
                });
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateBenefitPlans.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })

            .addCase(getDefaultBenefitPlans.fulfilled, (state, action) => {
                const defaultBenefitPlan = cloneDeep(action.payload);
                defaultBenefitPlan.id = uuid();

                state.selectedBenefitPlan = action.payload;
                state.isAdding = true;
            });
    },
});

export const {
    cleanupSelectedBenefitPlan,
    toggleShowBenefitPlanHistory,
    updateSelectedBenefitPlanProp,
    updateBenefitPlansProp,
    updateSelectedBenefitPlanPercentageProp,
} = benefitPlans.actions;

export default benefitPlans.reducer;

export type BenefitPlansState = {
    benefitPlans: Dictionary<IBenefitPlan>;
    procedureCategories?: IProcedureCategory;
    procedureInsuranceClass?: IInsuranceClass;
    selectedBenefitPlan?: IBenefitPlan;
    saving: LoadingStatus;
    loading: LoadingStatus;
    loadingBenefitPlan: LoadingStatus;
    isAdding: boolean;
    showHistory: boolean;
};
