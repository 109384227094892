import { createAsyncThunk } from '@reduxjs/toolkit';
import IBatchHardClosed from 'api/models/hardClosedHistory.model';
import IHardCloseBatchPayload from 'api/models/hardClosePayload.model';
import ErrorTypes from 'state/errorTypes';
import { AppThunk } from 'state/store';
import financialClosingApi from './financialClosing.api';
import { v4 as uuid } from 'uuid';
import { selectSelectedTenantHardCloseHistory } from './financialClosing.selectors';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const getTenantHardCloseThroughDate = createAsyncThunk<string, { tenantId: string }, { rejectValue: string }>(
    'financialClosing/getTenantHardCloseThroughDate',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: result } = await financialClosingApi.getTenantHardCloseThroughDate(tenantId);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getTenantHardCloseHistory = createAsyncThunk<IBatchHardClosed, { tenantId: string }, { rejectValue: string }>(
    'financialClosing/getTenantHardCloseHistory',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: result } = await financialClosingApi.getTenantHardCloseHistory(tenantId);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const hardCloseBatches = createAsyncThunk<
    IHardCloseBatchPayload,
    { tenantId: string; payload: IHardCloseBatchPayload },
    { rejectValue: string }
>('financialClosing/hardCloseBatches', async ({ tenantId, payload }, { rejectWithValue, dispatch }) => {
    try {
        const { data: result } = await financialClosingApi.hardCloseBatches(tenantId, payload);

        dispatch(getTenantHardCloseHistory({ tenantId }));
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const updateHardCloseBatches = createAsyncThunk<
    IBatchHardClosed,
    { tenantId: string; payload: IBatchHardClosed; updatedProperty?: keyof IBatchHardClosed },
    { rejectValue: { code?: string | ErrorTypes; message: string; response?: AxiosResponse } }
>('financialClosing/updateHardClose', async ({ tenantId, payload }, { rejectWithValue }) => {
    try {
        const { data: result } = await financialClosingApi.updateHardClose(tenantId, payload);
        return result;
    } catch (err: unknown) {
        if (axios.isAxiosError(err))
            if (err.response && err.response.status === 503) {
                return rejectWithValue({ code: ErrorTypes.ServiceUnavailable, message: err.message, response: err.response });
            } else {
                return rejectWithValue({ code: err.code, message: err.message, response: err.response });
            }

        return rejectWithValue({ message: 'Unknown error occurred.' });
    }
});

type UpdateHardCloseBatchPropertyParms = {
    tenantId: string;
    prop: keyof IBatchHardClosed;
    value: boolean | string | number;
};

/*
 * Thunk action:
 * Calls updateHardCloseBatches action
 *
 * Handles updating a specific property on the hard close model.
 *
 * */
export const updateHardCloseBatchProperty =
    ({ tenantId, prop, value }: UpdateHardCloseBatchPropertyParms): AppThunk =>
        (dispatch, getState) => {
            const hardCloseBatchesHistory = selectSelectedTenantHardCloseHistory(getState());

            const newHardCloseHistory: IBatchHardClosed = hardCloseBatchesHistory
                ? { ...hardCloseBatchesHistory, [prop]: value }
                : { id: uuid(), isDeleted: false, autoHardClose: false, [prop]: value };

            dispatch(updateHardCloseBatches({ tenantId, payload: newHardCloseHistory, updatedProperty: prop }));
        };
