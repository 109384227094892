import IProduct from 'api/models/product.model';
import { useSelector } from 'hooks';
import moment from 'moment';
import {
    DocumentCard,
    DocumentCardActivity,
    DocumentCardDetails,
    DocumentCardTitle,
    IDocumentCardStyles,
    IDocumentCardTitleStyles,
} from '@fluentui/react';

type ProductTileProps = {
    product: IProduct;
    onEdit: () => void;
};

const ProductTile = ({ product, onEdit }: ProductTileProps): JSX.Element => {
    const { selectedTheme, theme } = useSelector((state) => state.ui);

    const cardStyles: IDocumentCardStyles = {
        root: {
            cursor: product.isDeleted ? 'not-allowed' : 'pointer',
            display: 'inline-block',
            width: 300,
            opacity: product.isDeleted ? 0.5 : 1,
        },
    };

    const descriptionStyle: IDocumentCardTitleStyles = {
        root: {
            color: selectedTheme === 'light' ? 'black' : theme?.palette.neutralLight,
            width: 280,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    return (
        <>
            <DocumentCard title={product.displayName} styles={cardStyles} onClick={onEdit}>
                <DocumentCardDetails>
                    <DocumentCardTitle title={product.displayName} shouldTruncate />
                    <DocumentCardTitle
                        title={product.description}
                        showAsSecondaryTitle
                        styles={descriptionStyle}
                    />
                    <DocumentCardActivity
                        activity={moment(product.modifiedOn).format('[Modified] MMMM DD, yyyy')}
                        people={[
                            {
                                name: product.modifiedByUser
                                    ? product.modifiedByUser?.firstName +
                                      ' ' +
                                      product.modifiedByUser?.lastName
                                    : 'User Info Not A',
                                profileImageSrc: '',
                            },
                        ]}
                    />
                </DocumentCardDetails>
            </DocumentCard>
        </>
    );
};

export default ProductTile;
