import { IComboBoxOption } from '@fluentui/react';

const states: IComboBoxOption[] = [
    {
        key: "AL",
        text: "AL"
    },
    {
        key: "AK",
        text: "AK"
    },
    {
        key: "AS",
        text: "AS"
    },
    {
        key: "AZ",
        text: "AZ"
    },
    {
        key: "AR",
        text: "AR"
    },
    {
        key: "CA",
        text: "CA"
    },
    {
        key: "CO",
        text: "CO"
    },
    {
        key: "CT",
        text: "CT"
    },
    {
        key: "DE",
        text: "DE"
    },
    {
        key: "DC",
        text: "DC"
    },
    {
        key: "FM",
        text: "FM"
    },
    {
        key: "FL",
        text: "FL"
    },
    {
        key: "GA",
        text: "GA"
    },
    {
        key: "GU",
        text: "GU"
    },
    {
        key: "HI",
        text: "HI"
    },
    {
        key: "ID",
        text: "ID"
    },
    {
        key: "IL",
        text: "IL"
    },
    {
        key: "IN",
        text: "IN"
    },
    {
        key: "IA",
        text: "IA"
    },
    {
        key: "KS",
        text: "KS"
    },
    {
        key: "KY",
        text: "KY"
    },
    {
        key: "LA",
        text: "LA"
    },
    {
        key: "ME",
        text: "ME"
    },
    {
        key: "MH",
        text: "MH"
    },
    {
        key: "MD",
        text: "MD"
    },
    {
        key: "MA",
        text: "MA"
    },
    {
        key: "MI",
        text: "MI"
    },
    {
        key: "MN",
        text: "MN"
    },
    {
        key: "MS",
        text: "MS"
    },
    {
        key: "MO",
        text: "MO"
    },
    {
        key: "MT",
        text: "MT"
    },
    {
        key: "NE",
        text: "NE"
    },
    {
        key: "NV",
        text: "NV"
    },
    {
        key: "NH",
        text: "NH"
    },
    {
        key: "NJ",
        text: "NJ"
    },
    {
        key: "NM",
        text: "NM"
    },
    {
        key: "NY",
        text: "NY"
    },
    {
        key: "NC",
        text: "NC"
    },
    {
        key: "ND",
        text: "ND"
    },
    {
        key: "MP",
        text: "MP"
    },
    {
        key: "OH",
        text: "OH"
    },
    {
        key: "OK",
        text: "OK"
    },
    {
        key: "OR",
        text: "OR"
    },
    {
        key: "PW",
        text: "PW"
    },
    {
        key: "PA",
        text: "PA"
    },
    {
        key: "PR",
        text: "PR"
    },
    {
        key: "RI",
        text: "RI"
    },
    {
        key: "SC",
        text: "SC"
    },
    {
        key: "SD",
        text: "SD"
    },
    {
        key: "TN",
        text: "TN"
    },
    {
        key: "TX",
        text: "TX"
    },
    {
        key: "UT",
        text: "UT"
    },
    {
        key: "VT",
        text: "VT"
    },
    {
        key: "VI",
        text: "VI"
    },
    {
        key: "VA",
        text: "VA"
    },
    {
        key: "WA",
        text: "WA"
    },
    {
        key: "WV",
        text: "WV"
    },
    {
        key: "WI",
        text: "WI"
    },
    {
        key: "WY",
        text: "WY"
    }
]

export default states;
