import { isAfter, isBefore, isEqual } from 'date-fns';
import dateOnly from './dateOnly';

type IsDateBetweenArgs = {
    dateToCheck?: string;
    start?: string;
    end?: string;
};

/**
 * isDateBetween
 *
 * @export
 * @param {IsDateBetweenArgs} params
 * @param {string | undefined} params.dateToCheck The date to compare to start and end dates
 * @param {string | undefined} params.start Starting date
 * @param {string | undefined} params.end Ending date
 * @return {*}  {boolean}
 */
export function isDateBetween({ dateToCheck, start, end }: IsDateBetweenArgs): boolean {
    const date = dateToCheck ? dateOnly(dateToCheck) : '';
    const startDate = start ? dateOnly(start) : '';
    const endDate = end ? dateOnly(end) : '';

    // No dates to compare, return false
    if (!date || !startDate) return false;

    const dateObject = new Date(date);
    const startDateObject = new Date(startDate);

    const dateIsAfterStart = isAfter(dateObject, startDateObject) || isEqual(dateObject, startDateObject);

    // Date is after start, and no end date set, return true
    if (dateIsAfterStart && !endDate) return true;

    const endDateObject = new Date(endDate);
    const dateIsBeforeEnd = isBefore(dateObject, endDateObject) || isEqual(dateObject, endDateObject);

    return dateIsAfterStart && dateIsBeforeEnd;
}
