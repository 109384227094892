import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { IPalette, ITheme } from '@fluentui/react';

import { RootState } from 'state/store';
import appLocalStorage from 'utils/appLocalStorage';

type IPaletteCustom = IPalette & { palette: { navbar: string; navbarLighter: string } };
export type IThemeCustom = ITheme & IPaletteCustom;

export const darkTheme = {
    palette: {
        themePrimary: '#48a7fa',
        themeLighterAlt: '#03070a',
        themeLighter: '#0c1b28',
        themeLight: '#16324b',
        themeTertiary: '#2b6496',
        themeSecondary: '#4093dc',
        themeDarkAlt: '#5ab0fa',
        themeDark: '#74bcfb',
        themeDarker: '#98cdfc',
        neutralLighterAlt: '#1c1c1c',
        neutralLighter: '#252525',
        neutralLight: '#343434',
        neutralQuaternaryAlt: '#3d3d3d',
        neutralQuaternary: '#454545',
        neutralTertiaryAlt: '#656565',
        neutralTertiary: '#e9e9e9',
        neutralSecondary: '#ececec',
        neutralPrimaryAlt: '#f0f0f0',
        neutralPrimary: '#dddddd',
        neutralDark: '#f7f7f7',
        black: '#fbfbfb',
        white: '#111111',
        navbar: '#1d375a',
        navbarLighter: '#28538e',
    },
};

export const lightTheme = {
    palette: {
        themePrimary: '#006aff',
        themeLighterAlt: '#f5f9ff',
        themeLighter: '#d6e7ff',
        themeLight: '#b3d2ff',
        themeTertiary: '#66a6ff',
        themeSecondary: '#1f7cff',
        themeDarkAlt: '#0060e6',
        themeDark: '#0051c2',
        themeDarker: '#003b8f',
        neutralLighterAlt: '#f1f1f1',
        neutralLighter: '#e0e0e0',
        neutralLight: '#d9d9d9',
        neutralQuaternaryAlt: '#d3d3d3',
        neutralQuaternary: '#cacaca',
        neutralTertiaryAlt: '#c2c2c2',
        neutralTertiary: '#b2b2b2',
        neutralSecondary: '#999999',
        neutralPrimaryAlt: '#808080',
        neutralPrimary: '#1c1c1c',
        neutralDark: '#4e4e4e',
        black: '#353535',
        white: '#f7f7f7',
        navbar: '#1d375a',
        navbarLighter: '#28538e',
    },
};

const initialState: UiState = {
    theme: lightTheme,
    selectedTheme: appLocalStorage.theme,
    activityBarExpanded: appLocalStorage.activityBarExpanded,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setTheme(state, action: PayloadAction<'dark' | 'light'>) {
            const { payload } = action;
            state.selectedTheme = payload;
            appLocalStorage.theme = action.payload;
        },
        setActivityBarExpanded(state, action: PayloadAction<boolean>) {
            state.activityBarExpanded = action.payload;
            appLocalStorage.activityBarExpanded = action.payload;
        },
        addTooltipCard(state, action: PayloadAction<ITooltipCard>) {
            state.tooltipCards = state.tooltipCards
                ? [action.payload, ...state.tooltipCards]
                : [action.payload];
        },
        removeTooltipCard(state, action) {
            if (state.tooltipCards) {
                const id = action.payload;
                state.tooltipCards = state.tooltipCards.filter((card) => card.id !== id);
            }
        },
    },
});

const { reducer, actions } = uiSlice;

export const { setTheme, setActivityBarExpanded, addTooltipCard, removeTooltipCard } = actions;

const panelSelector = (state: any) => state.ui.panelsOpen;
export const panelsAreOpen = createSelector(panelSelector, (panels) => panels.length > 0);

const selectTheme = (state: RootState) => state.ui.selectedTheme;
export const getSelectedTheme = createSelector(selectTheme, (theme) =>
    theme === 'dark' ? darkTheme : lightTheme,
);

export default reducer;

export interface ITooltipCard {
    id: string;
    content: JSX.Element;
    title: string | JSX.Element;
    ttl?: number;
}

type UiState = {
    theme: any;
    selectedTheme: 'dark' | 'light';
    activityBarExpanded: boolean;
    tooltipCards?: ITooltipCard[];
};
