import {
    DefaultButton,
    getFocusStyle,
    getRTL,
    getTheme,
    Icon,
    IconButton,
    ITheme,
    List,
    mergeStyleSets,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Separator,
    Stack,
    TextField,
    MessageBar,
    MessageBarType,
    Text,
    Dropdown,
    IDropdownOption,
    TooltipHost,
} from '@fluentui/react';
import IProduct, { IProductRole, IProductService } from 'api/models/product.model';
import { serviceNameLookup, Services } from 'views/pages/Tenants/state/tenants.model';
import { SubSection } from 'components';
import { useValidation } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, editProduct, selectProductsData } from 'state/slices/products.slice';
import { v4 as uuidv4 } from 'uuid';
import ErrorMessageBar from 'views/component/Notifications/ErrorMessageBar';

const buttonStyles = { root: { marginRight: 8 } };

export enum ModalMode {
    AddRecord,
    EditRecord,
}

interface AddEditProductRolesModalProps {
    isOpen: boolean;
    productData: IProduct;
    onDismiss: () => void;
    onRemoveProduct: () => void;
    modalMode: ModalMode;
}

const AddEditProductRolesPanel = ({
    isOpen,
    onDismiss,
    onRemoveProduct,
    productData,
    modalMode = ModalMode.AddRecord,
}: AddEditProductRolesModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const theme: ITheme = getTheme();
    const { palette, semanticColors, fonts } = theme;

    const productsList = useSelector(selectProductsData);

    const [isRoleEditorVisible, setRoleEditorVisible] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<IProductService>();
    const [selectedRole, setSelectedRole] = useState<IProductRole>();
    const [selectedProduct, setSelectedProduct] = useState<IProduct>(productData as IProduct);
    const [selectedProductHasRoles, setSelectedProductHasRoles] = useState<boolean>(false);

    const isServiceEditorVisible = !!selectedService;

    const [errorMsg, setErrorMsg] = useState<string>('');

    useEffect(() => {
        if (selectedProduct.roles == null || selectedProduct.roles === undefined) {
            setSelectedProduct({ ...selectedProduct!, roles: [] });
        } else evaluateRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProduct]);

    const validateProductId = (productId: string) => {
        let result = true;
        setErrorMsg(''); //clear error msg
        const re = new RegExp('^[a-z\\d\\-]{4,}$');
        const reResult = re.exec(productId);

        if (!reResult) {
            result = false;
            setErrorMsg('The id requires a minimum of 4 lower-case letters/numbers without space/special characters.');
            return result;
        }

        if (modalMode === ModalMode.AddRecord) {
            productsList.map((product) => {
                if (product.id === productId) {
                    result = false;
                    setErrorMsg('This Product Id is already in use.');
                    return result;
                }
                return undefined;
            });
        }
        return result;
    };

    const onSaveProductBegin = () => {
        // Save the product first
        const isValid = validateProductId(selectedProduct.id);
        if (isValid) {
            if (modalMode === ModalMode.AddRecord) dispatch(addProduct({ product: selectedProduct }));
            else dispatch(editProduct({ product: selectedProduct }));

            onDismiss();
        }
    };

    const closePanel = () => {
        onDismiss();
    };

    const onRoleClick = (item: IProductRole) => {
        setRoleEditorVisible(true);
        setSelectedRole(item);
    };

    const onServiceClick = (item?: IProductService) => {
        setSelectedService(item);
    };

    const onAddRole = () => {
        setRoleEditorVisible(true);
        if (productData && productData.roles) {
            const defaultProductRole: IProductRole = {
                id: uuidv4(),
                productId: productData.id,
                displayName: 'New Product Role',
                description: '',
                claims: [],
                createdOn: new Date(),
                createdBy: '',
                modifiedOn: new Date(),
                modifiedBy: '',
                isDeleted: false,
            };

            setSelectedRole(defaultProductRole);
        }
    };
    const onAddService = () => {
        if (productData) {
            const defaultProductRole: IProductService = {
                id: uuidv4(),
                displayName: '',
                description: '',
                isDeleted: false,
            };

            setSelectedService(defaultProductRole);
        }
    };

    const validateRoles = () => {
        if (selectedRole?.displayName === '') {
            setErrorMsg('A display name is required for roles.');
            return false;
        }

        const claims: string[] = selectedRole?.claims as string[];
        if (claims.length < 1) {
            setErrorMsg('You must specify at least 1 claim.');
            return false;
        }

        setErrorMsg('');
        return true;
    };

    const onSaveRole = () => {
        if (!validateRoles()) {
            return;
        }

        const roles = selectedProduct.roles as IProductRole[];
        const newRoles =
            roles.findIndex((e) => e.id === selectedRole?.id) > -1
                ? roles.map((role) => {
                      return role.id === selectedRole?.id ? selectedRole : role;
                  })
                : roles.concat([selectedRole] as IProductRole[]);

        // Update the roles (in-state)
        updateProduct('roles', newRoles);

        setRoleEditorVisible(false);
    };

    const onSaveRoleCancel = () => {
        setRoleEditorVisible(false);
        setSelectedRole(undefined);
    };
    const onCancelSaveService = () => {
        setSelectedService(undefined);
    };

    // this updates the object in state
    const updateRole = (path: keyof IProductRole, value: any) => {
        setSelectedRole({ ...selectedRole!, [path]: value });
        evaluateRoles();
    };
    const updateService = (path: keyof IProductService, value: any) => {
        if (selectedService) {
            setSelectedService({
                ...selectedService,
                [path]: value,
                displayName: path === 'id' ? serviceNameLookup[value as Services] : selectedService.displayName,
            });
        }
    };

    const evaluateRoles = () => {
        const roles = selectedProduct.roles as IProductRole[];
        setSelectedProductHasRoles(roles.length > 0 ? true : false);
    };

    const onRemoveService = () => {
        const productServices = selectedProduct.services;

        if (productServices?.length) {
            const serviceIndex = productServices.findIndex((service) => service.id === selectedService?.id);
            if (serviceIndex > -1) {
                const newServices: IProductService[] = [...productServices];
                newServices[serviceIndex] = { ...newServices[serviceIndex], isDeleted: true };
                updateProduct('services', newServices);
            }
        }

        setSelectedService(undefined);
    };

    const onRemoveRole = () => {
        const roles = selectedProduct.roles as IProductRole[];

        const newRoles =
            roles.findIndex((e) => e.id === selectedRole?.id) > -1
                ? roles.filter((role) => {
                      return role.id !== selectedRole?.id;
                  })
                : roles;

        // Update the roles (in-state)
        updateProduct('roles', newRoles);
        setRoleEditorVisible(false);
    };

    const updateRoleClaims = (value: any) => {
        updateRole(
            'claims',
            value.split(',').map((claim: string) => {
                return claim.trimStart();
            }),
        );
    };

    // this updates the object in state
    const updateProduct = (path: keyof IProduct, value: any) => {
        setSelectedProduct({ ...selectedProduct!, [path]: value });
    };

    const classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                cursor: 'pointer',
                // backgroundColor: palette.neutralLight,
                selectors: {
                    '&:hover': { background: palette.neutralSecondaryAlt },
                },
            },
        ],
        itemImage: {
            flexShrink: 0,
        },
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
        },
        itemName: [
            fonts.mediumPlus,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        ],
        itemDescription: {
            fontSize: fonts.medium.fontSize,
            marginBottom: 10,
        },
        itemClaims: {
            fontSize: fonts.small.fontSize,
            marginBottom: 10,
        },
        chevron: {
            alignSelf: 'center',
            color: palette.neutralTertiary,
            marginLeft: 10,
            fontSize: fonts.large.fontSize,
            flexShrink: 0,
        },
    });

    const onRenderRoleCell = (item: IProductRole | undefined): JSX.Element => {
        return (
            <div className={classNames.itemCell} data-is-focusable={true} onClick={() => onRoleClick(item as IProductRole)}>
                <Stack className={classNames.itemContent}>
                    <Text className={classNames.itemName}>{item?.displayName}</Text>
                    <Text className={classNames.itemDescription}>{item?.description}</Text>
                    <Text className={classNames.itemClaims}>Claims: {item?.claims.join(', ')}</Text>
                </Stack>
                <Icon className={classNames.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
            </div>
        );
    };

    const onRenderServiceCell = (item: IProductService | undefined): JSX.Element => {
        return (
            <div onClick={() => onServiceClick(item)} className={classNames.itemCell} data-is-focusable={true}>
                <Stack className={classNames.itemContent}>
                    <Text className={classNames.itemName}>{item?.displayName}</Text>
                    <Text className={classNames.itemDescription}>{item?.description}</Text>
                </Stack>
                <Icon className={classNames.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
            </div>
        );
    };

    const getPanelControls = React.useCallback(
        () => (
            <>
                <Stack horizontal grow>
                    <Stack.Item grow>
                        <PrimaryButton
                            onClick={onSaveProductBegin}
                            styles={buttonStyles}
                            disabled={isRoleEditorVisible || isServiceEditorVisible}
                        >
                            Save
                        </PrimaryButton>
                        <DefaultButton onClick={closePanel} disabled={isRoleEditorVisible || isServiceEditorVisible}>
                            Cancel
                        </DefaultButton>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton onClick={onRemoveProduct}>
                            <Icon iconName="Delete" />
                        </IconButton>
                    </Stack.Item>
                </Stack>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [closePanel],
    );

    const roleEditor = (
        <div hidden={!isRoleEditorVisible}>
            <SubSection title={`Editing Role: ${selectedRole?.displayName}`} />
            <TextField
                label="Display Name"
                value={selectedRole?.displayName}
                onChange={(ev, value: any) => updateRole('displayName', value)}
            />

            <TextField
                label="Description"
                rows={6}
                multiline
                value={selectedRole?.description}
                onChange={(ev, value: any) => updateRole('description', value)}
            />

            <TextField
                label="Claims"
                placeholder="Comma separate list of claims"
                value={selectedRole?.claims.join(', ')}
                rows={6}
                multiline
                onChange={(ev, value: any) => updateRoleClaims(value)}
            />

            <div style={{ marginTop: 10 }}>
                <Stack horizontal grow>
                    <Stack.Item grow>
                        <PrimaryButton onClick={onSaveRole} style={{ marginRight: 8 }}>
                            OK
                        </PrimaryButton>
                        <DefaultButton onClick={onSaveRoleCancel}>Cancel</DefaultButton>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton onClick={onRemoveRole}>
                            <Icon iconName="Delete" />
                        </IconButton>
                    </Stack.Item>
                </Stack>
            </div>
        </div>
    );

    const isEditingService = selectedProduct.services
        ? selectedProduct.services
              .filter((service) => !service.isDeleted)
              .findIndex((service) => service.id === selectedService?.id) > -1
        : false;

    const servicesOptionsList: IDropdownOption[] = (
        !isEditingService
            ? map(Services).filter((service) =>
                  selectedProduct.services ? selectedProduct.services?.findIndex((s) => s.id === service) === -1 : true,
              )
            : map(Services)
    ).map((service) => ({
        key: service as string,
        text: serviceNameLookup[service],
    }));

    const onSaveService = () => {
        const services = selectedProduct.services ?? [];

        let newServices: IProductService[] = [];
        if (selectedService)
            if (isEditingService) {
                const indexOfService = services?.findIndex((service) => service.id === selectedService.id);
                newServices = [...services];
                newServices[indexOfService] = { ...selectedService };
            } else {
                newServices = services.length ? [...services, selectedService] : [selectedService];
            }

        updateProduct('services', newServices);
        setSelectedService(undefined);
    };

    const [errors, onSubmitService, cleanupServiceErrors] = useValidation(
        [
            {
                fieldName: 'Display Name',
                validation: ['required'],
                value: selectedService?.displayName,
            },
            {
                fieldName: 'Description',
                validation: ['required'],
                value: selectedService?.description,
            },
        ],
        onSaveService,
    );

    useEffect(() => {
        if (!isServiceEditorVisible) cleanupServiceErrors();
    }, [isServiceEditorVisible]);

    const serviceEditor = (
        <div hidden={!isServiceEditorVisible}>
            <SubSection title={`${isEditingService ? 'Edit' : 'Add'} Service: ${selectedService?.displayName}`} />
            <Dropdown
                label="Service"
                selectedKey={selectedService?.id}
                options={servicesOptionsList}
                onChange={(ev, option) => {
                    updateService('id', option?.key as string);
                }}
                disabled={isEditingService}
                required
                placeholder="(Select service)"
                errorMessage={getValidationError(errors, 'Display Name') ? 'Service is required.' : ''}
            />

            <TextField
                label="Description"
                multiline
                required
                autoComplete="off"
                value={selectedService?.description}
                onChange={(ev, value: any) => updateService('description', value)}
                errorMessage={getValidationError(errors, 'Description') ? 'Description is required.' : ''}
            />

            <div style={{ marginTop: 10 }}>
                <Stack horizontal grow>
                    <Stack.Item grow>
                        <PrimaryButton style={{ marginRight: 8 }} onClick={onSubmitService}>
                            {isEditingService ? 'Save' : 'Add'}
                        </PrimaryButton>
                        <DefaultButton onClick={onCancelSaveService}>Cancel</DefaultButton>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton onClick={onRemoveService}>
                            <Icon iconName="Delete" />
                        </IconButton>
                    </Stack.Item>
                </Stack>
            </div>
        </div>
    );

    const services = selectedProduct?.services?.filter((service) => !service.isDeleted);

    return (
        <Panel
            headerText="Manage Product"
            closeButtonAriaLabel="Close"
            isOpen={isOpen}
            type={PanelType.medium}
            onDismiss={onDismiss}
            onRenderFooterContent={getPanelControls}
            isFooterAtBottom={true}
                        styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}

        >
            <Separator />

            {errorMsg ? <ErrorMessageBar message={errorMsg} /> : <></>}

            <div hidden={isRoleEditorVisible || isServiceEditorVisible}>
                <TextField
                    label="Product Id"
                    title="Product Id"
                    disabled={modalMode === ModalMode.EditRecord}
                    value={selectedProduct.id}
                    onChange={(ev, value: any) => updateProduct('id', value)}
                />

                <TextField
                    label="Display Name"
                    title="Display Name"
                    value={selectedProduct.displayName}
                    onChange={(ev, value: any) => updateProduct('displayName', value)}
                />

                <TextField
                    label="Description"
                    title="Description"
                    value={selectedProduct.description}
                    onChange={(ev, value: any) => updateProduct('description', value)}
                    multiline
                />

                <Pivot>
                    <PivotItem
                        headerText="Roles"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'Roles',
                        }}
                    >
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <List items={selectedProduct.roles} onRenderCell={onRenderRoleCell} />
                            <div hidden={selectedProductHasRoles}>
                                <MessageBar messageBarType={MessageBarType.info}>
                                    This product does not have any roles defined. Use the add button below to add a new role.
                                </MessageBar>
                            </div>
                        </div>

                        <DefaultButton onClick={onAddRole}>Add Role</DefaultButton>
                    </PivotItem>
                    <PivotItem
                        headerText="Services"
                        headerButtonProps={{
                            'data-order': 2,
                            'data-title': 'Services',
                        }}
                    >
                        <Stack style={{ paddingTop: 5 }} tokens={{ childrenGap: 5 }} grow>
                            {services?.length ? (
                                <List items={services} onRenderCell={onRenderServiceCell} />
                            ) : (
                                <MessageBar>No services have been added to this product.</MessageBar>
                            )}
                            <Stack.Item>
                                <TooltipHost
                                    delay={0}
                                    content={!servicesOptionsList.length ? 'No available services to add.' : undefined}
                                >
                                    <DefaultButton disabled={!servicesOptionsList.length} onClick={onAddService}>
                                        Add Service
                                    </DefaultButton>
                                </TooltipHost>
                            </Stack.Item>
                        </Stack>
                    </PivotItem>
                </Pivot>
            </div>

            {roleEditor}
            {serviceEditor}
        </Panel>
    );
};

export default AddEditProductRolesPanel;
