import {
    DefaultButton,
    IColumn,
    IconButton,
    Label,
    Link,
    MessageBar,
    Panel,
    PanelType,
    PrimaryButton,
    SelectionMode,
    Separator,
    Spinner,
    Stack,
    StackItem,
    Text,
    TextField,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import IPovertyGuideline, { IPovertyIcomeLevel } from 'api/models/povertyGuidline.model';
import { Field, SortableDetailsList, SubSection, ValidationBar } from 'components';
import { useValidation } from 'hooks';
import useFPG from 'hooks/state/useFPG';
import { LoadingStatus } from 'interfaces/loadingStatus';
import React, { useEffect, useState } from 'react';
import dateOnly, { convertDateTimeToBeforeMidnight, convertDateTimeToMidnight } from 'utils/dateOnly';
import { PanelSectionHeader } from 'views/component';

export default function FPGPanel() {
    const {
        selectedLocation,
        selectedPovertyGuildeline,
        cleanupSelectedPovertyGuidlinesData,
        poveryGuidelinesBySelectedLocation,
        latestPovertyGuideline,
        getPovertyGuidlineById,
        loadingSelectedPovertyGuideline,
        saving,
        addGuidelineYear,
        isAdding,
        updatePovertyGuidline,
        createPovertyGuidline,
    } = useFPG();
    const { palette } = useTheme();

    const isOpen = !!selectedLocation;

    useEffect(() => {
        if (isOpen && latestPovertyGuideline && !selectedPovertyGuildeline) {
            getPovertyGuidlineById(latestPovertyGuideline.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const [errors, onSubmit, cleanupValdiationErrors] = useValidation(
        [
            {
                fieldName: 'Display Name',
                validation: ['required'],
                value: selectedPovertyGuildeline?.displayName,
            },
            {
                fieldName: 'Year',
                validation: ['required'],
                value: selectedPovertyGuildeline?.year,
            },
            {
                fieldName: 'Effective Date',
                validation: ['required'],
                value: selectedPovertyGuildeline?.effectiveDate,
            },
            {
                fieldName: 'Income Increment',
                validation: ['required'],
                value: selectedPovertyGuildeline?.additionalPersonValue,
            },
        ],
        onSaveOrAdd,
    );

    function _onDismiss() {
        cleanupSelectedPovertyGuidlinesData();
    }

    function onSaveOrAdd() {
        if (selectedPovertyGuildeline) {
            if (isAdding) {
                createPovertyGuidline(selectedPovertyGuildeline);
            } else {
                updatePovertyGuidline(selectedPovertyGuildeline);
            }
            cleanupValdiationErrors();
        }
    }

    function onEditYear(item?: IPovertyGuideline) {
        if (loadingSelectedPovertyGuideline !== LoadingStatus.Pending && item) {
            getPovertyGuidlineById(item.id);
            cleanupValdiationErrors();
        }
    }

    const columns: IColumn[] = [
        {
            key: 'displayName',
            minWidth: 120,
            name: 'Display Name',
            fieldName: 'displayName',
            onRender: (item: IPovertyGuideline) => (
                <Link onClick={() => onEditYear(item)} title={item.displayName}>
                    {item.displayName}
                </Link>
            ),
        },
        {
            key: 'year',
            minWidth: 120,
            name: 'Year',
            fieldName: 'year',
            onRender: (item: IPovertyGuideline) => <span>{item.year ?? item.year}</span>,
        },
        {
            key: 'effectiveDate',
            minWidth: 120,
            name: 'Effective Date',
            fieldName: 'effectiveDate',
            onRender: (item: IPovertyGuideline) => <span>{item.effectiveDate ? dateOnly(item.effectiveDate) : ''}</span>,
        },
        {
            key: 'endDate',
            minWidth: 120,
            name: 'End Date',
            fieldName: 'endDate',
            onRender: (item: IPovertyGuideline) => <span>{item.endDate ? dateOnly(item.endDate) : ''}</span>,
        },
    ];

    return (
        <Panel
            headerText="Poverty Guidelines"
            isOpen={isOpen}
            type={PanelType.medium}
            onDismiss={_onDismiss}
            isFooterAtBottom
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <ValidationBar errors={errors} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton
                            text={isAdding ? 'Add' : 'Save'}
                            onClick={onSubmit}
                            disabled={saving === LoadingStatus.Pending || !selectedPovertyGuildeline}
                        />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                        <Stack grow horizontalAlign="end">
                            {saving === LoadingStatus.Pending && (
                                <Spinner label={isAdding ? 'Adding...' : 'Saving...'} labelPosition="left" />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Stack
                        style={{
                            borderRight: `1px solid ${palette.neutralTertiaryAlt}`,
                            paddingRight: 10,
                        }}
                    >
                        <Label>Region</Label>
                        <Text>{selectedLocation}</Text>
                    </Stack>
                    <Stack>
                        <Label>Current Year</Label>
                        <Text>{latestPovertyGuideline?.year ?? latestPovertyGuideline?.year}</Text>
                    </Stack>
                </Stack>
                <SubSection
                    title="Guidelines"
                    style={{ marginBottom: 10 }}
                    headingEndContent={
                        <TooltipHost content="Add year">
                            <IconButton onClick={addGuidelineYear} iconProps={{ iconName: 'Add' }} disabled={isAdding} />
                        </TooltipHost>
                    }
                >
                    <SortableDetailsList
                        selectionMode={SelectionMode.none}
                        columns={columns}
                        items={poveryGuidelinesBySelectedLocation}
                        sortOnMount
                        onItemInvoked={onEditYear}
                        sortColumns={['year']}
                        initialSortDirection={['desc']}
                    />
                </SubSection>
                <PanelSectionHeader style={{ marginTop: 10 }} text={isAdding ? 'Add Year:' : 'Selected Year:'} isPrimary />
                <Guideline />
            </Stack>
        </Panel>
    );
}

function Guideline() {
    const {
        selectedPovertyGuildeline,
        loadingSelectedPovertyGuideline,
        updateSelectedGuidelineProp,
        isAdding,
        updateIncomeLevel,
    } = useFPG();
    const { palette } = useTheme();

    const incrementIncomeAmount = selectedPovertyGuildeline?.incomeLevels
        ? selectedPovertyGuildeline?.incomeLevels.findIndex((item) => item.personsCount >= 8) > -1
        : false;

    function getNextIncomeLevel(): IPovertyIcomeLevel {
        return selectedPovertyGuildeline?.incomeLevels?.length
            ? {
                  personsCount:
                      selectedPovertyGuildeline.incomeLevels[selectedPovertyGuildeline.incomeLevels.length - 1].personsCount + 1,
                  incomeAmount:
                      incrementIncomeAmount && selectedPovertyGuildeline.additionalPersonValue
                          ? selectedPovertyGuildeline.incomeLevels[selectedPovertyGuildeline.incomeLevels.length - 1]
                                .incomeAmount + selectedPovertyGuildeline.additionalPersonValue
                          : 0,
              }
            : { incomeAmount: 0, personsCount: 1 };
    }

    const [personsCount, setPersonsCount] = useState<number>(0);
    const [incomeAmount, setIncomeAmount] = useState<number>(0);

    useEffect(() => {
        if (selectedPovertyGuildeline) {
            const nextIncomeLevel = getNextIncomeLevel();
            setPersonsCount(nextIncomeLevel.personsCount);
            setIncomeAmount(nextIncomeLevel.incomeAmount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPovertyGuildeline]);

    function onAddIncomeLevel() {
        if (selectedPovertyGuildeline) {
            const newIncomeLevel = { personsCount, incomeAmount };
            const newIncomeLevels = selectedPovertyGuildeline.incomeLevels
                ? [...selectedPovertyGuildeline.incomeLevels, newIncomeLevel]
                : [newIncomeLevel];
            updateSelectedGuidelineProp('incomeLevels', newIncomeLevels);
            cleanupErrors();
        }
    }

    const idOfSElectedPovertyGuideline = selectedPovertyGuildeline?.id;

    useEffect(() => {
        cleanupErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idOfSElectedPovertyGuideline]);

    const [errors, onAdd, cleanupErrors] = useValidation(
        [
            {
                fieldName: 'Persons Count',
                validation: ['required'],
                value: personsCount,
            },
            {
                fieldName: 'Income Amount',
                validation: ['required'],
                value: incomeAmount,
            },
        ],
        onAddIncomeLevel,
    );

    if (!selectedPovertyGuildeline || loadingSelectedPovertyGuideline === LoadingStatus.Pending)
        return <Spinner labelPosition="left" label="Loading..." />;

    return (
        <SubSection
            title={
                !isAdding ? (
                    selectedPovertyGuildeline.displayName
                ) : (
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <StackItem grow>
                            <TextField
                                value={selectedPovertyGuildeline.displayName}
                                label="Display Name"
                                onChange={(ev, value) => {
                                    updateSelectedGuidelineProp('displayName', value);
                                }}
                                autoComplete="off"
                                required
                            />
                        </StackItem>
                        <StackItem>
                            <TextField
                                value={selectedPovertyGuildeline.year}
                                onChange={(ev, value) => {
                                    if (value !== undefined && isNaN(+value)) return;
                                    updateSelectedGuidelineProp('year', value !== undefined ? value.replace(' ', '') : undefined);
                                }}
                                label="Year"
                                required
                                autoComplete="off"
                                maxLength={4}
                            />
                        </StackItem>
                    </Stack>
                )
            }
        >
            <Stack tokens={{ childrenGap: 5 }} grow>
                {!isAdding && (
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <StackItem grow>
                            <TextField
                                value={selectedPovertyGuildeline.displayName}
                                label="Display Name"
                                onChange={(ev, value) => {
                                    updateSelectedGuidelineProp('displayName', value);
                                }}
                                autoComplete="off"
                                required
                            />
                        </StackItem>
                        <StackItem>
                            <TextField
                                value={selectedPovertyGuildeline.year}
                                onChange={(ev, value) => {
                                    updateSelectedGuidelineProp('year', value);
                                }}
                                label="Year"
                                required
                                autoComplete="off"
                                maxLength={4}
                                disabled
                            />
                        </StackItem>
                    </Stack>
                )}
                <Stack tokens={{ childrenGap: 5 }} horizontal grow>
                    <StackItem grow>
                        <Field.Date
                            value={selectedPovertyGuildeline.effectiveDate ? selectedPovertyGuildeline.effectiveDate : ''}
                            label="Effective Date"
                            required
                            hasDatePicker
                            onChange={(ev, value) => {
                                updateSelectedGuidelineProp('effectiveDate', convertDateTimeToMidnight(value));
                            }}
                        />
                    </StackItem>
                    <StackItem grow>
                        <Field.Date
                            value={selectedPovertyGuildeline.endDate ? selectedPovertyGuildeline.endDate : ''}
                            label="End Date"
                            hasDatePicker
                            onChange={(ev, value) => {
                                updateSelectedGuidelineProp('endDate', convertDateTimeToBeforeMidnight(value));
                            }}
                        />
                    </StackItem>
                </Stack>
                <PanelSectionHeader style={{ marginTop: 10 }} text="Income Levels:" />
                <StackItem grow>
                    <Field.Currency
                        label="Income Increment"
                        value={selectedPovertyGuildeline.additionalPersonValue}
                        required
                        onChange={(ev, value) => {
                            updateSelectedGuidelineProp('additionalPersonValue', value !== undefined ? +value : 0);
                        }}
                    />
                </StackItem>
                <Separator />
                {!selectedPovertyGuildeline.incomeLevels?.length && (
                    <MessageBar>No income levels have been added yet.</MessageBar>
                )}
                <Stack tokens={{ childrenGap: 2 }} grow>
                    {selectedPovertyGuildeline.incomeLevels?.map((item, index) => (
                        <Stack tokens={{ childrenGap: 4 }} horizontal grow key={index}>
                            <StackItem grow>
                                <TextField
                                    label={index === 0 ? `Persons Count` : ''}
                                    value={item.personsCount.toString()}
                                    onChange={(ev, value) => {
                                        if (value !== undefined) {
                                            if (+value <= 8 || +value <= 1) updateIncomeLevel(index, 'personsCount', +value);
                                        }
                                    }}
                                    autoComplete="off"
                                    disabled
                                />
                            </StackItem>
                            <StackItem grow>
                                <Field.Currency
                                    label={index === 0 ? `Income Amount` : ''}
                                    value={item.incomeAmount}
                                    onChange={(ev, value) => {
                                        if (value !== undefined) updateIncomeLevel(index, 'incomeAmount', +value);
                                    }}
                                    disabled={index >= 8}
                                    autoComplete="off"
                                />
                            </StackItem>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
            <Stack
                tokens={{ childrenGap: 5 }}
                style={{
                    border: `1px solid ${palette.neutralPrimary}`,
                    borderRadius: 2,
                    padding: 5,
                    marginTop: 10,
                }}
                grow
            >
                <Stack tokens={{ childrenGap: 5 }} verticalAlign="end" horizontal grow>
                    <StackItem grow>
                        <TextField
                            onChange={(ev, value) => {
                                if (value !== undefined && isNaN(+value)) return;
                                if (value !== undefined && +value > 8) return;
                                setPersonsCount(value !== undefined ? +value : 0);
                            }}
                            label="Persons Count"
                            value={personsCount?.toString()}
                            autoComplete="off"
                            disabled
                        />
                    </StackItem>
                    <StackItem grow>
                        <Field.Currency
                            label="Income Amount"
                            onChange={(ev, value) => setIncomeAmount(value !== undefined ? +value : 0)}
                            value={incomeAmount}
                            disabled={incrementIncomeAmount}
                            autoComplete="off"
                        />
                    </StackItem>
                    <TooltipHost content="Add income level">
                        <DefaultButton text="Add" onClick={onAdd} />
                    </TooltipHost>
                </Stack>
                <ValidationBar errors={errors}></ValidationBar>
            </Stack>
        </SubSection>
    );
}
