import { createSelector } from '@reduxjs/toolkit';
import { flatten } from 'lodash';
import { tenantsState } from '../../state/tenants.selectors';
import { LoadingStatus } from 'interfaces/loadingStatus';

export const selectTenantOnboarding = createSelector(tenantsState, (state) => state.selectTenantOnboarding);

export const selectTenantOnboardingStepsList = createSelector(selectTenantOnboarding, (onboarding) => {
    return flatten(onboarding?.tenantOnboardingSteps) ?? [];
});

export const selectTenantOnboardingLoading = createSelector(tenantsState, (state) => state.loadingTenantOnboarding);

export const selectVerifyTenantOnboardingLoading = createSelector(
    tenantsState,
    (state) => state.verifyTenantOnboarding === LoadingStatus.Pending,
);
