import {
    DetailsListLayoutMode,
    DetailsRow,
    FontIcon,
    IColumn,
    IDetailsColumnProps,
    IDetailsRowProps,
    IconButton,
    Link,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Spinner,
    Stack,
    TooltipHost,
    mergeStyles,
    useTheme,
    Text,
    DirectionalHint,
} from '@fluentui/react';
import { HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { Section, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { RouteParams } from 'interfaces/route-params';
import { flatten } from 'lodash';
import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SaveStatusIcon, { SyncStatus } from 'views/component/SavingStatusIcon';
import { ITenantOnboardingProcessHistory, ITenantOnboardingSteps } from 'views/pages/Tenants/state/tenants.model';
import OnBoardingStepsList from './OnBoardingStepsList';
import RunTaskButton from './RunTaskButton';
import { getTenantOnboardingById, rerunTenantOnboardingStep } from './state/onboarding.actions';
import {
    selectTenantOnboarding,
    selectTenantOnboardingLoading,
    selectTenantOnboardingStepsList,
} from './state/onboarding.selectors';
import { format, formatDistanceToNow } from 'date-fns';
import { isDev } from 'api/platform.api';

const hubString = isDev ? `${process.env.REACT_APP_AppApiUri}/notifications` : `#{AppApiUri}#/notifications`;
const options: IHttpConnectionOptions = {};
const connection = new HubConnectionBuilder().withUrl(hubString, options).build();

export default function Onboarding() {
    const theme = useTheme();
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (tenantId) dispatch(getTenantOnboardingById({ tenantId }));
    }, [tenantId, dispatch]);

    const [indexRow, setIndexRow] = useState<number | undefined>(undefined);
    const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);

    const onboarding = useSelector(selectTenantOnboarding);
    const onboardingSteps = useSelector(selectTenantOnboardingStepsList);

    const loading = useSelector(selectTenantOnboardingLoading);

    const rerunOnboardingStep = (item: ITenantOnboardingSteps) => {
        const model = {
            tenantId: tenantId ?? '',
            tenantOnboardingStepIds: [item.id],
        };
        if (tenantId && item.id) dispatch(rerunTenantOnboardingStep({ model }));
    };

    useEffect(() => {
        if (tenantId) {
            setTimeout(() => {
                connection
                    .start()
                    .then(() => {
                        const { connectionId } = connection;

                        // Connect to tenant group
                        connection
                            .invoke('AddConnectionToGroupAsync', connectionId, tenantId)
                            .then(() => console.log('Connected to tenant onboarding...'))
                            .catch((err) => console.error(err.toString()));

                        // Listen to tenant onboarding message
                        connection.on('TenantOnboarding', (data) => {
                            console.log('TenantOnboarding fired');
                            dispatch(getTenantOnboardingById({ tenantId }));
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }, 150);
        }
        return () => {
            connection.stop();
        };
    }, [tenantId]);

    const iconColumnClass = mergeStyles({
        fontSize: 15,
        fontWeight: 'bold !important',
        height: 24,
        width: 24,
        padding: '0 5px',
        cursor: 'pointer',
        color: theme.palette.black,
        userSelect: 'none',
    });

    const iconRowClass = mergeStyles({
        fontSize: 15,
        fontWeight: 'bold !important',
        height: 24,
        width: 24,
        padding: '1px 5px',
        cursor: 'pointer',
        color: theme?.palette.black,
        userSelect: 'none',
    });

    const columns: IColumn[] = [
        {
            key: 'displayOrder',
            name: 'Step',
            fieldName: 'displayOrder',
            minWidth: 50,
            maxWidth: 50,
        },
        {
            key: 'all',
            name: 'All',
            fieldName: '',
            minWidth: 25,
            maxWidth: 25,
            onRenderHeader: (props: IDetailsColumnProps | undefined) => {
                return (
                    <Stack horizontalAlign="center" verticalAlign="center">
                        <TooltipHost content={`${!expandAll ? 'Expand' : 'Collapse'} ${props?.column.name}`} id="toggle">
                            <FontIcon
                                iconName={`${!expandAll ? 'ChevronDown' : 'ChevronUp'}`}
                                className={iconColumnClass}
                                onClick={() => {
                                    setExpandAll(!expandAll);
                                    setIndexRow(undefined);
                                }}
                            />
                        </TooltipHost>
                    </Stack>
                );
            },
            onRender: (item, index) => {
                if (item)
                    return (
                        <Stack horizontalAlign="center" verticalAlign="center">
                            <TooltipHost
                                content={`${indexRow !== index && !expandAll ? 'Expand' : 'Collapse'} Procedures`}
                                id="toggle"
                            >
                                <FontIcon
                                    iconName={`${indexRow !== index && !expandAll ? 'ChevronDown' : 'ChevronUp'}`}
                                    className={iconRowClass}
                                    onClick={() => {
                                        setExpandAll(false);
                                        if (indexRow !== index && !expandAll) {
                                            setIndexRow(index);
                                        } else {
                                            setIndexRow(undefined);
                                        }
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    );
            },
        },
        {
            key: 'displayName',
            name: 'Name',
            fieldName: 'displayName',
            minWidth: 200,
            maxWidth: 650,
            isResizable: true,
            isMultiline: true,
            onRender: (item: ITenantOnboardingSteps) => {
                return (
                    <Stack>
                        <Stack.Item>
                            <Text variant="smallPlus">{item.displayName}</Text>
                        </Stack.Item>
                        <Stack wrap>
                            <Text variant="xSmall">
                                <i>{item.description}</i>
                            </Text>
                        </Stack>
                    </Stack>
                );
            },
        },

        {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 50,
            maxWidth: 300,

            onRender: (item) => {
                return <SaveStatusIcon saveStatus={item.status ?? ''} />;
            },
        },
        {
            key: 'lastRunDurationSeconds',
            name: '',
            fieldName: 'lastRunDurationSeconds',
            minWidth: 200,
            onRender: (item) => {
                if (item.processHistories?.length > 0) {
                    const stepIndex = item.processHistories ? item.processHistories?.length - 1 : 0;
                    const lastRan = (flatten(item?.processHistories[stepIndex].steps) as ITenantOnboardingSteps[]) ?? [];
                    const lastRanDate = lastRan.length ? lastRan[0].createdOn : '';

                    if (item.lastRunDurationSeconds) {
                        return (
                            <TooltipHost
                                directionalHint={DirectionalHint.rightCenter}
                                content={
                                    <Stack>
                                        <Text> Last Ran: {format(new Date(lastRanDate ?? ''), 'Pp')} </Text>
                                    </Stack>
                                }
                            >
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                    <IconButton
                                        iconProps={{ iconName: 'Calendar' }}
                                        styles={{ root: { maxHeight: 5, maxWidth: 5 } }}
                                    />
                                    <Text> {formatDistanceToNow(new Date(lastRanDate ?? ''))} ago </Text>
                                </Stack>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                    <IconButton
                                        iconProps={{ iconName: 'Stopwatch' }}
                                        styles={{ root: { maxHeight: 5, maxWidth: 5 } }}
                                    />
                                    <Text> {` ${item.lastRunDurationSeconds.toFixed(2)}s `} </Text>
                                </Stack>
                            </TooltipHost>
                        );
                    }
                    return null;
                }
                return null;
            },
        },
        {
            key: 'reRun',
            name: 'Run',
            fieldName: 'reRun',
            minWidth: 50,
            onRender: (item: ITenantOnboardingSteps) => {
                if (item.status !== SyncStatus.Completed || item.canBeRerun) {
                    return (
                        <IconButton
                            onClick={() => {
                                rerunOnboardingStep(item);
                            }}
                            iconProps={{ iconName: 'Refresh' }}
                        />
                    );
                }
                return null;
            },
        },
    ];

    const onRenderRow = (props?: IDetailsRowProps | undefined): JSX.Element | null => {
        if (props) {
            const steps = props.item.processHistories.map((x: ITenantOnboardingProcessHistory) => x.steps ?? []);
            return (
                <Stack>
                    <DetailsRow {...props} />
                    {(props.itemIndex === indexRow && indexRow !== undefined) || expandAll ? (
                        <OnBoardingStepsList items={flatten(steps)} />
                    ) : null}
                </Stack>
            );
        }
        return null;
    };

    // TODO: Fix the section component to prevent table from being cut off

    if (loading !== LoadingStatus.Completed) {
        return <Spinner />;
    }

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Section
                    heading={'Tenant Integration Steps'}
                    headingCenterContent={
                        <i>Last Ran: {onboarding?.lastRunOn ? format(new Date(onboarding?.lastRunOn), 'Pp') : 'Never'} </i>
                    }
                    preBodyContent={<ConfigVerificationWarning />}
                    headingRightContent={<RunTaskButton />}
                >
                    <Stack>
                        <SortableDetailsList
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            items={onboardingSteps}
                            columns={columns}
                            setKey="column1"
                            onRenderRow={onRenderRow}
                            isHeaderVisible={true}
                            sortOnMount={true}
                        />
                    </Stack>
                </Section>
            </ScrollablePane>
        </Stack>
    );
}

function ConfigVerificationWarning() {
    const { push } = useHistory();
    const { tenantId } = useParams<RouteParams>();
    const onboardingSteps = useSelector(selectTenantOnboardingStepsList);

    const onClick = (e?: MouseEvent) => {
        e?.preventDefault();
        push(`/tenants/${tenantId}`);
    };

    // Change this to a check for the config verification status
    if (!onboardingSteps.length) {
        return (
            <MessageBar messageBarType={MessageBarType.severeWarning}>
                Practice ID has not been verified, please check the{' '}
                <Link onClick={onClick}>
                    <strong>About section</strong>
                </Link>{' '}
                to verify and start the tenant integration steps.
            </MessageBar>
        );
    }
    if (onboardingSteps.every((e) => e.status === 'idle')) {
        return (
            <MessageBar messageBarType={MessageBarType.warning}>
                Integration steps are currently being processed. Please wait a minimum of 3 minutes prior to refreshing or
                re-running any step(s).
            </MessageBar>
        );
    } else {
        return null;
    }
}
