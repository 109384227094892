import { DefaultButton, IDropdownOption, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import { Field } from 'components';
import { useSelector } from 'hooks';
import { map, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTenants } from '../Tenants/state/tenants.actions';
import { updateFeatureFlagTenant } from './state/actions';
import { IFeatureFlagTenantModel } from './state/model';
import { openFeatureFlagTenantModal, selectedFeatureFlag, selectedFeatureFlagTenantListHistory } from './state/selector';
import { setFeatureFlagTenantModal } from './state/slice';
import useValidation, { IValidationConfig, getValidationError } from 'hooks/useValidation';

function AddTeanantModal() {
    const dispatch = useDispatch();
    const isPanelOpen = useSelector(openFeatureFlagTenantModal);
    const tenantList = useSelector(selectedFeatureFlagTenantListHistory);
    const selectFeatureFlag = useSelector(selectedFeatureFlag);

    const [selectedTenantIds, setTenantIds] = useState<string[]>([]);

    useEffect(() => {
        dispatch(getTenants());
    }, []);

    const _onDismiss = () => {
        dispatch(setFeatureFlagTenantModal(false));
        setTenantIds([]);
    };

    const tenantOptions: IDropdownOption[] = tenantList
        ? sortBy(
              map(tenantList, (tenant) => ({
                  key: tenant.id ?? '',
                  text: `${tenant.name} (${tenant.id}) ` ?? '',
              })) ?? [],
              'text',
          )
        : [];

    const onSave = () => {
        const model: IFeatureFlagTenantModel = {
            tenantIds: selectedTenantIds,
            featureFlag: {
                isActive: true,
                isDeleted: false,
            },
        };

        if (selectFeatureFlag) {
            dispatch(setFeatureFlagTenantModal(false));
            dispatch(
                updateFeatureFlagTenant({
                    featureFlagId: selectFeatureFlag.id,
                    model,
                }),
            );
        }
        setTenantIds([]);
    };

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'ids',
            validation: ['required'],
            value: selectedTenantIds,
        },
    ];

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onSave);

    useEffect(() => {
        return () => {
            cleanupErrors();
        };
    }, [isPanelOpen]);

    return (
        <Panel
            headerText={`Add Tenant`}
            isOpen={isPanelOpen}
            isFooterAtBottom
            type={PanelType.medium}
            onDismiss={_onDismiss}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    {/* <ValidationBar errors={errors} /> */}
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton text={'Save'} onClick={onSubmit} />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                    </Stack>
                </Stack>
            )}
        >
            <Stack styles={{ root: { padding: 10 } }} grow>
                <Field.SearchCombo
                    placeholder="(Select Tenant(s))"
                    label="Tenant(s)"
                    multiSelect
                    options={tenantOptions}
                    onChange={(ev, option) => {
                        if (option?.key)
                            setTenantIds((prev) => {
                                if (prev.includes(option.key as string)) {
                                    return prev.filter((x) => x !== option.key);
                                } else {
                                    return [...prev, option.key as string];
                                }
                            });
                    }}
                    selectedKey={selectedTenantIds}
                    errorMessage={getValidationError(errors, 'ids') ? 'Tenant is required' : undefined}
                />
            </Stack>
        </Panel>
    );
}

export default AddTeanantModal;
